import * as React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    reset as resetAction,
    change as changeAction,
    submit as submitAction,
    isSubmitting as isSubmittingStatus
} from 'redux-form'
import {
    SimpleForm,
    LongTextInput,
    SelectInput,
    ReferenceInput,
    GET_ONE,
    crudGetMatching as crudGetMatchingAction,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction,
    refreshView as refreshViewAction,
    showNotification as showNotificationAction
} from 'react-admin'
import { Resources, RoleTypes } from '../../api/api'
import { getFioFromReferencedPerson } from '../../utils/dataHelpers'
import oDataProvider, { customRequest } from '../../api/oDataProvider'

const formName = 'assign-request-form'

const validate = (values) => {
    const errors: any = {}
    return errors
}

class RequestAssignComponent extends React.Component<any, any> {
    private id: number
    state: { request: any }

    constructor(props) {
        super(props)

        this.state = { request: null }
        this.id =
            (props.match.params.id && parseInt(props.match.params.id)) || 0
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        console.log('ASSIGN PAGE PARAMS: ', this.props)
        const { dataProvider } = this.props
        const { data: request } = await oDataProvider(
            GET_ONE,
            Resources.Request,
            { id: this.id }
        )
        this.setState({ request })
    }

    render() {
        return (
            <SimpleForm
                record={{}}
                resource="Request"
                form={formName}
                onSubmit={this.handleSubmit}
            >
                <ReferenceInput
                    label="Исполнитель"
                    source="assigneeId"
                    reference={Resources.CompanyEmployee}
                    resource={Resources.CompanyEmployee}
                    filter={{ $expand: ['Person'] }}
                >
                    <SelectInput optionText={getFioFromReferencedPerson} />
                </ReferenceInput>
                <LongTextInput
                    label="Комментарий"
                    options={{ inputProps: { rows: 10 } }}
                    resettable={true}
                />
            </SimpleForm>
        )
    }

    private async handleSubmit(values) {
        const {
            fetchStart,
            fetchEnd,
            refreshView,
            reset,
            showNotification,
            record
        } = this.props

        fetchStart()
        await customRequest(
            this.props.dispatch,
            '/api/requestcommands/assign',
            { id: values.id, data: { ...values, requestId: record.id } },
            'POST'
        )
            .then(() => {
                this.setState({ showDialog: false })
                reset(formName)
                refreshView()
            })
            .catch((error) => {
                showNotification(error.message, 'error')
            })

        fetchEnd()
    }
}

const mapStateToProps = (state) => ({
    isSubmitting: isSubmittingStatus(formName)(state)
})

export const RequestAssignPage = compose<any>(
    connect(mapStateToProps, (dispatch) => ({
        dispatch,
        change: changeAction,
        crudGetMatching: crudGetMatchingAction,
        fetchEnd: fetchEndAction,
        fetchStart: fetchStartAction,
        refreshView: refreshViewAction,
        reset: resetAction,
        showNotification: showNotificationAction,
        submit: submitAction
    })),
    withRouter
)(RequestAssignComponent)

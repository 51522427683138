import * as React from 'react'
import {
    Button,
    Card,
    CardHeader,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    withStyles
} from '@material-ui/core'
import {
    FileDownload as WidgetIcon,
    CloudDownload as FileIcon
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { translate } from 'react-admin'
import { compose } from 'recompose'
import { CardIcon } from '../cardIcon'
import { Resources } from '../../../api/api'
import { ROOT_URL } from '../../../api/oDataProvider'

const style = (theme) => ({
    root: {
        flex: 1
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right'
    },
    avatar: {
        background: theme.palette.background.avatar
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary
    },
    itemButton: {
        '&:active': {
            color: '#5EC3C3'
        }
    }
})

function MyFilesComponent({ files = [], translate, classes }) {
    return (
        <div className={classes.root}>
            <CardIcon Icon={WidgetIcon} bgColor="#31708f" />
            <Card className={classes.card}>
                <CardHeader title={'Мои файлы'} />
                <List dense={true}>
                    {files.map((record: any, i) => {
                        const linkTo = `${Resources.VersionedFileAttachment}/${record.id}`

                        return (
                            <Link
                                key={`li-${record.id}`}
                                style={{ textDecoration: 'none' }}
                                to={linkTo}
                            >
                                <ListItem
                                    button={true}
                                    component="li"
                                    classes={{
                                        button: classes.itemButton
                                    }}
                                >
                                    <Icon
                                        className="far fa-file"
                                        color="secondary"
                                        fontSize="large"
                                    />
                                    <ListItemText
                                        primary={record.name}
                                        secondary={new Date(
                                            record.createdOn
                                        ).toLocaleString('ru-RU')}
                                    />
                                    <ListItemSecondaryAction
                                        style={{ marginTop: '3px' }}
                                    >
                                        <a
                                            download
                                            href={`${ROOT_URL}/api/filestorage/${record.files[0].internalGuid}`}
                                            onClick={(e) => e.stopPropagation()}
                                            style={{ marginRight: '20px' }}
                                        >
                                            <FileIcon
                                                style={{ color: '#757575' }}
                                            />
                                        </a>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Link>
                        )
                    })}
                </List>
            </Card>
        </div>
    )
}

export const MyFiles = compose(
    withStyles(style as any),
    translate
)(MyFilesComponent)

import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewPhaseStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewPhaseComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        color={'secondary'}
        variant={'contained'}
        to={`/Phase/create?projectId=${record.id}`}
        label="Добавить этап"
        // title="Добавить этап"
    >
        <TemplateIcon />
    </Button>
)
export const AddNewPhaseButton = withStyles(addNewPhaseStyles)(
    AddNewPhaseComponent
)

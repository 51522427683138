import React from 'react'
import {
    FormDataConsumer,
    SimpleForm,
    SelectInput,
    TextInput,
    required,
    CheckboxGroupInput
} from 'react-admin'
import {
    RequestClassifications,
    BankruptcyVariant,
    ExecutiveProceedingVariant,
    NegotiationsAssistanceVariant
} from '../../api/publicOjects'
import {
    Resources,
    eNegotiationsAssistanceVariant,
    RoleTypes
} from '../../api/api'
import { withStyles } from '@material-ui/core'
import { ToolbarGeneral } from '../../layout'
import { SelectAndSearchInput } from '../fields'
import { eRequestClassificationType, ITitle } from '../../api/api'

const styles = {
    input: {
        minWidth: 252
    }
}

const RequestInputBase = (props) => {
    const { isEditToolbar, setTitle, classes, ...rest } = props

    const validate = (values) => {
        let error: any = {}
        return error
    }

    if (isEditToolbar) {
        const titles: Array<ITitle> = []
        titles.push({
            type: 'link',
            href: '/Request',
            value: 'Заявки'
        })
        titles.push({
            type: 'text',
            value: `Редактирование заявки №${props.record.id} - ${props.record.subject}`
        })
        setTitle(titles)
    }

    if (
        !!props.record &&
        !!props.record.classification &&
        !!props.record.classification.isSubsidiaryLiability
    )
        props.record.classification.isSubsidiaryLiability = [true]

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    shouldParse
                    isEditToolbar={isEditToolbar}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <TextInput label="Тема" source="subject" validate={required()} />
            {localStorage.getItem('role') === RoleTypes.Client && (
                <TextInput
                    source="customerId"
                    style={{ display: 'none' }}
                    defaultValue={123}
                ></TextInput>
            )}
            {localStorage.getItem('role') !== RoleTypes.Client && (
                <SelectAndSearchInput
                    label="Клиент"
                    source="customerId"
                    reference={Resources.Customer}
                    optionText="displayName"
                    validate={required()}
                />
            )}
            <SelectInput
                label="Классификация заявки"
                source="classification.type"
                choices={RequestClassifications}
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData }) => {
                    if (
                        !formData.classification ||
                        !formData.classification.type
                    )
                        return <div />

                    if (
                        !!formData.classification &&
                        formData.classification.type ===
                            eRequestClassificationType.bankruptcy
                    ) {
                        return (
                            <div>
                                <SelectInput
                                    label="Вариант банкротства"
                                    source="classification.bankruptcyVariant"
                                    validate={required()}
                                    className={classes.input}
                                    choices={BankruptcyVariant}
                                />
                                <CheckboxGroupInput
                                    label=""
                                    source="classification.isSubsidiaryLiability"
                                    choices={[
                                        {
                                            id: true,
                                            name: 'Субсид. ответственность'
                                        }
                                    ]}
                                />
                            </div>
                        )
                    }

                    if (
                        formData.classification.type ===
                        eRequestClassificationType.executiveProceeding
                    ) {
                        return (
                            <SelectInput
                                label="Отношение"
                                source="classification.executiveProceedingVariant"
                                validate={required()}
                                className={classes.input}
                                choices={ExecutiveProceedingVariant}
                            />
                        )
                    }

                    if (
                        formData.classification.type ===
                        eRequestClassificationType.negotiationsAssistance
                    ) {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 255
                                }}
                            >
                                <SelectInput
                                    label="С кем участие"
                                    source="classification.negotiationsAssistanceVariant"
                                    validate={required()}
                                    className={classes.input}
                                    choices={NegotiationsAssistanceVariant}
                                />
                                {formData.classification
                                    .negotiationsAssistanceVariant ===
                                    eNegotiationsAssistanceVariant.another && (
                                    <TextInput
                                        label="Прочие"
                                        source="classification.anotherVariant"
                                        className={classes.input}
                                    />
                                )}
                            </div>
                        )
                    }

                    return <div />
                }}
            </FormDataConsumer>
            <TextInput
                label="Краткое описание сути запроса"
                source="text"
                validate={required()}
            />
            {}
        </SimpleForm>
    )
}
export const RequestInput = withStyles(styles as any)(RequestInputBase)

import React, { useState, useEffect } from 'react'
import {
    AutocompleteInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    required,
    FormDataConsumer,
    BooleanInput,
    DisabledInput,
    GET_LIST
} from 'react-admin'
import { Resources } from '../../api/api'
import { ToolbarGeneral } from '../../layout'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import Create from '../../layout/create'
import Edit from '../../layout/edit'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warningUr: {
        width: '175%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    input: {
        minWidth: 252
    }
}

const validate = (values) => {
    const errors: any = {}

    if (!values.isCompany && values.personId === undefined)
        errors.personId = 'Необходимо выбрать физ. лицо'
    else if (values.isCompany && values.companyId === undefined)
        errors.companyId = 'Необходимо выбрать компанию'

    return errors
}

const optionRenderer = (choice) =>
    (choice.firstName ? choice.firstName : '') +
    ' ' +
    (choice.lastName ? choice.lastName : '')

const CompanyInputLabel = (props) => {
    return (
        <div className={props.style.warningUr}>
            <div>
                Нет юр. лиц
                *&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;
            </div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const CompanyInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Контакт (физ. лицо) *"
            source="companyId"
            reference="Company"
            optionText="shortName"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.input}
            label={<CompanyInputLabel style={props.classes} />}
        />
    )
}

const PersonInputLabel = (props) => {
    return (
        <div className={props.style.warningUr}>
            <div>
                Нет физ.
                лиц&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;
            </div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const PersonInput = (props) => {
    const { atLeastOne } = props

    return atLeastOne ? (
        <SelectAndSearchInput
            label="Контакт (физ. лицо) *"
            source="personId"
            reference="Person"
            optionText="lastName"
            optionRenderer={optionRenderer}
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.input}
            label={<PersonInputLabel style={props.classes} />}
        />
    )
}

export const CustomerFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOnePerson, setAtLeastOnePerson] = useState(false)
    const [atLeastOneCompany, setAtLeastOneCompany] = useState(false)
    const [isCompany, setIsCompany] = useState(false)

    if (props.record.companyId === null || props.record.companyId === undefined)
        props.record.isCompany = false
    else props.record.isCompany = true

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/Customer',
            value: 'Клиенты'
        })
        titles.push({
            type: 'text',
            value: `Редактирование клиента №${props.record.id} - ${props.record.displayName}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            const action = GET_LIST
            const { data: person } = await oDataProvider(
                action,
                Resources.Person,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: company } = await oDataProvider(
                action,
                Resources.Company,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            person.length > 0
                ? setAtLeastOnePerson(true)
                : setAtLeastOnePerson(false)
            company.length > 0
                ? setAtLeastOneCompany(true)
                : setAtLeastOneCompany(false)
        })()
    })

    return (
        <SimpleForm
            validate={validate}
            {...rest}
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    shouldParse
                    disabled={
                        isCompany ? !atLeastOneCompany : !atLeastOnePerson
                    }
                    {...rest}
                />
            }
        >
            <FormDataConsumer>
                {({ formData }) => (
                    <TextInput
                        style={{ width: 252 }}
                        label="Наименование клиента"
                        source="displayName"
                        validate={required()}
                    />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) => {
                    setIsCompany(formData.isCompany)
                    return (
                        <BooleanInput
                            label={
                                formData.isCompany ? 'Юр. лицо' : 'Физ. лицо'
                            }
                            source={'isCompany'}
                        />
                    )
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) =>
                    !formData.isCompany && (
                        <PersonInput atLeastOne={atLeastOnePerson} {...rest} />
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) =>
                    formData.isCompany && (
                        <CompanyInput
                            atLeastOne={atLeastOneCompany}
                            {...rest}
                        />
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    )
}
export const CustomerForm = withStyles(styles as any)(CustomerFormBase)

const CustomerCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/Customer',
        value: 'Клиенты'
    })
    titles.push({
        type: 'text',
        value: 'Создание клиента'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <CustomerForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const CustomerCreateView = enchanceCreate(CustomerCreateViewBase)

export const CustomerEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <CustomerForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const CustomerEditView = enchanceEdit(CustomerEditViewBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IPhaseModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'

    projectId: 'projectId'
    project: 'project'

    procedureId: 'procedureId'
    procedure: 'procedure'

    tasks: 'tasks'

    phaseTemplateId: 'phaseTemplateId'
    phaseTemplate: 'phaseTemplate'

    status: 'status'

    startDate: 'startDate'
    endDate: 'endDate'
}

const phase: IPhaseModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',

    projectId: 'projectId',
    project: 'project',

    procedureId: 'procedureId',
    procedure: 'procedure',

    tasks: 'tasks',

    phaseTemplateId: 'phaseTemplateId',
    phaseTemplate: 'phaseTemplate',

    status: 'status',

    startDate: 'startDate',
    endDate: 'endDate'
}

export const phaseModel = Object.freeze(phase)

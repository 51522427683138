import {
    RoleTypes,
    InfoRecordTypesValues,
    eRequestClassificationType,
    eBankruptcyVariant,
    eExecutiveProceedingVariant,
    eNegotiationsAssistanceVariant
} from './api'

export const RoleTypeById = {
    1: RoleTypes.Admin,
    2: RoleTypes.Dispatcher,
    3: RoleTypes.Employee,
    4: RoleTypes.Client,
    5: RoleTypes.Consultant
}

export const RoleIdByType = {
    [RoleTypes.Admin]: 1,
    [RoleTypes.Dispatcher]: 2,
    [RoleTypes.Employee]: 3,
    [RoleTypes.Client]: 4,
    [RoleTypes.Consultant]: 5
}

export const ContactInfoTypes = [
    { id: InfoRecordTypesValues.Phone, name: 'Телефон' },
    { id: InfoRecordTypesValues.Fax, name: 'Факс' },
    { id: InfoRecordTypesValues.Address, name: 'Адрес' },
    { id: InfoRecordTypesValues.Email, name: 'Email' },
    { id: InfoRecordTypesValues.Skype, name: 'Skype' },
    { id: InfoRecordTypesValues.Whatsapp, name: 'WhatsApp' },
    { id: InfoRecordTypesValues.Viber, name: 'Viber' },
    { id: InfoRecordTypesValues.Telegram, name: 'Telegram' },
    { id: InfoRecordTypesValues.Facebook, name: 'Facebook' },
    { id: InfoRecordTypesValues.Google, name: 'Google' },
    { id: InfoRecordTypesValues.Vkontakte, name: 'VK' },
    { id: InfoRecordTypesValues.Odnoklassniki, name: 'Одноклассники' },
    { id: InfoRecordTypesValues.Icq, name: 'ICQ' }
]

export const Roles = [
    { id: RoleTypes.Client, name: 'Клиент' },
    { id: RoleTypes.Admin, name: 'Администратор' },
    { id: RoleTypes.Dispatcher, name: 'Диспетчер' },
    { id: RoleTypes.Dispatcher, name: 'Сотрудник' },
    { id: RoleTypes.Employee, name: 'Консультант' }
]

export const RolesId = [
    { id: RoleIdByType[RoleTypes.Client], name: 'Клиент' },
    { id: RoleIdByType[RoleTypes.Admin], name: 'Администратор' },
    { id: RoleIdByType[RoleTypes.Dispatcher], name: 'Диспетчер' },
    { id: RoleIdByType[RoleTypes.Employee], name: 'Сотрудник' },
    { id: RoleIdByType[RoleTypes.Consultant], name: 'Консультант' }
]

export const RequestClassifications = [
    { id: eRequestClassificationType.bankruptcy, name: 'Банкротство' },
    { id: eRequestClassificationType.arbitration, name: 'Арбитраж' },
    {
        id: eRequestClassificationType.crisisManagement,
        name: 'Антикризисное управление'
    },
    {
        id: eRequestClassificationType.executiveProceeding,
        name: 'Исполнительное производство'
    },
    {
        id: eRequestClassificationType.negotiationsAssistance,
        name: 'Помощь в переговорах'
    }
]

export const requestTypeEnum = {
    Bankruptcy: 'Банкротство',
    Arbitration: 'Арбитраж',
    CrisisManagement: 'Антикризисное управление',
    ExecutiveProceeding: 'Исполнительное производство',
    NegotiationsAssistance: 'Помощь в переговорах'
}

export const bankruptcyVariantEnum = {
    Creditor: 'Я кредитор',
    Debtor: 'Я должник'
}

export const executiveProceedingVariantEnum = {
    Debtor: 'Я должник',
    Claimant: 'Я взыскатель'
}

export const negotiationsAssistanceVariantEnum = {
    Creditor: 'С кредитором',
    Debtor: 'С должником',
    Investor: 'С инвестором',
    Another: 'Прочие'
}

export const RequestStatuses = [
    { id: 'New', name: 'Новый' },
    { id: 'Processing', name: 'В обработке' },
    { id: 'Completed', name: 'Завершена' },
    { id: 'Rejected', name: 'Отклонена' }
]

export const BankruptcyVariant = [
    { id: eBankruptcyVariant.creditor, name: bankruptcyVariantEnum.Creditor },
    { id: eBankruptcyVariant.debtor, name: bankruptcyVariantEnum.Debtor }
]

export const ExecutiveProceedingVariant = [
    {
        id: eExecutiveProceedingVariant.debtor,
        name: executiveProceedingVariantEnum.Debtor
    },
    {
        id: eExecutiveProceedingVariant.claimant,
        name: executiveProceedingVariantEnum.Claimant
    }
]

export const NegotiationsAssistanceVariant = [
    {
        id: eNegotiationsAssistanceVariant.debtor,
        name: negotiationsAssistanceVariantEnum.Debtor
    },
    {
        id: eNegotiationsAssistanceVariant.creditor,
        name: negotiationsAssistanceVariantEnum.Creditor
    },
    {
        id: eNegotiationsAssistanceVariant.investor,
        name: negotiationsAssistanceVariantEnum.Investor
    },
    {
        id: eNegotiationsAssistanceVariant.another,
        name: negotiationsAssistanceVariantEnum.Another
    }
]

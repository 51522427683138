import * as React from 'react'
import {
    SimpleForm,
    TextInput,
    TextField,
    LongTextInput,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    DateInput
} from 'react-admin'
import { parse } from 'query-string'
import { Resources } from '../../api/api'
import Edit from '../../layout/edit'
import Create from '../../layout/create'

const validate = (values) => {
    const erros: any = {}
    return erros
}

const ProcedureForm = (props: any) => (
    <SimpleForm validate={validate} {...props}>
        <TextInput source={'title'} label={'Название'} />
        <LongTextInput
            source={'description'}
            label={'Описание'}
            options={{ inputProps: { rows: 10 } }}
        />

        <DateInput
            label="Дата начала"
            source="startDate"
            options={{ dateFormat: 'yyyy-MM-dd' }}
        />
        <DateInput
            label="Дата завершения"
            source="endDate"
            options={{ dateFormat: 'yyyy-MM-dd' }}
        />

        {!props.record.projectId && (
            <ReferenceInput
                label="Проект"
                source="projectId"
                reference={Resources.Project}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        )}

        {props.record.projectId && (
            <ReferenceField
                label="Проект"
                source="projectId"
                reference={Resources.Project}
            >
                <TextField source="name" />
            </ReferenceField>
        )}

        {props.record.procedureTemplateId && (
            <ReferenceField
                label="Создана из шаблона"
                source="procedureTemplateId"
                reference={Resources.ProcedureTemplate}
            >
                <TextField source="title" />
            </ReferenceField>
        )}
    </SimpleForm>
)

export const ProcedureCreateView = (props: any) => {
    const { projectId: projectIdString } = parse(props.location.search)
    const projectId = projectIdString
        ? parseInt(projectIdString.toString(), 10)
        : ''
    const redirect = projectId ? `/Project/${projectId}/procedures` : false

    return (
        <Create title="Создание процедуры" {...props}>
            <ProcedureForm
                {...props}
                defaultValue={{ projectId: projectId }}
                redirect={redirect}
            />
        </Create>
    )
}

export const ProcedureEditView = (props: any) => (
    <Edit title="Редактирование процедуры" {...props}>
        <ProcedureForm {...props} />
    </Edit>
)

import * as React from 'react'
import {
    Authenticated,
    GET_LIST,
    Responsive,
    withDataProvider,
    WithPermissions
} from 'react-admin'
import { MyNotifications } from './widgets/myNotifications'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { createStyles, withStyles } from '@material-ui/core'
import { MyProjects } from './widgets/myProjects'
import { MyTasks } from './widgets/myTasks'
import { MyFiles } from './widgets/myFiles'
import { titleAction } from '../../reducers'
import { FabDashboard } from './widgets/Fab'
import { RoleTypes } from '../../api/api'

const styles = (theme) =>
    createStyles({
        flex: {
            display: 'flex'
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column'
        },
        widgetsFlex: {
            display: 'flex',
            flexDirection: 'column'
        },
        leftCol: {
            flex: 1,
            marginRight: '1em'
        },
        rightCol: {
            flex: 1,
            marginLeft: '1em'
        },
        singleCol: {
            marginTop: '2em',
            marginBottom: '2em'
        },
        widgetItem: {
            width: '100%',
            minWidth: 400,
            maxWidth: 600,
            marginBottom: '2em',
            [theme.breakpoints.up('md')]: {
                marginRight: '1em'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '2em'
            }
        },
        fab: {
            position: 'fixed',
            bottom: '30px',
            right: '30px'
        }
    })

class DashboardComponent extends React.Component<any, any> {
    state = { projects: [], tasks: [], messages: [], files: [] }

    constructor(props) {
        super(props)
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Главная'
        })
        props.setTitle(titles)
    }

    componentDidMount(): void {
        this.fetchData()
    }

    fetchData() {
        this.fetchProjects()
        this.fetchTasks()
        this.fetchNotifications()
        this.fetchDocuments()
    }

    async fetchProjects() {
        const { dataProvider } = this.props
        const { data: projects } = await dataProvider(GET_LIST, 'Project', {
            filter: null,
            sort: { field: 'Id', order: 'DESC' },
            pagination: { page: 1, perPage: 10 }
        })

        this.setState({ projects })
    }

    async fetchTasks() {
        const { dataProvider } = this.props
        const { data: tasks } = await dataProvider(GET_LIST, 'ProjectTask', {
            filter: null,
            sort: { field: 'Id', order: 'DESC' },
            pagination: { page: 1, perPage: 10 }
        })

        this.setState({ tasks })
    }

    async fetchNotifications() {
        const { dataProvider } = this.props
        const { data: messages } = await dataProvider(
            GET_LIST,
            'Notification',
            {
                sort: { field: 'Id', order: 'DESC' },
                pagination: { page: 1, perPage: 10 }
            }
        )

        this.setState({ messages })
    }

    async fetchDocuments() {
        const { dataProvider } = this.props
        const id: any = localStorage.getItem('id')
        const { data: files } = await dataProvider(
            GET_LIST,
            'VersionedFileAttachment',
            {
                filter: { ownerUserId: parseInt(id) },
                sort: { field: 'Id', order: 'DESC' },
                pagination: { page: 1, perPage: 10 }
            }
        )

        this.setState({ files })
    }

    render(): React.ReactNode {
        const { projects, tasks, messages, files } = this.state
        const { classes, location } = this.props
        return (
            <Authenticated location={location}>
                <WithPermissions
                    render={({ permissions }) => (
                        <Responsive
                            xsmall={
                                <div>
                                    <div className={classes.flexColumn}>
                                        <div className={classes.widgetsFlex}>
                                            <div className={classes.widgetItem}>
                                                <MyProjects
                                                    projects={projects}
                                                    permissions={permissions}
                                                />
                                            </div>
                                            {permissions ===
                                                RoleTypes.Admin && (
                                                <div
                                                    className={
                                                        classes.widgetItem
                                                    }
                                                >
                                                    <MyTasks projects={tasks} />
                                                </div>
                                            )}
                                            <div className={classes.widgetItem}>
                                                <MyNotifications
                                                    messages={messages}
                                                />
                                            </div>
                                            <div className={classes.widgetItem}>
                                                <MyFiles files={files} />
                                            </div>
                                        </div>
                                    </div>
                                    <FabDashboard classes={classes} />
                                </div>
                            }
                            small={
                                <div>
                                    <div className={classes.flexColumn}>
                                        <div className={classes.widgetsFlex}>
                                            <div className={classes.widgetItem}>
                                                <MyProjects
                                                    projects={projects}
                                                    permissions={permissions}
                                                />
                                            </div>
                                            {permissions ===
                                                RoleTypes.Admin && (
                                                <div
                                                    className={
                                                        classes.widgetItem
                                                    }
                                                >
                                                    <MyTasks projects={tasks} />
                                                </div>
                                            )}
                                            <div className={classes.widgetItem}>
                                                <MyNotifications
                                                    messages={messages}
                                                />
                                            </div>
                                            <div className={classes.widgetItem}>
                                                <MyFiles files={files} />
                                            </div>
                                        </div>
                                    </div>
                                    <FabDashboard classes={classes} />
                                </div>
                            }
                            medium={
                                <div className={classes.flex}>
                                    <div className={classes.leftCol}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <div className={classes.widgetItem}>
                                                <MyProjects
                                                    projects={projects}
                                                    permissions={permissions}
                                                />
                                            </div>
                                            {permissions ===
                                                RoleTypes.Admin && (
                                                <div
                                                    className={
                                                        classes.widgetItem
                                                    }
                                                >
                                                    <MyTasks projects={tasks} />
                                                </div>
                                            )}
                                            <div className={classes.widgetItem}>
                                                <MyNotifications
                                                    messages={messages}
                                                />
                                            </div>
                                            <div className={classes.widgetItem}>
                                                <MyFiles files={files} />
                                            </div>
                                        </div>
                                    </div>
                                    <FabDashboard classes={classes} />
                                </div>
                            }
                        />
                    )}
                />
            </Authenticated>
        )
    }
}

const mapStateToProps = (state) => ({
    version: state.admin.ui.viewVersion
})

export const Dashboard = compose<any>(
    connect(mapStateToProps, { setTitle: titleAction }),
    withDataProvider,
    withStyles(styles),
    withRouter
)(DashboardComponent)

import { standardFieldsModel, IStandardFieldsModel } from './'

export interface IUserFavoriteIgnoreFilterModel extends IStandardFieldsModel {
    userId: 'userId'
    user: 'user'

    projectId: 'projectId'
    project: 'project'

    projectTaskId: 'projectTaskId'
    projectTask: 'projectTask'

    personId: 'personId'
    person: 'person'

    requestId: 'requestId'
    request: 'request'

    versionedFileId: 'versionedFileId'
    versionedFile: 'versionedFile'

    isHide: 'isHide'
    isFavorite: 'isFavorite'
}

const userFavoriteIgnoreFilter: IUserFavoriteIgnoreFilterModel = {
    ...standardFieldsModel,

    userId: 'userId',
    user: 'user',

    projectId: 'projectId',
    project: 'project',

    projectTaskId: 'projectTaskId',
    projectTask: 'projectTask',

    personId: 'personId',
    person: 'person',

    requestId: 'requestId',
    request: 'request',

    versionedFileId: 'versionedFileId',
    versionedFile: 'versionedFile',

    isHide: 'isHide',
    isFavorite: 'isFavorite'
}

export const userFavoriteIgnoreFilterModel = Object.freeze(
    userFavoriteIgnoreFilter
)

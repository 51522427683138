import React from 'react'
import {
    BooleanField,
    EditButton,
    ShowButton,
    Datagrid,
    DateField,
    DeleteButton,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    TextField,
    TabbedForm,
    FormTab,
    Pagination,
    Loading,
    ArrayField,
    WithPermissions
} from 'react-admin'
import {
    LetterTypeField,
    LetterStatusField,
    PersonField
} from '../lettersOfAuthorityList/lettersOfAuthorityList'
import { CardActions } from '@material-ui/core'
import { UserFullNameField } from '../fields'
import { AddDocumentButton } from '../documents/addDocumentButton'
import { checkPermissionsEmployeeAndUp } from '../auth/authHelpers'
import { FileLink } from '../fields'
import { ProjectStatuses } from './projectForm'
import { CourtCaseInfoDetails } from '../courtCase/courtCaseInfoDetails'
import { AccessTypes } from './projectParticipantForm'
import { AddNewTaskButton } from '../tasks/addNewTaskButton'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources } from '../../api/api'
import { ActionGeneral } from '../../layout'
import { AddNewProcedureButton } from '../tasks/addNewProcedureButton'
import { AddNewPhaseButton } from '../tasks/addNewPhaseButton'
import { AddNewLetterButton } from '../lettersOfAuthorityList/addNewLetterButton'
import Timeline from '../timeline/timeline'
import CommentsBlock from '../comments/comments'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right'
}

const setNavbarTitle = (props) => {
    const { setTitle, record } = props
    const titles: Array<object> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/Project',
        value: 'Проекты'
    })
    titles.push({
        type: 'text',
        value: `Карта проекта №${record.id} - ${record.name}`
    })
    setTitle(titles)
}

const ProjectShowBase = (props) => {
    setNavbarTitle(props)
    const id = Number(localStorage.getItem('id'))
    let isVO = false
    if (!!props.record.participants) {
        for (const participant of props.record.participants) {
            const isID = participant.userId === id
            const isType = participant.accessType === 'Viewer'
            if (isID && isType) {
                isVO = true
            }
        }
    }
    console.log(props)

    return (
        <TabbedForm toolbar={null} {...props}>
            <FormTab label="Проект">
                <TextField label="Имя" source="name" />
                <TextField label="Уникальный номер проекта" source="number" />
                <SelectField
                    label="Статус проекта"
                    source="status"
                    choices={ProjectStatuses}
                />
                <ReferenceField
                    label="Клиент"
                    source="customerId"
                    reference={Resources.Customer}
                    linkType={false}
                >
                    <TextField source="displayName" />
                </ReferenceField>
                <TextField label="Бюджет" source="budget" />
                <ReferenceManyField
                    label="Судебные дела"
                    target="projectId"
                    reference={Resources.ProjectCourtCase}
                    styles={{ width: '100%' }}
                >
                    <Datagrid expand={<CourtCaseInfoDetails />}>
                        <TextField label="Номер дела" source="caseNumber" />
                        <TextField label="Тип" source="caseType" />
                        <DateField label="Дата" source="startDate" />
                        <BooleanField label="Завершено" source="finished" />
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                    label="Активные задачи"
                    target="projectId"
                    reference={Resources.ProjectTask}
                >
                    <Datagrid>
                        <TextField label="Название задачи" source="title" />
                        <DateField label="Дата начала" source="startDate" />
                        <DateField label="Дата завершения" source="endDate" />
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                    label="Участники"
                    reference={Resources.ProjectParticipant}
                    target="projectId"
                    allowEmpty={true}
                >
                    <Datagrid>
                        <TextField
                            label="Фамилия"
                            source="user.person.lastName"
                        />
                        <TextField label="Имя" source="user.person.firstName" />
                        <TextField
                            label="Отчество"
                            source="user.person.patronymicName"
                        />
                        <SelectField
                            label="Доступ"
                            source="accessType"
                            choices={AccessTypes}
                        />
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                    label="Заявки"
                    reference={Resources.Request}
                    target="projectId"
                    allowEmpty={true}
                >
                    <Datagrid
                        rowClick={(id, basePath, _) => `${basePath}/${id}`}
                    >
                        <TextField label="Номер" source="id" />
                        <TextField label="Тема" source="subject" />
                        <TextField label="Текст" source="text" />
                        <DateField label="Подана" source="createdOn" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Комментарии" path="comments">
                <CommentsBlock commentListId={props.record.commentListId} />
            </FormTab>
            <FormTab label="Файлы" path="files">
                {isVO && <AddDocumentButton {...props} />}
                <ReferenceManyField
                    addLabel={false}
                    filter={{ ownerProjectId: props.record.id }}
                    target="ownerProjectId"
                    reference={Resources.VersionedFileAttachment}
                    linkType={false}
                >
                    <Datagrid rowClick="edit">
                        <TextField label="Документ" source="name" />
                        <DateField label="Дата документа" source="atDate" />
                        <DateField label="Дата создания" source="createdOn" />
                        <DateField
                            label="Дата последнего обновления"
                            source="updatedOn"
                        />
                        <FileLink />
                        <ReferenceField
                            label="Автор"
                            source="createdById"
                            reference={Resources.AppUser}
                            allowEmpty={true}
                            linkType={false}
                        >
                            <UserFullNameField />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Задачи" path="tasks">
                {isVO && checkPermissionsEmployeeAndUp(null) ? (
                    <AddNewTaskButton {...props} />
                ) : null}

                <ReferenceManyField
                    addLabel={false}
                    target="projectId"
                    reference={Resources.ProjectTask}
                >
                    <Datagrid>
                        <TextField label="Название задачи" source="title" />
                        <DateField label="Дата начала" source="startDate" />
                        <DateField label="Дата завершения" source="endDate" />
                        <ReferenceField
                            label="Процедура"
                            source="procedureId"
                            reference={Resources.Procedure}
                            allowEmpty={true}
                        >
                            <TextField source="title" />
                        </ReferenceField>
                        <ReferenceField
                            label="Этап"
                            source="phaseId"
                            reference={Resources.Phase}
                            allowEmpty={true}
                        >
                            <TextField source="title" />
                        </ReferenceField>
                        {checkPermissionsEmployeeAndUp(null) ? (
                            <ShowButton />
                        ) : null}
                        {checkPermissionsEmployeeAndUp(null) ? (
                            <EditButton />
                        ) : null}
                        {checkPermissionsEmployeeAndUp(null) ? (
                            <DeleteButton redirect={props.location.pathname} />
                        ) : null}
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Процедуры" path="procedures">
                <WithPermissions
                    render={({ permissions }) =>
                        checkPermissionsEmployeeAndUp(permissions) ? (
                            <AddNewProcedureButton {...props} />
                        ) : null
                    }
                />

                <ReferenceManyField
                    addLabel={false}
                    target="projectId"
                    reference="Procedure"
                >
                    <Datagrid>
                        <TextField label="Название процедуры" source="title" />
                        <DateField label="Дата начала" source="startDate" />
                        <DateField label="Дата завершения" source="endDate" />
                        <DeleteButton redirect={props.location.pathname} />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Этапы" path="phases">
                <WithPermissions
                    render={({ permissions }) =>
                        checkPermissionsEmployeeAndUp(permissions) ? (
                            <AddNewPhaseButton {...props} />
                        ) : null
                    }
                />

                <ReferenceManyField
                    addLabel={false}
                    target="projectId"
                    reference="Phase"
                >
                    <Datagrid>
                        <TextField label="Название этапа" source="title" />
                        <DateField label="Дата начала" source="startDate" />
                        <DateField label="Дата завершения" source="endDate" />
                        <DeleteButton redirect={props.location.pathname} />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Доверенности" path="lettersOfAuthority">
                <WithPermissions
                    render={({ permissions }) =>
                        checkPermissionsEmployeeAndUp(permissions) ? (
                            <AddNewLetterButton {...props} />
                        ) : null
                    }
                />
                <ArrayField source="lettersOfAuthority" label="">
                    <Datagrid
                        rowClick={(data) =>
                            `/LetterOfAuthority/${JSON.parse(data).id}/show`
                        }
                    >
                        <TextField label="Номер" source="number" />
                        <ReferenceField
                            label="Проект"
                            source="projectId"
                            reference={Resources.Project}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField label="Имя" source="name" />
                        <DateField label="Дата выдачи" source="issuedAt" />
                        <DateField label="Дата истечения" source="expireAt" />
                        <LetterStatusField label="Статус" />
                        <LetterTypeField label="Тип" />
                        <ReferenceField
                            label="Классификация"
                            source="classificationDictValueId"
                            reference={
                                Resources.LetterOfAuthorityClassificationDictValue
                            }
                            linkType="show"
                        >
                            <TextField source="value" />
                        </ReferenceField>
                        <ReferenceField
                            label="Место предъявления"
                            source="requesterDictValueId"
                            reference={
                                Resources.LetterOfAuthorityRequesterDictValue
                            }
                            linkType="show"
                        >
                            <TextField source="value" />
                        </ReferenceField>
                        <ReferenceField
                            label="Доверенное лицо"
                            source="toPersonId"
                            reference={Resources.Person}
                        >
                            <PersonField />
                        </ReferenceField>
                        <ReferenceField
                            label="Доверитель"
                            source="personTrusteeId"
                            reference={Resources.Person}
                        >
                            <PersonField />
                        </ReferenceField>
                    </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="Таймлайн" path="timeline">
                <Timeline projectId={parseInt(props.id)} />
            </FormTab>
        </TabbedForm>
    )
}

export const ProjectShowStd = (props) => {
    const { ...rest } = props

    return (
        <Show
            {...rest}
            className="test"
            actions={
                checkPermissionsEmployeeAndUp(props.permissions) ? (
                    <ActionGeneral style={cardActionStyle} {...rest} />
                ) : null
            }
        >
            <ProjectShowBase {...rest} />
        </Show>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ProjectShow = enchanceEdit(ProjectShowStd)

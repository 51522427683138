import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILookupValueRequirementModel extends IStandardFieldsModel {
    requirementName: 'requirementName'
    lookupValueId: 'lookupValueId'
    lookupValue: 'lookupValue'
}

const lookupValueRequirement: ILookupValueRequirementModel = {
    ...standardFieldsModel,
    requirementName: 'requirementName',
    lookupValueId: 'lookupValueId',
    lookupValue: 'lookupValue'
}

export const lookupValueRequirementModel = Object.freeze(lookupValueRequirement)

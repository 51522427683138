import * as React from 'react'
import { withRouter } from 'react-router-dom'
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    NumberInput,
    SelectField,
    DateTimeInput,
    WithPermissions,
    SelectInput,
    ReferenceField
} from 'react-admin'
import { requestModel as rm } from '../../api/models'
import { FullNameField } from '../fields'
import { checkPermissionsEmployeeAndUp } from '../auth/authHelpers'
import { makeStyles } from '@material-ui/styles'
import { Add as AddIcon, Close as CancelIcon } from '@material-ui/icons/'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { RequestFavoriteListActions } from '../../layout/favoriteHidenToobars'
import { AddToHide, AddToFavorite } from '../fields'
import { Resources, eRequestStatus } from '../../api/api'
import { RequestStatuses } from '../../api/publicOjects'

const filterStyles = makeStyles({
    header: {
        backgroundColor: '#ccc'
    }
})

export const getViewableTime = (time: string): string => {
    const splited = time.split('T')
    const temp = splited[1].split(':')
    const hm = `${temp[0]}:${temp[1]}`
    let sign = ''
    const timeZone = time.includes('+')
    timeZone ? (sign = '+') : (sign = '-')
    const timeZoneValue = time.split(sign)[1]
    return `${splited[0].replaceAll('-', '.')}, ${hm}${sign}${timeZoneValue}`
}

const RequestsFilter = (props: any) => {
    const classes = filterStyles()
    return (
        <Filter {...props} classes={{ header: classes.header }}>
            <TextInput
                label="Тема"
                source={`$contains(${rm.subject})`}
                alwaysOn
            />
            <TextInput label="Компания" source={`$contains(${rm.company})`} />
            <TextInput label="Эл. адрес" source={`$contains(${rm.email})`} />
            <TextInput label="Телефон" source={`$contains(${rm.phone})`} />
            <TextInput
                label="Другие контактные данные"
                source={`$contains(${rm.other})`}
            />
            <TextInput source={`$contains(${rm.text})`} label="Компания" />
            <SelectInput
                label="Статус"
                source={rm.status}
                choices={RequestStatuses}
                allowEmpty={false}
                resettable
            />
            <DateTimeInput
                source={`$date(${rm.createdOn})`}
                label="Дата создания"
            />
            <NumberInput source={rm.id} label="Номер" />
        </Filter>
    )
}

const NavigateToAssignRequestButtonCmp = (props) => {
    if (!!props.record && props.record.status !== eRequestStatus.New)
        return null
    return (
        <WithPermissions
            render={({ permissions }) =>
                checkPermissionsEmployeeAndUp(permissions) ? (
                    <Button
                        onClick={(evt) => {
                            evt.stopPropagation()
                            const id = props.record.id || props.id
                            const subject = props.record.subject
                            props.history.push(
                                `/${Resources.RequestCommand}/create?commandType=Assign&requestId=${id}&subject=${subject}`
                            )
                        }}
                        color="primary"
                        size="small"
                    >
                        <AddIcon />
                        &nbsp;Принять
                    </Button>
                ) : null
            }
        />
    )
}
export const NavigateToAssignRequestButton = withRouter(
    NavigateToAssignRequestButtonCmp
)

const NavigateToDeclineRequestButtonCmp = (props) => {
    if (!!props.record && props.record.status !== eRequestStatus.New)
        return null
    return (
        <WithPermissions
            render={({ permissions }) =>
                checkPermissionsEmployeeAndUp(permissions) ? (
                    <Button
                        onClick={(evt) => {
                            evt.stopPropagation()
                            const id = props.record.id || props.id
                            const subject = props.record.subject
                            props.history.push(
                                `/RequestCommand/create?commandType=Decline&requestId=${id}&subject=${subject}`
                            )
                        }}
                        color="secondary"
                        size="small"
                    >
                        <CancelIcon />
                        &nbsp;Отклонить
                    </Button>
                ) : null
            }
        />
    )
}
export const NavigateToDeclineRequestButton = withRouter(
    NavigateToDeclineRequestButtonCmp
)

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    },
    acceptButton: {
        maxWidth: '4em'
    }
})

const IdDatetime = (props) => {
    const { id, createdOn } = props.record

    if (!createdOn) return null
    return <span>{`№${id} - ${getViewableTime(createdOn)}`}</span>
}
IdDatetime.defaultProps = { label: 'Номер - время создания' }

export const RequestListBase = (props: any) => {
    console.log(props)
    const classes = useStyles()
    const { setTitle, isFavorite, isHidden, ...rest } = props
    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Заявки'
    })
    setTitle(titles)

    const filter: any = {}
    if (isFavorite) filter.isFavorite = true
    if (isHidden) filter.isHide = true

    return (
        <List
            {...rest}
            title="Заявки"
            sort={{ field: rm.id, order: 'DESC' }}
            filters={<RequestsFilter />}
            actions={<RequestFavoriteListActions />}
            filter={filter}
            exporter={false}
        >
            <Datagrid rowClick="show" classes={{ rowEven: classes.evenRow }}>
                <IdDatetime />
                <SelectField
                    label="Статус"
                    source={rm.status}
                    choices={RequestStatuses}
                />
                <TextField source={rm.subject} label={'Тема'} />
                <FullNameField label={'ФИО'} />
                <ReferenceField
                    label="Клиент"
                    reference={Resources.Customer}
                    source={rm.customerId}
                    allowEmpty
                >
                    <TextField source="displayName" />
                </ReferenceField>
                <TextField label="Эл. адрес" source={rm.email} />
                <TextField label="Телефон" source={rm.phone} />
                <TextField label="Другие контактные данные" source={rm.other} />
                <AddToFavorite />
                <AddToHide />
                <NavigateToAssignRequestButton />
                <NavigateToDeclineRequestButton />
            </Datagrid>
        </List>
    )
}

export const RequestList = connect(
    (state: any) => ({
        theme: state.themeContain,
        themeType: state.theme,
        isFavorite: state.isFavoriteRequest,
        isHidden: state.isHiddenRequest
    }),
    {
        setTitle: titleAction
    }
)(RequestListBase)

import * as React from 'react'
import ErrorBoundary from './errorBoundary'

export const catchError = (WrappedComponent) => {
    return class extends React.Component<any, any> {
        public render() {
            return (
                <ErrorBoundary>
                    <WrappedComponent {...this.props} />
                </ErrorBoundary>
            )
        }
    }
}

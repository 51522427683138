import { CREATE } from 'react-admin'

export const addUploadFeature = (requestHandler) => (
    type,
    resource,
    params
) => {
    if (type === CREATE && resource === 'Document') {
        console.log('---params', params)
    }
    return requestHandler(type, resource, params)
}

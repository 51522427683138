import * as React from 'react'
import {
    companyEmployeeModel as ce,
    personModel as ps,
    appUserModel as au,
    requestCommandModel as rc,
    userRoleModel as ur
} from '../../../api/models'
import { SimpleForm, LongTextInput, ReferenceField } from 'react-admin'
import { getFio, getFioFromReferencedPerson } from '../../../utils/dataHelpers'
import { SelectAndSearchInput } from '../../fields'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ToolbarGeneral } from '../../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/'
import { Resources } from '../../../api/api'
import { RoleIdByType } from '../../../api/publicOjects'
import Create from '../../../layout/create'
import qs from 'query-string'

const validate = (values) => {
    const errors: any = {}
    if (!values.employeeId) {
        errors.employeeId = 'Обязан для заполнения'
    }
    return errors
}

const RequestLabelField = (props) => {
    const { isDisableSave, setIsDisabledSave } = props
    setIsDisabledSave(!props.record.customerId)
    return isDisableSave ? (
        <span>
            Невозможно принять заявку №{props.record.id} &laquo;
            {props.record.subject}&raquo;, так как в ней не указан Клиент
        </span>
    ) : (
        <span>
            Заявка №{props.record.id} &laquo;{props.record.subject}&raquo; от{' '}
            {getFio(props.record as any)}
        </span>
    )
}

function RequestCommandForm(props: any) {
    const { commandParams, setTitle, ...rest } = props

    const [isDisabledSave, setIsDisabledSave] = useState(false)

    const byId = (id: number): string => `x/${ur.roleId} eq ${id}`
    const filter = `${ce.person}/${ps.user}/${au.userRoles}/all(x: ${byId(
        RoleIdByType.Сотрудник
    )} or ${byId(RoleIdByType.Диспетчер)} or ${byId(
        RoleIdByType.Консультант
    )}) and ${ce.person}/${ps.user} ne null`

    const redirect = () => {
        props.history.push(`/${Resources.Request}`)
    }
    useEffect(() => {
        async function loader() {
            const titles: Array<any> = []
            titles.push({
                type: 'link',
                href: '/Request',
                value: 'Заявки'
            })
            titles.push({
                type: 'text',
                value: `${
                    commandParams.commandType === 'Assign'
                        ? 'Принятие'
                        : 'Отклонение'
                } заявки №${commandParams.requestId} - ${commandParams.subject}`
            })
            setTitle(titles)
        }
        loader()
    }, [])
    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={false}
                    shouldParse
                    customRedirect={redirect}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <ReferenceField
                label={isDisabledSave ? 'Предупреждение' : 'Клиент'}
                resource={Resources.Request}
                reference={Resources.Request}
                source={rc.requestId}
            >
                <RequestLabelField
                    setIsDisabledSave={setIsDisabledSave}
                    isDisableSave={isDisabledSave}
                />
            </ReferenceField>
            {!isDisabledSave ? (
                <>
                    {commandParams.commandType == 'Assign' && (
                        <SelectAndSearchInput
                            label="Исполнитель"
                            source={rc.employeeId}
                            reference={Resources.CompanyEmployee}
                            filter={{ customFilter: filter }}
                            style={{ width: 255 }}
                            optionRenderer={getFioFromReferencedPerson}
                            required
                        />
                    )}
                    <LongTextInput
                        label={
                            commandParams.commandType == 'Assign'
                                ? 'Комментарий'
                                : 'Причина'
                        }
                        source={rc.comment}
                        options={{ inputProps: { rows: 10 } }}
                        resettable={true}
                    />
                </>
            ) : null}
        </SimpleForm>
    )
}

const RequestCommandViewCmp = (props: any) => {
    const parameters = qs.parse(props.location.search)
    if (!parameters || !parameters.requestId) {
        return null
    }

    return (
        <Create
            title={
                parameters.commandType == 'Assign'
                    ? 'Заявки | Принять заявку'
                    : 'Заявки | Отклонить заявку'
            }
            {...props}
        >
            <RequestCommandForm {...props} commandParams={parameters} />
        </Create>
    )
}

const RequestCommandViewBase = withRouter(RequestCommandViewCmp)

export const RequestCommandView = connect(undefined, {
    setTitle: titleAction
})(RequestCommandViewBase)

import * as React from 'react'
import {
    ReferenceField,
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'
import { FullNameField } from '../fields/fullNameField'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ProjectParticipantsFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            label="Фамилия"
            source="$startsWith(user/person/lastName)"
            reference={Resources.Person}
            alwaysOn
        >
            <TextInput optionText="lastName" />
        </ReferenceInput>
        <ReferenceInput
            label="Имя"
            source="$startsWith(user/person/firstName)"
            reference={Resources.Person}
            alwaysOn
        >
            <TextInput optionText="firstName" />
        </ReferenceInput>
        <ReferenceInput
            label="Отчество"
            source="$startsWith(user/person/patronymicName)"
            reference={Resources.Person}
            alwaysOn
        >
            <TextInput optionText="patronymicName" />
        </ReferenceInput>
    </Filter>
)

export const ProjectParticipantListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Участники'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<ProjectParticipantsFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <ReferenceField
                    label="Участник"
                    source="user.person.id"
                    reference={Resources.Person}
                >
                    <FullNameField />
                </ReferenceField>
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    resource="Project"
                    reference={Resources.Project}
                    linkType="show"
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ProjectParticipantList = enchanceEdit(ProjectParticipantListBase)

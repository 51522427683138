const IS_IGNORE_PROJECT_TASK_FILTERS = 'SET_IS_IGNORE_PROJECT_TASK_FILTERS'
const initialState = false

export const isIgnoreProjectTaskFiltersAction = (
    isIgnoreProjectTaskFilters
) => {
    const action = {
        type: IS_IGNORE_PROJECT_TASK_FILTERS,
        isIgnoreProjectTaskFilters
    }
    return action
}

export const isIgnoreProjectTaskFiltersReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_IGNORE_PROJECT_TASK_FILTERS
        ? action.isIgnoreProjectTaskFilters
        : priviousState

import * as React from 'react'
import { Create } from 'react-admin'
import { DefaultFormActions } from './bar'

export default (props) => (
    <Create
        actions={<DefaultFormActions {...props} />}
        undoable={false}
        {...props}
    />
)

import React, { useState, useEffect } from 'react'
import {
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    required,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { Resources } from '../../api/api'
import { ToolbarGeneral } from '../../layout'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import oDataProvider from '../../api/oDataProvider'
import Edit from '../../layout/edit'
import Create from '../../layout/create'

const styles = {
    warning: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

const InvoiceInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>
                Нет счетов *&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;
            </div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const InvoiceInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            className={props.classes.inputs}
            label="Счет"
            source="invoiceId"
            reference="Invoice"
            optionText="title"
            validate={required()}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<InvoiceInputLabel style={props.classes} />}
        />
    )
}

const ProjectInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет проектов *&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProjectInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProjectInputLabel style={props.classes} />}
        />
    )
}

export const paymentFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneInvoice, setAtLeastOneInvoice] = useState(false)
    const [atLeastOneProject, setAtLeastOneProject] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/Payment',
            value: 'Платежи'
        })
        titles.push({
            type: 'text',
            value: `Редактирование платежа №${props.record.id} - счета ${props.record.invoice.title}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: procedure } = await oDataProvider(
                action,
                Resources.Invoice,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: project } = await oDataProvider(
                action,
                Resources.Project,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            procedure.length > 0
                ? setAtLeastOneInvoice(true)
                : setAtLeastOneInvoice(false)
            project.length > 0
                ? setAtLeastOneProject(true)
                : setAtLeastOneProject(false)
            fetchEnd()
        })()
    })
    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    {...rest}
                    disabled={!atLeastOneInvoice || !atLeastOneProject}
                />
            }
            validate={validate}
            {...rest}
        >
            <ProjectInput atLeastOne={atLeastOneProject} {...rest} />
            <NumberInput label="Сумма" source="amount" validate={required()} />
            <NumberInput label="Налог" source="tax" />
            <TextInput
                label="Комментарий"
                source="comment"
                validate={required()}
            />
            <InvoiceInput atLeastOne={atLeastOneInvoice} {...rest} />
        </SimpleForm>
    )
}
export const PaymentForm: any = withStyles(styles as any)(paymentFormBase)

const PaymentCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/Payment',
        value: 'Платежи'
    })
    titles.push({
        type: 'text',
        value: 'Создание платежа'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <PaymentForm isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const PaymentCreateView = enchanceCreate(PaymentCreateViewBase)

const PaymentEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <PaymentForm isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const PaymentEditView = enchanceEdit(PaymentEditViewBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ITaskAssigneeModel extends IStandardFieldsModel {
    taskId: 'taskId'
    projectTask: 'task'

    userId: 'userId'
    user: 'user'

    comment: 'comment'
    hasCompletedTask: 'hasCompletedTask'
    canComplete: 'canComplete'
}

const taskAssignee: ITaskAssigneeModel = {
    ...standardFieldsModel,
    taskId: 'taskId',
    projectTask: 'task',

    userId: 'userId',
    user: 'user',

    comment: 'comment',
    hasCompletedTask: 'hasCompletedTask',
    canComplete: 'canComplete'
}

export const taskAssigneeModel = Object.freeze(taskAssignee)

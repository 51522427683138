export class SkipSettings {
    public skip?: number
    public defaultSkip?: number

    public toString(): string {
        return '$skip=' + (this.skip || this.defaultSkip)
    }

    public reset(): void {
        this.skip = undefined
    }

    public isSet(): boolean {
        return !!this.skip || !!this.defaultSkip
    }
}

const IS_FAVORITE_FILE = 'SET_IS_FAVORITE_FILE'
const initialState = false

export const isFavoriteFileAction = (isFavoriteFile) => {
    const action = {
        type: IS_FAVORITE_FILE,
        isFavoriteFile
    }
    return action
}

export const isFavoriteFileReducer = (priviousState = initialState, action) =>
    action.type === IS_FAVORITE_FILE ? action.isFavoriteFile : priviousState

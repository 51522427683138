import React, { useState, useEffect } from 'react'
import {
    DateInput,
    DateTimeInput,
    SimpleForm,
    TextInput,
    SelectInput,
    Create,
    ArrayInput,
    ReferenceInput,
    required,
    BooleanInput,
    SimpleFormIterator,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { ToolbarGeneral } from '../../layout/bar'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import Edit from '../../layout/edit'
import qs from 'query-string'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warning: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

const parseJudges = (v) => {
    console.log('JUDGES PARSE', v)
    return v
}

const formatJudges = (v) => {
    console.log('JUDGES FORMAT', v)
    return v
}

export const CourtCaseParticipantStatuses = [
    { id: 'Plaintiffs', name: 'Истец' },
    { id: 'Respondents', name: 'Ответчик' },
    { id: 'Thirds', name: 'Третье лицо' },
    { id: 'Others', name: 'Другое' }
]

const ProjectInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет проектов *&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProjectInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="По проекту"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProjectInputLabel style={props.classes} />}
        />
    )
}

const CourtCaseFormBase = (props) => {
    const { params, isEditToolbar, setTitle, ...rest } = props
    const redirect =
        params && params.projectId
            ? `/Project/${params.projectId}/court`
            : '/ProjectCourtCase'
    console.log('COURT CASE FORM PARAMS', props, params)

    const [atLeastOneProject, setAtLeastOneProject] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'link',
            href: '/ProjectCourtCase',
            value: 'Дела'
        })
        titles.push({
            type: 'text',
            value: `Редактирование дела №${props.record.id} - тип ${props.record.caseType}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: project } = await oDataProvider(
                action,
                Resources.Project,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            project.length > 0
                ? setAtLeastOneProject(true)
                : setAtLeastOneProject(false)
            fetchEnd()
        })()
    }, [])

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    disabled={!atLeastOneProject}
                    {...rest}
                />
            }
            validate={validate}
            redirect={redirect}
            {...rest}
        >
            <ProjectInput atLeastOne={atLeastOneProject} {...rest} />
            <TextInput
                label="Номер дела"
                source="caseNumber"
                validate={required()}
            />
            <TextInput
                label="Тип дела"
                source="caseType"
                validate={required()}
            />
            <DateInput label="Дата регистрации дела" source="startDate" />
            <BooleanInput label="Закрыто" source="finished" />

            <ArrayInput
                label="Слушания"
                source="courtHearings"
                target="ProjectCourtHearing"
            >
                <SimpleFormIterator>
                    <TextInput label="Место" source="location" />
                    <TextInput label="Информация" source="summary" />
                    <DateTimeInput
                        label="Дата начала"
                        source="start"
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                    <DateTimeInput
                        label="Дата завершения"
                        source="end"
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput
                label="Инстанции"
                source="caseInstances"
                target="ProjectCourtInstance"
            >
                <SimpleFormIterator>
                    <TextInput
                        label="Номер инстанции"
                        source="instanceNumber"
                    />
                    <TextInput label="Имя/наименование" source="name" />
                    <DateInput
                        label="Дата начала"
                        source="startDate"
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                    <ArrayInput
                        label="Судьи"
                        source="judges"
                        format={formatJudges}
                        parse={parseJudges}
                    >
                        <SimpleFormIterator>
                            <TextInput label="ФИО" source={null} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput label="Код суда" source="court.code" />
                    <TextInput label="Наименование суда" source="court.name" />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput
                label="Участники"
                source="participants"
                target="ProjectCourtCaseParticipant"
            >
                <SimpleFormIterator>
                    <SelectInput
                        label="Тип участника"
                        source="participantType"
                        choices={CourtCaseParticipantStatuses}
                        validate={required()}
                    />
                    <TextInput label="Имя/наименование" source="name" />
                    <TextInput label="Адрес" source="address" />
                    <TextInput label="ИНН" source="inn" />
                    <TextInput label="ОГРН" source="ogrn" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    )
}
export const CourtCaseForm = withStyles(styles as any)(CourtCaseFormBase)

const CourtCaseCreateViewCmp = (props: any) => {
    const parameters = qs.parse(props.location.search)
    const projectId =
        parameters && parameters.projectId
            ? parseInt(parameters.projectId as any)
            : null
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'link',
        href: '/ProjectCourtCase',
        value: 'Дела'
    })
    titles.push({
        type: 'text',
        value: 'Создание дела'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <CourtCaseForm
                {...rest}
                params={{ projectId: projectId }}
                isEditToolbar={false}
            />
        </Create>
    )
}
const CourtCaseCreateViewBase = withRouter(CourtCaseCreateViewCmp)
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const CourtCaseCreateView = enchanceCreate(CourtCaseCreateViewBase)

const CourtCaseEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest} filter={{ $expand: 'CourtHearings' }}>
            <CourtCaseForm
                {...props}
                params={{ projectId: props.record }}
                isEditToolbar
            />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const CourtCaseEditView = enchanceEdit(CourtCaseEditViewBase)

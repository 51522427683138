import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectTemplateModel extends IStandardFieldsModel {
    templateName: 'templateName'
    numberTemplate: 'numberTemplate'
    nameTemplate: 'nameTemplate'
    color: 'color'

    procedureTemplates: 'procedures'
    taskTemplates: 'tasks'
    phaseTemplates: 'phases'
}

const projectTemplate: IProjectTemplateModel = {
    ...standardFieldsModel,
    templateName: 'templateName',
    numberTemplate: 'numberTemplate',
    nameTemplate: 'nameTemplate',
    color: 'color',

    procedureTemplates: 'procedures',
    taskTemplates: 'tasks',
    phaseTemplates: 'phases'
}

export const projectTemplateModel = Object.freeze(projectTemplate)

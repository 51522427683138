import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import {
    Today as EveryDateIcon,
    EventBusy as ClosedDateIcon,
    EventNote as OpenedDateIcon
} from '@material-ui/icons/'

export const types = {
    moreThan: 'gt',
    lessThan: 'lt',
    ignore: 'ignore'
}
export const handleChange = (state, setState) => {
    if (state === types.ignore) setState(types.moreThan)
    else if (state === types.moreThan) setState(types.lessThan)
    else setState(types.ignore)
}
const Icon = (props) => {
    const { type } = props
    if (type === types.moreThan) return <OpenedDateIcon color="primary" />
    if (type === types.lessThan) return <ClosedDateIcon color="error" />
    return <EveryDateIcon />
}
const Label = (props) => {
    const { type } = props
    if (type === types.moreThan)
        return <Typography color="primary">Незавершенные</Typography>
    if (type === types.lessThan)
        return <Typography color="error">Завершенные</Typography>
    return <Typography>Все даты</Typography>
}

export default (props) => {
    const { type, onHandleChange } = props
    return (
        <Button size="small" onClick={onHandleChange}>
            <Icon type={type} /> &nbsp;
            <Label type={type} />
        </Button>
    )
}

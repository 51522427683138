import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceField
} from 'react-admin'
import { FullNameField } from '../fields/fullNameField'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const EmployeesFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Должность" source="jobPosition" alwaysOn={true} />
    </Filter>
)

const EmployeeListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props
    console.log(props)
    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'text',
        value: 'Сотрудники'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<EmployeesFilter />}
            filter={{ companyId: window['defaultCompanyId'] }}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <ReferenceField
                    label={'Компания'}
                    source={'companyId'}
                    reference={Resources.Company}
                >
                    <TextField source={'shortName'} />
                </ReferenceField>
                <ReferenceField
                    label="ФИО"
                    source="personId"
                    reference={Resources.Person}
                >
                    <FullNameField />
                </ReferenceField>
                <TextField label="Должность" source="jobPosition" />
                <TextField label="Телефон" source="phone" />
                <TextField label="Email" source="email" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const EmployeeList = enchanceList(EmployeeListBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILookupValueModel extends IStandardFieldsModel {
    lookupId: 'lookupId'
    lookup: 'lookup'
    value: 'value'
    intValue: 'intValue'
    decimalValue: 'decimalValue'
    order: 'order'
    systemName: 'systemName'
    lookupValueRequirement: 'requirements'
}

const lookupValue: ILookupValueModel = {
    ...standardFieldsModel,
    lookupId: 'lookupId',
    lookup: 'lookup',
    value: 'value',
    intValue: 'intValue',
    decimalValue: 'decimalValue',
    order: 'order',
    systemName: 'systemName',
    lookupValueRequirement: 'requirements'
}

export const lookupValueModel = Object.freeze(lookupValue)

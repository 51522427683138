import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IProjectParticipantsModel extends IStandardFieldsModel {
    userId: 'userId'
    appUser: 'user'
    projectId: 'projectId'
    project: 'project'
    accessType: 'accessType'
    accessExpirationDate: 'accessExpirationDate'
}

const projectParticipants = {
    ...standardFieldsModel,
    userId: 'userId',
    appUser: 'user',
    projectId: 'projectId',
    project: 'project',
    accessType: 'accessType',
    accessExpirationDate: 'accessExpirationDate'
}

export const projectParticipantsModel = Object.freeze(projectParticipants)

import React from 'react'
import { Typography } from '@material-ui/core'

const NotifyWrapper = ({ classes, value, children, deltaTime }) => (
    <div className={classes.menuItem}>
        <div>
            <Typography
                className={classes.isSentPoint}
                style={{ color: value.isRead ? '#333333' : '#85DBC7' }}
                variant="h6"
            >
                &#8226;
            </Typography>
        </div>
        <div className={classes.dataContainer}>
            {children}
            <Typography variant="caption" className={classes.notificationSent}>
                {deltaTime}
            </Typography>
        </div>
    </div>
)

export const NotifyWithContent = ({
    classes,
    value,
    title,
    contents,
    deltaTime
}) => (
    <NotifyWrapper classes={classes} value={value} deltaTime={deltaTime}>
        {title.map((value) => (
            <Typography
                variant="subtitle1"
                className={classes.notificationTitle}
            >
                {value}
            </Typography>
        ))}
        {contents.map((content) => (
            <Typography
                variant="subtitle2"
                className={classes.notificationContent}
            >
                {content}
            </Typography>
        ))}
    </NotifyWrapper>
)

export const Notify = ({ classes, value, title, deltaTime }) => {
    return (
        <NotifyWrapper classes={classes} value={value} deltaTime={deltaTime}>
            {title.map((value) => (
                <Typography
                    variant="subtitle1"
                    className={classes.notificationTitle}
                >
                    {value}
                </Typography>
            ))}
        </NotifyWrapper>
    )
}

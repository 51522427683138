import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    fetchEnd,
    fetchStart,
    GET_LIST,
    List,
    SaveButton,
    UPDATE
} from 'react-admin'
import {
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers'
import { Resources } from '../../../api/api'
import oDataProvider from '../../../api/oDataProvider'

export const ReadableTypeNotify = (props) => {
    const notificationTypeText: Object = {
        TaskAssignee: 'Задачи',
        Test: 'Тестовые',
        ProjectCommentBroadcast: 'Проекты уведомления',
        RequestCommentReply: 'Заявки комментарии',
        RequestCommentBroadcast: 'Заявки уведомления',
        ProjectCommentReply: 'Проект комментарии',
        ProjectTaskEndOfWork: 'Об истечение срока задач',
        ProjectEndOfWork: 'Об истечение срока проекта',
        VerFileCommentReply: 'Файлы, ответ на коментарии',
        VerFileCommentBroadcast: 'Об файлах',
        RequestAccept: 'Об приеме заявки',
        NewRequest: 'Новые заявки',
        LoaEndOfValidity: 'Об окончании доверенности'
    }

    const { notificationType } = props.record
    if (!notificationType) {
        return <span>Загрузка...</span>
    }
    return (
        <span>
            {notificationTypeText[notificationType] || notificationType}
        </span>
    )
}

const CustomSwitch = ({ name, checked, handleChange }) => {
    const [state, setState] = useState(checked)

    const handleChangeState = (event) => {
        setState(!state)
        handleChange(event)
    }

    return (
        <Switch
            checked={state}
            onChange={handleChangeState}
            name={name}
            color="primary"
        />
    )
}

const CustomSaveButton = ({
    notifyByEmail,
    notifyBySite,
    notificationType
}) => {
    const [isButtonSend, setIsButtonSend] = useState(true)

    const [copyState, setCopyState] = useState({
        email: notifyByEmail,
        site: notifyBySite
    })

    const [state, setState] = useState({
        email: notifyByEmail,
        site: notifyBySite
    })

    useEffect(() => {
        setIsButtonSend(
            !(state.email !== copyState.email || state.site !== copyState.site)
        )
    }, [state])

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked })
    }

    return (
        <>
            <TableCell align="center">
                <CustomSwitch
                    name="site"
                    handleChange={handleChange}
                    checked={notifyBySite}
                />
            </TableCell>
            <TableCell align="center">
                <CustomSwitch
                    name="email"
                    handleChange={handleChange}
                    checked={notifyByEmail}
                />
            </TableCell>
            <TableCell align="center">
                <SaveButton
                    variant="text"
                    disabled={isButtonSend}
                    onClick={() => {
                        ;(async () => {
                            fetchStart()
                            const action = UPDATE
                            await oDataProvider(
                                action,
                                Resources.UserNotificationChannel,
                                {
                                    data: {
                                        notificationType: notificationType,
                                        notifyByEmail: state.email,
                                        notifyBySite: state.site
                                    }
                                }
                            )
                            fetchEnd()
                        })()
                        setIsButtonSend(true)
                    }}
                />
            </TableCell>
        </>
    )
}
const CustomList = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: notifySetting } = await oDataProvider(
                action,
                Resources.UserNotificationChannel,
                {
                    sort: { field: 'id', order: 'DESC' }
                }
            )
            setData(notifySetting)
            fetchEnd()
        })()
    }, [])

    return (
        <>
            {data.map((item) => {
                const { notificationType, notifyByEmail, notifyBySite } = item
                return (
                    <TableRow key={notificationType}>
                        <TableCell component="th" scope="row">
                            <ReadableTypeNotify record={item} />
                        </TableCell>

                        <CustomSaveButton
                            notifyByEmail={notifyByEmail}
                            notifyBySite={notifyBySite}
                            notificationType={notificationType}
                        />
                    </TableRow>
                )
            })}
        </>
    )
}

const UserNotificationBase = (props: any) => {
    const { setTitle, ...rest } = props
    const titles: Array<any> = []

    titles.push({
        type: 'text',
        value: 'Уведомления'
    })
    titles.push({
        type: 'text',
        value: 'Настройки'
    })

    setTitle(titles)

    return (
        <List
            {...rest}
            exporter={false}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={false}
        >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Тип Уведомлений</TableCell>
                        <TableCell align="center">
                            Уведомления на сайте
                        </TableCell>
                        <TableCell align="center">
                            Уведомления на почту
                        </TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <CustomList />
                </TableBody>
            </Table>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const UserNotification = enchanceList(UserNotificationBase)

import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { People as PeopleIcon } from '@material-ui/icons'

const addNewParticipantStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewParticipantComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        color={'secondary'}
        variant={'contained'}
        to={`/ProjectParticipant/create?projectId=${record.id}`}
        label="Добавить участника"
        // title="Добавить участника"
    >
        <PeopleIcon />
    </Button>
)
export const AddNewParticipantButton = withStyles(addNewParticipantStyles)(
    AddNewParticipantComponent
)

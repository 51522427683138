import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICommentListModel extends IStandardFieldsModel {
    projects: 'projects'
    requests: 'requests'
    versionedFileAttachment: 'versionedFiles'
    comments: 'comments'
}

const commentList: ICommentListModel = {
    ...standardFieldsModel,
    projects: 'projects',
    requests: 'requests',
    versionedFileAttachment: 'versionedFiles',
    comments: 'comments'
}

export const commentListModel = Object.freeze(commentList)

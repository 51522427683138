import React, { useEffect, useState } from 'react'
import {
    SelectInput,
    ReferenceInput,
    BooleanInput,
    NumberInput,
    TabbedForm,
    FormTab,
    TextInput,
    LongTextInput,
    required,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import { CustomerFiltersProvider } from '../../api/filterToODataConverter'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { ToolbarGeneral } from '../../layout/bar'
import { Resources } from '../../api/api'
import RichTextInput from 'ra-input-rich-text'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    textEditor: {
        '& .ra-rich-text-input': {
            height: 200
        }
    },
    warning: {
        width: '150%',
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

const ParentPageInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет других страниц</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ParentPageInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Родительская страница"
            source="parentId"
            reference={Resources.Page}
            resource="Page"
            filter={CustomerFiltersProvider.NotSelf(props.record)}
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ParentPageInputLabel style={props.classes} />}
        />
    )
}

const pageFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneParentPage, setAtLeastOneParentPage] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контент сайта'
        })
        titles.push({
            type: 'link',
            href: '/Page',
            value: 'Страницы'
        })
        titles.push({
            type: 'text',
            value: `Редактирование страницы №${props.record.id} - ${props.record.title}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: parentPage } = await oDataProvider(
                action,
                Resources.Page,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            parentPage.length > 0
                ? setAtLeastOneParentPage(true)
                : setAtLeastOneParentPage(false)
            fetchEnd()
        })()
    })
    return (
        <TabbedForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <FormTab label="Основная ифнормация">
                <ParentPageInput atLeastOne={atLeastOneParentPage} {...rest} />
                <TextInput
                    fullWidth={true}
                    label="Заголовок"
                    source="title"
                    validate={required()}
                />
                <RichTextInput
                    className={rest.classes.textEditor}
                    label="Содержание"
                    source="content"
                />

                <NumberInput
                    label="Порядок в списке (приоритет)"
                    source="order"
                />
                <BooleanInput label="Показывать в меню" source="isInMenu" />
                <BooleanInput label="Опубликована" source="isPublished" />
            </FormTab>
            <FormTab label="Содержание (разметка)">
                <LongTextInput
                    label="Содержание"
                    source="content"
                    options={{ inputProps: { rows: 20 } }}
                />
            </FormTab>
            <FormTab label="SEO">
                <TextInput label="Заголовок окна" source="metaTitle" />
                <TextInput label="Имя страницы" source="slug" />
                <TextInput label="Путь страницы" source="path" />
                <TextInput label="Meta ключевые слова" source="metaKeywords" />
                <LongTextInput label="Meta описание" source="metaDescription" />
            </FormTab>
        </TabbedForm>
    )
}
export const PageForm = withStyles(styles)(pageFormBase)

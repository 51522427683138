import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewPhaseTemplateStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewPhaseTemplateComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        variant="raised"
        to={`/PhaseTemplate/create?projectTemplateId=${record.id}`}
        label="Добавить шаблон этапа"
        title="Добавить шаблон этапа"
    >
        <TemplateIcon />
    </Button>
)
export const AddNewPhaseTemplateButton = withStyles(addNewPhaseTemplateStyles)(
    AddNewPhaseTemplateComponent
)

import { createMuiTheme } from '@material-ui/core'
const THEME_CONTAIN = 'SET_THEME_CONTAIN'
const initialState = createMuiTheme({
    palette: {
        primary: {
            main: '#00AA3C'
        },
        secondary: {
            main: '#333333'
        }
    }
})

export const themeContainAction = (theme) => {
    const action = {
        type: THEME_CONTAIN,
        theme
    }
    return action
}

export const themeContainReducer = (priviousState = initialState, action) => {
    if (action.type === THEME_CONTAIN) return action.theme
    return priviousState
}

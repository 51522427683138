import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewProcedureStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewProcedureTemplateComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        variant="raised"
        to={`/ProcedureTemplate/create?projectTemplateId=${record.id}`}
        label="Добавить процедуру"
        // title="Добавить процедуру"
    >
        <TemplateIcon />
    </Button>
)
export const AddNewProcedureTemplateButton = withStyles(addNewProcedureStyles)(
    AddNewProcedureTemplateComponent
)

export const AUTH_SERVER_URL = window['authServer']
export const APP_URL = window['appUrl']
export const REDIRECT_URI = window['redirect_uri']

export const AuthConfiguration = {
    client_id: 'app',
    client_root: `${APP_URL}/`,
    redirect_uri: `${APP_URL}/callback.html?origin=${REDIRECT_URI}`,
    response_type: 'id_token token',
    post_logout_redirect_uri: `${APP_URL}/`,
    scope: 'openid profile api',
    authority: AUTH_SERVER_URL,
    silent_redirect_uri: `${APP_URL}/authentication/silent_callback`,
    automaticSilentRenew: true,
    loadUserInfo: true,
    triggerAuthFlow: true
}

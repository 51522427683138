import * as React from 'react'
import {
    NumberField,
    BooleanField,
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceField
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const PagesFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="Заголовок содержит"
            source="$startsWith(title)"
            alwaysOn={true}
        />
    </Filter>
)

const PageListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контент сайта'
    })
    titles.push({
        type: 'text',
        value: 'Страницы'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<PagesFilter />}
            exporter={true}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <ReferenceField
                    label="Родительская страница"
                    source="parentId"
                    reference={Resources.Page}
                    resource={Resources.Page}
                    allowEmpty={true}
                >
                    <TextField source="title" />
                </ReferenceField>
                <TextField label="Заголовок страницы" source="title" />
                <TextField label="Имя сегмента URL" source="slug" />
                <TextField label="Путь" source="path" />
                <BooleanField label="В меню" source="isInMenu" />
                <BooleanField label="Опубликована" source="isPublished" />
                <NumberField label="Порядок" source="order" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const PageList = enchanceList(PageListBase)

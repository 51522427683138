import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IPersonPassportModel extends IStandardFieldsModel {
    batch: 'batch'
    serialNumber: 'serialNumber'
    issuer: 'issuer'
    issuerNum: 'issuerNum'
    issuedAt: 'issuedAt'
    birthDay: 'birthDay'
    regPlace: 'regPlace'
    birthPlace: 'birthPlace'
    personId: 'personId'
    sex: 'sex'
    person: 'person'
}

const personPassport: IPersonPassportModel = {
    ...standardFieldsModel,
    batch: 'batch',
    serialNumber: 'serialNumber',
    issuer: 'issuer',
    issuerNum: 'issuerNum',
    issuedAt: 'issuedAt',
    birthDay: 'birthDay',
    regPlace: 'regPlace',
    birthPlace: 'birthPlace',
    personId: 'personId',
    sex: 'sex',
    person: 'person'
}

export const personPassportModel = Object.freeze(personPassport)

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface ITaskTemplateAssigneeModel extends IStandardFieldsModel {
    taskTemplateId: 'taskTemplateId'
    taskTemplate: 'taskTemplate'

    userId: 'userId'
    user: 'user'

    comment: 'comment'
}

const taskTemplateAssignee: ITaskTemplateAssigneeModel = {
    ...standardFieldsModel,
    taskTemplateId: 'taskTemplateId',
    taskTemplate: 'taskTemplate',

    userId: 'userId',
    user: 'user',

    comment: 'comment'
}

export const taskTemplateAssigneeModel = Object.freeze(taskTemplateAssignee)

import React from 'react'
import { Create } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { UserEditForm, UserCreateForm } from './userInput'
import Edit from '../../layout/edit'

const useStyles = makeStyles({
    input: {
        minWidth: 251
    },
    rootContactsInput: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contactsInput: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactInput: {
        marginBottom: '8px',
        marginLeft: '10px',
        marginTop: '16px',
        minWidth: 232
    },
    contactsTitle: {
        marginTop: '16px',
        color: 'gray'
    },
    materialInput: {
        marginBottom: '8px',
        marginTop: '16px',
        minWidth: 252
    }
})

export const UserRoles = [
    { id: 'Клиент', name: 'Клиент' },
    { id: 'Консультант', name: 'Консультант' },
    { id: 'Сотрудник', name: 'Сотрудник' },
    { id: 'Диспетчер', name: 'Диспетчер' },
    { id: 'Администратор', name: 'Администратор' }
]

const UserCreateBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []

    titles.push({
        type: 'link',
        href: '/AppUser',
        value: 'Пользователи'
    })
    titles.push({
        type: 'text',
        value: 'Редактирование пользователя'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <UserCreateForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const UserCreate = enchanceCreate(UserCreateBase)

const UserEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    const classes = useStyles()
    return (
        <Edit {...rest}>
            <UserEditForm isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const UserEditView = enchanceEdit(UserEditViewBase)

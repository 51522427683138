import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { InfoOutline as InfoIcon } from '@material-ui/icons/'
import { withRouter } from 'react-router-dom'

const Icon = (props) => <InfoIcon fontSize="small" color="secondary" />
const Label = (props) => (
    <Typography variant="button" color="secondary">
        Запросить доп. инфо
    </Typography>
)

const extraButtonBase = (props) => {
    const { path, history } = props
    const onHandle = async () => history.push(path)
    return (
        <Button variant="flat" size="small" onClick={onHandle}>
            <Icon /> &nbsp;
            <Label />
        </Button>
    )
}
export default withRouter(extraButtonBase)

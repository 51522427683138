import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import {
    Favorite as FavoriteIcon,
    FavoriteBorder as FavoriteBorderIcon
} from '@material-ui/icons/'

const Icon = (props) =>
    props.isSelected ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />
const Label = (props) => (
    <Typography color={props.isSelected ? 'primary' : undefined}>
        {props.text}
    </Typography>
)

export default (props) => {
    const { isSelected, onHandleChange, text } = props
    return (
        <Button size="small" onClick={onHandleChange}>
            <Icon isSelected={isSelected} /> &nbsp;
            <Label isSelected={isSelected} text={text} />
        </Button>
    )
}

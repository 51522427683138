import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewProcedureStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewProcedureComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        color={'secondary'}
        variant={'contained'}
        to={`/Procedure/create?projectId=${record.id}`}
        label="Добавить процедуру"
        // title="Добавить процедуру"
    >
        <TemplateIcon />
    </Button>
)
export const AddNewProcedureButton = withStyles(addNewProcedureStyles)(
    AddNewProcedureComponent
)

import { CREATE } from 'react-admin'
import { Resources } from '../api/api'
import oDataProvider from '../api/oDataProvider'

export default (value, resource: Resources) => {
    switch (resource) {
        case Resources.Project: {
            const { customer, userFilters, ...rest } = value
            return rest
        }
        case Resources.Customer: {
            const { person, ...rest } = value
            return rest
        }
        case Resources.ProjectParticipant: {
            const { user, ...rest } = value
            return rest
        }
        case Resources.CompanyEmployee: {
            const { person, ...rest } = value
            return rest
        }
        case Resources.Company: {
            const { checked, ...rest } = value
            if (!!checked)
                oDataProvider(CREATE, Resources.Customer, {
                    data: { companyId: value.id, displayName: value.shortName }
                })
            return rest
        }
        case Resources.VersionedFileAttachment: {
            const {
                attachments,
                files,
                ownerProject,
                userFilters,
                commentListId,
                createdById,
                createdOn,
                deletedById,
                deletedOn,
                id,
                isDeleted,
                ownerProjectId,
                ownerRequest,
                ownerRequestId,
                ownerUserId,
                updatedById,
                updatedOn,
                ...rest
            } = value

            if (!!attachments)
                oDataProvider(
                    CREATE,
                    Resources.VersionedFileAttachment_UpdateFile,
                    { data: { attachments: attachments, id: value.id } }
                )
            return rest
        }
        case Resources.LetterOfAuthority: {
            const { regPlace, ...rest } = value
            rest.personTrusteeId = Number(localStorage.getItem('personId'))
            return rest
        }
        case Resources.LetterOfAuthorityClassificationDictValue: {
            return { value: value.value }
        }
        case Resources.LetterOfAuthorityRequesterDictValue: {
            return { value: value.value }
        }
        case Resources.RequestCommand: {
            const { subject, ...rest } = value
            return { ...rest }
        }
        case Resources.Request: {
            if (
                !!value.classification &&
                !!value.classification.isSubsidiaryLiability &&
                !!value.classification.isSubsidiaryLiability.length
            ) {
                delete value.classification.isSubsidiaryLiability
                value.classification.isSubsidiaryLiability = true
                return value
            }
            return value
        }
    }
    return value
}

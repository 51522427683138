import * as React from 'react'
import {
    Show,
    SimpleShowLayout,
    TextField,
    SelectField,
    ReferenceField
} from 'react-admin'
import { FullNameField } from '../fields/fullNameField'
import { UserRoles } from './userForm'
import { Resources } from '../../api/api'

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Имя пользователя" source="userName" />
            <ReferenceField
                label="ФИО"
                source="person.id"
                reference={Resources.Person}
                allowEmpty={true}
            >
                <FullNameField />
            </ReferenceField>
            <SelectField label="Роль" source="role" choices={UserRoles} />
        </SimpleShowLayout>
    </Show>
)

export class SelectSettings {
    public select?: string[]
    public defaultSelect?: string[]

    public toString(): string {
        const selectArray = this.select || this.defaultSelect
        if (!selectArray) {
            return ''
        }
        return '$select=' + selectArray.join(',')
    }

    public reset(): void {
        this.select = undefined
    }

    public isSet(): boolean {
        return (
            (!!this.select && this.select.length > 0) ||
            (!!this.defaultSelect && this.defaultSelect.length > 0)
        )
    }
}

import * as React from 'react'
import {
    Button,
    Datagrid,
    ReferenceManyField,
    TextField,
    SimpleShowLayout
} from 'react-admin'
import {
    RemoveRedEye as IconImageEye,
    KeyboardArrowRight as IconKeyboardArrowRight
} from '@material-ui/icons'
import { connect } from 'react-redux'
import { createStyles, Drawer, withStyles } from '@material-ui/core'
import { UserFullNameField } from '../fields/fullNameField'
import { Resources } from '../../api/api'

const styles = (theme) => ({
    field: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        '& span': {
            display: 'inline-block',
            maxWidth: '30em'
        }
    }
})

const CustomerQuickView = ({ classes, ...props }) => {
    console.log('---customer preview', props.record)
    return (
        <SimpleShowLayout {...props}>
            {props.record && props.record.person && (
                <TextField
                    label="Имя"
                    source="person.firstName"
                    className={classes.field}
                />
            )}
            {props.record && props.record.person && (
                <TextField
                    label="Фамилия"
                    source="person.lastName"
                    className={classes.field}
                />
            )}
            {props.record && props.record.person && (
                <TextField
                    label="Отчество"
                    source="person.patronymicName"
                    className={classes.field}
                />
            )}

            {props.record && props.record.person && (
                <TextField
                    label="Эл. почта"
                    source="person.email"
                    className={classes.field}
                />
            )}
            {props.record && props.record.person && (
                <TextField
                    label="Телефон"
                    source="person.phone"
                    className={classes.field}
                />
            )}

            {props.record && props.record.company && (
                <TextField
                    label="Компания"
                    source="company.fullName"
                    className={classes.field}
                />
            )}
            {props.record && props.record.company && (
                <TextField
                    label="ИНН"
                    source="company.inn"
                    className={classes.field}
                />
            )}
            {props.record && props.record.company && (
                <TextField
                    label="КПП"
                    source="company.kpp"
                    className={classes.field}
                />
            )}
            {props.record && props.record.company && (
                <TextField
                    label="Комментарий"
                    source="company.comments"
                    className={classes.field}
                />
            )}

            {props.record && props.record.company && (
                <ReferenceManyField
                    label="Сотрудники"
                    record={props.record.company}
                    target="companyId"
                    reference={Resources.CompanyEmployee}
                    resource="CompanyEmployee"
                >
                    <Datagrid>
                        <TextField label="Должность" source="jobPosition" />
                        <UserFullNameField label="ФИО" />
                    </Datagrid>
                </ReferenceManyField>
            )}
        </SimpleShowLayout>
    )
}

const mapStateToProps = (state, props) => ({
    record: state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    version: state.admin.ui.viewVersion
})

const CustomerPreview = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(CustomerQuickView))

const panelStyles = (theme) =>
    createStyles({
        drawerContent: {
            width: 350
        }
    })

class CustomerQuickViewPanelBase extends React.Component<any, any> {
    state = { showPanel: false }

    handleClick = () => {
        this.setState({ showPanel: true })
    }

    handleCloseClick = () => {
        this.setState({ showPanel: false })
    }

    render() {
        const { showPanel } = this.state
        const { id, classes, record } = this.props
        return (
            <React.Fragment>
                {record && (record.company || record.person) && (
                    <Button onClick={this.handleClick} label="ra.action.show">
                        <IconImageEye />
                    </Button>
                )}
                {/*{!record || (!record.company && !record.person) && <CustomerQuickEditButton variant="contained" />}*/}
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Закрыть" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <CustomerPreview
                        className={classes.drawerContent}
                        id={id}
                        basePath="/Customer"
                        resource="Customer"
                    />
                </Drawer>
            </React.Fragment>
        )
    }
}

export const CustomerQuickViewPanel = connect()(
    withStyles(panelStyles)(CustomerQuickViewPanelBase)
)

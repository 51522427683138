import * as React from 'react'
import {
    ReferenceInput,
    SelectInput,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    NumberField
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { Resources } from '../../api/api'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const PaymentFilter = (props: any) => (
    <Filter {...props}>
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    </Filter>
)

const PaymentListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Платежи'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<PaymentFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    reference={Resources.Project}
                    linkType="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField label="Сумма" source="amount" />
                <NumberField label="Налог" source="tax" />
                <TextField label="Комментарий" source="comment" />
                <ReferenceField
                    label="Счет"
                    source="invoiceId"
                    reference={Resources.Invoice}
                >
                    <TextField source="displayName" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const PaymentList = enchanceList(PaymentListBase)

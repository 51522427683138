import React, { useState, useEffect } from 'react'
import {
    SimpleForm,
    TextInput,
    required,
    ArrayInput,
    SelectInput,
    NumberInput,
    DateTimeInput,
    ReferenceInput,
    SimpleFormIterator,
    GET_ONE,
    GET_LIST,
    email,
    FormDataConsumer
} from 'react-admin'
import { Resources, RoleTypes } from '../../api/api'
import { RoleTypeById } from '../../api/publicOjects'
import {
    personModel as pm,
    appUserModel as au,
    personPassportModel as pp,
    personContactModel as pc
} from '../../api/models'
import { ToolbarGeneral } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { makeStyles } from '@material-ui/styles'
import { forceUpdate } from '../../utils/forceUpdate'
import oDataProvider from '../../api/oDataProvider'

export const ContactInfoTypes = [
    { id: 'Phone', name: 'Телефон' },
    { id: 'Fax', name: 'Факс' },
    { id: 'Address', name: 'Адрес' },
    { id: 'Email', name: 'Email' },
    { id: 'Skype', name: 'Skype' },
    { id: 'Whatsapp', name: 'WhatsApp' },
    { id: 'Viber', name: 'Viber' },
    { id: 'Telegram', name: 'Telegram' },
    { id: 'Facebook', name: 'Facebook' },
    { id: 'Google', name: 'Google' },
    { id: 'Vkontakte', name: 'VK' },
    { id: 'Odnoklassniki', name: 'Одноклассники' },
    { id: 'Icq', name: 'ICQ' }
]

export const Roles = [
    { id: 'Клиент', name: 'Клиент' },
    { id: 'Администратор', name: 'Администратор' },
    { id: 'Диспетчер', name: 'Диспетчер' },
    { id: 'Сотрудник', name: 'Сотрудник' },
    { id: 'Консультант', name: 'Консультант' }
]

const useStyles = makeStyles({
    fields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& div': {
            minWidth: 250,
            marginRight: 24
        }
    }
})

const validate = (values: any) => {
    const errors: any = { user: {}, passport: {} }
    if (!!values.user) {
        if (values.user.rePassword !== values.user.password)
            errors.user.rePassword = 'Пароли должны совпадать'
        if (!!values.user.password && values.user.password.length < 5)
            errors.user.password = 'Обязательный ввод'
    }

    if (!!values.passport) {
        if (
            !!values.passport.batch &&
            String(values.passport.batch).length !== 4
        )
            errors.passport.batch = 'Необходимо ввести 4 цифры'
        if (
            !!values.passport.serialNumber &&
            String(values.passport.serialNumber).length !== 6
        )
            errors.passport.serialNumber = 'Необходимо ввести 6 цифр'
        if (
            !!values.passport.issuerNum &&
            String(values.passport.issuerNum).length !== 6
        )
            errors.passport.issuerNum = 'Необходимо ввести 6 цифр'
    }
    return errors
}

const ContactInputBase = (props) => {
    const { isEditToolbar, setTitle, ...rest } = props

    const [createPassport, setCreatePassport] = useState(
        !!props.record.passports && !!props.record.passports.length
    )
    const [createUser, setCreateUser] = useState(!!props.record.userId)
    const [customerCreated, setCustomerCreated] = useState(false)
    const [passportCanBeEdited, setPassportCanBeEdited] = useState(true)

    const role = localStorage.getItem('role')

    const userCreated = !!props.record.userId
    const classes = useStyles()

    if (isEditToolbar) {
        if (!!props.record.passports && !!props.record.passports.length)
            props.record.passports = { ...props.record.passports[0] }
        if (
            !!props.record.user &&
            !!props.record.user.userRoles &&
            !!props.record.user.userRoles.length
        )
            props.record.user.role =
                RoleTypeById[props.record.user.userRoles[0].roleId]

        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контакты'
        })
        titles.push({
            type: 'link',
            href: '/Person',
            value: 'Люди'
        })
        titles.push({
            type: 'text',
            value: `Редактирование контакта №${rest.id} ${
                '- ' + rest.record.lastName || ''
            }`
        })

        setTitle(titles)
    }

    useEffect(() => {
        const loader = async () => {
            if (!!props.record.user && props.record.user.role === null) {
                const { data: user } = await oDataProvider(
                    GET_ONE,
                    Resources.AppUser,
                    { id: props.record.user.id }
                )
                props.record.user.role = user.role
            }
            if (isEditToolbar) {
                const { data: client } = await oDataProvider(
                    GET_LIST,
                    Resources.Customer,
                    {
                        pagination: { page: 1, perPage: 1 },
                        filter: { personId: Number(props.id) }
                    }
                )
                setCustomerCreated(!!client.length)
            }
            if (role === RoleTypes.Employee && isEditToolbar) {
                const { data: projects } = await oDataProvider(
                    GET_LIST,
                    Resources.Project,
                    {
                        pagination: { page: 1, perPage: 1 },
                        filter: { personId: props.record.id },
                        data: {
                            findCustomerClient: true
                        }
                    }
                )
                setPassportCanBeEdited(!!projects.length)
            }
        }
        loader()
    }, [])

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    createPassport={createPassport}
                    setCreatePassport={setCreatePassport}
                    createUser={createUser}
                    setCreateUser={setCreateUser}
                    isEditToolbar={isEditToolbar}
                    userCreated={userCreated}
                    passportCreated={!!props.record.passports}
                    customerCreated={customerCreated}
                    showCreatePassportButton={(() => {
                        if (role === RoleTypes.Employee)
                            return (
                                (!!props.record.passports &&
                                    !!props.record.passports.length &&
                                    passportCanBeEdited) ||
                                props.record.id ===
                                    Number(localStorage.getItem('personId'))
                            )
                        return true
                    })()}
                    {...rest}
                />
            }
            validate={validate}
            redirect="list"
            {...rest}
        >
            <TextInput
                label="Фамилия"
                source={pm.lastName}
                validate={required()}
            />
            <TextInput
                label="Имя"
                source={pm.firstName}
                validate={required()}
            />
            <TextInput label="Отчество" source={pm.patronymicName} />
            <TextInput label="Телефон" source={pm.phone} />
            <TextInput
                label="Эл. почта"
                source={pm.email}
                heplerText="Введите корректный адрес"
                validate={[email(), required()]}
            />
            <ArrayInput
                label="Контактная информация"
                source={pm.contactInformation}
                variant="text"
                size="small"
            >
                <SimpleFormIterator>
                    <SelectInput
                        label="Тип"
                        source={`${pc.infoRecordType}`}
                        choices={ContactInfoTypes}
                    />
                    <TextInput label="Значение" source={`${pc.contact}`} />
                    <TextInput label="Комментарий" source={`${pc.comment}`} />
                </SimpleFormIterator>
            </ArrayInput>

            {createUser && !userCreated && (
                <div className={classes.fields}>
                    <TextInput
                        source={`${pm.user}.${au.password}`}
                        type="password"
                        label="Пароль"
                        autoComplete="new-password"
                        helperText="Не менее 5 символов"
                        validate={!isEditToolbar && required()}
                    />
                    <TextInput
                        label="Повторение пароля"
                        type="password"
                        autoComplete="new-password"
                        source={`${pm.user}.${au.rePassword}`}
                        style={{ width: 255 }}
                        validate={required()}
                    />
                </div>
            )}
            {createUser && (
                <SelectInput
                    label="Роль"
                    source={`${pm.user}.${au.role}`}
                    choices={Roles}
                    validate={required()}
                />
            )}
            {createUser && !userCreated && (
                <ReferenceInput
                    label="Компания"
                    source="user.companyId"
                    reference={Resources.Company}
                >
                    <SelectInput optionText="shortName" />
                </ReferenceInput>
            )}

            {createPassport && passportCanBeEdited && (
                <div className={classes.fields}>
                    <NumberInput
                        label="Серия паспорта"
                        source={`${pm.passports}.${pp.batch}`}
                        validate={required()}
                    />
                    <NumberInput
                        label="Номер паспорта"
                        source={`${pm.passports}.${pp.serialNumber}`}
                        validate={required()}
                    />
                </div>
            )}

            {createPassport && passportCanBeEdited && (
                <div className={classes.fields}>
                    <TextInput
                        label="Паспорт выдан"
                        source={`${pm.passports}.${pp.issuer}`}
                        validate={required()}
                    />
                    <NumberInput
                        label="Код подразделения"
                        source={`${pm.passports}.${pp.issuerNum}`}
                        validate={required()}
                    />
                </div>
            )}

            {createPassport && passportCanBeEdited && (
                <div className={classes.fields}>
                    <DateTimeInput
                        label="Дата выдачи"
                        source={`${pm.passports}.${pp.issuedAt}`}
                        validate={required()}
                    />
                    <DateTimeInput
                        label="Дата рождения"
                        source={`${pm.passports}.${pp.birthDay}`}
                        validate={required()}
                    />
                </div>
            )}

            {createPassport && passportCanBeEdited && (
                <div className={classes.fields}>
                    <TextInput
                        label="Место регистрации"
                        source={`${pm.passports}.${pp.regPlace}`}
                        validate={required()}
                    />
                    <TextInput
                        label="Место Рождения"
                        source={`${pm.passports}.${pp.birthPlace}`}
                        validate={required()}
                    />
                </div>
            )}
        </SimpleForm>
    )
}
export const ContactInput = connect(undefined, {
    setTitle: titleAction
})(ContactInputBase)

import * as React from 'react'
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core'

const CaseParticipant = (props) => {
    return (
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={props.Name}
                secondary={
                    <React.Fragment>
                        <Typography component="span" color="textPrimary">
                            {props.Inn}/{props.Ogrn}
                        </Typography>
                        {props.Address}
                    </React.Fragment>
                }
            />
        </ListItem>
    )
}

const CaseInstance = (props) => {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary>
                <Typography>
                    {props.Name} ({props.InstanceNumber})
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Table padding={'none'}>
                        <TableBody>
                            <TableRow>
                                <TableCell>Дата регистрации</TableCell>
                                <TableCell>{props.StartDate}</TableCell>
                            </TableRow>
                            {props.Court && (
                                <TableRow>
                                    <TableCell>Суд</TableCell>
                                    <TableCell>
                                        {props.Court.Name} ({props.Court.Code})
                                    </TableCell>
                                </TableRow>
                            )}
                            {props.File && (
                                <TableRow>
                                    <TableCell>Решение по инстанции</TableCell>
                                    <TableCell>
                                        <Typography>
                                            <Link
                                                href={props.File.URL}
                                                target={'_blank'}
                                            >
                                                {props.File.Name}
                                            </Link>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>Судьи</TableCell>
                                <TableCell>
                                    {props.Judges && props.Judges.join(', ')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div>
                        <Typography variant={'caption'}>
                            Материалы электронного дела
                        </Typography>
                        <List>
                            {props.MoiArbitrDocuments &&
                                props.MoiArbitrDocuments.map((d, key) => (
                                    <ListItem key={key}>
                                        <ListItemText
                                            primary={d.DocumentName}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography>
                                                        {d.CourtName}
                                                    </Typography>
                                                    <Typography>
                                                        Зарегистрирован:{' '}
                                                        {d.RegisterDate},
                                                        Опубликован:{' '}
                                                        {d.PublishDate}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            {!props.MoiArbitrDocuments && (
                                <ListItem>
                                    <ListItemText primary={'Нет материалов'} />
                                </ListItem>
                            )}
                        </List>
                    </div>
                    <div>
                        <Typography variant={'caption'}>
                            События инстанции
                        </Typography>
                        <List>
                            {props.InstanceEvents &&
                                props.InstanceEvents.map((d, key) => (
                                    <ListItem key={key}>
                                        <ListItemText
                                            primary={d.AdditionalInfo}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography>
                                                        {d.EventContentTypeName}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            {!props.InstanceEvents && (
                                <ListItem>
                                    <ListItemText primary={'Нет событий'} />
                                </ListItem>
                            )}
                        </List>
                    </div>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const dateParser = (date: string): string =>
    date.split('T').join(' ').split('+')[0]
export const CourtCaseInfoDetails = (props) => {
    const { record: data } = props

    return (
        <Paper style={{ maxWidth: 600, padding: 20 }}>
            <Typography variant={'h5'}>Дело</Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Номер дела</TableCell>
                        <TableCell>{data.caseNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Тип дела</TableCell>
                        <TableCell>{data.caseType}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Дата регистрации дела</TableCell>
                        <TableCell>
                            {!!data.startDate ? dateParser(data.startDate) : ''}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Закрыто</TableCell>
                        <TableCell>{data.finished ? 'Да' : 'Нет'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {data.plaintiffs && data.plaintiffs.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Истцы</Typography>
                    <List>
                        {data.plaintiffs.map((p, key) => (
                            <CaseParticipant key={key} {...p} />
                        ))}
                    </List>
                </React.Fragment>
            )}

            {data.respondents && data.respondents.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Ответчики</Typography>
                    <List>
                        {data.respondents.map((r, key) => (
                            <CaseParticipant key={key} {...r} />
                        ))}
                    </List>
                </React.Fragment>
            )}

            {data.thirds && data.thirds.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Третьи лица</Typography>
                    <List>
                        {data.thirds.map((t, key) => (
                            <CaseParticipant key={key} {...t} />
                        ))}
                    </List>
                </React.Fragment>
            )}

            {data.others && data.others.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Другие участники</Typography>
                    <List>
                        {data.others.map((o, key) => (
                            <CaseParticipant key={key} {...o} />
                        ))}
                    </List>
                </React.Fragment>
            )}

            {data.caseInstances && data.caseInstances.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Инстанции</Typography>
                    {data.caseInstances.map((i, key) => (
                        <CaseInstance key={key} {...i} />
                    ))}
                </React.Fragment>
            )}

            {data.courtHearings && data.courtHearings.length > 0 && (
                <React.Fragment>
                    <Typography variant={'h5'}>Слушания по делу</Typography>
                    <List>
                        {data.courtHearings.map((h, key) => (
                            <ListItem key={key}>
                                <ListItemText
                                    primary={h.Summary}
                                    secondary={`${h.Start} - ${h.End} ${h.Location}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            )}
        </Paper>
    )
}

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IProjectTaskModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'
    startDate: 'startDate'
    endDate: 'endDate'
    priorityLevel: 'priorityLevel'
    projectId: 'projectId'

    procedureId: 'procedureId'

    phaseId: 'phaseId'

    isCompleted: 'isCompleted'
    completedOn: 'completedOn'

    taskAssignees: 'assignees'

    project: 'project'
    procedure: 'procedure'
    phase: 'phase'

    userFilters: 'userFilters'
    assignSelf: 'assignSelf'
}

const projectTask: IProjectTaskModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',
    startDate: 'startDate',
    endDate: 'endDate',
    priorityLevel: 'priorityLevel',
    projectId: 'projectId',

    procedureId: 'procedureId',

    phaseId: 'phaseId',

    isCompleted: 'isCompleted',
    completedOn: 'completedOn',

    taskAssignees: 'assignees',

    project: 'project',
    procedure: 'procedure',
    phase: 'phase',

    userFilters: 'userFilters',
    assignSelf: 'assignSelf'
}

export const projectTaskModel = Object.freeze(projectTask)

import { Person } from '../api/api'
import { crudUpdate, crudCreate } from 'react-admin'

export const getFio = (
    record:
        | Person
        | { firstName: string; lastName: string; patronymicName: string }
): string => {
    const fio = [
        record.lastName || '',
        record.firstName || '',
        record.patronymicName || ''
    ]
        .filter((e) => !!e)
        .join(' ')
        .trim()
    return fio
}

export const getFioFromReferencedPerson = (record: { person: Person }) => {
    if (!record || !record.person) {
        return null
    }
    return getFio(record.person)
}

export const saveWithNoteUpdate = (
    values,
    basePath,
    resource,
    id,
    redirect = 'list'
) => crudUpdate(resource, id, { ...values }, '', basePath, redirect)

export const saveWithNoteCreate = (
    values,
    basePath,
    resource,
    redirect = 'list'
) => crudCreate(resource, { ...values }, basePath, redirect)

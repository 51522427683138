import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import dates from './utils/dates'
import { Link } from "react-router-dom";

function hexToRgb(hex, brigtness) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16) * brigtness,
    g: parseInt(result[2], 16) * brigtness,
    b: parseInt(result[3], 16) * brigtness
  } : null;
}


class EventCell extends React.Component {
  render() {
    let {
      style,
      className,
      event,
      selected,
      isAllDay,
      onSelect,
      onDoubleClick,
      localizer,
      continuesPrior,
      continuesAfter,
      accessors,
      getters,
      children,
      components: { event: Event, eventWrapper: EventWrapper },
      ...props
    } = this.props

    let title = accessors.title(event)
    let tooltip = accessors.tooltip(event)
    let end = accessors.end(event)
    let start = accessors.start(event)
    let allDay = accessors.allDay(event)

    let showAsAllDay =
      isAllDay || allDay || dates.diff(start, dates.ceil(end, 'day'), 'day') > 1

    let userProps = getters.eventProp(event, start, end, selected)

    const content = (
      <div className="rbc-event-content" title={tooltip || undefined}>
        {Event ? (
          <Event
            event={event}
            title={title}
            isAllDay={allDay}
            localizer={localizer}
          />
        ) : (
          title
        )}
      </div>
    )

    const backColor = !!selected ? hexToRgb(event.backColor, 0.75) : hexToRgb(event.backColor, 1)
    return (
      <Link to={event.link} style={{ textDecoration: 'none' }}>
        <EventWrapper {...this.props} type="date">
          <div
            {...props}
            tabIndex={0}
            style={{ backgroundColor: `rgb(${backColor.r},${backColor.g},${backColor.b})`, ...userProps.style, ...style }}
            className={cn('rbc-event', className, userProps.className, {
              'rbc-selected': selected,
              'rbc-event-allday': showAsAllDay,
              'rbc-event-continues-prior': continuesPrior,
              'rbc-event-continues-after': continuesAfter,
            })}
          >
            {typeof children === 'function' ? children(content) : content}
          </div>
        </EventWrapper>
      </Link>
    )
  }
}

EventCell.propTypes = {
  event: PropTypes.object.isRequired,
  slotStart: PropTypes.instanceOf(Date),
  slotEnd: PropTypes.instanceOf(Date),

  selected: PropTypes.bool,
  isAllDay: PropTypes.bool,
  continuesPrior: PropTypes.bool,
  continuesAfter: PropTypes.bool,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object,

  onSelect: PropTypes.func,
  onDoubleClick: PropTypes.func,
}

export default EventCell

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IVersionedFileTimelineEventModel extends IStandardFieldsModel {
    versionedFileId: 'versionedFileId'
    versionedFileAttachment: 'versionedFile'

    timelineEventId: 'timelineEventId'
    timelineEvent: 'timelineEvent'
}

const versionedFileTimelineEvent: IVersionedFileTimelineEventModel = {
    ...standardFieldsModel,
    versionedFileId: 'versionedFileId',
    versionedFileAttachment: 'versionedFile',

    timelineEventId: 'timelineEventId',
    timelineEvent: 'timelineEvent'
}

export const versionedFileTimelineEventModel = Object.freeze(
    versionedFileTimelineEvent
)

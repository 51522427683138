import * as React from 'react'
import {
    TextField,
    Show,
    SimpleShowLayout,
    DateField,
    ReferenceField,
    Datagrid,
    ReferenceManyField,
    BooleanField
} from 'react-admin'
import { HistoryBackButton } from '../fields'
import { UserLinkFullNameField } from '../fields'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources } from '../../api/api'

const setNavbarTitle = (props) => {
    const { setTitle } = props
    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/ProjectTask',
        value: 'Задачи'
    })
    titles.push({
        type: 'text',
        value: `Карта задачи №${props.record.id} - ${props.record.title}`
    })
    setTitle(titles)
}

const TaskShowBase = (props) => {
    console.log(props, 'props show')
    setNavbarTitle(props)

    return (
        <SimpleShowLayout {...props}>
            <ReferenceField
                label="Проект"
                source="projectId"
                reference={Resources.Project}
                allowEmpty={true}
            >
                <TextField source="name" />
            </ReferenceField>

            <TextField source={'title'} label={'Название'} />
            <TextField source={'description'} label={'Описание'} />

            <DateField
                label="Дата начала"
                source="startDate"
                options={{ dateFormat: 'yyyy-MM-dd' }}
            />
            <DateField
                label="Дата завершения"
                source="endDate"
                options={{ dateFormat: 'yyyy-MM-dd' }}
            />

            <ReferenceManyField
                label="Исполнители"
                target="taskId"
                reference={Resources.TaskAssignee}
                allowEmpty={true}
            >
                <Datagrid>
                    <UserLinkFullNameField label="ФИО" />
                    <TextField label="Комментарий" source="comment" />
                </Datagrid>
            </ReferenceManyField>

            <ReferenceField
                label="Процедура"
                source="procedureId"
                reference={Resources.Procedure}
                allowEmpty={true}
            >
                <TextField source="title" />
            </ReferenceField>

            <ReferenceField
                label="Этап"
                source="phaseId"
                reference={Resources.Phase}
                allowEmpty={true}
            >
                <TextField source="title" />
            </ReferenceField>

            <BooleanField label={'Задача закрыта'} source="isCompleted" />

            <HistoryBackButton label={'Закрыть'} />
        </SimpleShowLayout>
    )
}

const TaskShowComponent = (props) => {
    const { ...rest } = props

    return (
        <Show {...rest}>
            <TaskShowBase {...rest} />
        </Show>
    )
}

const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const TaskShow = enchanceEdit(TaskShowComponent)

const IS_EDITED_LETTERS_OF_AUTHORITY = 'SET_IS_EDITED_LETTERS_OF_AUTHORITY'
const initialState = false

export const isEditedLettersOfAuthorityAction = (isEdited) => {
    const action = {
        type: IS_EDITED_LETTERS_OF_AUTHORITY,
        isEdited
    }
    return action
}

export const isEditedLettersOfAuthorityReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_EDITED_LETTERS_OF_AUTHORITY
        ? action.isEdited
        : priviousState

import React from 'react'
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceField,
    DateField,
    ReferenceInput,
    SelectInput,
    DateInput
} from 'react-admin'
import {
    LetterOfAuthorityType,
    LetterOfAuthorityStatus
} from './lettersOfAuthorityForm'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { getFio } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const LettersOfAuthorityFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Номер" source="$contains(number)" alwaysOn={true} />
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            multi={false}
        />
        <TextInput label="Название" source="$contains(name)" />
        <DateInput label="Дата выдачи" source="$date(issuedAt)" />
        <DateInput label="Дата истечения" source="$date(expireAt)" />
        <SelectInput
            label="Статус"
            source="status"
            choices={LetterOfAuthorityStatus}
        />
        <SelectInput
            label="Тип"
            source="type"
            choices={LetterOfAuthorityType}
        />
        <SelectAndSearchInput
            label="Классификация"
            source="classificationDictValueId"
            reference="LetterOfAuthorityClassificationDictValue"
            optionText="value"
            style={{ width: 255 }}
            multi={false}
        />
        <SelectAndSearchInput
            label="Место предъявления"
            source="requesterDictValueId"
            reference="LetterOfAuthorityRequesterDictValue"
            optionText="value"
            style={{ width: 255 }}
            multi={false}
        />
        <SelectAndSearchInput
            label="Доверительное лицо"
            source="toPersonId"
            reference="Person"
            optionText="lastName"
            optionRenderer={getFio}
            multi={false}
        />
        <SelectAndSearchInput
            label="Доверитель"
            source="personTrusteeId"
            reference="Person"
            optionText="lastName"
            optionRenderer={getFio}
            multi={false}
        />
    </Filter>
)

export const LetterStatusField = (props) => {
    const status = LetterOfAuthorityStatus.find(
        (status) => status.id === props.record.status
    )
    return status === undefined ? (
        <span>Без статуса</span>
    ) : (
        <span>{status.name}</span>
    )
}

export const LetterTypeField = (props) => {
    const type = LetterOfAuthorityType.find(
        (status) => status.id === props.record.type
    )
    return type === undefined ? <span>Без типа</span> : <span>{type.name}</span>
}

export const PersonField = (props) => <span>{getFio(props.record)}</span>

const LettersOfAuthorityListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'text',
        value: 'Договоры'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<LettersOfAuthorityFilter />}
            exporter={false}
        >
            <Datagrid rowClick="show" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Номер" source="number" />
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    reference={Resources.Project}
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField label="Название" source="name" />
                <DateField label="Дата выдачи" source="issuedAt" />
                <DateField label="Дата истечения" source="expireAt" />
                <LetterStatusField label="Статус" />
                <LetterTypeField label="Тип" />
                <ReferenceField
                    label="Классификация"
                    source="classificationDictValueId"
                    reference={
                        Resources.LetterOfAuthorityClassificationDictValue
                    }
                    linkType="show"
                >
                    <TextField source="value" />
                </ReferenceField>
                <ReferenceField
                    label="Место предъявления"
                    source="requesterDictValueId"
                    reference={Resources.LetterOfAuthorityRequesterDictValue}
                    linkType="show"
                >
                    <TextField source="value" />
                </ReferenceField>
                <ReferenceField
                    label="Доверенное лицо"
                    source="toPersonId"
                    reference={Resources.Person}
                >
                    <PersonField />
                </ReferenceField>
                <ReferenceField
                    label="Доверитель"
                    source="personTrusteeId"
                    reference={Resources.Person}
                >
                    <PersonField />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const LettersOfAuthorityList = enchanceList(LettersOfAuthorityListBase)

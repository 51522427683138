export interface IStandardFieldsModel {
    id: string
    createdOn: string
    updatedOn: string
    deletedOn: string
    createdById: string
    createdBy: 'createdBy'
    updatedById: string
    updatedBy: 'updatedBy'
    deletedById: string
    deletedBy: 'deletedBy'
    isDeleted: string
}

const standardFields: IStandardFieldsModel = {
    id: 'id',
    createdOn: 'createdOn',
    updatedOn: 'updatedOn',
    deletedOn: 'deletedOn',
    createdById: 'createdById',
    createdBy: 'createdBy',
    updatedById: 'updatedById',
    updatedBy: 'updatedBy',
    deletedById: 'deletedById',
    deletedBy: 'deletedBy',
    isDeleted: 'isDeleted'
}

export const standardFieldsModel = Object.freeze(standardFields)

import * as React from 'react'
import {
    DateField,
    Datagrid,
    Filter,
    List,
    ReferenceInput,
    ReferenceField,
    TextField,
    TextInput,
    SelectField,
    SelectInput,
    CREATE
} from 'react-admin'
import { Resources } from '../../api/api'
import { AddToFavorite, AddToHide } from '../fields'
import { ProjectStatuses } from './projectForm'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { checkPermissionsDispatcherAndUp } from '../auth/authHelpers'
import { ProjectFavoriteListActions } from '../../layout/favoriteHidenToobars'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const ProjectsFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Номер" source="number" />
        <TextInput label="Название" source="$contains(name)" alwaysOn={true} />
        <SelectInput
            label="Статус"
            source="status"
            choices={ProjectStatuses}
            alwaysOn={true}
            allowEmpty={false}
            resettable
        />
        {checkPermissionsDispatcherAndUp(props.permissions) && (
            <SelectAndSearchInput
                label="Клиент"
                source="customerId"
                reference="Customer"
                optionText="displayName"
                alwaysOn={true}
                multi={false}
            />
        )}
    </Filter>
)
const rowStyle = (record, index, defaultStyle = {}) => {
    return {
        ...defaultStyle,
        borderLeftColor: record.color || 'gray',
        borderLeftWidth: 8,
        borderLeftStyle: 'solid'
    }
}

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ProjectListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, isFavorite, isHidden, endedState, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Проекты'
    })
    setTitle(titles)

    const filter: any = { endedState: endedState }
    if (isFavorite) filter.isFavorite = true
    if (isHidden) filter.isHide = true

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<ProjectsFilter permissions={props.permissions} />}
            filter={filter}
            actions={<ProjectFavoriteListActions />}
            exporter={false}
        >
            <Datagrid
                rowClick="show"
                rowStyle={rowStyle}
                classes={{ rowEven: classes.evenRow }}
            >
                <TextField label="Номер" source="number" />
                <TextField label="Название" source="name" />
                <ReferenceField
                    label="Клиент"
                    source="customerId"
                    reference={Resources.Customer}
                >
                    <TextField source="displayName" />
                </ReferenceField>
                <SelectField
                    label="Статус"
                    source="status"
                    choices={ProjectStatuses}
                />
                <DateField label="Дата начала" source="startDate" />
                <DateField label="Дата завершения" source="endDate" />
                <AddToFavorite />
                <AddToHide />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteProject,
        isHidden: state.isHiddenProject,
        endedState: state.endedStateProject
    }),
    {
        setTitle: titleAction
    }
)
export const ProjectList = enchanceList(ProjectListBase)

import * as React from 'react'
import {
    FavoriteButton,
    HideButton,
    EndedButton,
    FiltersIgnoreButton,
    handleEndedState
} from '../components/fields'
import { connect } from 'react-redux'
import {
    isFavoriteProjectAction,
    isHiddenProjectAction,
    endedStateProjectAction,
    isFavoriteRequestAction,
    isHiddenRequestAction,
    isFavoriteProjectTaskAction,
    isHiddenProjectTaskAction,
    endedStateProjectTaskAction,
    isIgnoreProjectTaskFiltersAction,
    isFavoritePersonAction,
    isFavoriteFileAction
} from '../reducers'
import { CardActions, CreateButton } from 'react-admin'

const ProjectFavoriteListActionsBase = (props) => {
    const {
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,

        isFavorite,
        setIsFavorite,

        setIsHidden,
        isHidden,

        endedState,
        setEndedState,

        total
    } = props

    return (
        <CardActions>
            <EndedButton
                type={endedState}
                onHandleChange={() => {
                    handleEndedState(endedState, setEndedState)
                }}
            />
            <FavoriteButton
                text="Избранные"
                isSelected={isFavorite}
                onHandleChange={() => {
                    setIsFavorite(!isFavorite)
                }}
            />
            <HideButton
                text="Скрытые"
                isSelected={isHidden}
                onHandleChange={() => {
                    setIsHidden(!isHidden)
                }}
            />
            {bulkActions &&
                React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems
                })}
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton {...props} />
        </CardActions>
    )
}
export const enchanceListProject = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteProject,
        isHidden: state.isHiddenProject,
        endedState: state.endedStateProject
    }),
    {
        setIsFavorite: isFavoriteProjectAction,
        setIsHidden: isHiddenProjectAction,
        setEndedState: endedStateProjectAction
    }
)
export const ProjectFavoriteListActions = enchanceListProject(
    ProjectFavoriteListActionsBase
)

const RequestFavoriteListActionsBase = (props) => {
    const {
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,

        isFavorite,
        setIsFavorite,

        setIsHidden,
        isHidden

        // endedState,
        // setEndedState,
    } = props

    return (
        <CardActions>
            {/* <EndedButton
                type={endedState}
                onHandleChange={() => {handleEndedState(endedState, setEndedState)}}
            /> */}
            <FavoriteButton
                text="Избранные"
                isSelected={isFavorite}
                onHandleChange={() => {
                    setIsFavorite(!isFavorite)
                }}
            />
            <HideButton
                text="Скрытые"
                isSelected={isHidden}
                onHandleChange={() => {
                    setIsHidden(!isHidden)
                }}
            />
            {bulkActions &&
                React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems
                })}
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton {...props} />
        </CardActions>
    )
}
export const enchanceListRequest = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteRequest,
        isHidden: state.isHiddenRequest
        // endedState: state.endedStateProject
    }),
    {
        setIsFavorite: isFavoriteRequestAction,
        setIsHidden: isHiddenRequestAction
        // setEndedState: endedStateProjectAction
    }
)
export const RequestFavoriteListActions = enchanceListRequest(
    RequestFavoriteListActionsBase
)

const ProjectTaskFavoriteListActionsBase = (props) => {
    const {
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,

        isFavorite,
        setIsFavorite,

        setIsHidden,
        isHidden,

        endedState,
        setEndedState,

        isIgnoreFilters,
        setIsIgnoreFilters,

        total
    } = props

    return (
        <CardActions>
            <EndedButton
                type={endedState}
                onHandleChange={() => {
                    handleEndedState(endedState, setEndedState)
                }}
            />
            <FavoriteButton
                text="Избранные"
                isSelected={isFavorite}
                onHandleChange={() => {
                    setIsFavorite(!isFavorite)
                }}
            />
            <HideButton
                text="Скрытые"
                isSelected={isHidden}
                onHandleChange={() => {
                    setIsHidden(!isHidden)
                }}
            />
            <FiltersIgnoreButton
                text="Все"
                isSelected={isIgnoreFilters}
                onHandleChange={() => {
                    setIsIgnoreFilters(!isIgnoreFilters)
                }}
            />
            {bulkActions &&
                React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems
                })}
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton {...props} />
        </CardActions>
    )
}
export const enchanceListProjectTask = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteProjectTask,
        isHidden: state.isHiddenProjectTask,
        endedState: state.endedStateProjectTask,
        isIgnoreFilters: state.isIgnoreProjectTaskFilters
    }),
    {
        setIsFavorite: isFavoriteProjectTaskAction,
        setIsHidden: isHiddenProjectTaskAction,
        setEndedState: endedStateProjectTaskAction,
        setIsIgnoreFilters: isIgnoreProjectTaskFiltersAction
    }
)
export const ProjectTaskFavoriteListActions = enchanceListProjectTask(
    ProjectTaskFavoriteListActionsBase
)

const PersonFavoriteListActionsBase = (props) => {
    const {
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,

        isFavorite,
        setIsFavorite,

        total
    } = props

    return (
        <CardActions>
            <FavoriteButton
                text="Избранные"
                isSelected={isFavorite}
                onHandleChange={() => {
                    setIsFavorite(!isFavorite)
                }}
            />
            {bulkActions &&
                React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems
                })}
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton {...props} />
        </CardActions>
    )
}
export const enchanceListPerson = connect(
    (state: any) => ({
        isFavorite: state.isFavoritePerson
    }),
    {
        setIsFavorite: isFavoritePersonAction
    }
)
export const PersonFavoriteListActions = enchanceListPerson(
    PersonFavoriteListActionsBase
)

const FileFavoriteListActionsBase = (props) => {
    const {
        bulkActions,
        basePath,
        currentSort,
        displayedFilters,
        exporter,
        filters,
        filterValues,
        onUnselectItems,
        resource,
        selectedIds,
        showFilter,

        isFavorite,
        setIsFavorite,

        total
    } = props

    return (
        <CardActions>
            <FavoriteButton
                text="Избранные"
                isSelected={isFavorite}
                onHandleChange={() => {
                    setIsFavorite(!isFavorite)
                }}
            />
            {bulkActions &&
                React.cloneElement(bulkActions, {
                    basePath,
                    filterValues,
                    resource,
                    selectedIds,
                    onUnselectItems
                })}
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            <CreateButton {...props} />
        </CardActions>
    )
}
export const enchanceListFile = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteFile
    }),
    {
        setIsFavorite: isFavoriteFileAction
    }
)
export const FileFavoriteListActions = enchanceListFile(
    FileFavoriteListActionsBase
)

import { Concat } from './Concat'
import { Helpers } from './Helpers'

export class FilterClause {
    private property?: string
    private components: string[]
    private isClauseEmpty: boolean = true
    private propertyIncluded: boolean = false
    private usingNot: boolean = false
    private value: any
    private funcReturnType: any
    private transformFunc: (() => any) | null = null

    constructor(property?: string) {
        this.property = property
        this.components = []
    }

    public toString(): string {
        let strComps: any
        let i: any
        let filterStr: any
        strComps = []

        if (!this.propertyIncluded) {
            strComps.push(this.property)
        }

        for (i = 0; i < this.components.length; i++) {
            strComps.push(this.components[i])
        }
        filterStr = strComps.join(' ')

        if (!this.usingNot) {
            return filterStr
        }

        return typeof this.funcReturnType === 'boolean'
            ? 'not ' + filterStr
            : 'not (' + filterStr + ')'
    }

    public isEmpty(): boolean {
        return this.isClauseEmpty || (this.propertyIncluded && this.usingNot)
    }

    public custom(value: string): FilterClause {
        return Helpers.addCustomExpression(value, this)
    }

    // Logical operators
    public withId(id: any): FilterClause {
        return Helpers.addLogicalOperator(parseInt(id), 'eq', this)
    }

    public eq(value: string | number | boolean): FilterClause {
        if (value && typeof value === 'string') {
            value = encodeURIComponent(value)
        }
        return Helpers.addLogicalOperator(value, 'eq', this)
    }

    public ne(value: string | number | boolean): FilterClause {
        return Helpers.addLogicalOperator(value, 'ne', this)
    }

    public gt(value: string | number | boolean): FilterClause {
        return Helpers.addLogicalOperator(value, 'gt', this)
    }

    public ge(value: string | number | boolean): FilterClause {
        return Helpers.addLogicalOperator(value, 'ge', this)
    }

    public lt(value: string | number | boolean): FilterClause {
        return Helpers.addLogicalOperator(value, 'lt', this)
    }

    public le(value: string | number | boolean): FilterClause {
        return Helpers.addLogicalOperator(value, 'le', this)
    }

    public not(): FilterClause {
        this.usingNot = true
        return this
    }

    // Arithmetic methods
    public add(amount: number): FilterClause {
        return Helpers.addArithmeticOperator(amount, 'add', this)
    }

    public sub(amount: number): FilterClause {
        return Helpers.addArithmeticOperator(amount, 'sub', this)
    }

    public mul(amount: number): FilterClause {
        return Helpers.addArithmeticOperator(amount, 'mul', this)
    }

    public div(amount: number): FilterClause {
        return Helpers.addArithmeticOperator(amount, 'div', this)
    }

    public mod(amount: number): FilterClause {
        return Helpers.addArithmeticOperator(amount, 'mod', this)
    }

    // String functions
    public substringof(value: string): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = Boolean()

        let property = this.property
        if (this.transformFunc !== null) {
            property = this.components[this.components.length - 1]
            this.components.splice(this.components.length - 1, 1)
        }

        const escapedValue = encodeURIComponent(value)
        if (property) {
            this.components.push(
                "substringof('" + escapedValue + "'," + property + ')'
            )
        } else {
            this.components.push(
                "substringof('" + escapedValue + "'," + this.property + ')'
            )
        }
        return this
    }

    public contains(value: string): FilterClause {
        const loweredValue = encodeURIComponent(
            (value || '').toLowerCase()
        ).replace(/'/g, "''")
        this.propertyIncluded = true
        this.funcReturnType = Boolean()
        let property = this.property
        if (this.transformFunc !== null) {
            property = this.components[this.components.length - 1]
            this.components.splice(this.components.length - 1, 1)
        }

        if (property) {
            this.components.push(
                `contains(tolower(${property}),'${loweredValue}')`
            )
        } else {
            this.components.push(
                `contains(tolower(${this.property}),'${loweredValue}')`
            )
        }
        return this
    }

    public substring(position: number, length?: number): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()

        const comps = [this.property, position]
        if (length !== undefined) {
            comps.push(length)
        }

        this.components.push('substring(' + comps.join(',') + ')')

        return this
    }

    public toLower(): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()
        this.transformFunc = this.toLower
        this.components.push('tolower(' + this.property + ')')
        return this
    }

    public toUpper(): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()
        this.transformFunc = this.toUpper
        this.components.push('toupper(' + this.property + ')')

        return this
    }

    public trim(): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()
        this.transformFunc = this.trim
        this.components.push('trim(' + this.property + ')')
        return this
    }

    public endswith(value: string): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = Boolean()
        const escapedValue = encodeURIComponent(value)
        this.components.push(
            'endswith(' + this.property + ",'" + escapedValue + "')"
        )
        return this
    }

    public startswith(value: string): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = Boolean()
        const escapedValue = encodeURIComponent(value)
        this.components.push(
            'startswith(' + this.property + ",'" + escapedValue + "')"
        )
        return this
    }

    public length(): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = Number()
        this.components.push('length(' + this.property + ')')
        return this
    }

    public indexof(value: string): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = Number()
        this.components.push('indexof(' + this.property + ",'" + value + "')")
        return this
    }

    public replace(find: string, replace: string): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()
        const escapedFind = encodeURIComponent(find)
        const escapedReplace = encodeURIComponent(replace)
        this.components.push(
            'replace(' +
                this.property +
                ",'" +
                escapedFind +
                "','" +
                escapedReplace +
                "')"
        )
        return this
    }

    // Concat
    public Concat(concat: Concat): FilterClause {
        this.propertyIncluded = true
        this.funcReturnType = String()
        this.components.push(concat.toString())
        return this
    }

    // Date functions
    public day(): FilterClause {
        return Helpers.addMethodWrapper(this, 'day')
    }

    public hour(): FilterClause {
        return Helpers.addMethodWrapper(this, 'hour')
    }

    public minute(): FilterClause {
        return Helpers.addMethodWrapper(this, 'minute')
    }

    public month(): FilterClause {
        return Helpers.addMethodWrapper(this, 'month')
    }

    public second(): FilterClause {
        return Helpers.addMethodWrapper(this, 'second')
    }

    public year(): FilterClause {
        return Helpers.addMethodWrapper(this, 'year')
    }

    // Math functions
    public round(): FilterClause {
        return Helpers.addMethodWrapper(this, 'round')
    }

    public floor(): FilterClause {
        return Helpers.addMethodWrapper(this, 'floor')
    }

    public ceiling(): FilterClause {
        return Helpers.addMethodWrapper(this, 'ceiling')
    }
}

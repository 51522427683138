import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IUserNotificationChannelModel extends IStandardFieldsModel {
    userId: 'userId'
    user: 'user'

    notificationType: 'notificationType'

    notifyByEmail: 'notifyByEmail'
    notifyBySite: 'notifyBySite'
}

const userNotificationChannel: IUserNotificationChannelModel = {
    ...standardFieldsModel,
    userId: 'userId',
    user: 'user',

    notificationType: 'notificationType',

    notifyByEmail: 'notifyByEmail',
    notifyBySite: 'notifyBySite'
}

export const userNotificationChannelModel = Object.freeze(
    userNotificationChannel
)

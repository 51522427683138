import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtInstanceModel extends IStandardFieldsModel {
    projectCourtCaseId: 'projectCourtCaseId'
    projectCourtCase: 'courtCase'

    externalId: 'externalId'
    instanceNumber: 'instanceNumber'
    name: 'name'
    startDate: 'startDate'
    judges: 'judges'

    court: 'court'
    file: 'file'

    projectCourtDocument: 'moiArbitrDocuments'
    projectCourtInstanceEvent: 'instanceEvents'
}

const projectCourtInstance: IProjectCourtInstanceModel = {
    ...standardFieldsModel,
    projectCourtCaseId: 'projectCourtCaseId',
    projectCourtCase: 'courtCase',

    externalId: 'externalId',
    instanceNumber: 'instanceNumber',
    name: 'name',
    startDate: 'startDate',
    judges: 'judges',

    court: 'court',
    file: 'file',

    projectCourtDocument: 'moiArbitrDocuments',
    projectCourtInstanceEvent: 'instanceEvents'
}

export const projectCourtInstanceModel = Object.freeze(projectCourtInstance)

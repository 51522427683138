import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { CREATE, UPDATE, fetchStart, fetchEnd } from 'react-admin'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    disabled: {
        backgroundColor: 'gray'
    }
})

const Icon = (props) => (
    <SaveIcon
        style={!!props.disabled ? { color: '#bdbdbd' } : undefined}
        fontSize="small"
        color="primary"
    />
)
const Label = (props) => (
    <Typography
        style={!!props.disabled ? { color: '#bdbdbd' } : undefined}
        variant="button"
        color="primary"
    >
        Сохранить
    </Typography>
)

export default (props) => {
    const {
        data,
        res,
        onEdit,
        onExtra,
        onError,
        onSave,
        isEdit,
        isError,
        dontFetch,
        index,
        disabled
    } = props
    const classes = useStyles('')

    const onHandle = async () => {
        if (typeof onExtra === 'function') await onExtra()
        if (isError) {
            if (typeof onError === 'function') await onError()

            return
        }
        fetchStart()
        if (!isEdit && typeof onSave === 'function') {
            await onSave()
        } else if (isEdit && typeof onEdit === 'function') await onEdit()

        if (dontFetch === undefined) {
            if (!isEdit) await oDataProvider(CREATE, res, { data: data })
            else await oDataProvider(UPDATE, res, { id: index, data: data })
            fetchEnd()
        }
        if (!isEdit && typeof onSave === 'function') await onSave()
        else if (isEdit && typeof onEdit === 'function') await onEdit()
    }
    return (
        <Button
            variant="text"
            size="small"
            onClick={onHandle}
            disabled={disabled}
        >
            <Icon disabled={disabled} /> &nbsp;
            <Label disabled={disabled} />
        </Button>
    )
}

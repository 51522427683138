export class Helpers {
    public static formatValue(value: any): any {
        if (
            !!value &&
            value.length > 8 &&
            value.substring(0, 8) === 'datetime'
        ) {
            return value.replace('datetime', '').replace(/[']/g, '')
        }
        const isGuid = /([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})/
        if (typeof value === 'string' && isGuid.test(value)) {
            return value
        }

        if (typeof value === 'string') {
            const numberSuffixes = ['m', 'f', 'd']
            for (const suffix of numberSuffixes) {
                if (
                    value.indexOf(suffix, value.length - suffix.length) !== -1
                ) {
                    const numberValue = value.substring(0, value.length - 1)
                    if (!isNaN(+numberValue)) {
                        return value
                    }
                }
            }

            value = value.replace(/'/g, "''")
            return "'" + value + "'"
        }

        return value
    }
    public static addLogicalOperator(
        value: any,
        operator: any,
        filterClause: any
    ): any {
        filterClause.value = value
        filterClause.isClauseEmpty = false

        filterClause.components.push(operator + ' ' + this.formatValue(value))

        return filterClause
    }
    public static addArithmeticOperator(
        amount: any,
        operator: any,
        filterClause: any
    ): any {
        filterClause.components.push(operator + ' ' + amount)
        return filterClause
    }
    public static addMethodWrapper(filterClause: any, func: any): any {
        filterClause.propertyIncluded = true
        filterClause.funcReturnType = Number()
        const that = filterClause
        filterClause.components.push(func + '(' + that.property + ')')

        return filterClause
    }

    public static addCustomExpression(
        expression: string,
        filterClause: any
    ): any {
        filterClause.propertyIncluded = true
        filterClause.components.push(expression)
        return filterClause
    }
}

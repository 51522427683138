import { Log, User, UserManager } from 'oidc-client'
import { AuthConfiguration } from './authConfiguration'

export class AuthService {
    public userManager: UserManager

    constructor() {
        this.userManager = new UserManager(AuthConfiguration)
        Log.logger = console
        Log.level = Log.INFO
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser()
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect()
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent()
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect()
    }
}

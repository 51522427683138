import React, { useState, useEffect } from 'react'
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    DateField,
    DateInput,
    BooleanInput
} from 'react-admin'
import {
    versionedFileAttachmentModel as vf,
    fileAttachmentModel as fa,
    projectModel as pm,
    requestModel as rm,
    requestModel
} from '../../api/models'
import { rpValues } from '../../api/queryModifier'
import { Resources } from '../../api/api'
import { FileLink } from '../fields'
import { getFioFromReferencedPerson } from '../../utils/dataHelpers'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Link } from 'react-router-dom'
import { FileFavoriteListActions } from '../../layout/favoriteHidenToobars'
import { AddToFavorite } from '../fields'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const projectNotNullFilter = (data: boolean): string | undefined => {
    return !!data ? 'ownerProject ne null' : undefined
}

const requsetNotNullFilter = (data: boolean): string | undefined => {
    return !!data ? 'ownerRequest ne null' : undefined
}

const PersonalFileFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="По названию"
            source={`$startsWith(${vf.name})`}
            alwaysOn
        />
        <TextInput
            label="По расширению"
            source={`${vf.files}/any(x: contains(x/${fa.extension}, '${rpValues.value}'))`}
            alwaysOn
        />
        <TextInput
            label="По описанию"
            source={`$contains(${vf.description})`}
        />
        <DateInput
            label="По дате добавления"
            source={`$date(${vf.createdOn})`}
        />
        <DateInput
            label="По дате обновления"
            source={`$date(${vf.updatedOn})`}
        />
        <DateInput label="По дате файла" source={`$date(${vf.atDate})`} />
        <SelectAndSearchInput
            label="По проекту"
            source={vf.ownerProjectId}
            reference={Resources.Project}
            optionText={pm.name}
            alwaysOn={true}
            multi={false}
        />
        <SelectAndSearchInput
            label="По заявке"
            source={vf.ownerRequestId}
            reference={Resources.Request}
            optionText={rm.subject}
            alwaysOn={true}
            multi={false}
        />
        <SelectAndSearchInput
            label="Автор"
            source={vf.ownerUserId}
            reference={Resources.AppUser}
            optionRenderer={getFioFromReferencedPerson}
            alwaysOn={true}
            multi={false}
        />
        <BooleanInput
            label="Только файлы проектов"
            source={projectNotNullFilter}
            alwaysOn={true}
        />
        <BooleanInput
            label="Только файлы заявок"
            source={requsetNotNullFilter}
            alwaysOn={true}
        />
    </Filter>
)

const ProjectTitle = (props) => {
    const { ownerProjectId } = props.record
    if (!ownerProjectId) return <span>Не привязан</span>
    return (
        <Link
            onClick={(e) => e.stopPropagation()}
            style={{ textDecoration: 'none', color: 'rgb(0,170,60)' }}
            to={`${Resources.Project}/${ownerProjectId}/show`}
        >
            {props.record.ownerProject.name}
        </Link>
    )
}
ProjectTitle.defaultProps = { label: 'Проект' }

const RequestTitle = (props) => {
    const { ownerRequestId } = props.record
    if (!ownerRequestId) return <span>Не привязан</span>
    return (
        <Link
            onClick={(e) => e.stopPropagation()}
            style={{ textDecoration: 'none', color: 'rgb(0,170,60)' }}
            to={`${Resources.Request}/${ownerRequestId}/show`}
        >
            {props.record.ownerRequest.subject}
        </Link>
    )
}
RequestTitle.defaultProps = { label: 'Заявка' }

const PersonalFileListBase = (props) => {
    const classes = useStyles()
    const { setTitle, isFavorite, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Мои файлы'
    })
    setTitle(titles)

    const filter: any = {}
    if (!!isFavorite) filter.isFavorite = isFavorite

    return (
        <List
            {...rest}
            sort={{ field: vf.createdOn, order: 'DESC' }}
            filters={<PersonalFileFilter />}
            actions={<FileFavoriteListActions />}
            filter={filter}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Название" source={vf.name} />
                <FileLink />
                <ProjectTitle />
                <RequestTitle />
                <DateField label="Дата документа" source={vf.atDate} />
                <DateField label="Обновлен" source={vf.updatedOn} />
                <DateField label="Добавлен" source={vf.createdOn} />
                <AddToFavorite />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteFile
    }),
    {
        setTitle: titleAction
    }
)
export const PersonalFileList = enchanceList(PersonalFileListBase)

const IS_HIDDEN_REQUEST = 'SET_IS_HIDDEN_REQUEST'
const initialState = false

export const isHiddenRequestAction = (isHiddenRequest) => {
    const action = {
        type: IS_HIDDEN_REQUEST,
        isHiddenRequest
    }
    return action
}

export const isHiddenRequestReducer = (priviousState = initialState, action) =>
    action.type === IS_HIDDEN_REQUEST ? action.isHiddenRequest : priviousState

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IPersonModel extends IStandardFieldsModel {
    lastName: 'lastName'
    firstName: 'firstName'
    patronymicName: 'patronymicName'
    email: 'email'
    phone: 'phone'
    contactInformation: 'contacts'
    user: 'user'
    userId: 'userId'
    passports: 'passports'
    companiesEmployee: 'companiesEmployee'
    userFilters: 'userFilters'
}

const person: IPersonModel = {
    ...standardFieldsModel,
    lastName: 'lastName',
    firstName: 'firstName',
    patronymicName: 'patronymicName',
    email: 'email',
    phone: 'phone',
    contactInformation: 'contacts',
    user: 'user',
    userId: 'userId',
    passports: 'passports',
    companiesEmployee: 'companiesEmployee',
    userFilters: 'userFilters'
}

export const personModel = Object.freeze(person)

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtCaseParticipantModel
    extends IStandardFieldsModel {
    projectCourtCaseId: 'projectCourtCaseId'
    projectCourtCase: 'courtCase'

    participantType: 'participantType'

    name: 'name'
    address: 'address'
    inn: 'inn'
    ogrn: 'ogrn'
    externalId: 'externalId'
}

const projectCourtCaseParticipant: IProjectCourtCaseParticipantModel = {
    ...standardFieldsModel,
    projectCourtCaseId: 'projectCourtCaseId',
    projectCourtCase: 'courtCase',

    participantType: 'participantType',

    name: 'name',
    address: 'address',
    inn: 'inn',
    ogrn: 'ogrn',
    externalId: 'externalId'
}

export const projectCourtCaseParticipantModel = Object.freeze(
    projectCourtCaseParticipant
)

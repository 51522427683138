import * as React from 'react'
import { BlogCategoryForm } from './blogCategoryForm'
import { titleAction } from '../../reducers/titleReducer'
import { connect } from 'react-redux'
import Create from '../../layout/create'
import Edit from '../../layout/edit'

const BlogCategoryCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контент сайта'
    })
    titles.push({
        type: 'link',
        href: '/BlogCategory',
        value: 'Рубрики блога'
    })
    titles.push({
        type: 'text',
        value: 'Создание рубрики блога'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <BlogCategoryForm isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const BlogCategoryCreateView = enchanceCreate(BlogCategoryCreateViewBase)

const BlogCategoryEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <BlogCategoryForm isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const BlogCategoryEditView = enchanceEdit(BlogCategoryEditViewBase)

import { FilterClause } from './FilterClause'
import { FilterObj } from './FilterObj'
import { IFormatOptions } from './Options/IFormatOptions'
import { IInlineCountOptions } from './Options/IInlineCountOptions'
import { PrecedenceGroup } from './PrecedenceGroup'
import { ExpandSettings } from './Settings/ExpandSettings'
import { FilterSettings } from './Settings/FilterSettings'
import { FormatSettings } from './Settings/FormatSettings'
import { GetSettings } from './Settings/GetSettings'
import { InlineCountSettings } from './Settings/InlineCountSettings'
import { OrderBySettings } from './Settings/OrderBySettings'
import { QueryOptionSettings } from './Settings/QueryOptionSettings'
import { SelectSettings } from './Settings/SelectSettings'
import { SkipSettings } from './Settings/SkipSettings'
import { TopSettings } from './Settings/TopSettings'

export class Tso {
    public static literal(stringLiteral: string): string {
        return "'" + stringLiteral.toString() + "'"
    }

    public static datetime(datetime: string): string {
        return "datetime'" + datetime + "'"
    }

    public static guid(guid: string): string {
        return "guid'" + guid + "'"
    }

    public static v4guid(guid: string): string {
        return 'v4guid' + guid
    }

    public static decimal(decimal: number): string {
        return decimal + 'm'
    }

    public static double(double: number): string {
        return double + 'd'
    }

    public static single(single: number) {
        return single + 'f'
    }

    public baseUri: string
    public QueryOptionSettings: QueryOptionSettings
    public ExpandSettings: ExpandSettings
    public FilterSettings: FilterSettings
    public FormatSettings: FormatSettings
    public InlineCountSettings: InlineCountSettings
    public OrderBySettings: OrderBySettings
    public SelectSettings: SelectSettings
    public SkipSettings: SkipSettings
    public TopSettings: TopSettings
    public GetSettings: GetSettings
    public format: IFormatOptions
    public formatDefault: IFormatOptions
    public inlineCount: IInlineCountOptions
    public inlineCountDefault: IInlineCountOptions
    public currentHashRoute: string | null = null

    constructor(baseUri: string) {
        this.baseUri = baseUri
        this.OrderBySettings = new OrderBySettings()
        this.ExpandSettings = new ExpandSettings()
        this.FilterSettings = new FilterSettings()
        this.FormatSettings = new FormatSettings()
        this.InlineCountSettings = new InlineCountSettings()
        this.OrderBySettings = new OrderBySettings()
        this.SelectSettings = new SelectSettings()
        this.SkipSettings = new SkipSettings()
        this.TopSettings = new TopSettings()
        this.QueryOptionSettings = new QueryOptionSettings()
        this.GetSettings = new GetSettings()
        const contextThis = this

        this.format = {
            atom: () => {
                contextThis.FormatSettings.format = 'atom'
                return contextThis
            },
            custom: (value) => {
                contextThis.FormatSettings.format = value
                return contextThis
            },
            json: () => {
                contextThis.FormatSettings.format = 'json'
                return contextThis
            },
            xml: () => {
                contextThis.FormatSettings.format = 'xml'
                return contextThis
            }
        }
        this.formatDefault = {
            atom: () => {
                contextThis.FormatSettings.defaultFormat = 'atom'
                return contextThis
            },
            custom: (value) => {
                contextThis.FormatSettings.defaultFormat = value
                return contextThis
            },
            json: () => {
                contextThis.FormatSettings.defaultFormat = 'json'
                return contextThis
            },
            xml: () => {
                contextThis.FormatSettings.defaultFormat = 'xml'
                return contextThis
            }
        }

        this.inlineCount = {
            enable: () => {
                contextThis.InlineCountSettings.inlineCount = true
                return contextThis
            },
            none: () => {
                contextThis.InlineCountSettings.inlineCount = false
                return contextThis
            }
        }

        this.inlineCountDefault = {
            enable: () => {
                contextThis.InlineCountSettings.defaultInlineCount = true
                return contextThis
            },
            none: () => {
                contextThis.InlineCountSettings.defaultInlineCount = false
                return contextThis
            }
        }
    }

    public updateHashRoute(hashRoute: string): void {
        this.currentHashRoute = hashRoute
    }

    // order by
    public setOrderByDefault(property: string, order?: string): Tso {
        this.OrderBySettings.defaultProperty = property
        this.OrderBySettings.defaultOrder = order === undefined ? 'desc' : order
        return this
    }

    public toggleOrderBy(property: string, callback?: () => any): Tso {
        const useDesc =
            this.OrderBySettings.property === null ||
            this.OrderBySettings.order === 'asc'
        this.orderBy(property)[useDesc ? 'desc' : 'asc']()

        if (callback && typeof callback === 'function') {
            callback.call(this)
        }

        return this
    }

    public orderBy(property: string, direction?: string): Tso {
        this.OrderBySettings.property = property
        if (!!direction) {
            this.OrderBySettings.order = direction
        }
        return this
    }

    public desc(): Tso {
        this.OrderBySettings.order = 'desc'
        return this
    }

    public asc(): Tso {
        this.OrderBySettings.order = 'asc'
        return this
    }

    public resetOrderBy(): Tso {
        this.OrderBySettings.reset()
        return this
    }

    // top
    public setTopDefault(top: number): Tso {
        this.TopSettings.defaultTop = top
        return this
    }

    public top(top: number): Tso {
        this.TopSettings.top = top
        return this
    }

    public resetTop(): Tso {
        this.TopSettings.reset()
        return this
    }

    // skip
    public setSkipDefault(skip: number): Tso {
        this.SkipSettings.defaultSkip = skip
        return this
    }

    public skip(skip: number): Tso {
        this.SkipSettings.skip = skip
        return this
    }

    public resetSkip(): Tso {
        this.SkipSettings.reset()
        return this
    }

    // select
    public get(key: string): Tso {
        this.GetSettings.key = key
        return this
    }

    public setSelectDefault(select: string[]): Tso {
        this.SelectSettings.defaultSelect = select
        return this
    }

    public select(select: string[]): Tso {
        this.SelectSettings.select = select
        return this
    }

    public resetSelect(): Tso {
        this.SelectSettings.reset()
        return this
    }

    // expand
    public setExpandDefault(expand: string): Tso {
        this.ExpandSettings.defaultExpand = expand
        return this
    }

    public expand(expand: string): Tso {
        this.ExpandSettings.expand = expand
        return this
    }

    public resetExpand(): Tso {
        this.ExpandSettings.reset()
        return this
    }

    public queryOption(option: string): Tso {
        this.QueryOptionSettings.option = option
        return this
    }

    // format

    public resetFormat(): void {
        this.FormatSettings.reset()
    }

    // Inline count

    public resetInlineCount(): Tso {
        this.InlineCountSettings.reset()
        return this
    }

    // Filter
    public filter(filterClause: FilterClause | PrecedenceGroup): Tso {
        this.FilterSettings.Filters.push(new FilterObj(filterClause))
        return this
    }

    public andFilter(filterClause: FilterClause | PrecedenceGroup): Tso {
        this.FilterSettings.Filters.push(new FilterObj(filterClause, 'and'))
        return this
    }

    public orFilter(filterClause: FilterClause | PrecedenceGroup): Tso {
        this.FilterSettings.Filters.push(new FilterObj(filterClause, 'or'))
        return this
    }

    public removeFilter(property: string): Tso {
        if (!this.FilterSettings.isSet()) {
            return this
        }

        for (let i = 0; i < this.FilterSettings.Filters.length; i++) {
            if (
                this.FilterSettings.Filters[i].filterObj.property === property
            ) {
                this.FilterSettings.Filters.splice(i, 1)
            }
        }

        return this
    }

    public captureFilter(): void {
        this.FilterSettings.CapturedFilter = []
        for (const filter of this.FilterSettings.Filters) {
            this.FilterSettings.CapturedFilter.push(filter)
        }
    }

    public resetFilter(): Tso {
        this.FilterSettings.fullReset()
        return this
    }

    public resetToCapturedFilter(): Tso {
        this.FilterSettings.reset()
        return this
    }

    public defaultFilter(filterClause: FilterClause): Tso {
        this.FilterSettings.DefaultFilters.push(new FilterObj(filterClause))
        return this
    }

    public defaultAndFilter(filterClause: FilterClause): Tso {
        this.FilterSettings.DefaultFilters.push(
            new FilterObj(filterClause, 'and')
        )
        return this
    }

    public defaultOrFilter(filterClause: FilterClause): Tso {
        this.FilterSettings.DefaultFilters.push(
            new FilterObj(filterClause, 'or')
        )
        return this
    }

    // Casts
    public toString(): string {
        let url: any
        let components: any

        url = this.baseUri
        components = []

        if (this.GetSettings.isSet()) {
            return url + this.GetSettings.toString()
        }

        if (this.QueryOptionSettings.isSet()) {
            components.push(this.QueryOptionSettings.toString())
        }

        if (this.OrderBySettings.isSet()) {
            components.push(this.OrderBySettings.toString())
        }

        if (this.TopSettings.isSet()) {
            components.push(this.TopSettings.toString())
        }

        if (this.SkipSettings.isSet()) {
            components.push(this.SkipSettings.toString())
        }

        if (this.SelectSettings.isSet()) {
            components.push(this.SelectSettings.toString())
        }

        if (this.FilterSettings.isSet()) {
            components.push(this.FilterSettings.toString())
        }

        if (this.ExpandSettings.isSet()) {
            components.push(this.ExpandSettings.toString())
        }

        if (this.FormatSettings.isSet()) {
            components.push(this.FormatSettings.toString())
        }

        if (this.InlineCountSettings.isSet()) {
            components.push(this.InlineCountSettings.toString())
        }

        return components.length > 0 ? url + '?' + components.join('&') : url
    }

    public toJson(): string {
        const jsonObj: any = {}

        jsonObj.baseUri = this.baseUri
        jsonObj.currentHashRoute = this.currentHashRoute

        jsonObj.OrderBySettings = null
        jsonObj.TopSettings = null
        jsonObj.SkipSettings = null
        jsonObj.SelectSettings = null
        jsonObj.ExpandSettings = null
        jsonObj.FormatSettings = null
        jsonObj.InlineCountSettings = null
        jsonObj.FilterSettings = null

        jsonObj.defaults = (this as any).defaults

        if (this.OrderBySettings.isSet()) {
            jsonObj.OrderBySettings = this.OrderBySettings
        }

        if (this.TopSettings.isSet()) {
            jsonObj.TopSettings = this.TopSettings
        }

        if (this.SkipSettings.isSet()) {
            jsonObj.SkipSettings = this.SkipSettings
        }

        if (this.SelectSettings.isSet()) {
            jsonObj.SelectSettings = this.SelectSettings
        }

        if (this.ExpandSettings.isSet()) {
            jsonObj.ExpandSettings = this.ExpandSettings
        }

        if (this.FormatSettings.isSet()) {
            jsonObj.FormatSettings = this.FormatSettings
        }

        if (this.InlineCountSettings.isSet()) {
            jsonObj.InlineCountSettings = this.InlineCountSettings
        }

        if (this.FilterSettings.isSet()) {
            jsonObj.FilterSettings = this.FilterSettings
        }

        return JSON.stringify(jsonObj)
    }
}

import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    DateField,
    BooleanField,
    ReferenceField,
    TextField,
    TextInput,
    AutocompleteInput,
    CardActions,
    RefreshButton
} from 'react-admin'
import {
    appUserModel as au,
    personModel as pm,
    userRoleModel as ur
} from '../../api/models'
import { FullNameField } from '../fields/fullNameField'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Link } from 'react-router-dom'
import { Button } from 'react-admin'
import { People as PeopleIcon } from '@material-ui/icons'
import { Resources } from '../../api/api'
import { RoleTypeById } from '../../api/publicOjects'
import { RolesId } from '../../api/publicOjects'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const UserFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="Имя пользователя"
            source={`$contains(${au.userName})`}
            alwaysOn={true}
        />
        <TextInput
            label="Имя"
            source={`$contains(${au.person}/${pm.firstName})`}
            alwaysOn={true}
        />
        <TextInput
            label="Фамилия"
            source={`$contains(${au.person}/${pm.lastName})`}
            alwaysOn={true}
        />
        <TextInput
            label="Отчество"
            source={`$contains(${au.person}/${pm.patronymicName})`}
        />
        <AutocompleteInput
            label="Роль"
            source={`${au.userRoles}/any(x: x/${ur.roleId} eq #value#)`}
            choices={RolesId}
        />
    </Filter>
)

const UserListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    ...rest
}) => (
    <CardActions>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button'
            })}
        <RefreshButton />
        <Button
            label="Создать пользователя"
            color="primary"
            variant="flat"
            component={Link}
            to={`/${Resources.AppUser}/create`}
        >
            <PeopleIcon />
        </Button>
    </CardActions>
)

function RoleField(props) {
    if (!!props.record.userRoles && !props.record.userRoles.length) return null
    return <span>{RoleTypeById[props.record.userRoles[0].roleId]}</span>
}

function UserListBase(props) {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Пользователи'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: au.userName, order: 'ASC' }}
            filters={<UserFilter />}
            actions={<UserListActions {...rest} />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Имя пользователя" source={au.userName} />
                <ReferenceField
                    label="ФИО"
                    source={`${au.person}.${pm.id}`}
                    reference={Resources.Person}
                    allowEmpty={true}
                    sortable={false}
                >
                    <FullNameField />
                </ReferenceField>
                <BooleanField
                    label="Email подтвержден"
                    source={`${au.emailConfirmed}`}
                />
                <DateField
                    label="Заблокирован до"
                    source={`${au.lockoutEnd}`}
                />
                <RoleField label="Роль" source={au.role} sortable={false} />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const UserList = enchanceList(UserListBase)

const IS_FAVORITE_REQUEST = 'SET_IS_FAVORITE_REQUEST'
const initialState = false

export const isFavoriteRequestAction = (isFavoriteRequest) => {
    const action = {
        type: IS_FAVORITE_REQUEST,
        isFavoriteRequest
    }
    return action
}

export const isFavoriteRequestReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_FAVORITE_REQUEST
        ? action.isFavoriteRequest
        : priviousState

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtCaseModel extends IStandardFieldsModel {
    caseId: 'caseId'
    caseNumber: 'caseNumber'
    caseType: 'caseType'
    startDate: 'startDate'
    finished: 'finished'

    projectId: 'projectId'
    project: 'project'

    receivedInfoJson: 'receivedInfoJson'

    courtHearings: 'courtHearings'
    caseInstances: 'caseInstances'
    projectCourtCaseParticipant: 'participants'
}

const projectCourtCase: IProjectCourtCaseModel = {
    ...standardFieldsModel,
    caseId: 'caseId',
    caseNumber: 'caseNumber',
    caseType: 'caseType',
    startDate: 'startDate',
    finished: 'finished',

    projectId: 'projectId',
    project: 'project',

    receivedInfoJson: 'receivedInfoJson',

    courtHearings: 'courtHearings',
    caseInstances: 'caseInstances',
    projectCourtCaseParticipant: 'participants'
}

export const projectCourtCaseModel = Object.freeze(projectCourtCase)

import React from 'react'
import {
    SelectInput,
    TextInput,
    ReferenceInput,
    required,
    FormDataConsumer,
    DateTimeInput,
    SimpleForm,
    ReferenceField,
    TextField
} from 'react-admin'
import {
    LetterOfAuthorityType,
    LetterOfAuthorityStatus,
    choice
} from './lettersOfAuthorityForm'
import {
    projectModel as pm,
    personModel as per,
    letterOfAuthorityModel as la
} from '../../api/models/'
import { withStyles } from '@material-ui/styles'
import { getFio } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import { ToolbarGeneral } from '../../layout'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const validate = (values) => {
    const errors: any = {}
    return errors
}
const personSelect = [per.id, per.firstName, per.lastName, per.patronymicName]
export const LettersOfAuthorityInput = (props: any) => {
    const { isEditToolbar, classes, setTitle, ...rest } = props

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Документы'
        })
        titles.push({
            type: 'link',
            href: '/LetterOfAuthority',
            value: 'Договоры'
        })
        titles.push({
            type: 'text',
            value: `Редактировать договор номер ${props.record.number} - ${props.record.name}`
        })
        setTitle(titles)
    }

    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <SelectAndSearchInput
                label="Проект"
                source="projectId"
                reference={Resources.Project}
                filter={{
                    ignoreDefaultExpand: true,
                    ignoreDefaultSelect: true,
                    customSelect: [pm.id, pm.name]
                }}
                optionText="name"
                style={{ width: 255 }}
                multi={false}
            />
            <TextInput label="Номер" source={la.number} validate={required()} />
            <TextInput
                label="Название"
                source={la.name}
                validate={required()}
            />

            <DateTimeInput
                label="Дата выдачи"
                source={la.issuedAt}
                validate={required()}
            />
            <DateTimeInput
                label="Дата истечения"
                source={la.expireAt}
                validate={required()}
            />

            <SelectInput
                label="Тип доверенности"
                source={la.type}
                choices={LetterOfAuthorityType}
                validate={required()}
            />

            <SelectInput
                label="Статус"
                source={la.status}
                choices={LetterOfAuthorityStatus}
                validate={required()}
            />

            <SelectInput
                label="Классификация"
                source="classificationChoice"
                choices={choice}
                defaultValue={choice[0].id}
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.classificationChoice === 'fromList') {
                        return (
                            <SelectAndSearchInput
                                label="Классификация"
                                source="classificationDictValueId"
                                reference={
                                    Resources.LetterOfAuthorityClassificationDictValue
                                }
                                optionText="value"
                                style={{ width: 255 }}
                                validate={required()}
                                multi={false}
                            />
                        )
                    } else if (
                        formData.classificationChoice === 'fromTextInput'
                    ) {
                        return (
                            <TextInput
                                style={{ width: 255 }}
                                label="Классификация"
                                source="classificationValue"
                                validate={required()}
                            />
                        )
                    }
                    return <div />
                }}
            </FormDataConsumer>

            <SelectInput
                label="Место регистрации"
                source="requesterChoice"
                choices={choice}
                defaultValue={choice[0].id}
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.requesterChoice === 'fromList') {
                        return (
                            <SelectAndSearchInput
                                label="Места из справочника"
                                source="requesterDictValueId"
                                reference={
                                    Resources.LetterOfAuthorityRequesterDictValue
                                }
                                optionText="value"
                                style={{ width: 255 }}
                                validate={required()}
                                multi={false}
                            />
                        )
                    } else if (formData.requesterChoice === 'fromTextInput') {
                        return (
                            <TextInput
                                style={{ width: 255 }}
                                label="Место регистрации"
                                source="requesterValue"
                                validate={required()}
                            />
                        )
                    }
                    return <div />
                }}
            </FormDataConsumer>

            <SelectAndSearchInput
                label="Доверитель"
                source={la.personTrusteeId}
                reference={Resources.Person}
                filter={{
                    customSelect: personSelect,
                    ignoreDefaultExpand: true,
                    ignoreDefaultSelect: true
                }}
                optionText="lastName"
                optionRenderer={getFio}
                style={{ width: 255 }}
                validate={required()}
                multi={false}
            />

            <SelectAndSearchInput
                label="Доверительное лицо"
                source={la.toPersonId}
                reference={Resources.Person}
                filter={{
                    customSelect: personSelect,
                    ignoreDefaultExpand: true,
                    ignoreDefaultSelect: true
                }}
                optionText="lastName"
                optionRenderer={getFio}
                style={{ width: 255 }}
                validate={required()}
                multi={false}
            />
        </SimpleForm>
    )
}

export interface IAppRoleModel {
    id: 'id'
    isDeleted: 'isDeleted'
    name: 'name'
    normalizedName: 'normalizedName'
}

const appRole: IAppRoleModel = {
    id: 'id',
    isDeleted: 'isDeleted',
    name: 'name',
    normalizedName: 'normalizedName'
}

export const appRoleModel = Object.freeze(appRole)

import { seoEnabledEntity, ISeoEnabledEntity } from '../'

export interface IBlogModel extends ISeoEnabledEntity {
    categoryId: 'categoryId'
    blogCategory: 'blogCategory'
    content: 'content'
    isPublished: 'isPublished'
    author: 'author'
}

const blog: IBlogModel = {
    ...seoEnabledEntity,
    categoryId: 'categoryId',
    blogCategory: 'blogCategory',
    content: 'content',
    isPublished: 'isPublished',
    author: 'author'
}

export const blogModel = Object.freeze(blog)

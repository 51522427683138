import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    NumberField,
    TextInput,
    ReferenceInput,
    SelectInput,
    DeleteButton,
    SelectField
} from 'react-admin'
import { InvoiceStatuses } from './invoiceForm'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { Resources } from '../../api/api'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const InvoiceFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="Название"
            source="$startsWith(title)"
            alwaysOn={true}
        />
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    </Filter>
)

const InvoiceListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Счета на оплату'
    })
    setTitle(titles)

    return (
        <List
            {...props}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<InvoiceFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Название" source="title" />
                <NumberField label="Сумма счета" source="requestedAmount" />
                <SelectField
                    label="Статус"
                    source="status"
                    choices={InvoiceStatuses}
                />
                <NumberField label="Оплачено" source="зaidAmount" />
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    reference={Resources.Project}
                    allowEmpty={true}
                    linkType="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="Клиент"
                    source="customerId"
                    reference={Resources.Customer}
                    allowEmpty={true}
                >
                    <TextField source="displayName" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const InvoiceList = enchanceList(InvoiceListBase)

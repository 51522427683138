/**
 * Everything in this file is PoC and has to be refactored once acceptance tests will be written.
 */

import { showNotification as showNotificationAction } from 'ra-core'
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from 'react-admin'
import { FilterClause, Tso } from '../utils/ts-odata'
import { FilterToODataConverter } from './filterToODataConverter'
import { Resources } from './api'
import { get } from 'lodash'
import queryModifier from './queryModifier'

interface IDataProviderResult {
    options?: {
        body?: any
        credentials?: string
        method: string
        headers?: any
    }
    url: string
}

// tslint:disable-next-line:no-string-literal
export const ROOT_URL: string = (window as any)['apiRootUrl'] || ''
export const AUTH_URL: string = (window as any)['authServer'] || ''
export const API_URL = '/odata'

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

function convertDataProviderRequestToHTTP(
    type: string,
    resource: Resources,
    params: any
): IDataProviderResult {
    const odataQuery = new Tso(`${API_URL}/${resource}`)
    let filter = !!params.filter ? params.filter : {}

    if (filter.isIgnoreFilters === true) filter = null
    else if (filter.isIgnoreFilters === false) delete filter.isIgnoreFilters
    console.log(odataQuery, filter, params)

    const showDeleted = get(filter, 'showDeleted', false)
    queryModifier(odataQuery, resource, params)

    switch (type) {
        case GET_LIST: {
            FilterToODataConverter.convertToODataQuery(
                odataQuery,
                filter,
                params.pagination,
                params.sort
            )
            !!showDeleted
                ? odataQuery.andFilter(
                      new FilterClause(
                          `isDeleted eq true || isDeleted eq false`
                      )
                  )
                : odataQuery.andFilter(new FilterClause(`isDeleted eq false`))
            const query = odataQuery.toString()
            console.log(query)
            return {
                options: { method: 'GET' },
                url: query
            }
        }
        case GET_ONE: {
            if (resource !== 'Attachment' && resource !== 'FileMetadata')
                odataQuery.filter(new FilterClause('Id').withId(params.id))
            else odataQuery.filter(new FilterClause('Id').eq(params.id))

            !!showDeleted
                ? odataQuery.andFilter(
                      new FilterClause(
                          `isDeleted eq true || isDeleted eq false`
                      )
                  )
                : odataQuery.andFilter(new FilterClause(`isDeleted eq false`))

            const query = odataQuery.toString()
            console.log(query)

            return {
                options: { method: 'GET' },
                url: query
            }
        }
        case GET_MANY: {
            for (const id of params.ids) {
                if (
                    resource !== Resources.Attachment &&
                    resource !== Resources.FileMetadata
                )
                    odataQuery.orFilter(new FilterClause('Id').withId(id))
                else odataQuery.orFilter(new FilterClause('Id').eq(id))
            }

            if (filter.isIgnoreFilters === true) filter = null
            else if (filter.isIgnoreFilters === false)
                delete filter.isIgnoreFilters
            FilterToODataConverter.convertToODataQuery(
                odataQuery,
                filter,
                params.pagination,
                params.sort
            )

            !!showDeleted
                ? odataQuery.andFilter(
                      new FilterClause(
                          `isDeleted eq true || isDeleted eq false`
                      )
                  )
                : odataQuery.andFilter(new FilterClause(`isDeleted eq false`))

            const query = odataQuery.toString()
            console.log(query)

            return {
                options: { method: 'GET' },
                url: query
            }
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination
            const { field, order } = params.sort
            const manyReferenceFilter = {
                ...params.filter,
                [params.target]: params.id
            }
            odataQuery.inlineCount.enable()
            for (const prop in manyReferenceFilter) {
                if (!manyReferenceFilter.hasOwnProperty(prop)) continue
                odataQuery.andFilter(
                    new FilterClause(prop).eq(manyReferenceFilter[prop])
                )
            }
            odataQuery
                .skip((page - 1) * perPage)
                .top(perPage)
                .orderBy(field, order)

            !!showDeleted
                ? odataQuery.andFilter(
                      new FilterClause(
                          `isDeleted eq true || isDeleted eq false`
                      )
                  )
                : odataQuery.andFilter(new FilterClause(`isDeleted eq false`))

            const query = odataQuery.toString()
            console.log(query)
            return {
                options: { method: 'GET' },
                url: query
            }
        }
        case UPDATE: {
            if (resource === Resources.UserNotificationChannel) {
                const {
                    notifyBySite,
                    notifyByEmail,
                    notificationType
                } = params.data

                !!showDeleted
                    ? odataQuery.andFilter(
                          new FilterClause(
                              `isDeleted eq true || isDeleted eq false`
                          )
                      )
                    : odataQuery.andFilter(
                          new FilterClause(`isDeleted eq false`)
                      )

                const query = odataQuery.toString()
                console.log(query)

                return {
                    options: {
                        body: JSON.stringify([
                            { notifyBySite, notifyByEmail, notificationType }
                        ]),
                        method: 'POST'
                    },
                    url: query
                }
            }
            odataQuery.get(params.id)

            const query = odataQuery.toString()
            console.log(query)

            return {
                options: {
                    body: JSON.stringify(params.data),
                    method: 'PATCH'
                },
                url: query
            }
        }
        case CREATE: {
            if (params.data && params.data.attachments) {
                const formData = new FormData()
                const { attachments, ...otherData } = params.data
                formData.append('file', attachments.rawFile, attachments.title)
                for (const data in otherData) {
                    formData.append(data, otherData[data])
                }

                const query = `${API_URL}/${resource}`
                console.log(query)

                return {
                    options: {
                        body: formData,
                        method: 'POST'
                    },
                    url: `${API_URL}/${resource}`
                }
            }

            const query = `${API_URL}/${resource}`
            console.log(query)

            return {
                options: {
                    body: JSON.stringify(params.data),
                    method: 'POST'
                },
                url: query
            }
        }
        case DELETE: {
            const query = `${API_URL}/${resource}(${params.id})`
            console.log(query)

            return {
                options: { method: 'DELETE' },
                url: query
            }
        }
        case DELETE_MANY: {
            const multipleKeys = params.ids.map((e) => `keys=${e}`).join('&')

            const query = `${API_URL}/${resource}/deletemany?${multipleKeys}`
            console.log(query)

            return {
                options: { method: 'DELETE' },
                url: query
            }
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`)
    }
}

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponse = (
    response: any,
    type: any,
    resource: string,
    params: any
) => {
    const { json } = response
    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
            let value
            if (resource === 'EmployeeGroupUser') {
                value =
                    json.value &&
                    Array.isArray(json.value) &&
                    json.value.map((e: any) => ({
                        ...e,
                        id: `EmployeeGroupId=${e.employeeGroupId},EmployeeId=${e.employeeId}`
                    }))
            } else {
                value = json.value
            }

            return {
                data: value,
                ids: value.map((v: any) => v.id),
                total: json['@odata.count'] || value.length
            }
        case GET_MANY:
            return {
                data: json.value,
                ids: json.value.map((v: any) => v.id)
            }
        case GET_ONE:
            return { data: json.value[0] }
        case CREATE:
            return { data: { ...params.data, id: (json && json.id) || null } }
        case DELETE:
            return { data: { ...params.data, id: (json && json.id) || null } }
        case DELETE_MANY:
            return { data: params.ids }
        case UPDATE:
            return { data: { ...params.data, id: (json && json.id) || null } }
        default:
            return { data: params }
    }
}

interface IParams {
    filter: {
        customFilter: string
        customExpand: string
        customSelect: string
        ignoreDefaultFilter: string
        ignoreDefaultExpand: string
        ignoreDefaultSelect: string
    }
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param params
 * @returns {Promise} the Promise for response
 */
export default async (
    type: any,
    resource: Resources,
    params: any | IParams
) => {
    const { fetchJson } = fetchUtils
    const { url, options } = convertDataProviderRequestToHTTP(
        type,
        resource,
        params
    )
    if (options) {
        options.headers = new Headers({
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        })
        options.credentials = 'include'
    }

    const rootURL = ROOT_URL.startsWith('http')
        ? ROOT_URL
        : `${document.location.protocol}//${document.location.host}`
    const urlStruct = new URL(
        resource.startsWith('http') ? `${resource}` : `${rootURL}${url}`
    )

    return fetchJson(`${urlStruct.toString()}`, options).then((response: any) =>
        convertHTTPResponse(response, type, resource, params)
    )
}

export const filterHashCode = (s: string): string => {
    let hash = 0
    if (!s || s.length === 0) {
        return hash.toString()
    }
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i)
        // tslint:disable-next-line:no-bitwise
        hash = (hash << 5) - hash + char
        // tslint:disable-next-line:no-bitwise
        hash = hash & hash // Convert to 32bit integer
    }
    return `${s.length}-${hash}`
}

export const customRequest = (
    dispatch: any,
    url: string,
    data: any,
    method: string = 'POST',
    silent: boolean = false
): Promise<boolean> => {
    // print the form values to the console
    const fullUrl = `${ROOT_URL}${url}`
    const options = { method, body: JSON.stringify(data) }
    return new Promise((resolve, reject) => {
        fetchUtils
            .fetchJson(fullUrl, options)
            .then((response: any) => resolve(response.json))
            .catch((error: any) => {
                // tslint:disable-next-line:no-console
                console.error(error)
                dispatch(showNotificationAction(error.toString(), 'warning'))
                return silent ? resolve(true) : reject(error)
            })
    })
}

const stdLen = (time): string =>
    time.toString().length === 1 ? `0${time}` : time.toString()
const addDays = (date, days) => {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}
const castToNormal = (date) => {
    const dateB =
        date.getFullYear() +
        '-' +
        stdLen(date.getMonth() + 1) +
        '-' +
        stdLen(date.getDate())
    const timeB =
        stdLen(date.getHours()) +
        ':' +
        stdLen(date.getMinutes()) +
        ':' +
        stdLen(date.getSeconds())
    return `${dateB}T${timeB}Z`
}

interface IDataProviderResult {
    options?: {
        body?: any
        credentials?: string
        method: string
        headers?: any
    }
    url: string
}

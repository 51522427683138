import * as React from 'react'
import { getFio } from '../../utils/dataHelpers'

export class FullNameField extends React.Component<any, any> {
    public static defaultProps = {
        addLabel: true
    }

    public render() {
        const { record } = this.props
        if (!record) {
            return null
        }
        const fio = getFio(record)
        console.log('record', record, fio)
        return <span>{fio}</span>
    }
}

export class UserFullNameField extends React.Component<any, any> {
    public static defaultProps = {
        addLabel: true
    }

    public render() {
        const { record } = this.props
        console.log('fullname field props', this.props)
        if (!record || !record.person) {
            return null
        }
        const fio = getFio(record.person)

        console.log('user full name field: ', record.person, fio)
        return <span>{fio}</span>
    }
}

export class UserLinkFullNameField extends React.Component<any, any> {
    public static defaultProps = {
        addLabel: true
    }

    public render() {
        const { record } = this.props
        if (!record || !record.user || !record.user.person) {
            return null
        }
        const fio = getFio(record.user.person)
        return <span>{fio}</span>
    }
}

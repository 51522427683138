import * as React from 'react'
import { Create, Edit } from 'react-admin'
import { BlogForm } from './blogForm'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'

const BlogCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []

    titles.push({
        type: 'text',
        value: 'Контент сайта'
    })
    titles.push({
        type: 'link',
        href: '/Blog',
        value: 'Блог'
    })
    titles.push({
        type: 'text',
        value: 'Создание блога'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <BlogForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const BlogCreateView = enchanceCreate(BlogCreateViewBase)

const BlogEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <BlogForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const BlogEditView = enchanceEdit(BlogEditViewBase)

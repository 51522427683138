import * as React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'
import { ColorField } from 'react-admin-color-input'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ProjectTemplateFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Заголовок" source="$startsWith(title)" />
    </Filter>
)

const ProjectTemplateListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'text',
        value: 'Шаблоны проектов'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<ProjectTemplateFilter />}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <ColorField label="Цвет" source="color" />
                <TextField label="Название шаблона" source="templateName" />
                <TextField
                    label="Шаблон номера проекта"
                    source="numberTemplate"
                />
                <TextField label="Шаблон имени проекта" source="nameTemplate" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const ProjectTemplateList = enchanceList(ProjectTemplateListBase)

import React from 'react'
import { Edit } from 'react-admin'
import { DefaultFormActions } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { ContactInput } from './contactInput'
import Create from '../../layout/create'

const ContactCreateViewBase = (props) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'link',
        href: '/Person',
        value: 'Люди'
    })
    titles.push({
        type: 'text',
        value: 'Создание контакта'
    })
    setTitle(titles)

    return (
        <Create actions={<DefaultFormActions {...rest} />} {...rest}>
            <ContactInput isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const ContactCreateView = enchanceCreate(ContactCreateViewBase)

export const ContactEditViewBase = (props) => {
    const { setTitle, ...rest } = props
    return (
        <Edit actions={<DefaultFormActions {...rest} />} {...rest}>
            <ContactInput isEditToolbar={true} setTitle={setTitle} {...rest} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ContactEditView = enchanceEdit(ContactEditViewBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ISeoEnabledEntity extends IStandardFieldsModel {
    title: 'title'
    titleLong: 'titleLong'
    slug: 'slug'
    path: 'path'
    metaTitle: 'metaTitle'
    metaKeywords: 'metaKeywords'
    metaDescription: 'metaDescription'
}

const seoEnabledEntityBase: ISeoEnabledEntity = {
    ...standardFieldsModel,
    title: 'title',
    titleLong: 'titleLong',
    slug: 'slug',
    path: 'path',
    metaTitle: 'metaTitle',
    metaKeywords: 'metaKeywords',
    metaDescription: 'metaDescription'
}

export const seoEnabledEntity = Object.freeze(seoEnabledEntityBase)

import React from 'react'
import { EditButton } from 'react-admin'
import { CardActions } from '@material-ui/core'

const style = {
    display: 'flex',
    justifyContent: 'flex-end'
}

export default (props) => {
    return (
        <CardActions style={props.style || style}>
            <EditButton basePath={props.basePath} record={props.data} />
        </CardActions>
    )
}

import * as React from 'react'
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    withStyles,
    Typography
} from '@material-ui/core'
import { Link, LinkProps } from 'react-router-dom'
import { translate } from 'react-admin'
import { compose } from 'recompose'
import { CardIcon } from '../cardIcon'
import { BusinessCenter as WidgetIcon } from '@material-ui/icons'
import { checkPermissionsEmployeeAndUp } from '../../auth/authHelpers'
import { ProjectStatuses } from '../../project/projectForm'

const style = (theme) => ({
    root: {
        flex: 1
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right'
    },
    avatar: {
        background: theme.palette.background.avatar
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary
    }
})

const ProjectStatusesMap = {
    New: 'Новый',
    Active: 'Активный',
    Closed: 'Закрыт',
    Postponed: 'Отложен'
}

const LinkToProjects = (props) => <Link to={'/Project/create'} {...props} />

const MyProjectsComponent = ({
    projects = [],
    permissions,
    translate,
    classes
}) => (
    <div className={classes.root}>
        <CardIcon Icon={WidgetIcon} bgColor="#31708f" />
        <Card className={classes.card}>
            <CardHeader title={'Проекты'} />
            <List dense={true}>
                {projects.map((record: any, i) => (
                    <ListItem
                        key={`key-${i}`}
                        button={true}
                        component={({ children, ...props }) => (
                            <Link
                                to={`/Project/${record.id}/show`}
                                {...(props as LinkProps)}
                            >
                                {children}
                            </Link>
                        )}
                    >
                        <ListItemText
                            primary={new Date(record.startDate).toLocaleString(
                                'ru-RU'
                            )}
                            secondary={`${record.number} ${record.name}`}
                        />
                        <ListItemSecondaryAction>
                            <Typography className={classes.cost}>
                                {ProjectStatusesMap[record.status]}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            {checkPermissionsEmployeeAndUp(permissions) && (
                <CardActions>
                    <Button
                        variant={'contained'}
                        color="primary"
                        component={LinkToProjects}
                    >
                        Новый проект
                    </Button>
                </CardActions>
            )}
        </Card>
    </div>
)

export const MyProjects = compose(
    withStyles(style as any),
    translate
)(MyProjectsComponent)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IFileAttachmentModel extends IStandardFieldsModel {
    name: 'name'
    extension: 'extension'

    internalGuid: 'internalGuid'
    googleDriveExternalToken: 'googleDriveExternalToken'
    googleDriveInternalToken: 'googleDriveInternalToken'
    externalUrl: 'externalUrl'

    versionedFileId: 'versionedFileId'
    versionedFile: 'versionedFile'
}

const fileAttachment = {
    ...standardFieldsModel,
    name: 'name',
    extension: 'extension',

    internalGuid: 'internalGuid',
    googleDriveExternalToken: 'googleDriveExternalToken',
    googleDriveInternalToken: 'googleDriveInternalToken',
    externalUrl: 'externalUrl',

    versionedFileId: 'versionedFileId',
    versionedFile: 'versionedFile'
}

export const fileAttachmentModel = Object.freeze(fileAttachment)

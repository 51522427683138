import React, { FC } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { Fab } from '@material-ui/core'

type Props = {
    classes: any
    to?: string
}

export const FabDashboard: FC<Props> = ({ classes, to }) => (
    <Fab className={classes.fab} color="primary" aria-label="add">
        <Button color="inherit" component={Link} to={to || '/Request/create'}>
            <AddIcon color="inherit" />
        </Button>
    </Fab>
)

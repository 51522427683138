export interface IPersonContactModel {
    infoRecordType: 'type'
    contact: 'contact'
    comment: 'comment'
}

const personContact: IPersonContactModel = {
    infoRecordType: 'type',
    contact: 'contact',
    comment: 'comment'
}

export const personContactModel = Object.freeze(personContact)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IProjectModel extends IStandardFieldsModel {
    number: 'number'
    name: 'name'
    note: 'note'
    description: 'description'

    color: 'color'

    budget: 'budget'

    customerId: 'customerId'
    customer: 'customer'

    status: 'status'
    startDate: 'startDate'
    endDate: 'endDate'

    projectTemplateId: 'projectTemplateId'
    projectTemplate: 'projectTemplate'
    commentListId: 'commentListId'
    commentList: 'commentList'

    requestId: 'requestId'
    request: 'request'

    projectParticipants: 'participants'
    procedures: 'procedures'
    projectTask: 'tasks'
    phases: 'phases'

    legalContracts: 'legalContracts'
    lettersOfAuthority: 'lettersOfAuthority'

    userFilters: 'userFilters'
    versionedFileAttachment: 'versionedFiles'
    projectTimelineEvent: 'timelineEvents'

    assignSelf: 'assignSelf'
}

const project: IProjectModel = {
    ...standardFieldsModel,
    number: 'number',
    name: 'name',
    note: 'note',
    description: 'description',

    color: 'color',

    budget: 'budget',

    customerId: 'customerId',
    customer: 'customer',

    status: 'status',
    startDate: 'startDate',
    endDate: 'endDate',

    projectTemplateId: 'projectTemplateId',
    projectTemplate: 'projectTemplate',
    commentListId: 'commentListId',
    commentList: 'commentList',

    requestId: 'requestId',
    request: 'request',

    projectParticipants: 'participants',
    procedures: 'procedures',
    projectTask: 'tasks',
    phases: 'phases',

    legalContracts: 'legalContracts',
    lettersOfAuthority: 'lettersOfAuthority',

    userFilters: 'userFilters',
    versionedFileAttachment: 'versionedFiles',
    projectTimelineEvent: 'timelineEvents',

    assignSelf: 'assignSelf'
}

export const projectModel = Object.freeze(project)

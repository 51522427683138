import * as React from 'react'
import { ColorInput } from 'react-admin-color-input'
import { InplaceEditButton } from '../../fields/inplaceEditButton/inplaceEditButton'
import { LongTextInput, NumberInput } from 'react-admin'
import {
    TextInput,
    required,
    TabbedForm,
    FormTab,
    ReferenceManyField,
    TextField,
    Datagrid
} from 'react-admin'
import { AddNewProcedureTemplateButton } from './addNewProcedureButton'
import { AddNewTaskTemplateButton } from './addNewTaskTemplateButton'
import { AddNewPhaseTemplateButton } from './addNewPhaseTemplateButton'
import { ToolbarGeneral } from '../../../layout/bar'
import { connect } from 'react-redux'
import { Resources } from '../../../api/api'
import { titleAction } from '../../../reducers/titleReducer'
import Edit from '../../../layout/edit'
import Create from '../../../layout/create'

const validate = () => {
    const erros: any = {}
    return erros
}

const addProcedureForm = (props: any) => (
    <React.Fragment>
        <TextInput source="title" />
        <LongTextInput source="Decription" />
        <NumberInput source="maxDurationDays" />
    </React.Fragment>
)

export const ProjectTemplateForm = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Настройка процесса'
        })
        titles.push({
            type: 'link',
            href: '/ProjectTemplate',
            value: 'Шаблоны проектов'
        })
        titles.push({
            type: 'text',
            value: `Редактирование шаблона проекта №${props.record.id} - ${props.record.templateName}`
        })
        setTitle(titles)
    }

    return (
        <TabbedForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <FormTab label="Основная информация">
                <TextInput
                    label="Название шаблона"
                    source="templateName"
                    validate={required()}
                />
                <TextInput
                    label="Шаблон номера проекта"
                    source="numberTemplate"
                    validate={required()}
                />
                <TextInput
                    label="Шаблон имени проекта"
                    source="nameTemplate"
                    validate={required()}
                />
                <ColorInput label="Цвет" source="color" picker="Github" />
            </FormTab>
            {rest.id && (
                <FormTab label="Шаблоны процедур" path="procedures">
                    <ReferenceManyField
                        addLabel={false}
                        target="projectTemplateId"
                        reference={Resources.ProcedureTemplate}
                    >
                        <Datagrid>
                            <TextField
                                label="Название процедуры"
                                source="title"
                            />
                            <InplaceEditButton
                                formName="project-procedure-template-form"
                                formFields={addProcedureForm}
                                parentPropertyName="projectTemplateId"
                                parentPropertyValue={props.id}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewProcedureTemplateButton {...rest} />
                </FormTab>
            )}
            {rest.id && (
                <FormTab label="Шаблоны этапов" path="phases">
                    <ReferenceManyField
                        addLabel={false}
                        target="projectTemplateId"
                        reference={Resources.PhaseTemplate}
                    >
                        <Datagrid>
                            <TextField label="Название этапа" source="title" />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewPhaseTemplateButton {...rest} />
                </FormTab>
            )}
            {rest.id && (
                <FormTab label="Шаблоны задач" path="tasks">
                    <ReferenceManyField
                        addLabel={false}
                        target="projectTemplateId"
                        reference={Resources.ProjectTaskTemplate}
                    >
                        <Datagrid>
                            <TextField label="Название задачи" source="title" />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewTaskTemplateButton {...rest} />
                </FormTab>
            )}
        </TabbedForm>
    )
}

const ProjectTemplateCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'link',
        href: '/ProjectTemplate',
        value: 'Шаблоны проектов'
    })
    titles.push({
        type: 'text',
        value: 'Создание шаблона проекта'
    })
    setTitle(titles)

    return (
        <Create
            title="Настройка процесса | Шаблоны проектов | Создание шаблона проекта"
            {...rest}
        >
            <ProjectTemplateForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const ProjectTemplateCreateView = enchanceCreate(
    ProjectTemplateCreateViewBase
)

const ProjectTemplateEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <ProjectTemplateForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ProjectTemplateEditView = enchanceEdit(ProjectTemplateEditViewBase)

import * as React from 'react'
import { Button } from 'react-admin'
import { withRouter } from 'react-router-dom'

const AddCourtCaseButtonComponent = (props) => {
    return (
        <Button
            style={{ marginTop: 20 }}
            onClick={(evt) => {
                evt.stopPropagation()
                props.history.push(
                    `/ProjectCourtCase/create?commandType=Manual&projectId=${props.id}`
                )
            }}
            color={'secondary'}
            variant={'contained'}
            label={'Добавить дело вручную'}
        />
    )
}
export const AddCourtCaseButton = withRouter(AddCourtCaseButtonComponent)

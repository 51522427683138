import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IProjectTimelineEventModel extends IStandardFieldsModel {
    projectId: 'projectId'
    project: 'project'

    timelineEventId: 'timelineEventId'
    timelineEvent: 'timelineEvent'
}

const projectTimelineEvent: IProjectTimelineEventModel = {
    ...standardFieldsModel,
    projectId: 'projectId',
    project: 'project',

    timelineEventId: 'timelineEventId',
    timelineEvent: 'timelineEvent'
}

export const projectTimelineEventModel = Object.freeze(projectTimelineEvent)

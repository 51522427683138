import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProcedureTemplateModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'

    maxDurationDays: 'maxDurationDays'

    projectTemplateId: 'projectTemplateId'
    projectTemplate: 'projectTemplate'

    projectTaskTemplates: 'taskTemplates'

    phaseTemplates: 'phaseTemplates'
}

const procedureTemplate: IProcedureTemplateModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',

    maxDurationDays: 'maxDurationDays',

    projectTemplateId: 'projectTemplateId',
    projectTemplate: 'projectTemplate',

    projectTaskTemplates: 'taskTemplates',

    phaseTemplates: 'phaseTemplates'
}

export const procedureTemplateModel = Object.freeze(procedureTemplate)

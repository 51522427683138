const TITLE = 'SET_TITLE'
const initialState = []

export const titleAction = (title) => {
    const action = {
        type: TITLE,
        title
    }
    return action
}

export const titleReducer = (priviousState = initialState, action) => {
    if (action.type === TITLE) return action.title
    return priviousState
}

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IAppUserModel extends IStandardFieldsModel {
    userName: 'userName'
    normalizedUserName: 'normalizedUserName'
    email: 'email'
    normalizedEmail: 'normalizedEmail'
    emailConfirmed: 'emailConfirmed'
    phoneNumber: 'phoneNumber'
    phoneNumberConfirmed: 'phoneNumberConfirmed'
    twoFactorEnabled: 'twoFactorEnabled'
    lockoutEnd: 'lockoutEnd'
    lockoutEnabled: 'lockoutEnabled'
    personId: 'personId'
    password: 'password'
    rePassword: 'rePassword'
    role: 'role'
    userRoles: 'userRoles'
    person: 'person'
    oldPassword: 'oldPassword'
    newPassword: 'newPassword'
}

const appUser: IAppUserModel = {
    ...standardFieldsModel,
    userName: 'userName',
    normalizedUserName: 'normalizedUserName',
    email: 'email',
    normalizedEmail: 'normalizedEmail',
    emailConfirmed: 'emailConfirmed',
    phoneNumber: 'phoneNumber',
    phoneNumberConfirmed: 'phoneNumberConfirmed',
    twoFactorEnabled: 'twoFactorEnabled',
    lockoutEnd: 'lockoutEnd',
    lockoutEnabled: 'lockoutEnabled',
    personId: 'personId',
    password: 'password',
    role: 'role',
    rePassword: 'rePassword',
    userRoles: 'userRoles',
    person: 'person',
    oldPassword: 'oldPassword',
    newPassword: 'newPassword'
}

export const appUserModel = Object.freeze(appUser)

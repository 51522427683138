import React from 'react'
import { forceUpdate } from '../../utils/forceUpdate'
import { HideButton } from '../fields'
import { CREATE } from 'react-admin'
import { withRouter } from 'react-router-dom'
import oDataProvider from '../../api/oDataProvider'

const AddToHideBase = (props) => {
    const rerender = forceUpdate()

    if (
        props.record.userFilters === undefined ||
        !props.record.userFilters.length
    ) {
        props.record.userFilters = [{ isHide: false }]
    }

    const handleClick = (e) => {
        e.stopPropagation()
        props.record.userFilters[0].isHide = !props.record.userFilters[0].isHide
        rerender()
        oDataProvider(
            CREATE,
            //@ts-ignore
            `${props.resource}(${props.record.id})/SetHidden`,
            { data: { State: props.record.userFilters[0].isHide } }
        )
    }

    return (
        <HideButton
            isSelected={props.record.userFilters[0].isHide}
            onHandleChange={handleClick}
        />
    )
}
export const AddToHide = withRouter(AddToHideBase)
AddToHide.defaultProps = { label: 'Скрытый' }

import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput,
    SelectInput,
    DateField,
    CheckboxGroupInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { AddToFavorite, AddToHide, SelectAndSearchInput } from '../fields'
import { ProjectTaskFavoriteListActions } from '../../layout/favoriteHidenToobars'

const TaskPriorities = [
    { id: 'High', name: 'Высокая' },
    { id: 'Middle', name: 'Средняя' },
    { id: 'Low', name: 'Низкая' }
]

const TaskFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="Заголовок"
            source="$startsWith(title)"
            alwaysOn={true}
        />
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
        <SelectAndSearchInput
            label="Процедура"
            source="procedureId"
            reference="Procedure"
            optionText="title"
            multi={false}
        />
        <SelectAndSearchInput
            label="Этап"
            source="phaseId"
            reference="Phase"
            optionText="title"
            multi={false}
        />
        <SelectInput
            label="Приоритетность"
            source="priorityLevel"
            choices={TaskPriorities}
            allowEmpty={false}
        />
        <CheckboxGroupInput
            alwaysOn
            label=""
            optionText="label"
            source="createdById"
            choices={[
                { id: localStorage.getItem('id'), label: 'Создано мной' }
            ]}
        />
        <CheckboxGroupInput
            alwaysOn
            label=""
            optionText="label"
            source={`assignees/any(b: b/userId eq ${localStorage.getItem(
                'id'
            )})`}
            choices={[{ id: true, label: 'Назначенно мне' }]}
        />
    </Filter>
)

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ParsePriority = (priority) => {
    const priorities = {
        High: 'Высокая',
        Middle: 'Средняя',
        Low: 'Низкая',
        default: '-'
    }
    return priorities[priority] || priorities.default
}

const CustomPriorityField = (props) => {
    const pririty = ParsePriority(props.record.priorityLevel)
    return <span>{pririty}</span>
}
CustomPriorityField.defaultProps = {
    label: 'Приоритетность',
    source: 'priorityLevel'
}

const rowStyle = (record, index, defaultStyle = {}) => ({
    ...defaultStyle,
    borderLeftColor: (!!record.project && record.project.color) || 'gray',
    borderLeftWidth: 8,
    borderLeftStyle: 'solid'
})

export const TaskListBase = (props: any) => {
    const classes = useStyles()
    const {
        setTitle,
        isFavorite,
        isHidden,
        endedState,
        isIgnoreFilters,
        ...rest
    } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Задачи'
    })
    setTitle(titles)

    const filter: any = {
        endedState: endedState,
        isIgnoreFilters: isIgnoreFilters
    }
    if (isFavorite) filter.isFavorite = true
    if (isHidden) filter.isHide = true

    return (
        <List
            {...rest}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<TaskFilter />}
            actions={<ProjectTaskFavoriteListActions />}
            filter={filter}
            exporter={false}
        >
            <Datagrid
                rowClick="show"
                classes={{ rowEven: classes.evenRow }}
                rowStyle={rowStyle}
            >
                <TextField label="Заголовок" source="title" />
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    reference="Project"
                    allowEmpty={true}
                    linkType="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="Процедура"
                    source="procedureId"
                    reference="Procedure"
                    allowEmpty={true}
                >
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField
                    label="Этап"
                    source="phaseId"
                    reference="Phase"
                    allowEmpty={true}
                >
                    <TextField source="title" />
                </ReferenceField>
                <CustomPriorityField />
                <DateField label="Дата начала" source="startDate" />
                <DateField label="Дата завершения" source="endDate" />
                <AddToFavorite />
                <AddToHide />
                {/* <EditButton/> */}
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(
    (state: any) => ({
        isFavorite: state.isFavoriteProjectTask,
        isHidden: state.isHiddenProjectTask,
        endedState: state.endedStateProjectTask,
        isIgnoreFilters: state.isIgnoreProjectTaskFilters
    }),
    {
        setTitle: titleAction
    }
)
export const TaskList = enchanceList(TaskListBase)

export class InlineCountSettings {
    public inlineCount?: boolean
    public defaultInlineCount?: boolean

    public toString(): string {
        return '$count=' + (this.inlineCount || this.defaultInlineCount)
    }

    public reset(): void {
        this.inlineCount = undefined
    }

    public isSet(): boolean {
        return !!this.inlineCount || !!this.defaultInlineCount
    }
}

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILetterOfAuthorityRequesterDictValueModel
    extends IStandardFieldsModel {
    value: 'value'
    lettersOfAuthority: 'lettersOfAuthority'
}

const letterOfAuthorityRequesterDictValue: ILetterOfAuthorityRequesterDictValueModel = {
    ...standardFieldsModel,
    value: 'value',
    lettersOfAuthority: 'lettersOfAuthority'
}

export const letterOfAuthorityRequesterDictValueModel = Object.freeze(
    letterOfAuthorityRequesterDictValue
)

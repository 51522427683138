import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    SelectField,
    SelectInput
} from 'react-admin'
import { OrgTypes } from './companyEdit'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const CompaniesFilter = (props: any) => (
    <Filter {...props}>
        <SelectInput
            label="Тип"
            source="companyType"
            choices={OrgTypes}
            alwaysOn={true}
            allowEmpty={false}
            resettable
        />
        <TextInput
            label="Название"
            source="$contains(shortName)"
            alwaysOn={true}
        />
    </Filter>
)

const CompanyListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'text',
        value: 'Компании'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<CompaniesFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <SelectField
                    label="Тип"
                    source="companyType"
                    choices={OrgTypes}
                />
                <TextField label="Название" source="shortName" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const CompanyList = enchanceList(CompanyListBase)

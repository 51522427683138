import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IRequestClassificationModel extends IStandardFieldsModel {
    requestId: 'requestId'
    request: 'request'

    type: 'type'
    isSubsidiaryLiability: 'isSubsidiaryLiability'

    bankruptcyVariant: 'bankruptcyVariant'
    executiveProceedingVariant: 'executiveProceedingVariant'
    negotiationsAssistanceVariant: 'negotiationsAssistanceVariant'
    anotherVariant: 'anotherVariant'
}

const requestClassification: IRequestClassificationModel = {
    ...standardFieldsModel,
    requestId: 'requestId',
    request: 'request',

    type: 'type',
    isSubsidiaryLiability: 'isSubsidiaryLiability',

    bankruptcyVariant: 'bankruptcyVariant',
    executiveProceedingVariant: 'executiveProceedingVariant',
    negotiationsAssistanceVariant: 'negotiationsAssistanceVariant',
    anotherVariant: 'anotherVariant'
}

export const requestClassificationModel = Object.freeze(requestClassification)

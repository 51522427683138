import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtDocumentModel extends IStandardFieldsModel {
    projectCourtInstanceId: 'projectCourtInstanceId'
    projectCourtInstance: 'courtInstance'
    publishDate: 'publishDate'
    registerDate: 'registerDate'
    documentName: 'documentName'
    courtName: 'courtName'
}

const projectCourtDocument: IProjectCourtDocumentModel = {
    ...standardFieldsModel,
    projectCourtInstanceId: 'projectCourtInstanceId',
    projectCourtInstance: 'courtInstance',

    publishDate: 'publishDate',
    registerDate: 'registerDate',
    documentName: 'documentName',
    courtName: 'courtName'
}

export const projectCourtDocumentModel = Object.freeze(projectCourtDocument)

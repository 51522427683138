/* tslint:disable */
export enum ProjectAccessTypes {
    Admin = 100,
    Consultant = 1,
    Employee = 2,
    Manager = 3,
    Supervisor = 4,
    Viewer = 0
}
export enum Resources {
    Project = 'Project',
    Customer = 'Customer',
    ProjectParticipant = 'ProjectParticipant',
    CompanyEmployee = 'CompanyEmployee',
    VersionedFileAttachment = 'VersionedFileAttachment',
    Company = 'Company',
    LetterOfAuthority = 'LetterOfAuthority',
    LetterOfAuthorityClassificationDictValue = 'LetterOfAuthorityClassificationDictValue',
    LetterOfAuthorityRequesterDictValue = 'LetterOfAuthorityRequesterDictValue',
    Request = 'Request',
    AppUser = 'AppUser',
    Person = 'Person',
    ProjectTask = 'ProjectTask',
    UserRole = 'UserRole',
    TaskAssignee = 'TaskAssignee',
    FileMetadata = 'FileMetadata',
    ProjectCourtCase = 'ProjectCourtCase',
    EditPassword = 'EditPassword',
    Comment = 'Comment',
    Notification = 'Notification',
    UserNotificationChannel = 'UserNotificationChannel',
    Attachment = 'Attachment',
    AppRole = 'AppRole',
    CommentList = 'CommentList',
    Document = 'Document',
    FileAttachment = 'FileAttachment',
    TimelineEvent = 'TimelineEvent',
    CalendarEvent = 'CalendarEvent',
    PersonPassport = 'PersonPassport',
    Page = 'Page',
    Blog = 'Blog',
    BlogCategory = 'BlogCategory',
    Phase = 'Phase',
    Procedure = 'Procedure',
    Invoice = 'Invoice',
    Payment = 'Payment',
    Lookup = 'Lookup',
    LookupValue = 'LookupValue',
    ProjectTaskTemplate = 'ProjectTaskTemplate',
    PhaseTemplate = 'PhaseTemplate',
    ProcedureTemplate = 'ProcedureTemplate',
    ProjectTemplate = 'ProjectTemplate',
    RequestCommand = 'RequestCommand',
    RequestClassification = 'RequestClassification',
    ProjectCourtCaseParticipant = 'ProjectCourtCaseParticipant',
    Notification_Read = 'Notification/MarkAsRead',
    VersionedFileAttachment_UpdateFile = 'VersionedFileAttachment/UpdateFile'
}

export enum NotificationTypes {
    TaskAssignee = 'TaskAssignee',
    ProjectCommentBroadcast = 'ProjectCommentBroadcast',
    ProjectCommentReply = 'ProjectCommentReply',
    RequestCommentReply = 'RequestCommentReply',
    RequestCommentBroadcast = 'ProjectCommentReply',
    ProjectTaskEndOfWork = 'ProjectTaskEndOfWork',
    ProjectEndOfWork = 'ProjectEndOfWork',
    VerFileCommentReply = 'VerFileCommentReply',
    VerFileCommentBroadcast = 'VerFileCommentBroadcast',
    RequestAccept = 'RequestAccept',
    NewRequest = 'NewRequest'
}

export enum RoleTypes {
    Admin = 'Администратор',
    Dispatcher = 'Диспетчер',
    Client = 'Клиент',
    Consultant = 'Консультант',
    Employee = 'Сотрудник'
}
export enum eRequestClassificationType {
    bankruptcy = 'Bankruptcy',
    arbitration = 'Arbitration',
    crisisManagement = 'CrisisManagement',
    executiveProceeding = 'ExecutiveProceeding',
    negotiationsAssistance = 'NegotiationsAssistance'
}
export enum eBankruptcyVariant {
    creditor = 'Creditor',
    debtor = 'Debtor'
}
export enum eExecutiveProceedingVariant {
    claimant = 'Claimant',
    debtor = 'Debtor'
}
export enum eNegotiationsAssistanceVariant {
    debtor = 'Debtor',
    creditor = 'Creditor',
    investor = 'Investor',
    another = 'Another'
}
export enum eRequestStatus {
    New = 'New',
    Processing = 'Processing',
    Completed = 'Completed',
    Rejected = 'Rejected'
}
export enum ProjectStatus {
    Active = 1,
    Closed = 3,
    New = 0,
    Postponed = 2
}
export interface ITitle {
    type: 'link' | 'text'
    value: string
    href?: string
}
export interface Project extends Entity {
    budget?: Decimal
    color?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    customer?: Customer
    customerId?: string
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    descritpion?: string
    endDate?: ApiDate
    id?: string
    isDeleted?: boolean
    name?: string
    note?: string
    number?: string
    participants?: ProjectParticipant[]
    phases?: Phase[]
    procedures?: Procedure[]
    startDate?: ApiDate
    status?: ProjectStatus
    tasks?: ProjectTask[]
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}
export interface ProjectParticipant extends Entity {
    accessExpirationDate?: ApiDate
    accessType?: ProjectAccessTypes
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    project?: Project
    projectId?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    user?: AppUser
    userId?: string
}

export interface ProjectTemplate extends Entity {
    color?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    nameTemplate?: string
    numberTemplate?: string
    phases?: PhaseTemplate[]
    procedures?: ProcedureTemplate[]
    tasks?: ProjectTaskTemplate[]
    templateName?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface IAuditEnabledEntity {
    createdById?: string
    createdOn?: ApiDate
    deletedById?: string
    deletedOn?: ApiDate
    updatedById?: string
    updatedOn?: ApiDate
}

export interface IEntity {}

export interface IHasId {
    id?: string
}

export interface IPersonName {
    firstName?: string
    lastName?: string
    patronymicName?: string
}

export interface ISoftDeletable {
    isDeleted?: boolean
}

export interface Lookup extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    name?: string
    systemName?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    values?: LookupValue[]
}

export interface LookupValue extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    decimalValue?: Decimal
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    intValue?: number
    isDeleted?: boolean
    lookup?: Lookup
    lookupId?: string
    order?: number
    requirements?: LookupValueRequirement[]
    systemName?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    value?: string
}

export interface LookupValueRequirement extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    lookupValue?: LookupValue
    lookupValueId?: string
    requirementName?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface SeoEnabledEntity extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    path?: string
    slug?: string
    title?: string
    titleLong?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface AppUser extends IdentityUser<Guid> {
    role?: string
    accessFailedCount?: number
    concurrencyStamp?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    email?: string
    emailConfirmed?: boolean
    fullName?: string
    id?: string
    isDeleted?: boolean
    lockoutEnabled?: boolean
    lockoutEnd?: ApiDate
    normalizedEmail?: string
    normalizedUserName?: string
    passwordHash?: string
    person: Person
    personId?: string
    phoneNumber?: string
    phoneNumberConfirmed?: boolean
    securityStamp?: string
    twoFactorEnabled?: boolean
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    userName?: string
}

export interface IUser {
    email?: string
}

export interface IdentityUser<TKey extends IEquatable> {
    accessFailedCount?: number
    concurrencyStamp?: string
    email?: string
    emailConfirmed?: boolean
    id?: TKey
    lockoutEnabled?: boolean
    lockoutEnd?: ApiDate
    normalizedEmail?: string
    normalizedUserName?: string
    passwordHash?: string
    phoneNumber?: string
    phoneNumberConfirmed?: boolean
    securityStamp?: string
    twoFactorEnabled?: boolean
    userName?: string
}

export interface Decimal {}

export interface Guid {}

export interface IConvertible {}

export interface ISpanFormattable {}

export enum CompanyTypes {
    Company = 0,
    IndividualEntrepreneur = 1
}
export enum InfoRecordTypes {
    Address = 2,
    Email = 3,
    Facebook = 8,
    Fax = 1,
    Google = 9,
    Icq = 12,
    Odnoklassniki = 11,
    Phone = 0,
    Skype = 4,
    Telegram = 7,
    Viber = 6,
    Vkontakte = 10,
    Whatsapp = 5
}
export enum InfoRecordTypesValues {
    Address = 'Address',
    Email = 'Email',
    Facebook = 'Facebook',
    Fax = 'Fax',
    Google = 'Google',
    Icq = 'Icq',
    Odnoklassniki = 'Odnoklassniki',
    Phone = 'Phone',
    Skype = 'Skype',
    Telegram = 'Telegram',
    Viber = 'Viber',
    Vkontakte = 'Vkontakte',
    Whatsapp = 'Whatsapp'
}
export interface Company extends Entity {
    comments?: string
    companyType?: CompanyTypes
    contactInformation?: CompanyInfoRecord[]
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    employees?: CompanyEmployee[]
    fullName?: string
    id?: string
    isDeleted?: boolean
    shortName?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface CompanyEmployee extends Entity {
    company?: Company
    companyId?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    isDesignatedContact?: boolean
    jobPosition?: string
    person?: Person
    personId?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface CompanyInfoRecord extends Entity {
    comments?: string
    company?: Company
    companyId?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    info?: string
    infoRecordType?: InfoRecordTypes
    isDeleted?: boolean
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface ContactInfoRecord extends Entity {
    comments?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    info?: string
    infoRecordType?: InfoRecordTypes
    isDeleted?: boolean
    person?: Person
    personId?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface Customer extends Entity {
    company?: Company
    companyId?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    displayName?: string
    id?: string
    isDeleted?: boolean
    person?: Person
    personId?: string
    projects?: Project[]
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface Person extends Entity {
    contactInformation?: ContactInfoRecord[]
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    email?: string
    firstName?: string
    id?: string
    isDeleted?: boolean
    lastName?: string
    patronymicName?: string
    phone?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    userId?: string
}

export interface IDeserializationCallback {}

export interface ICalendarEvent {
    endDate?: ApiDate
    startDate?: ApiDate
}

export interface Phase extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    endDate?: ApiDate
    id?: string
    isDeleted?: boolean
    phaseTemplate?: PhaseTemplate
    phaseTemplateId?: string
    procedure?: Procedure
    procedureId?: string
    project?: Project
    projectId?: string
    startDate?: ApiDate
    tasks?: ProjectTask[]
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface PhaseTemplate extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    id?: string
    isDeleted?: boolean
    procedureTemplate?: ProcedureTemplate
    procedureTemplateId?: string
    projectTemplate?: ProjectTemplate
    projectTemplateId?: string
    taskTemplates?: ProjectTaskTemplate[]
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface Procedure extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    endDate?: ApiDate
    id?: string
    isDeleted?: boolean
    phases?: Phase[]
    procedureTemplate?: Procedure
    procedureTemplateId?: string
    project?: Project
    projectId?: string
    startDate?: ApiDate
    tasks?: ProjectTask[]
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface ProcedureTemplate extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    id?: string
    isDeleted?: boolean
    maxDurationDays?: number
    phaseTemplates?: PhaseTemplate[]
    projectTemplate?: ProjectTemplate
    projectTemplateId?: string
    taskTemplates?: ProjectTaskTemplate[]
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface ProjectTask extends Entity {
    assignees?: TaskAssignee[]
    completedOn?: ApiDate
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    endDate?: ApiDate
    id?: string
    isCompleted?: boolean
    isDeleted?: boolean
    phase?: Phase
    phaseId?: string
    procedure?: Procedure
    procedureId?: string
    project?: Project
    projectId?: string
    startDate?: ApiDate
    taskTemplate?: ProjectTaskTemplate
    taskTemplateId?: string
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface ProjectTaskTemplate extends Entity {
    assignees?: TaskTemplateAssignee[]
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    id?: string
    isDeleted?: boolean
    phaseTemplate?: PhaseTemplate
    phaseTemplateId?: string
    procedureTemplate?: ProcedureTemplate
    procedureTemplateId?: string
    projectTemplate?: ProjectTemplate
    projectTemplateId?: string
    title?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface TaskAssignee extends Entity {
    canComplete?: boolean
    comment?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    hasCompletedTask?: boolean
    id?: string
    isDeleted?: boolean
    task?: ProjectTask
    taskId?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    user?: AppUser
    userId?: string
}

export interface TaskTemplateAssignee extends Entity {
    comment?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    taskTemplate?: ProjectTaskTemplate
    taskTemplateId?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    user?: AppUser
    userId?: string
}

export interface Blog extends SeoEnabledEntity {
    author?: string
    category?: BlogCategory
    categoryId?: string
    content?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    isPublished?: boolean
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    path?: string
    slug?: string
    title?: string
    titleLong?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface BlogCategory extends SeoEnabledEntity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    id?: string
    isDeleted?: boolean
    isNews?: boolean
    isPublished?: boolean
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    parent?: BlogCategory
    parentId?: string
    path?: string
    slug?: string
    title?: string
    titleLong?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface ISeoEnabled {
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    path?: string
    slug?: string
    title?: string
    titleLong?: string
}

export interface Page extends SeoEnabledEntity {
    content?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    isInMenu?: boolean
    isPublished?: boolean
    metaDescription?: string
    metaKeywords?: string
    metaTitle?: string
    order?: number
    parent?: Page
    parentId?: string
    path?: string
    slug?: string
    title?: string
    titleLong?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface Attachment extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    files?: FileAttachment[]
    id?: string
    isDeleted?: boolean
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface FileAttachment {
    attachment?: Attachment
    attachmentId?: string
    file?: FileMetadata
    fileId?: string
}

export interface FileMetadata extends Entity {
    actualVersion?: FileVersionMetadata
    actualVersionId?: string
    attachments?: FileAttachment[]
    containerName?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    description?: string
    id?: string
    isDeleted?: boolean
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    versions?: FileVersionMetadata[]
}

export interface FileVersionMetadata extends Entity {
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    fileId?: string
    id?: string
    isDeleted?: boolean
    name?: string
    size?: number
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
    url?: string
}

export enum CommentListOwnerType {
    Customer = 1,
    Project = 0
}
export interface Comment extends Entity {
    attachment?: Attachment
    attachmentId?: string
    commentList?: CommentList
    commentListId?: string
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    creationTimeStamp?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    text?: string
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface CommentList extends Entity {
    comments?: Comment[]
    createdBy?: AppUser
    createdById?: string
    createdOn?: ApiDate
    deletedBy?: AppUser
    deletedById?: string
    deletedOn?: ApiDate
    id?: string
    isDeleted?: boolean
    ownerId?: string
    ownerType?: CommentListOwnerType
    updatedBy?: AppUser
    updatedById?: string
    updatedOn?: ApiDate
}

export interface PreloadUserModel {
    id?: string
    person?: Person
    userName?: string
}

export enum CustomerTypes {
    Company = 1,
    Person = 0
}
export interface CustomerListItem {
    customerType?: CustomerTypes
    displayName?: string
    id?: string
    info?: string
}

export interface IEquatable {}
export class ApiDate extends Date {}

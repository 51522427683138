import { FilterClause, PrecedenceGroup, Tso } from '../utils/ts-odata'

export interface ICustomFilter {
    expression: string
    template: string
    values: any
    asQueryOption?: boolean
    valuesJoinDelimiter?: string
}

export interface ICustomFilterContainer {
    $customFilter: ICustomFilter
}

export class CustomerFiltersProvider {
    public static NotSelf(record: {
        id: string
    }): ICustomFilterContainer | null {
        return (
            (record &&
                record.id && {
                    $customFilter: {
                        template: '#expression#',
                        expression: 'id ne #value#',
                        values: record.id
                    }
                }) ||
            null
        )
    }
}

export class FilterToODataConverter {
    public static convertToODataQuery(
        odataQuery: Tso,
        filter: any,
        pagination: { page: number; perPage: number },
        sort: { field: string; order: string },
        disableCount: boolean = false,
        ignorePaging: boolean = false,
        ignoreOrderBy: boolean = false
    ): string {
        let $count = undefined
        if (!!filter) $count = filter.$count

        if ($count !== false && !disableCount) {
            odataQuery.inlineCount.enable()
        }

        for (const filterProp in filter) {
            if (
                !filter.hasOwnProperty(filterProp) ||
                (filterProp === 'q' &&
                    (filter[filterProp] || filter[filterProp] === ''))
            ) {
                continue
            }

            if (
                this.applyExplicitODataCommands(odataQuery, filterProp, filter)
            ) {
                // we have processed some commands and they are already in a proper format so we don't need to process them further
                continue
            }

            const transformedProperty = FilterToODataConverter.applyPropertyTransformation(
                filterProp,
                filter
            )
            const { property, value } = transformedProperty

            const isCustom = property === '$customFilter'
            if (
                typeof value === 'string' &&
                (value[0] === '*' || value[value.length - 1] === '*')
            ) {
                FilterToODataConverter.applyStringSearch(
                    odataQuery,
                    property,
                    value
                )
            } else {
                if (Array.isArray(value)) {
                    FilterToODataConverter.applyMultiValueFilter(
                        odataQuery,
                        property,
                        value
                    )
                } else {
                    if (isCustom) {
                        FilterToODataConverter.applyCustomFilterTransformation(
                            odataQuery,
                            value
                        )
                    } else {
                        odataQuery.andFilter(
                            new FilterClause(property).eq(value)
                        )
                    }
                }
            }
        }

        if (ignorePaging) {
            odataQuery.resetSkip()
            odataQuery.resetTop()
        } else {
            if (
                !odataQuery.TopSettings.isSet() &&
                pagination &&
                pagination.perPage
            ) {
                odataQuery.top(pagination.perPage)
            }
            if (
                !odataQuery.SkipSettings.isSet() &&
                pagination &&
                pagination.page
            ) {
                const recordsPerPage =
                    odataQuery.TopSettings.top || pagination.perPage
                if (recordsPerPage) {
                    odataQuery.skip((pagination.page - 1) * recordsPerPage)
                }
            }
        }

        if (ignoreOrderBy) {
            odataQuery.resetOrderBy()
        } else if (!odataQuery.OrderBySettings.isSet() && sort) {
            odataQuery.orderBy(sort.field, sort.order)
        }

        return odataQuery.toString()
    }

    private static applyStringSearch(
        odataQuery: Tso,
        property: string,
        value: string
    ): void {
        if (value[0] === '*' && value[value.length - 1] === '*') {
            odataQuery.andFilter(
                new FilterClause(property).contains(
                    value.substring(1, value.length - 1)
                )
            )
        } else if (value[0] === '*') {
            odataQuery.andFilter(
                new FilterClause(property).endswith(
                    value.substring(1, value.length)
                )
            )
        } else {
            odataQuery.andFilter(
                new FilterClause(property).startswith(
                    value.substring(0, value.length - 1)
                )
            )
        }
    }

    private static applyMultiValueFilter(
        odataQuery: Tso,
        property: string,
        value: any[]
    ): void {
        if (value.length > 0) {
            const initialClause = new FilterClause(property).eq(value[0])
            const isCustom = property === '$customFilter'
            if (value.length === 1) {
                if (isCustom) {
                    FilterToODataConverter.applyCustomFilterTransformation(
                        odataQuery,
                        value
                    )
                } else {
                    odataQuery.andFilter(initialClause)
                }
            } else {
                if (isCustom) {
                    FilterToODataConverter.applyCustomFilterTransformation(
                        odataQuery,
                        value
                    )
                } else {
                    const clauseGroup = new PrecedenceGroup(initialClause)
                    for (const v of value) {
                        clauseGroup.orFilter(new FilterClause(property).eq(v))
                    }
                    odataQuery.andFilter(clauseGroup)
                }
            }
        }
    }

    private static applyExplicitODataCommands(
        odataQuery: Tso,
        filterProp: string,
        filter: any
    ): boolean {
        const reservedKeyWords: any = {
            $count: true,
            $select: true,
            $filter: true,
            $orderby: true,
            $top: true,
            $expand: true
        }
        if (reservedKeyWords[filterProp]) {
            switch (filterProp) {
                case '$select':
                    odataQuery.select(filter[filterProp])
                    return true
                case '$top':
                    odataQuery.top(filter[filterProp] || 10)
                    return true
                case '$expand':
                    if (!odataQuery.ExpandSettings.isSet()) {
                        odataQuery.expand(filter[filterProp])
                    } else if (odataQuery.ExpandSettings.expand) {
                        const existingExpandEntities = odataQuery.ExpandSettings.expand.split(
                            ','
                        )
                        const ix = existingExpandEntities.indexOf(
                            filter[filterProp]
                        )
                        if (ix < 0) {
                            existingExpandEntities.push(filter[filterProp])
                            odataQuery.expand(existingExpandEntities.join(','))
                        }
                    }
                    return true
                default:
                    return true
            }
        }
        return false
    }

    private static applyPropertyTransformation(
        source: string,
        filterValuesObj: any
    ): { command: string; property: string; value: any } {
        const sourceRegex = /^\$(contains|startsWith|endsWith|any|date)\(([a-zA-Z0-9_\/]+)\)$/i
        if (!sourceRegex.test(source)) {
            // return unchanged property and value
            return {
                command: '',
                property: source,
                value: filterValuesObj[source]
            }
        }

        const matches = source.match(sourceRegex)
        if (matches && matches.length >= 3) {
            const command = matches[1]
            let property = matches[2]
            let valueTransform = (v: any) => v
            switch (command) {
                case 'contains':
                    valueTransform = (v) => `*${v}*`
                    break
                case 'startsWith':
                    valueTransform = (v) => `${v}*`
                    break
                case 'endsWith':
                    valueTransform = (v) => `*${v}`
                    break
                case 'date':
                    const propertyName = property
                    property = '$customFilter'
                    valueTransform = (v) => ({
                        expression: `date(${propertyName}) eq #value#`,
                        template: `#expression#`,
                        values: new Date(v).toISOString().slice(0, 10)
                    })
                    break
                case 'any':
                    if (property.indexOf('/') > 0) {
                        const path = property.split('/')
                        property = '$customFilter'
                        valueTransform = (v) => ({
                            expression: `c/${path[1]} eq #value#`,
                            template: `${path[0]}/any(c: #expression#)`,
                            values: v
                        })
                    }
                    break
            }
            return {
                command,
                property,
                value: valueTransform(filterValuesObj[source])
            }
        }

        return { command: '', property: source, value: filterValuesObj[source] }
    }

    private static applyCustomFilterTransformation(
        odataQuery: Tso,
        filter: any
    ): void {
        const customFilters = Array.isArray(filter) ? [...filter] : [filter]
        for (const filterItem of customFilters) {
            const asQueryOption = filterItem.asQueryOption === true
            const customFilter = FilterToODataConverter.buildODataExpressionFromCustomFilter(
                filterItem
            )
            if (asQueryOption) {
                odataQuery.queryOption(customFilter)
            } else {
                odataQuery.andFilter(new FilterClause('').custom(customFilter))
            }
        }
    }

    private static buildODataExpressionFromCustomFilter(
        customFilterDefinition: ICustomFilter
    ): string {
        if (
            !customFilterDefinition ||
            !customFilterDefinition.expression ||
            !customFilterDefinition.values
        ) {
            return ''
        }

        const filterValues = Array.isArray(customFilterDefinition.values)
            ? [...customFilterDefinition.values]
            : [customFilterDefinition.values]
        const stringBuilder: string[] = []
        const template = customFilterDefinition.template
        const delimiter = customFilterDefinition.valuesJoinDelimiter || ' or '
        for (const v of filterValues) {
            const encodedValue =
                typeof v === 'string' ? encodeURIComponent(v) : v
            const renderedExpression = customFilterDefinition.expression.replace(
                '#value#',
                encodedValue
            )
            stringBuilder.push(renderedExpression)
        }
        return template.replace('#expression#', stringBuilder.join(delimiter))
    }
}

const IS_FAVORITE_PROJECT = 'SET_IS_FAVORITE_PROJECT'
const initialState = false

export const isFavoriteProjectAction = (isFavoriteProject) => {
    const action = {
        type: IS_FAVORITE_PROJECT,
        isFavoriteProject
    }
    return action
}

export const isFavoriteProjectReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_FAVORITE_PROJECT
        ? action.isFavoriteProject
        : priviousState

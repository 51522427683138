import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICompanyEmployeeModel extends IStandardFieldsModel {
    companyId: 'companyId'
    company: 'company'
    personId: 'personId'
    person: 'person'
    email: 'email'
    phone: 'phone'
    isDesignatedContact: 'isDesignatedContact'
    jobPosition: 'jobPosition'
}

const companyEmployee: ICompanyEmployeeModel = {
    ...standardFieldsModel,
    companyId: 'companyId',
    company: 'company',
    personId: 'personId',
    person: 'person',
    email: 'email',
    phone: 'phone',
    isDesignatedContact: 'isDesignatedContact',
    jobPosition: 'jobPosition'
}

export const companyEmployeeModel = Object.freeze(companyEmployee)

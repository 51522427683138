import React, { useState } from 'react'
import { Toolbar, showNotification, CREATE } from 'react-admin'
import { CheckBoxButton } from '../../components/fields'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { saveWithNoteCreate } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const CompanyBottomToolbarBase = (props) => {
    const classes = useStyles(``)
    const {
        showCheckBox,
        customRedirect,
        disabled,
        handleSubmit,
        resource,
        shouldParse,
        saveWithNoteCreate,
        basePath,
        showNotification,
        ...rest
    } = props
    const [checked, setChecked] = useState(false)

    const handleBeforeSubmit = () => handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )
        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            const values = handleBeforeSubmit()()

            const { data: company } = await oDataProvider(
                CREATE,
                Resources.Company,
                { data: { ...values } }
            )
            if (checked)
                oDataProvider(CREATE, Resources.Customer, {
                    data: {
                        companyId: company.id,
                        displayName: company.shortName
                    }
                })
            rest.history.push(`/Company`)
        }
        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar {...rest} className={classes.ToolbarCreate}>
            <CatchingSaveButton />
            {/* <SaveButton handleSubmitWithRedirect={shouldParse ? handleBeforeSubmit : undefined} disabled={disabled} size="small" redirect={!!customRedirect ? customRedirect : 'list'} variant="flat" /> */}
            {!!showCheckBox && (
                <CheckBoxButton
                    text="Создать клиента"
                    isSelected={checked}
                    onHandleChange={() => {
                        setChecked(!checked)
                    }}
                />
            )}
        </Toolbar>
    )
}
export default connect(undefined, { saveWithNoteCreate, showNotification })(
    CompanyBottomToolbarBase
)

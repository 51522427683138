import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICompanyInfoRecordModel extends IStandardFieldsModel {
    companyId: 'companyId'
    company: 'company'
    infoRecordType: 'infoRecordType'
    info: 'info'
    comments: 'comments'
}

const companyInfoRecord = {
    ...standardFieldsModel,
    companyId: 'companyId',
    company: 'company',
    infoRecordType: 'infoRecordType',
    info: 'info',
    comments: 'comments'
}

export const companyInfoRecordModel = Object.freeze(companyInfoRecord)

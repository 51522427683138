import * as React from 'react'
import {
    BooleanField,
    ReferenceField,
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const BlogCategoriesFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="Название содержит"
            source="$startsWith(title)"
            alwaysOn={true}
        />
        <ReferenceInput
            label="Род. категория"
            source="parentId"
            reference={Resources.BlogCategory}
            resource="BlogCategory"
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
    </Filter>
)

const BlogCategoryListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контент сайта'
    })
    titles.push({
        type: 'text',
        value: 'Рубрики блога'
    })
    setTitle(titles)

    return (
        <List
            {...props}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<BlogCategoriesFilter />}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Название" source="title" />
                <ReferenceField
                    label="Родительская категория"
                    source="parentId"
                    reference={Resources.BlogCategory}
                    resource="BlogCategory"
                    allowEmpty={true}
                >
                    <TextField source="title" />
                </ReferenceField>
                <BooleanField label="Содержит новости" source="isNews" />
                <BooleanField label="Опубликована" source="isPublished" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const BlogCategoryList = enchanceList(BlogCategoryListBase)

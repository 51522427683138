export interface IUserRoleModel {
    id: 'id'
    userId: 'userId'
    appUser: 'user'
    roleId: 'roleId'
    appRole: 'role'
}

const userRole: IUserRoleModel = {
    id: 'id',
    userId: 'userId',
    appUser: 'user',
    roleId: 'roleId',
    appRole: 'role'
}

export const userRoleModel = Object.freeze(userRole)

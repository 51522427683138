import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core'
import {
    Button,
    SaveButton,
    UPDATE,
    CREATE,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction,
    showNotification as showNotificationAction,
    refreshView as refreshViewAction
} from 'react-admin'
import oDataProvider from '../../../api/oDataProvider'
import { connect } from 'react-redux'
import {
    reset as resetAction,
    change as changeAction,
    submit as submitAction,
    isSubmitting as isSubmittingStatus
} from 'redux-form'
import { SimpleForm } from 'react-admin'

interface IButtonProps {
    formName: string
    formFields: any
    parentPropertyName: string
    parentPropertyValue: any
    validate: any
    color?: any
    variant?: any
    label?: any
}

class InplaceEditButtonComponent extends React.Component<
    IButtonProps | any,
    any
> {
    constructor(props: IButtonProps | any) {
        super(props)
        this.state = {
            error: false,
            showDialog: false
        }
        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    public render() {
        const { showDialog } = this.state
        const {
            formName,
            formFields,
            record,
            color,
            variant,
            label,
            isSubmitting,
            validate,
            parentPropertyValue,
            parentPropertyName
        } = this.props
        const editRecord = parentPropertyValue
            ? { [parentPropertyName]: parentPropertyValue }
            : record

        console.log('---record---', record)

        return (
            <React.Fragment>
                {(record.id || parentPropertyValue) && (
                    <Button
                        color={color || 'primary'}
                        variant={variant || 'flat'}
                        label={label || 'Edit'}
                        onClick={this.handleClick}
                    />
                )}
                <Dialog
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleClose}
                >
                    <DialogTitle>Edit Team Agenda Point</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            record={{ ...editRecord }}
                            form={formName}
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            validate={validate}
                        >
                            {formFields && formFields()}
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleClose}
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    private handleClick() {
        this.setState({ showDialog: true })
    }

    private handleClose() {
        const { formName, reset } = this.props
        reset(formName)
        this.setState({ showDialog: false })
    }

    private handleSaveClick() {
        const { formName, submit } = this.props
        submit(formName)
    }

    private async handleSubmit(values) {
        const {
            resource,
            formName,
            fetchStart,
            fetchEnd,
            refreshView,
            reset,
            showNotification
        } = this.props

        fetchStart()
        const action = !!values.id ? UPDATE : CREATE
        await oDataProvider(action, resource, {
            id: values.id,
            data: { ...values }
        })
            .then(({ data }) => {
                this.setState({ showDialog: false })
                reset(formName)
                refreshView()
            })
            .catch((error) => {
                showNotification(error.message, 'error')
            })

        fetchEnd()
    }
}

const mapStateToProps = (state, props) => ({
    isSubmitting: isSubmittingStatus(props.formName)(state)
})

export const InplaceEditButton = connect(mapStateToProps, {
    change: changeAction,
    fetchEnd: fetchEndAction,
    fetchStart: fetchStartAction,
    refreshView: refreshViewAction,
    reset: resetAction,
    showNotification: showNotificationAction,
    submit: submitAction
})(InplaceEditButtonComponent)

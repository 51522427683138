import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILetterOfAuthorityModel extends IStandardFieldsModel {
    projectId: 'projectId'
    project: 'project'

    number: 'number'
    name: 'name'

    issuedAt: 'issuedAt'
    expireAt: 'expireAt'

    type: 'type'
    status: 'status'

    classificationDictValueId: 'classificationDictValueId'
    classificationDictValue: 'classificationDictValue'

    requesterDictValueId: 'requesterDictValueId'
    requesterDictValue: 'requesterDictValue'

    personTrusteeId: 'personTrusteeId'
    personTrustee: 'personTrustee'

    toPersonId: 'toPersonId'
    toPerson: 'toPerson'
}

const letterOfAuthority: ILetterOfAuthorityModel = {
    ...standardFieldsModel,
    projectId: 'projectId',
    project: 'project',

    number: 'number',
    name: 'name',

    issuedAt: 'issuedAt',
    expireAt: 'expireAt',

    type: 'type',
    status: 'status',

    classificationDictValueId: 'classificationDictValueId',
    classificationDictValue: 'classificationDictValue',

    requesterDictValueId: 'requesterDictValueId',
    requesterDictValue: 'requesterDictValue',

    personTrusteeId: 'personTrusteeId',
    personTrustee: 'personTrustee',

    toPersonId: 'toPersonId',
    toPerson: 'toPerson'
}

export const letterOfAuthorityModel = Object.freeze(letterOfAuthority)

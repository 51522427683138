import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const ClassificationDictValueIdListBase = (props: any) => {
    const classes = useStyles('')
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'text',
        value: 'Классификация доверенностей'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Классификация" source="value" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const ClassificationDictValueIdList = enchanceList(
    ClassificationDictValueIdListBase
)

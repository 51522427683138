import {
    GET_LIST,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction
} from 'react-admin'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

export const getFullName = (person): string => {
    const firstName = person.firstName !== null ? person.firstName + ' ' : ''
    const lastName = person.lastName !== null ? person.lastName + ' ' : ''
    const patronymicName =
        person.patronymicName !== null ? person.patronymicName + ' ' : ''
    return firstName + lastName + patronymicName
}

const getDate = (createdOn: string): string => {
    const rawDate = new Date(createdOn)

    const fullYear = rawDate.getFullYear().toString()

    let months = rawDate.getMonth().toString()
    if (months.length === 1) months = '0' + months

    let days = rawDate.getDate().toString()
    if (days.length === 1) days = '0' + days

    let hours = rawDate.getHours().toString()
    if (hours.length === 1) hours = '0' + hours

    let minutes = rawDate.getMinutes().toString()
    if (minutes.length === 1) minutes = '0' + minutes

    let seconds = rawDate.getSeconds().toString()
    if (seconds.length === 1) seconds = '0' + seconds

    const date: string =
        fullYear +
        '.' +
        months +
        '.' +
        days +
        ', ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    return date
}

const buildChildrenComment = (comment) =>
    comment.map((value) => ({
        id: value.id,
        date: getDate(value.createdOn),
        comment: value.text,
        fullName: getFullName(value.createdBy.person)
    }))

export default async (commentListId, comments, page) => {
    fetchStartAction()
    const action = GET_LIST

    let commentsList: any = []
    const { data: tempComments } = await oDataProvider(
        action,
        Resources.Comment,
        {
            filter: { commentListId: commentListId, parentCommentId: null },
            pagination: { page: page, perPage: 10 },
            sort: { field: 'createdOn', order: 'ASC' }
        }
    )
    commentsList = commentsList.concat(tempComments)

    fetchEndAction()

    commentsList.map((value) => {
        comments.push({
            fullName: getFullName(value.createdBy.person),
            childrenComments: buildChildrenComment(value.childrenComments),
            id: value.id,
            date: getDate(value.createdOn),
            comment: value.text
        })
    })
}

import { FilterClause, Tso } from '../utils/ts-odata'

const stdLen = (time): string =>
    time.toString().length === 1 ? `0${time}` : time.toString()
const addDays = (date, days) => {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}
const castToNormal = (date) => {
    const dateB =
        date.getFullYear() +
        '-' +
        stdLen(date.getMonth() + 1) +
        '-' +
        stdLen(date.getDate())
    const timeB =
        stdLen(date.getHours()) +
        ':' +
        stdLen(date.getMinutes()) +
        ':' +
        stdLen(date.getSeconds())
    return `${dateB}T${timeB}Z`
}

export default (query: Tso, filterValue: string, name: string): void => {
    if (!filterValue) return
    const date = new Date(filterValue)
    query.filter(
        new FilterClause(
            `${name} ge date(${castToNormal(
                addDays(date, -1)
            )}) and ${name} le date(${castToNormal(addDays(date, 1))})`
        )
    )
}

import React, { useState } from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton,
    BooleanInput
} from 'react-admin'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { CheckBoxButton } from '../../components/fields'
import { getFio } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
})

const ToolbarContactCreateBase = (props) => {
    const classes = useStyles(``)
    const [createClient, setCreateClient] = useState(false)
    const {
        createPassport,
        setCreatePassport,
        createUser,
        setCreateUser,

        handleSubmit,
        showNotification,

        showCreatePassportButton,

        ...rest
    } = props

    const CatchingSaveButton = (eProps) => {
        const { invalid } = eProps

        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )

        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                eProps.handleSubmitWithRedirect('list')()
                e.preventDefault()
                return
            }

            const handleBeforeSubmit = () => handleSubmit((values) => values)
            const { user, passports, ...rest } = handleBeforeSubmit()()

            const { data: person } = await oDataProvider(
                CREATE,
                Resources.Person,
                { data: { ...rest } }
            )
            if (createPassport) {
                await oDataProvider(CREATE, Resources.PersonPassport, {
                    data: {
                        batch: passports.batch,
                        serialNumber: passports.serialNumber,
                        issuer: passports.issuer,
                        issuerNum: passports.issuerNum,
                        issuedAt: passports.issuedAt,
                        regPlace: passports.regPlace,
                        birthPlace: passports.birthPlace,
                        birthDay: passports.birthDay,
                        personId: person.id
                    }
                })
            }
            if (createClient) {
                await oDataProvider(CREATE, Resources.Customer, {
                    data: {
                        displayName: getFio(rest),
                        personId: person.id
                    }
                })
            }
            if (createUser) {
                await oDataProvider(CREATE, Resources.AppUser, {
                    data: {
                        personId: person.id,
                        email: rest.email,
                        password: user.password,
                        role: user.role,
                        companyId: user.companyId,
                        sendEmail: true
                    }
                })
            }

            showNotification('ra.notification.created')
            props.history.push(`/Person`, 'info')
        }

        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar {...rest} className={classes.dBET}>
            <div>
                <BooleanInput
                    label="Отправить приглашение на почту"
                    source="sendEmail"
                />
                <CatchingSaveButton {...rest} />
                <CheckBoxButton
                    text="Создать пользователя"
                    isSelected={createUser}
                    onHandleChange={() => setCreateUser(!createUser)}
                />
                {!!showCreatePassportButton && (
                    <CheckBoxButton
                        text="Создать паспорт"
                        isSelected={createPassport}
                        onHandleChange={() =>
                            setCreatePassport(!createPassport)
                        }
                    />
                )}
                <CheckBoxButton
                    text="Создать клиента"
                    isSelected={createClient}
                    onHandleChange={() => setCreateClient(!createClient)}
                />
            </div>
        </Toolbar>
    )
}
export const ToolbarContactCreate = withRouter(
    connect(undefined, { showNotification })(ToolbarContactCreateBase)
)

const ToolbarContactEditBase = (props) => {
    const classes = useStyles(``)
    const [createClient, setCreateClient] = useState(false)
    const {
        userCreated,
        passportCreated,
        customerCreated,

        createPassport,
        setCreatePassport,
        createUser,
        setCreateUser,

        handleSubmit,
        showNotification,

        showCreatePassportButton,

        ...rest
    } = props
    const CatchingSaveButton = (eProps) => {
        const { invalid } = eProps

        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )

        const onHandle = async (e) => {
            if (!!invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                eProps.handleSubmitWithRedirect('list')()
                e.preventDefault()
                return
            }

            const handleBeforeSubmit = () => handleSubmit((values) => values)
            const {
                user,
                passports,
                userFilters,
                ...rest
            } = handleBeforeSubmit()()

            const { data: person } = await oDataProvider(
                UPDATE,
                Resources.Person,
                { id: rest.id, data: { ...rest } }
            )
            // Создание/редактирование паспорта
            if (createPassport) {
                if (!passportCreated)
                    await oDataProvider(CREATE, Resources.PersonPassport, {
                        data: {
                            batch: passports.batch,
                            serialNumber: passports.serialNumber,
                            issuer: passports.issuer,
                            issuerNum: passports.issuerNum,
                            issuedAt: passports.issuedAt,
                            regPlace: passports.regPlace,
                            birthPlace: passports.birthPlace,
                            birthDay: passports.birthDay,
                            personId: rest.id
                        }
                    })
                else
                    await oDataProvider(UPDATE, Resources.PersonPassport, {
                        id: passports.id,
                        data: {
                            batch: passports.batch,
                            serialNumber: passports.serialNumber,
                            issuer: passports.issuer,
                            issuerNum: passports.issuerNum,
                            issuedAt: passports.issuedAt,
                            regPlace: passports.regPlace,
                            birthPlace: passports.birthPlace,
                            birthDay: passports.birthDay
                        }
                    })
            }

            if (createClient) {
                await oDataProvider(CREATE, Resources.Customer, {
                    data: {
                        displayName: getFio(rest),
                        personId: rest.id
                    }
                })
            }
            if (createUser) {
                if (!userCreated)
                    await oDataProvider(CREATE, Resources.AppUser, {
                        data: {
                            personId: rest.id,
                            email: rest.email,
                            password: user.password,
                            role: user.role,
                            companyId: user.companyId
                        }
                    })
                else {
                    await oDataProvider(UPDATE, Resources.AppUser, {
                        id: person.userId,
                        data: { role: user.role }
                    })
                }
            }

            showNotification('ra.notification.created')
            props.history.push(`/Person`, 'info')
        }

        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar {...rest} className={classes.dBET}>
            <div>
                <CatchingSaveButton {...props} />
                <CheckBoxButton
                    text={
                        userCreated
                            ? 'Редактировать пользователя'
                            : 'Создать пользователя'
                    }
                    isSelected={createUser}
                    onHandleChange={() => setCreateUser(!createUser)}
                />
                {!!showCreatePassportButton && (
                    <CheckBoxButton
                        text={
                            passportCreated
                                ? 'Редактировать паспорт'
                                : 'Создать паспорт'
                        }
                        isSelected={createPassport}
                        onHandleChange={() =>
                            setCreatePassport(!createPassport)
                        }
                    />
                )}
                {!customerCreated && (
                    <CheckBoxButton
                        text="Создать клиента"
                        isSelected={createClient}
                        onHandleChange={() => setCreateClient(!createClient)}
                    />
                )}
            </div>
            <DeleteButton {...props} />
        </Toolbar>
    )
}
export const ToolbarContactEdit = withRouter(
    connect(undefined, { showNotification })(ToolbarContactEditBase)
)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IInvoiceModel extends IStandardFieldsModel {
    title: 'title'

    requestedAmount: 'requestedAmount'
    paidAmount: 'paidAmount'
    status: 'status'

    projectId: 'projectId'
    project: 'project'

    customerId: 'customerId'
    customer: 'customer'
}

const invoice: IInvoiceModel = {
    ...standardFieldsModel,
    title: 'title',

    requestedAmount: 'requestedAmount',
    paidAmount: 'paidAmount',
    status: 'status',

    projectId: 'projectId',
    project: 'project',
    customerId: 'customerId',
    customer: 'customer'
}

export const invoiceModel = Object.freeze(invoice)

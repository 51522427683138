export class QueryOptionSettings {
    public option?: string

    public toString(): string {
        return `${this.option}`
    }

    public reset(): void {
        this.option = undefined
    }

    public isSet(): boolean {
        return !!this.option
    }
}

import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcoon
} from '@material-ui/icons/'

const Icon = (props) =>
    props.isSelected ? (
        <CheckBoxIcon color="primary" />
    ) : (
        <CheckBoxOutlineBlankIcoon />
    )
const Label = (props) =>
    props.isSelected ? (
        <Typography color="primary">{props.text}</Typography>
    ) : (
        <Typography>{props.text}</Typography>
    )

export default (props) => {
    const { text, isSelected, onHandleChange } = props
    return (
        <Button size="small" onClick={onHandleChange}>
            <Icon isSelected={isSelected} /> &nbsp;
            <Label isSelected={isSelected} text={text} />
        </Button>
    )
}

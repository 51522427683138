import React, { useState } from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton
} from 'react-admin'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { saveWithNoteCreate } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const getValidData = (data) => {
    const classification = {
        anotherVariant: data.classification.anotherVariant,
        bankruptcyVariant: data.classification.bankruptcyVariant,
        executiveProceedingVariant:
            data.classification.executiveProceedingVariant,
        isSubsidiaryLiability: data.classification.isSubsidiaryLiability,
        negotiationsAssistanceVariant:
            data.classification.negotiationsAssistanceVariant,
        requestId: data.classification.requestId,
        type: data.classification.type
    }
    const request = {
        customerId: data.customerId,
        subject: data.subject,
        text: data.text
    }
    return {
        request: request,
        classification: classification,
        id: data.id,
        classificationId: data.classification.id
    }
}

export const ToolbarRequestEditBase = (props) => {
    const classes = useStyles(``)
    const {
        handleSubmit,
        showNotification,
        setIncorrectPassword,
        ...rest
    } = props

    const [saving, setSaving] = useState(false)

    const handleBeforeSubmit = () => handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )
        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            setSaving(true)

            const {
                id,
                classificationId,
                request,
                classification
            } = getValidData(handleBeforeSubmit()())
            await oDataProvider(UPDATE, Resources.RequestClassification, {
                id: classificationId,
                data: { ...classification }
            })
            await oDataProvider(UPDATE, Resources.Request, {
                id: id,
                data: { ...request }
            })
            props.history.push('/Request')

            setSaving(false)
        }
        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }
    return (
        <>
            <Toolbar
                {...rest}
                className={classes.ToolbarCreate}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div>
                    {!saving && <CatchingSaveButton {...rest} />}
                    {saving && <CircularProgress size={18} thickness={2} />}
                </div>
                <DeleteButton />
            </Toolbar>
        </>
    )
}
export const ToolbarRequestEdit = connect(undefined, {
    saveWithNoteCreate,
    showNotification
})(ToolbarRequestEditBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILookupModel extends IStandardFieldsModel {
    name: 'name'
    systemName: 'systemName'
    lookupValues: 'values'
}

const lookup: ILookupModel = {
    ...standardFieldsModel,
    name: 'name',
    systemName: 'systemName',
    lookupValues: 'values'
}

export const lookupModel = Object.freeze(lookup)

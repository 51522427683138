const IS_ENDED_PROJECT = 'SET_IS_ENDED_PROJECT'
const initialState = 'ignore'

export const endedStateProjectAction = (isEndedProject) => {
    const action = {
        type: IS_ENDED_PROJECT,
        isEndedProject
    }
    return action
}

export const endedStateProjectReducer = (
    priviousState = initialState,
    action
) => (action.type === IS_ENDED_PROJECT ? action.isEndedProject : priviousState)

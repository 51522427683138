import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewTaskStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewTaskComponent = ({ record, classes, ...rest }) => {
    console.log('AddNewTaskComponent ', record, rest)
    return (
        <Button
            className={classes.button}
            component={Link}
            color={'secondary'}
            variant={'contained'}
            to={`/ProjectTask/create?projectId=${record.id}`}
            label="Добавить задачу"
            // title="Добавить задачу"
        >
            <TemplateIcon />
        </Button>
    )
}
export const AddNewTaskButton = withStyles(addNewTaskStyles)(
    AddNewTaskComponent
)

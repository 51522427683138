import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { People as PeopleIcon } from '@material-ui/icons'

const addNewLettertStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewLetterButtonBase = ({ record, classes }) => {
    return (
        <Button
            className={classes.button}
            component={Link}
            color={'secondary'}
            variant={'contained'}
            to={`/LetterOfAuthority/create?projectId=${record.id}`}
            label="Добавить доверенность"
            // title="Добавить участника"
        >
            <PeopleIcon />
        </Button>
    )
}
export const AddNewLetterButton = withStyles(addNewLettertStyles)(
    AddNewLetterButtonBase
)

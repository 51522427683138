import * as React from 'react'
import { PageForm } from './pageForm'
import { Create, Edit } from 'react-admin'
import { ToolbarGeneral } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'

const PageCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контент сайта'
    })
    titles.push({
        type: 'link',
        href: '/Page',
        value: 'Страницы'
    })
    titles.push({
        type: 'text',
        value: 'Редактирование страницы'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <PageForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const PageCreateView = enchanceCreate(PageCreateViewBase)

const PageEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <PageForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const PageEditView = enchanceEdit(PageEditViewBase)

const IS_FAVORITE_PERSON = 'SET_IS_FAVORITE_PERSON'
const initialState = false

export const isFavoritePersonAction = (isFavoritePerson) => {
    const action = {
        type: IS_FAVORITE_PERSON,
        isFavoritePerson
    }
    return action
}

export const isFavoritePersonReducer = (priviousState = initialState, action) =>
    action.type === IS_FAVORITE_PERSON ? action.isFavoritePerson : priviousState

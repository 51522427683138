import React from 'react'
import { EditButton } from 'react-admin'
import { CardActions } from '@material-ui/core'
import { HistoryBackButton } from '../../components/fields'
import { connect } from 'react-redux'

const style = {
    display: 'flex',
    justifyContent: 'flex-end'
}

const Action = (props) => {
    return (
        <CardActions style={props.style || style}>
            {props.canBeEdited ? (
                <EditButton basePath={props.basePath} record={props.data} />
            ) : null}
            <HistoryBackButton variant="text" size="small" />
        </CardActions>
    )
}
export default connect(
    (state: any) => ({
        canBeEdited: state.isEditedLettersOfAuthority
    }),
    undefined
)(Action)

import React from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton
} from 'react-admin'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { Resources } from '../../api/api'
import { withRouter } from 'react-router-dom'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const LetterOfAuthorityCreateToolbarBase = (props) => {
    const classes = useStyles()
    const { handleSubmit, showNotification, ...rest } = props

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps

        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )

        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                e.preventDefault()
                return
            }

            const handleBeforeSubmit = () => handleSubmit((values) => values)
            const {
                requesterChoice,
                requesterValue,
                requesterDictValueId,
                classificationChoice,
                classificationValue,
                classificationDictValueId,
                ...rest
            } = handleBeforeSubmit()()

            if (rest.personTrusteeId === undefined)
                rest.personTrusteeId = Number(localStorage.getItem('personId'))

            let regPlaceId = requesterDictValueId
            if (requesterChoice === 'fromTextInput') {
                const { data: regPlaceDict } = await oDataProvider(
                    CREATE,
                    Resources.LetterOfAuthorityRequesterDictValue,
                    { data: { value: requesterValue } }
                )
                regPlaceId = regPlaceDict.id
            }
            let classificationId = classificationDictValueId
            if (classificationChoice === 'fromTextInput') {
                const { data: classificationDict } = await oDataProvider(
                    CREATE,
                    Resources.LetterOfAuthorityClassificationDictValue,
                    { data: { value: classificationValue } }
                )
                classificationId = classificationDict.id
            }

            await oDataProvider(CREATE, Resources.LetterOfAuthority, {
                data: {
                    ...rest,
                    requesterDictValueId: regPlaceId,
                    classificationDictValueId: classificationId
                }
            })

            showNotification('ra.notification.created')
            props.history.push(`/LetterOfAuthority`, 'info')
        }

        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar {...rest} className={classes.ToolbarCreate}>
            <CatchingSaveButton />
        </Toolbar>
    )
}
export const ToolbarLetterOfAuthorityCreate = withRouter(
    connect(undefined, { showNotification })(LetterOfAuthorityCreateToolbarBase)
)

const LetterOfAuthorityEditToolbarBase = (props) => {
    const classes = useStyles()
    const { handleSubmit, showNotification, ...rest } = props

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps

        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )

        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                e.preventDefault()
                return
            }

            const handleBeforeSubmit = () => handleSubmit((values) => values)
            const {
                requesterChoice,
                requesterValue,
                requesterDictValueId,
                classificationChoice,
                classificationValue,
                classificationDictValueId,
                project,
                ...rest
            } = handleBeforeSubmit()()

            if (rest.personTrusteeId === undefined)
                rest.personTrusteeId = Number(localStorage.getItem('personId'))

            let regPlaceId = requesterDictValueId
            if (requesterChoice === 'fromTextInput') {
                const { data: regPlaceDict } = await oDataProvider(
                    CREATE,
                    Resources.LetterOfAuthorityRequesterDictValue,
                    { data: { value: requesterValue } }
                )
                regPlaceId = regPlaceDict.id
            }
            let classificationId = classificationDictValueId
            if (classificationChoice === 'fromTextInput') {
                const { data: classificationDict } = await oDataProvider(
                    CREATE,
                    Resources.LetterOfAuthorityClassificationDictValue,
                    { data: { value: classificationValue } }
                )
                classificationId = classificationDict.id
            }

            await oDataProvider(UPDATE, Resources.LetterOfAuthority, {
                id: props.record.id,
                data: {
                    ...rest,
                    requesterDictValueId: regPlaceId,
                    classificationDictValueId: classificationId
                }
            })

            showNotification('ra.notification.updated')
            props.history.push(`/LetterOfAuthority`, 'info')
        }

        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar
            style={{ display: 'flex', justifyContent: 'space-between' }}
            {...rest}
            className={classes.ToolbarCreate}
        >
            <CatchingSaveButton />
            <DeleteButton size="small" redirect="list" variant="text" />
        </Toolbar>
    )
}
export const ToolbarLetterOfAuthorityEdit = withRouter(
    connect(undefined, { showNotification })(LetterOfAuthorityEditToolbarBase)
)

import React, { useState } from 'react'
import { Edit, Show } from 'react-admin'
import { RequestEditActions } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources, ITitle } from '../../api/api'
import { RequestInput } from './requestInput'
import { ShowRequestForm } from './requestShow'
import Create from '../../layout/create'

export const validate = (values: any) => {
    const erros: any = {}
    return erros
}

const enchance = connect(undefined, {
    setTitle: titleAction
})

const RequestEditViewBase = (props) => {
    const { setTitle, ...rest } = props
    const [canBeEdited, setCanBeEdited] = useState(true)
    return (
        <Edit
            actions={
                <RequestEditActions
                    canBeEdited={canBeEdited}
                    isEdit={true}
                    id={props.id}
                    onHandleEditChange={() =>
                        props.history.push(
                            `/${Resources.Request}/${props.id}/show`
                        )
                    }
                />
            }
            undoable
            {...rest}
        >
            <RequestInput
                isEditToolbar={true}
                setCanBeEdited={setCanBeEdited}
                {...props}
            />
        </Edit>
    )
}
export const RequestEditView = enchance(RequestEditViewBase)

const RequestCreateViewBase = (props) => {
    const { setTitle, ...rest } = props

    const titles: Array<ITitle> = []
    titles.push({
        type: 'link',
        href: '/Request',
        value: 'Заявки'
    })
    titles.push({
        type: 'text',
        value: 'Создание заявки'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <RequestInput isEditToolbar={false} {...rest} />
        </Create>
    )
}
export const RequestCreateView = enchance(RequestCreateViewBase)

const RequestShowViewBase = (props) => {
    const [canBeEdited, setCanBeEdited] = useState(true)
    return (
        <Show
            actions={
                <RequestEditActions
                    canBeEdited={canBeEdited}
                    isEdit={false}
                    id={props.id}
                    onHandleEditChange={() =>
                        props.history.push(`/${Resources.Request}/${props.id}`)
                    }
                />
            }
            undoable
            {...props}
        >
            <ShowRequestForm
                isEditToolbar={null}
                setCanBeEdited={setCanBeEdited}
                {...props}
            />
        </Show>
    )
}
export const RequestShowView = enchance(RequestShowViewBase)

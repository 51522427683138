import React, { useState } from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton
} from 'react-admin'
import { CheckBoxButton } from '../../components/fields'
import { connect } from 'react-redux'
import { saveWithNoteUpdate } from '../../utils/dataHelpers'
import { makeStyles } from '@material-ui/styles'
import handleValueBeforeSubmit from '../../utils/handleValueBeforeSubmit'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Save as SaveIcon } from '@material-ui/icons'
import { Button, Typography } from '@material-ui/core'
import oDataProvider from '../../api/oDataProvider'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

const getValidData = (data) => {
    debugger
    const request = {
        name: data.name,
        budget: data.budget,
        color: data.color,
        createdOn: data.createdOn,
        description: data.description,
        deletedOn: data.deletedOn,
        note: data.note,
        number: data.number,
        endDate: data.endDate,
        startDate: data.startDate,
        status: data.status,
        updatedOn: data.updatedOn
    }
    return {
        request: request,
        id: data.id
    }
}

const ToolbarProject = (props) => {
    const {
        handleSubmit,
        showNotification,
        setIncorrectPassword,
        ...rest
    } = props

    const [saving, setSaving] = useState(false)
    const classes = useStyles()
    const handleBeforeSubmit = () => handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )

        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            setSaving(true)

            const { id, request } = getValidData(handleBeforeSubmit()())

            await oDataProvider(UPDATE, Resources.Project, {
                id: id,
                data: { ...request }
            })
            props.history.push('/Project')

            setSaving(false)
        }
        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }

    return (
        <Toolbar {...props} className={classes.dBET}>
            <div>
                <div>
                    {!saving && <CatchingSaveButton {...rest} />}
                    {saving && <CircularProgress size={18} thickness={2} />}
                </div>
            </div>
            <DeleteButton size="small" redirect="list" variant="text" />
        </Toolbar>
    )
}
export default connect(undefined, { saveWithNoteUpdate })(ToolbarProject)

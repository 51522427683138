import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons/'

const Icon = (props) =>
    props.isSelected ? (
        <VisibilityOffIcon color="primary" />
    ) : (
        <VisibilityIcon />
    )
const Label = (props) => (
    <Typography color={props.isSelected ? 'primary' : undefined}>
        {props.text}
    </Typography>
)

export default (props) => {
    const { isSelected, onHandleChange, text } = props
    return (
        <Button size="small" onClick={onHandleChange}>
            <Icon isSelected={isSelected} /> &nbsp;
            <Label isSelected={isSelected} text={text} />
        </Button>
    )
}

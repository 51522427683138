import React, { useState, useEffect } from 'react'
import {
    FormDataConsumer,
    SimpleForm,
    SelectInput,
    TextInput,
    ReferenceInput,
    required,
    minLength,
    DisabledInput,
    GET_LIST,
    email,
    BooleanInput,
    Create,
    fetchStart,
    fetchEnd,
    TextField,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import { getFio } from '../../utils/dataHelpers'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { UserRoles } from './userForm'
import { ToolbarGeneral } from '../../layout'
import { ContactInfoTypes } from '../../api/publicOjects'
import { SelectAndSearchInput } from '../fields'
import { Resources } from '../../api/api'
import { RoleTypeById } from '../../api/publicOjects'
import {
    appUserModel as au,
    personModel as pm,
    personContactModel as pc
} from '../../api/models'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warning: {
        minWidth: 252,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const formName = 'create-user-form'

const PersonInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет контактов</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const PersonInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Существующий контакт"
            source="personId"
            reference={Resources.Person}
            filter={{ user: null, customFilter: '', ignoreDefaultFilter: true }}
            optionRenderer={getFio}
            multi={false}
        />
    ) : (
        <DisabledInput label={<PersonInputLabel style={props.classes} />} />
    )
}

const EmployeeToCompanyInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет компаний</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const EmployeeToCompanyInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Привязать к компании"
            source="companyId"
            reference={Resources.Company}
            optionText="shortName"
            multi={false}
        />
    ) : (
        <DisabledInput
            label={<EmployeeToCompanyInputLabel style={props.classes} />}
        />
    )
}

const CreateUserComponentBase = (props) => {
    const [state, setState] = useState({
        atLeastOneCompany: true,
        atLeastOnePersons: true,
        emails: []
    })
    const { isEditToolbar, ...rest } = props

    useEffect(() => {
        const loader = async () => {
            fetchStart()
            const action = GET_LIST
            const { data: company } = await oDataProvider(
                action,
                Resources.Company,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: person } = await oDataProvider(
                action,
                Resources.Person,
                {
                    pagination: { page: 1, perPage: 1 },
                    filter: { userId: null }
                }
            )
            setState({
                ...state,
                atLeastOneCompany: company.length > 0,
                atLeastOnePersons: person.length > 0,
                emails: person.map((item) => item.email)
            })
            fetchEnd()
        }
        loader()
    }, [])

    const validate = (values) => {
        let error: any = {}
        return error
    }

    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <PersonInput atLeastOne={state.atLeastOnePersons} {...rest} />
            <TextInput
                label="Эл. почта"
                source={au.email}
                validate={[required(), email()]}
            />
            <TextInput
                label="Пароль"
                type="password"
                autoComplete="new-password"
                helperText="Должен быть не менее 5 символов"
                source={au.password}
                validate={[required(), minLength(5)]}
            />
            <FormDataConsumer>
                {({ formData }) => (
                    <TextInput
                        label="Повтор пароля"
                        source="confirmPassword"
                        type="password"
                        className={props.classes.inputs}
                        autoComplete="new-password"
                        helperText="Пароли должны совпадать"
                        error={formData.confirmPassword !== formData.password}
                        validate={[required(), minLength(5)]}
                    />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    !formData.personId && (
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <TextInput
                                label="Фамилия"
                                source={pm.lastName}
                                validate={required()}
                                {...rest}
                            />
                            <TextInput
                                label="Имя"
                                source={pm.firstName}
                                validate={required()}
                                {...rest}
                            />
                            <TextInput
                                label="Отчетство"
                                source={pm.patronymicName}
                                {...rest}
                            />
                            <TextInput
                                label="Телефон"
                                source={pm.phone}
                                {...rest}
                            />
                        </div>
                    )
                }
            </FormDataConsumer>

            <EmployeeToCompanyInput
                atLeastOne={state.atLeastOneCompany}
                {...props}
            />
            <SelectInput
                label="Роль"
                source="role"
                choices={UserRoles}
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.companyId && (
                        <TextInput
                            label="Должность"
                            source="jobPosition"
                            {...rest}
                        />
                    )
                }
            </FormDataConsumer>
            <BooleanInput
                label="Отправить приглашение на почту"
                source="sendEmail"
            />
        </SimpleForm>
    )
}
export const UserCreateForm = withStyles(styles as any)(CreateUserComponentBase)

const EditUserComponentBase = (props) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [createNewPassword, setCreateNewPassword] = useState(false)
    const [incorrectPassword, setIncorrectPassword] = useState(false)

    const validate = (values) => {
        let error: any = {}
        return error
    }

    if (!!props.record.userRoles && !!props.record.userRoles.length)
        props.record.role = RoleTypeById[props.record.userRoles[0].roleId]

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'link',
            href: '/AppUser',
            value: 'Пользователи'
        })
        titles.push({
            type: 'text',
            value: `Редактирование пользователя №${props.record.id} - ${props.record.email}`
        })
        setTitle(titles)
    }

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    setIncorrectPassword={setIncorrectPassword}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <TextField label="Имя пользователя" source={au.userName} />

            <TextInput label="Фамилия" source={`${au.person}.${pm.lastName}`} />
            <TextInput label="Имя" source={`${au.person}.${pm.firstName}`} />
            <TextInput
                label="Отчество"
                source={`${au.person}.${pm.patronymicName}`}
            />
            <TextInput label="Телефон" source={`${au.person}.${pm.phone}`} />

            <TextInput
                label="Эл. почта"
                source={`${au.person}.${pm.email}`}
                validate={[required(), email()]}
            />
            <ArrayInput
                label="Контактные данные"
                source={`${au.person}.${pm.contactInformation}`}
                variant="text"
                size="small"
            >
                <SimpleFormIterator>
                    <SelectInput
                        label="Тип"
                        source={pc.infoRecordType}
                        choices={ContactInfoTypes}
                    />
                    <TextInput label="Значение" source={pc.contact} />
                    <TextInput label="Комментарий" source={pc.comment} />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput
                label="Старый пароль"
                source={au.oldPassword}
                type="password"
                autoComplete="new-password"
                helperText={
                    createNewPassword
                        ? 'Обязательно для ввода'
                        : 'Нажмите, чтобы ввести новый пароль'
                }
                onClick={() => setCreateNewPassword(true)}
                onChange={() => setIncorrectPassword(false)}
                error={incorrectPassword}
                validate={
                    createNewPassword ? [required(), minLength(5)] : undefined
                }
            />
            {createNewPassword && (
                <TextInput
                    label="Пароль"
                    source={au.newPassword}
                    type="password"
                    autoComplete="new-password"
                    helperText="Должен быть не менее 5 символов"
                    validate={[required(), minLength(5)]}
                />
            )}
            {createNewPassword && (
                <FormDataConsumer>
                    {({ formData }) => (
                        <TextInput
                            label="Повтор пароля"
                            source="confirmPassword"
                            className={props.classes.inputs}
                            type="password"
                            error={
                                formData.confirmPassword !==
                                formData.newPassword
                            }
                            autoComplete="new-password"
                            helperText="Пароли должны совпадать"
                            validate={[required(), minLength(5)]}
                        />
                    )}
                </FormDataConsumer>
            )}
            <SelectInput
                label="Роль"
                source={au.role}
                choices={UserRoles}
                validate={required()}
            />
        </SimpleForm>
    )
}
export const UserEditForm = withStyles(styles as any)(EditUserComponentBase)

export class TopSettings {
    public top?: number
    public defaultTop?: number

    public toString(): string {
        return '$top=' + (this.top || this.defaultTop)
    }

    public reset(): void {
        this.top = undefined
    }

    public isSet(): boolean {
        return !!this.top || !!this.defaultTop
    }
}

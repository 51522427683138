import React from 'react'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { TaskInput } from './taskInput'
import Edit from '../../layout/edit'
import Create from '../../layout/create'

export const TaskCreateViewBase = (props: any) => {
    const { projectId: projectIdString } = parse(props.location.search)
    const projectId = projectIdString
        ? parseInt(projectIdString.toString(), 10)
        : ''
    const redirect = projectId ? `/Project/${projectId}/tasks` : false

    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/ProjectTask',
        value: 'Задачи'
    })
    titles.push({
        type: 'text',
        value: 'Создание задачи'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <TaskInput isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const TaskCreateView = enchanceCreate(TaskCreateViewBase)

export const TaskEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <TaskInput isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const TaskEditView = enchanceEdit(TaskEditViewBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IProcedureModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'

    projectId: 'projectId'
    project: 'project'

    projectTasks: 'tasks'
    phases: 'phases'

    procedureTemplateId: 'procedureTemplateId'
    procedureTemplate: 'procedureTemplate'

    status: 'status'

    startDate: 'startDate'
    endDate: 'endDate'
}

const procedure: IProcedureModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',

    projectId: 'projectId',
    project: 'project',

    projectTasks: 'tasks',
    phases: 'phases',

    procedureTemplateId: 'procedureTemplateId',
    procedureTemplate: 'procedureTemplate',

    status: 'status',

    startDate: 'startDate',
    endDate: 'endDate'
}

export const procedureModel = Object.freeze(procedure)

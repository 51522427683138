import React from 'react'
import { forceUpdate } from '../../utils/forceUpdate'
import { FavoriteButton } from '../fields'
import { CREATE } from 'react-admin'
import { withRouter } from 'react-router-dom'
import oDataProvider from '../../api/oDataProvider'

const AddToFavoriteBase = (props) => {
    const rerender = forceUpdate()

    if (!props.record.userFilters || !props.record.userFilters.length) {
        props.record.userFilters = [{ isFavorite: false }]
    }

    const handleClick = (e) => {
        e.stopPropagation()
        props.record.userFilters[0].isFavorite = !props.record.userFilters[0]
            .isFavorite
        rerender()
        oDataProvider(
            CREATE,
            //@ts-ignore
            `${props.resource}(${props.record.id})/SetFavorite`,
            { data: { State: props.record.userFilters[0].isFavorite } }
        )
    }

    return (
        <FavoriteButton
            isSelected={props.record.userFilters[0].isFavorite}
            onHandleChange={handleClick}
        />
    )
}
export const AddToFavorite = withRouter(AddToFavoriteBase)
AddToFavorite.defaultProps = { label: 'Избранный' }

import { FilterClause, Tso } from '../utils/ts-odata'
import { Resources as Rs } from '../api/api'

const stdLen = (time): string =>
    time.toString().length === 1 ? `0${time}` : time.toString()

export default (query: Tso, filter, resource: string): void => {
    if (
        resource !== Rs.Project &&
        resource !== Rs.Request &&
        resource !== Rs.ProjectTask &&
        resource !== Rs.Person &&
        resource !== Rs.VersionedFileAttachment
    )
        return

    if (!filter || filter.isIgnoreFilters === true) return
    const id = localStorage.getItem('id')

    let extraFilter = `not (userFilters/any(userFilter: userFilter/isHide and userFilter/userId eq ${id})) and `
    if (!!filter.isHide && !!filter.isFavorite)
        extraFilter = `userFilters/any(userFilter: userFilter/isHide eq true and userFilter/isFavorite eq true and userFilter/userId eq ${id}) and `
    else if (!!filter.isHide && !filter.isFavorite)
        extraFilter = `userFilters/any(userFilter: userFilter/isHide eq true and userFilter/userId eq ${id}) and `
    else if (!filter.isHide && !!filter.isFavorite)
        extraFilter = `userFilters/any(userFilter: userFilter/isHide eq false and userFilter/isFavorite eq true and userFilter/userId eq ${id}) and `

    if (filter.endedState !== 'ignore' && filter.endedState !== undefined) {
        const today = new Date()
        const date =
            today.getFullYear() +
            '-' +
            stdLen(today.getMonth() + 1) +
            '-' +
            stdLen(today.getDate())
        const time =
            stdLen(today.getHours()) +
            ':' +
            stdLen(today.getMinutes()) +
            ':' +
            stdLen(today.getSeconds())
        extraFilter += `endDate ${filter.endedState} date(${date}T${time}Z) and `
    }

    query.filter(
        new FilterClause(extraFilter.substr(0, extraFilter.length - 5))
    )
}

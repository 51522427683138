import * as React from 'react'
import { connect } from 'react-redux'
import { crudGetList } from 'react-admin'
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component'
import { Typography } from '@material-ui/core'
import { Work as WorkIcon } from '@material-ui/icons'
import { Resources } from '../../api/api'

class TimelineComponent extends React.Component<any, any> {
    page = 1

    constructor(props) {
        super(props)
        this.state = {
            events: props.ids.map((id) => props.data[id]),
            latestId: props.ids[props.ids.length - 1]
        }
    }

    updateData = () => {
        const filter = `${this.props.resource}TimelineEvent/${this.props.resource}Id`

        this.props.crudGetList(
            'TimelineEvent',
            { page: this.page, perPage: 20 },
            { field: 'id', order: 'DESC' },
            {
                [filter]: this.props.record.id,
                $expand:
                    'CreatedBy($expand=Person($select=FirstName,LastName);$select=Person;)'
            }
        )
    }

    componentDidMount() {
        this.updateData()
    }

    componentWillUnmount() {
        this.page = 1
        this.updateData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.ids !== prevProps.ids) {
            const { ids, data } = this.props
            const latestId = ids[ids.length - 1]
            if (latestId && latestId !== prevState.latestId) {
                const newEvents = ids.map((id) => data[id])
                this.setState((state) => ({
                    events: state.events.concat(newEvents),
                    latestId
                }))
            }
        }
    }

    handleLoadMore = () => {
        this.page = this.page + 1
        this.updateData()
    }

    render() {
        const { events } = this.state

        const { loadedOnce } = this.props
        return (
            <div style={{ backgroundColor: '#ddd', width: '100%' }}>
                <VerticalTimeline>
                    {!loadedOnce ? (
                        <Typography variant="h5">Загрузка...</Typography>
                    ) : events.length === 0 ? (
                        <Typography variant="caption">
                            Нет событий для отображения.
                        </Typography>
                    ) : (
                        events.map((e, key) => (
                            <VerticalTimelineElement
                                key={key + e.createdOn}
                                className="vertical-timeline-element--work"
                                date={e.message}
                                iconStyle={{
                                    background: 'rgb(33, 150, 243)',
                                    color: '#fff'
                                }}
                                icon={<WorkIcon />}
                            >
                                {e.createdBy && e.createdBy.person && (
                                    <h3 className="vertical-timeline-element-title">
                                        {e.createdBy.person.firstName}{' '}
                                        {e.createdBy.person.lastName}
                                    </h3>
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: e.eventMessage
                                    }}
                                />
                            </VerticalTimelineElement>
                        ))
                    )}
                </VerticalTimeline>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ids: state.admin.resources.TimelineEvent.list.ids,
    data: state.admin.resources.TimelineEvent.data,
    total: state.admin.resources.TimelineEvent.list.total,
    loadedOnce: state.admin.resources.TimelineEvent.list.loadedOnce
})

const Timeline = connect(mapStateToProps, { crudGetList })(TimelineComponent)

export default Timeline

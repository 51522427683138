import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IVersionedFileAttachmentModel extends IStandardFieldsModel {
    name: 'name'
    description: 'description'
    commentListId: 'commentListId'
    commentList: 'commentList'
    ownerUserId: 'ownerUserId'
    ownerUser: 'ownerUser'
    ownerProjectId: 'ownerProjectId'
    ownerProject: 'ownerProject'
    ownerRequestId: 'ownerRequestId'
    ownerRequest: 'ownerRequest'
    visibilityFlags: 'visibilityFlags'
    editAccessFlags: 'editAccessFlags'
    atDate: 'atDate'
    files: 'files'
    userFilters: 'userFilters'
}

const versionedFileAttachment: IVersionedFileAttachmentModel = {
    ...standardFieldsModel,
    name: 'name',
    description: 'description',
    commentListId: 'commentListId',
    commentList: 'commentList',
    ownerUserId: 'ownerUserId',
    ownerUser: 'ownerUser',
    ownerProjectId: 'ownerProjectId',
    ownerProject: 'ownerProject',
    ownerRequestId: 'ownerRequestId',
    ownerRequest: 'ownerRequest',
    visibilityFlags: 'visibilityFlags',
    editAccessFlags: 'editAccessFlags',
    atDate: 'atDate',
    files: 'files',
    userFilters: 'userFilters'
}

export const versionedFileAttachmentModel = Object.freeze(
    versionedFileAttachment
)

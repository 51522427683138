import React, { useState, useEffect, useRef } from 'react'
import {
    Notifications as NotificationsIcon,
    Settings as SettingsIcon
} from '@material-ui/icons/'
import { GET_LIST } from 'react-admin'
import { IconButton, Typography, MenuList, Paper } from '@material-ui/core'
import { Notify, NotifyWithContent } from './notificationTypes'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { useOutsideSetter } from './notificationUtils'
import { NotificationItems } from './notificationItems'
import { Resources, NotificationTypes } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

const useStyles = makeStyles({
    notificationsPaper: {
        position: 'absolute',
        right: 85,
        borderBottom: '1px solid #555555',
        borderLeft: '1px solid #555555',
        borderRight: '1px solid #555555',
        backgroundColor: '#333333',
        width: 430
    },
    notificationsHeader: {
        color: 'white',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        borderBottom: '1px solid #555555'
    },
    notificationTitle: {
        color: 'white'
    },
    notificationContent: {
        color: '#C2C2C2'
        // width: 400
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    notificationSent: {
        color: '#C2C2C2',
        paddingTop: 10
    },
    menuItem: {
        // width: 430,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    isSentPoint: {
        marginRight: '12px'
    }
})

export const Notification = ({
    value,
    classes,
    title,
    contents,
    deltaTime
}) => {
    const val = {
        [NotificationTypes.TaskAssignee]: (
            <NotifyWithContent
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
                contents={contents}
            />
        ),
        [NotificationTypes.ProjectCommentBroadcast]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.ProjectCommentReply]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.RequestCommentBroadcast]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.RequestCommentReply]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.ProjectTaskEndOfWork]: (
            <NotifyWithContent
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
                contents={contents}
            />
        ),
        [NotificationTypes.ProjectEndOfWork]: (
            <NotifyWithContent
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
                contents={contents}
            />
        ),
        [NotificationTypes.VerFileCommentReply]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.VerFileCommentBroadcast]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        [NotificationTypes.RequestAccept]: (
            <NotifyWithContent
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
                contents={contents}
            />
        ),
        [NotificationTypes.NewRequest]: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        ),
        default: (
            <Notify
                classes={classes}
                deltaTime={deltaTime}
                value={value}
                title={title}
            />
        )
    }
    return val[value.type] || val['default']
}

export function NotificationsMenu(props) {
    const { classes } = props

    const [anchorEl, setAnchorEl] = useState(false)
    const [notificationsData, setNotificationsData]: any = useState([])

    const wrapperRef = useRef(null)
    useOutsideSetter(wrapperRef, setAnchorEl, false)

    const handleClickNotificationIcon = () => setAnchorEl(!anchorEl)
    const onClick = () => {
        setAnchorEl(false)
    }

    useEffect(() => {
        const loader = async () => {
            const { data: messages } = await oDataProvider(
                GET_LIST,
                Resources.Notification,
                {
                    sort: { field: 'Id', order: 'DESC' },
                    pagination: { page: 1, perPage: 10 }
                }
            )
            setNotificationsData(messages)
        }
        loader()
    }, [anchorEl])

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickNotificationIcon}
            >
                <NotificationsIcon style={{ color: 'white' }} />
            </IconButton>
            {anchorEl && (
                <div ref={wrapperRef}>
                    <Paper
                        elevation={0}
                        square
                        className={classes.notificationsPaper}
                    >
                        <Link
                            to={`/${Resources.Notification}`}
                            style={{ textDecoration: 'none' }}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.notificationsHeader}
                                onClick={onClick}
                            >
                                Уведомления
                            </Typography>
                        </Link>
                        <Link to={`/${Resources.UserNotificationChannel}`}>
                            <IconButton
                                className={classes.notificationSettingIcon}
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Link>
                        <MenuList
                            id="simple-menu"
                            style={{
                                maxHeight: 450,
                                overflow: 'auto'
                            }}
                        >
                            <NotificationItems
                                classes={classes}
                                notificationsData={notificationsData}
                                onClick={onClick}
                            />
                        </MenuList>
                    </Paper>{' '}
                </div>
            )}
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import {
    Datagrid,
    FormTab,
    GET_LIST,
    ReferenceField,
    ReferenceManyField,
    TabbedForm,
    SelectField,
    DateField,
    TextField
} from 'react-admin'
import {
    BankruptcyVariant,
    bankruptcyVariantEnum,
    executiveProceedingVariantEnum,
    negotiationsAssistanceVariantEnum,
    RequestStatuses,
    requestTypeEnum
} from '../../api/publicOjects'
import {
    requestModel as rm,
    customerModel as cm,
    personModel as pm,
    projectTaskModel as pt,
    projectModel as pr,
    versionedFileAttachmentModel as vf,
    requestCommandModel as rc
} from '../../api/models'
import { FileLink } from '../fields'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { UserFullNameField } from '../fields'
import { Resources, ITitle, RoleTypes } from '../../api/api'
import { eRequestStatus } from '../../api/api'
import CommentsBlock from '../comments/comments'
import oDataProvider from '../../api/oDataProvider'
import Timeline from '../timeline/timeline'
import { ProjectStatuses } from '../project/projectForm'

export const useStyles = makeStyles({
    text: {
        fontSize: '1rem'
    },
    inputs: {
        minWidth: 252
    },
    warningCompanies: {
        width: '191%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    warningContacts: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    materialInput: {
        marginBottom: '8px',
        marginTop: '16px',
        minWidth: 252
    },
    rootContactsInput: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contactsInput: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactInput: {
        marginBottom: '8px',
        marginLeft: '10px',
        marginTop: '16px',
        minWidth: 232
    },
    linkToUser: {
        marginTop: '16px'
    },
    contactsTitle: {
        marginTop: '16px',
        color: 'gray'
    },
    filesContainer: {
        width: '100%',
        paddingTop: 30
    },
    classificationFieldsCaptions: {
        paddingTop: 14
    },
    classificationFieldsContents: {
        paddingTop: 3.5
    }
})

export function ShowRequestForm(props: any) {
    const { record, setCanBeEdited, setTitle } = props
    const classes = useStyles(``)

    // Почему-то где-то отправляется запрос без экспанда когда history.goBack() делаешь. В любом случае ререндер идет
    if (!record.classification) return null

    const type = requestTypeEnum[record.classification.type]

    const bankruptcyVariant =
        bankruptcyVariantEnum[record.classification.bankruptcyVariant]

    const executiveProceedingVariant =
        executiveProceedingVariantEnum[
            record.classification.executiveProceedingVariant
        ]

    const negotiationsAssistanceVariant =
        negotiationsAssistanceVariantEnum[
            record.classification.negotiationsAssistanceVariant
        ]
    const anotherVariant = record.classification.anotherVariant
    const isSubsidiaryLiability = record.classification.isSubsidiaryLiability
    const [isProject, setIsProject] = useState(false)

    const titles: Array<ITitle> = []
    titles.push({
        type: 'link',
        href: '/Request',
        value: 'Заявки'
    })
    titles.push({
        type: 'text',
        value: `Карта заявки №${props.record.id} - ${props.record.subject}`
    })
    setTitle(titles)

    useEffect(() => {
        const loader = async () => {
            const { data: project } = await oDataProvider(
                GET_LIST,
                Resources.Project,
                {
                    filter: { requestId: props.record.id }
                }
            )
            setIsProject(project.length > 0)
            setCanBeEdited(props.record.status !== eRequestStatus.Rejected)
        }
        loader()
    }, [])
    return (
        <TabbedForm toolbar={null} {...props}>
            <FormTab label="Заявка">
                <TextField
                    fullWidth
                    className={classes.text}
                    label="Тема"
                    source={rm.subject}
                />

                {props.record.customerId ? (
                    <ReferenceManyField
                        label="Клиент"
                        reference={Resources.Customer}
                        target={cm.id}
                        source={rm.customerId}
                        allowEmpty={true}
                    >
                        <Datagrid
                            rowClick={(id, basePath, _) =>
                                `${basePath}/${id}/show`
                            }
                        >
                            <TextField
                                label="Фамилия"
                                source={`${cm.person}.${pm.lastName}`}
                            />
                            <TextField label="Имя" source="person.firstName" />
                            <TextField
                                label="Отчество"
                                source={`${cm.person}.${pm.patronymicName}`}
                            />
                            <TextField
                                label="Компания"
                                source={cm.displayName}
                            />
                            <TextField
                                label="Эл. адрес"
                                source={`${cm.person}.${pm.email}`}
                            />
                            <TextField
                                label="Телефон"
                                source={`${cm.person}.${pm.phone}`}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                ) : (
                    <Typography style={{ padding: '10px 0' }}>
                        Нет активных клиентов
                    </Typography>
                )}

                <ReferenceManyField
                    label="Проект"
                    reference={Resources.Project}
                    target={pr.requestId}
                    allowEmpty={true}
                >
                    <Datagrid
                        rowClick={(id, basePath, _) => `${basePath}/${id}/show`}
                    >
                        <TextField label="Номер" source={pr.number} />
                        <TextField label="Имя" source={pr.name} />
                        <TextField
                            label="Клиент"
                            source={`${pr.customer}.${cm.displayName}`}
                        />
                        <DateField label="Дата начала" source={pr.startDate} />
                        <DateField
                            label="Дата завершения"
                            source={pr.endDate}
                        />
                    </Datagrid>
                </ReferenceManyField>

                {!!isProject ? (
                    <ReferenceManyField
                        label="Активные задачи"
                        reference={Resources.ProjectTask}
                        target={pt.projectId}
                        source={pt.projectId}
                        allowEmpty={true}
                    >
                        <Datagrid
                            rowClick={(id, basePath, _) =>
                                `${basePath}/${id}/show`
                            }
                        >
                            <TextField
                                label="название задачи"
                                source={pt.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={pt.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={pt.endDate}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                ) : (
                    <Typography style={{ padding: '10px 0' }}>
                        Нет активных задач
                    </Typography>
                )}

                <SelectField
                    label="Статус проекта"
                    source="status"
                    choices={RequestStatuses}
                />
                <SelectField
                    label="Статус проекта"
                    source="status"
                    choices={RequestStatuses}
                />
                {props.record.comment && (
                    <TextField
                        fullWidth
                        className={classes.text}
                        label="Комментарий"
                        source={rm.comment}
                    />
                )}

                <TextField
                    fullWidth
                    className={classes.text}
                    source={rm.other}
                    label={'Другие контактные данные'}
                />

                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.classificationFieldsCaptions}
                        gutterBottom
                    >
                        Классификация заявки
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        className={classes.classificationFieldsContents}
                        gutterBottom
                    >
                        {type}
                    </Typography>
                    {type === requestTypeEnum.Bankruptcy && (
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={classes.classificationFieldsCaptions}
                                gutterBottom
                            >
                                Вариант банкротства
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.classificationFieldsContents}
                                gutterBottom
                            >
                                {bankruptcyVariant}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.classificationFieldsCaptions}
                                gutterBottom
                            >
                                Субсидиарная ответственность
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.classificationFieldsContents}
                                gutterBottom
                            >
                                {isSubsidiaryLiability
                                    ? 'Присутствует'
                                    : 'Отсутствует'}
                            </Typography>
                        </div>
                    )}
                    {type === requestTypeEnum.ExecutiveProceeding && (
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={classes.classificationFieldsCaptions}
                                gutterBottom
                            >
                                Отношение
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.classificationFieldsContents}
                                gutterBottom
                            >
                                {executiveProceedingVariant}
                            </Typography>
                        </div>
                    )}
                    {type === requestTypeEnum.NegotiationsAssistance && (
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={classes.classificationFieldsCaptions}
                                gutterBottom
                            >
                                С кем участие
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.classificationFieldsContents}
                                gutterBottom
                            >
                                {negotiationsAssistanceVariant}
                            </Typography>
                            {negotiationsAssistanceVariant ===
                                negotiationsAssistanceVariantEnum.Another && (
                                <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        className={
                                            classes.classificationFieldsCaptions
                                        }
                                        gutterBottom
                                    >
                                        Объяснение
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        className={
                                            classes.classificationFieldsContents
                                        }
                                        gutterBottom
                                    >
                                        {anotherVariant}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <TextField
                    label="Краткое описание сути запроса"
                    fullWidth
                    className={classes.text}
                    source={rm.text}
                />
                <ReferenceManyField
                    addLabel={false}
                    target={vf.ownerRequestId}
                    reference={Resources.VersionedFileAttachment}
                    linkType={false}
                >
                    <Datagrid rowClick="edit">
                        <TextField label="Документ" source={vf.name} />
                        <DateField label="Дата документа" source={vf.atDate} />
                        <DateField
                            label="Дата создания"
                            source={vf.createdOn}
                        />
                        <DateField
                            label="Дата последнего обновления"
                            source={vf.updatedOn}
                        />
                        <FileLink />
                        <ReferenceField
                            label="Автор"
                            source={vf.createdById}
                            reference={Resources.AppUser}
                            allowEmpty={true}
                            linkType={false}
                        >
                            <UserFullNameField />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Комментарии" path="comments">
                <CommentsBlock commentListId={record.commentListId} />
            </FormTab>
            <FormTab label="Таймлайн" path="timeline">
                <Timeline />
            </FormTab>
        </TabbedForm>
    )
}

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectTaskTemplateModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'

    projectTemplateId: 'projectTemplateId'
    projectTemplate: 'projectTemplate'

    procedureTemplateId: 'procedureTemplateId'
    procedureTemplate: 'procedureTemplate'

    phaseTemplateId: 'phaseTemplateId'
    phaseTemplate: 'phaseTemplate'

    assigneesTemplate: 'assignees'
}

const projectTaskTemplate: IProjectTaskTemplateModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',

    projectTemplateId: 'projectTemplateId',
    projectTemplate: 'projectTemplate',

    procedureTemplateId: 'procedureTemplateId',
    procedureTemplate: 'procedureTemplate',

    phaseTemplateId: 'phaseTemplateId',
    phaseTemplate: 'phaseTemplate',

    assigneesTemplate: 'assignees'
}

export const projectTaskTemplateModel = Object.freeze(projectTaskTemplate)

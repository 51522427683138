import React, { useState } from 'react'
import {
    Toolbar,
    showNotification,
    CREATE,
    UPDATE,
    DeleteButton
} from 'react-admin'
import {
    personContactModel as pc,
    personModel as pm,
    appUserModel as au
} from '../../api/models'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { saveWithNoteCreate } from '../../utils/dataHelpers'
import { Resources } from '../../api/api'
import oDataProvider, { AUTH_URL } from '../../api/oDataProvider'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
    dBET: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    ToolbarCreate: {
        backgroundColor: 'white'
    }
})

export const ToolbarAppUserCreateBase = (props) => {
    const classes = useStyles(``)
    const { handleSubmit, showNotification, ...rest } = props

    const [saving, setSaving] = useState(false)

    const handleBeforeSubmit = () => handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )
        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            setSaving(true)
            const values = handleBeforeSubmit()()

            try {
                await oDataProvider(CREATE, Resources.AppUser, {
                    data: { ...values }
                })
                props.history.push(`/${Resources.AppUser}`)
            } catch (e) {
                if (
                    !!e.body &&
                    !!e.body.error &&
                    !!e.body.error.details.length &&
                    !!e.body.error.details[0].target
                )
                    showNotification(
                        `ra.message.${e.body.error.details[0].target}`,
                        'warning'
                    )
            }
            setSaving(false)
        }
        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }
    return (
        <>
            <Toolbar {...rest} className={classes.ToolbarCreate}>
                {!saving && <CatchingSaveButton />}
                {saving && <CircularProgress size={18} thickness={2} />}
            </Toolbar>
        </>
    )
}
export const ToolbarAppUserCreate = connect(undefined, {
    saveWithNoteCreate,
    showNotification
})(ToolbarAppUserCreateBase)

const getValidData = (data) => {
    const passwordEdit = {
        [au.newPassword]: data.newPassword,
        [au.oldPassword]: data.oldPassword,
        [au.email]: data.userName
    }
    const person = { ...data[au.person] }
    const appUser = { role: data[au.role] }
    return {
        passwordEdit: passwordEdit,
        person: person,
        appUser: appUser,
        id: data.id,
        personId: data.person.id
    }
}

export const ToolbarAppUserEditBase = (props) => {
    const classes = useStyles(``)
    const {
        handleSubmit,
        showNotification,
        setIncorrectPassword,
        ...rest
    } = props

    const [saving, setSaving] = useState(false)

    const handleBeforeSubmit = () => handleSubmit((values) => values)

    const CatchingSaveButton = (saveProps) => {
        const { invalid } = saveProps
        const Icon = () => <SaveIcon fontSize="small" color="primary" />
        const Label = () => (
            <Typography variant="button" color="primary">
                Сохранить
            </Typography>
        )
        const onHandle = async (e) => {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning')
                saveProps.handleSubmitWithRedirect('list')()
                return
            }
            setSaving(true)
            const {
                passwordEdit,
                person,
                appUser,
                id,
                personId
            } = getValidData(handleBeforeSubmit()())
            if (!!passwordEdit && !!passwordEdit.newPassword) {
                try {
                    await oDataProvider(
                        CREATE,
                        //@ts-ignore
                        `${AUTH_URL}/Api/User/${Resources.EditPassword}`,
                        { data: { ...passwordEdit } }
                    )
                } catch (e) {
                    showNotification('ra.validation.password', 'warning')
                    setIncorrectPassword(true)
                    setSaving(false)
                    return
                }
            }
            oDataProvider(UPDATE, Resources.Person, {
                id: personId,
                data: { ...person }
            })
            oDataProvider(UPDATE, Resources.AppUser, {
                id: id,
                data: { ...appUser }
            })

            props.history.push('/AppUser')
            setSaving(false)
        }
        return (
            <Button variant="text" size="small" onClick={onHandle}>
                <Icon /> &nbsp;
                <Label />
            </Button>
        )
    }
    return (
        <>
            <Toolbar
                {...rest}
                className={classes.ToolbarCreate}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div>
                    {!saving && <CatchingSaveButton {...rest} />}
                    {saving && <CircularProgress size={18} thickness={2} />}
                </div>
                <DeleteButton />
            </Toolbar>
        </>
    )
}
export const ToolbarAppUserEdit = connect(undefined, {
    saveWithNoteCreate,
    showNotification
})(ToolbarAppUserEditBase)

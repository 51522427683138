import * as React from 'react'
import { connect } from 'react-redux'
import { Button, MenuItem, Typography } from '@material-ui/core'
import { ExitToApp as ExitIcon } from '@material-ui/icons'
import { Responsive, userLogout } from 'react-admin'
import { AUTH_SERVER_URL } from '../auth/authConfiguration'
import sanitizeRestProps from '../../utils/sanitizeRestProps'

const myCustomUserLogout = () => userLogout(AUTH_SERVER_URL)

const CustomLogoutButton = ({ userLogout, ...rest }) => (
    <MenuItem onClick={userLogout} {...sanitizeRestProps(rest as any)}>
        <ExitIcon />
        &thinsp;<Typography>Выйти</Typography>
    </MenuItem>
)
export const LogoutButton = connect(undefined, {
    userLogout: myCustomUserLogout
})(CustomLogoutButton)

import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput,
    BooleanField,
    ReferenceInput,
    SelectInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { Resources } from '../../api/api'
import { titleAction } from '../../reducers/titleReducer'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const CourtCaseFilter = (props: any) => (
    <Filter {...props}>
        <TextInput
            label="По тексту"
            source="$contains(ReceivedInfoJson)"
            alwaysOn={true}
        />
        <SelectAndSearchInput
            label="По проекту"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    </Filter>
)

const CourtCaseListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Дела'
    })
    setTitle(titles)

    return (
        <List
            {...props}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<CourtCaseFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Название" source="caseNumber" />
                <TextField label="Название" source="caseType" />
                <BooleanField label="Закрыто?" source="finished" />
                <ReferenceField
                    label="Проект"
                    source="projectId"
                    reference={Resources.Project}
                    allowEmpty={true}
                    linkType="show"
                >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const CourtCaseList = enchanceList(CourtCaseListBase)

import * as React from 'react'
import logo from './logo.svg'

const styles = {
    img: {
        borderRight: 'solid 1px #fff',
        marginRight: '20px',
        paddingRight: '20px',
        verticalAlign: 'middle',
        width: 25,
        heigh: 25
    },
    text: {
        color: '#fff',
        display: 'inline-block',
        fontSize: '20px',
        verticalAlign: 'middle'
    }
}

const LogoImg = () => (
    <div>
        <a href="/">
            <img style={styles.img} src={logo} alt="BSR" />
        </a>
    </div>
)

export default LogoImg

import * as React from 'react'

import { Paper, Typography } from '@material-ui/core'

interface IState {
    error: any
    errorInfo: any
}

export default class ErrorBoundary extends React.Component<any, IState> {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    public componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    public render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <Paper elevation={4} style={{ padding: '1em', margin: '1em' }}>
                    <Typography variant="headline" component="h3">
                        Something went wrong.
                    </Typography>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </Paper>
            )
        }
        return this.props.children
    }
}

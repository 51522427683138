export class ExpandSettings {
    public expand?: string
    public defaultExpand?: string

    public toString(): string {
        return '$expand=' + (this.expand || this.defaultExpand)
    }

    public reset(): void {
        this.expand = undefined
    }

    public isSet(): boolean {
        return !!this.expand || !!this.defaultExpand
    }
}

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IPhaseTemplateModel extends IStandardFieldsModel {
    title: 'title'
    description: 'description'

    projectTemplateId: 'projectTemplateId'
    projectTemplate: 'projectTemplate'

    procedureTemplateId: 'procedureTemplateId'
    procedureTemplate: 'procedureTemplate'

    projectTaskTemplates: 'taskTemplates'
}

const phaseTemplate: IPhaseTemplateModel = {
    ...standardFieldsModel,
    title: 'title',
    description: 'description',

    projectTemplateId: 'projectTemplateId',
    projectTemplate: 'projectTemplate',

    procedureTemplateId: 'procedureTemplateId',
    procedureTemplate: 'procedureTemplate',

    projectTaskTemplates: 'taskTemplates'
}

export const phaseTemplateModel = Object.freeze(phaseTemplate)

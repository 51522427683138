import React, { FC, useEffect, useRef, useState } from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { FieldTitle } from 'ra-core'
import { AutocompleteArrayInputWrapper } from './autocompleteArrayInput/autocompleteArrayInputWrapper'
import { ReferenceInput, fetchStart, GET_LIST, fetchEnd } from 'react-admin'
import classnames from 'classnames'
import oDataProvider from '../../api/oDataProvider'
import 'react-select/dist/react-select.css'

const ITEM_HEIGHT = 48
const styles = (theme) => ({
    '@global': {
        '.Select--multi .Select-input': {
            margin: 0
        },

        '.Select-control': {
            alignItems: 'center',
            background: 'transparent',
            border: 0,
            display: 'flex',
            height: 'auto',

            '&:hover': {
                boxShadow: 'none'
            }
        },

        '.Select-noresults': {
            padding: theme.spacing.unit * 2
        },

        '.Select-multi-value-wrapper': {
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'wrap'
        },

        '.Select.has-value.is-clearable.Select--single > .Select-control .Select-value': {
            padding: 0
        },

        '.Select-input': {
            display: 'inline-flex !important',
            height: 'auto',
            padding: 0
        },

        '.Select-input input': {
            background: 'transparent',
            border: 0,
            cursor: 'default',
            display: 'inline-block',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            margin: 0,
            outline: 0,
            padding: 0
        },

        '.Select-placeholder, .Select--single .Select-value': {
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            left: 0,
            padding: 0,
            position: 'absolute',
            right: 0,
            top: 0
        },

        '.Select-placeholder': {
            color: theme.palette.common.black,
            opacity: 0.42
        },

        '.Select-menu-outer': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            left: 0,
            maxHeight: ITEM_HEIGHT * 10.5,
            position: 'absolute',
            top: `calc(100% + ${theme.spacing.unit}px)`,
            width: '100%',
            zIndex: 2
        },

        '.Select.is-focused:not(.is-open) > .Select-control': {
            boxShadow: 'none'
        },

        '.Select-menu': {
            maxHeight: ITEM_HEIGHT * 10.5,
            overflowY: 'auto'
        },

        '.Select-menu div': {
            boxSizing: 'content-box'
        },

        '.Select-arrow-zone, .Select-clear-zone': {
            color: theme.palette.action.active,
            cursor: 'pointer',
            height: 21,
            width: 21,
            zIndex: 1
        },

        '.Select-aria-only': {
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            position: 'absolute',
            width: 1
        }
    },
    root: {
        minWidth: '200px'
    }
})

const IntegrationReactSelect: FC<any> = (props) => {
    const {
        filter,
        reference,
        setState,
        autoFocus,
        className,
        classes = {},
        isRequired,
        label,
        meta,
        resource,
        source,
        ref,
        input,
        optionMapper,
        value,
        setFilter,
        optionText,
        choices,
        creatable,
        optionRenderer,
        handleChange,
        currentValueId,
        optionValue = 'id',
        multi = true
    } = props

    const [data, setData] = React.useState([])

    useEffect(() => {
        if (currentValueId && choices) {
            input.onChange(
                choices.find((item) => item[optionValue] === currentValueId)
            )
        }
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: template } = await oDataProvider(action, reference, {
                filter
            })
            setData(template)
            fetchEnd()
        })()
    }, [])

    const handleChangeValue = (addItems) => {
        if (handleChange)
            handleChange({ target: { value: addItems[optionValue] } })

        if (multi === undefined || multi) {
            const oldItems = value || []
            const newItems = [...oldItems, ...addItems]
            const onlyVals = newItems.map((v) => v[optionValue])
            input.onChange(onlyVals)
        } else {
            input.onChange((addItems && addItems[optionValue]) || null)
        }
    }

    const handleChangeText = (q: string) => {
        if (q.trim()) {
            if (setState) {
                setState(q.toLowerCase())
            }
            if (setFilter) {
                setFilter(q)
            }
        }
    }

    if (typeof meta === 'undefined') {
        throw new Error(
            "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
        )
    }

    const { touched, error, helperText = false } = meta
    const labelRenderProps = !!optionText ? { labelKey: optionText } : {}

    const options = optionMapper ? choices.map(optionMapper) : data || choices

    return (
        <TextField
            label={
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            }
            value={input.value}
            onChange={handleChangeValue}
            autoFocus={autoFocus}
            margin="normal"
            className={classnames(classes.root, className)}
            inputRef={ref}
            error={!!(touched && error)}
            helperText={(touched && error) || helperText}
            InputProps={{
                inputComponent: AutocompleteArrayInputWrapper,
                inputProps: {
                    ...labelRenderProps,
                    classes,
                    creatable: creatable,
                    multi: multi === undefined ? true : multi,
                    onInputChange: handleChangeText,
                    optionRenderer: optionRenderer,
                    options,
                    placeholder: '',
                    valueKey: optionValue,
                    valueRenderer: optionRenderer
                }
            }}
            style={{ width: 255 }}
        />
    )
}

const SelectAndSearch = withStyles(styles as any)(IntegrationReactSelect)

interface IProps {
    label: string
    source: string
    reference: string
    alwaysOn?: boolean
    validate?: Function | Array<Function>
    optionRenderer?: Function
    filter?: Object
    style?: any
    required?: boolean
    disabled?: boolean
    className?: any

    /**
     * id элемента которое отображаеться при редактировании
     */
    currentValueId?: any

    /**
     * Устанавливать в false если не нужен множественный инпут
     */
    multi?: boolean

    /**
     * Отображаемый текст, может быть массивом их 2 элементов, если надо взять вложенное значение
     */
    optionText?: string

    /**
     * Функция для взятия текущего значения.
     */
    handleChange?: Function
}

export const SelectAndSearchInput: FC<IProps> = ({
    label,
    source,
    reference,
    validate,
    handleChange,
    optionRenderer,
    filter,
    style,
    className,
    optionText = 'name',
    currentValueId = null,
    multi = false,
    disabled = false,
    required = false,
    alwaysOn = false
}) => {
    return (
        <ReferenceInput
            label={label}
            style={style}
            source={source}
            reference={reference}
            alwaysOn={alwaysOn}
            filter={filter}
            validate={validate}
            required={required}
            disabled={disabled}
            className={className}
        >
            <SelectAndSearch
                currentValueId={currentValueId}
                filter={filter}
                reference={reference}
                optionText={optionText}
                multi={multi}
                handleChange={handleChange}
                optionRenderer={optionRenderer}
            />
        </ReferenceInput>
    )
}

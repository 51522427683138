export class OrderBySettings {
    public property?: string
    public order?: string
    public defaultProperty?: string
    public defaultOrder?: string

    public toString(): string {
        let qsValue = '$orderby=' + (this.property || this.defaultProperty)
        if (!!this.defaultOrder || !!this.order) {
            qsValue += ' ' + (this.order || this.defaultOrder)
        }

        return encodeURI(qsValue)
    }

    public reset(): void {
        this.property = undefined
        this.order = undefined
    }

    public isSet(): boolean {
        return !!this.property || !!this.defaultProperty
    }
}

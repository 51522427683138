import React, { useEffect, useState } from 'react'
import {
    SimpleForm,
    TextInput,
    LongTextInput,
    SelectInput,
    ReferenceInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { createStyles, withStyles } from '@material-ui/core'
import { parse } from 'query-string'
import { ToolbarGeneral } from '../../../layout/bar'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'
import { Resources } from '../../../api/api'
import Edit from '../../../layout/edit'
import Create from '../../../layout/create'
import oDataProvider from '../../../api/oDataProvider'

const styles = (theme) =>
    createStyles({
        root: {},
        datesContainer: {
            marginTop: 36,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            '& div': {
                width: 250,
                marginRight: 24
            }
        },
        warning: {
            width: '123%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        inputs: {
            minWidth: 252
        }
    })

const validate = (values) => {
    const erros: any = {}
    return erros
}

const ProjectTemplateInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет шаблонов проекта&nbsp;&thinsp;&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProjectTemplateInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Шаблон проекта"
            source="projectTemplateId"
            reference={Resources.ProjectTemplate}
            filter={{ $count: false }}
        >
            <SelectInput optionText="templateName" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProjectTemplateInputLabel style={props.classes} />}
        />
    )
}

const ProcedureTemplateInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет шаблонов процедур</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProcedureTemplateInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Шаблон процедуры"
            source="procedureTemplateId"
            reference="ProcedureTemplate"
            filter={{ $count: false }}
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProcedureTemplateInputLabel style={props.classes} />}
        />
    )
}

const PhaseTemplateInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>
                Нет шаблонов
                этапов&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;&thinsp;
            </div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const PhaseTemplateInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Шаблон этапа"
            source="phaseTemplateId"
            reference={Resources.PhaseTemplate}
            filter={{ $count: false }}
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<PhaseTemplateInputLabel style={props.classes} />}
        />
    )
}

const TaskTempalteFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [
        atLeastOneProcedureTemplate,
        setAtLeastOneProcedureTemplate
    ] = useState(false)
    const [atLeastOnePhaseTemplate, setAtLeastOnePhaseTemplate] = useState(
        false
    )
    const [atLeastOneProjectTemplate, setAtLeastOneProjectTemplate] = useState(
        false
    )

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Настройка процесса'
        })
        titles.push({
            type: 'link',
            href: '/ProjectTaskTemplate',
            value: 'Шаблоны задач'
        })
        titles.push({
            type: 'text',
            value: `Редактирование шаблона задачи №${props.record.id} - ${props.record.title}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: projectTemlate } = await oDataProvider(
                action,
                Resources.ProjectTemplate,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: procedureTemplate } = await oDataProvider(
                action,
                Resources.ProcedureTemplate,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: phaseTemplate } = await oDataProvider(
                action,
                Resources.PhaseTemplate,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            procedureTemplate.length > 0
                ? setAtLeastOneProcedureTemplate(true)
                : setAtLeastOneProcedureTemplate(false)
            phaseTemplate.length > 0
                ? setAtLeastOnePhaseTemplate(true)
                : setAtLeastOnePhaseTemplate(false)
            projectTemlate.length > 0
                ? setAtLeastOneProjectTemplate(true)
                : setAtLeastOneProjectTemplate(false)
            fetchEnd()
        })()
    }, [])

    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <TextInput source={'title'} label={'Название'} />
            <LongTextInput
                source={'description'}
                label={'Описание'}
                options={{ inputProps: { rows: 10 } }}
            />

            {!rest.record.projectTemplateId && (
                <ProjectTemplateInput
                    atLeastOne={atLeastOneProjectTemplate}
                    {...rest}
                />
            )}
            {rest.record.projectTemplateId && (
                <ProjectTemplateInput
                    atLeastOne={atLeastOneProjectTemplate}
                    {...rest}
                />
            )}
            <ProcedureTemplateInput
                atLeastOne={atLeastOneProcedureTemplate}
                {...rest}
            />
            <PhaseTemplateInput
                atLeastOne={atLeastOnePhaseTemplate}
                {...rest}
            />
        </SimpleForm>
    )
}
const TaskTemplateForm = withStyles(styles)(TaskTempalteFormBase)

const TaskTemplateCreateViewBase = (props: any) => {
    const { projectTemplateId: projectIdString } = parse(props.location.search)
    const projectTemplateId = projectIdString
        ? parseInt(projectIdString.toString(), 10)
        : ''

    const redirect = projectTemplateId
        ? `/ProjectTemplate/${projectTemplateId}/tasks`
        : false
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'link',
        href: '/ProjectTaskTemplate',
        value: 'Шаблоны задач'
    })
    titles.push({
        type: 'text',
        value: 'Создание шаблона задачи'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <TaskTemplateForm
                {...rest}
                isEditToolbar={false}
                defaultValue={{ projectTemplateId: projectTemplateId }}
                redirect={redirect}
            />
        </Create>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const TaskTemplateCreateView = enchanceList(TaskTemplateCreateViewBase)

const TaskTemplateEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <TaskTemplateForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const TaskTemplateEditView = enchanceEdit(TaskTemplateEditViewBase)

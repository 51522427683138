import { RoleTypes } from '../../api/api'

export const checkPermissionsEmployeeAndUp = (permissions) => {
    console.log(
        '--checkPermissionsEmployeeAndUp',
        permissions,
        localStorage.getItem('role')
    )
    permissions = permissions || localStorage.getItem('role')
    return (
        permissions === RoleTypes.Admin ||
        permissions === RoleTypes.Dispatcher ||
        permissions === RoleTypes.Employee
    )
}

export const checkPermissionsAdminOnly = (permissions) => {
    console.log(
        '--checkPermissionsAdminOnly',
        permissions,
        localStorage.getItem('role')
    )
    permissions = permissions || localStorage.getItem('role')
    return permissions === RoleTypes.Admin
}

export const checkPermissionsDispatcherAndUp = (permissions) => {
    console.log(
        '--checkPermissionsDispatcherAndUp',
        permissions,
        localStorage.getItem('role')
    )
    permissions = permissions || localStorage.getItem('role')
    return (
        permissions === RoleTypes.Admin || permissions === RoleTypes.Dispatcher
    )
}

export const checkPermissionsClientOnly = (permissions) => {
    console.log(
        '--checkPermissionsClientOnly',
        permissions,
        localStorage.getItem('role')
    )
    permissions = permissions || localStorage.getItem('role')
    return (
        permissions === RoleTypes.Client || permissions === RoleTypes.Consultant
    )
}

import * as React from 'react'
import { ROOT_URL } from '../../api/oDataProvider'
import { Typography } from '@material-ui/core'

export const FileLink = (props) => {
    if (!props.record.files || !props.record.files.length) return <div />
    return (
        <a
            download
            href={`${ROOT_URL}/api/filestorage/${props.record.files[0].internalGuid}`}
            onClick={(e) => e.stopPropagation()}
        >
            <Typography>{props.record.files[0].name}</Typography>
        </a>
    )
}
FileLink.defaultProps = { label: 'Файл' }

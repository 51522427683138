import * as React from 'react'
import { Button } from '@material-ui/core'
import {
    Edit as EditIcon,
    Visibility as VisibilityIcon
} from '@material-ui/icons/'

const Icon = (props) => {
    return props.isEdit ? <VisibilityIcon /> : <EditIcon />
}
const Label = (props) => {
    return props.isEdit ? <div>Смотреть</div> : <div>Редактировать</div>
}

class EditButtonCmp extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.handleEditChange = this.handleEditChange.bind(this)
    }

    handleEditChange() {
        this.props.onHandleEditChange()
    }

    render() {
        const { isEdit } = this.props
        return (
            <Button size="small" onClick={this.handleEditChange}>
                <Icon isEdit={isEdit} /> &nbsp;
                <Label isEdit={isEdit} />
            </Button>
        )
    }
}
export default EditButtonCmp

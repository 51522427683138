import { FilterClause } from './FilterClause'
import { FilterObj } from './FilterObj'
export class PrecedenceGroup {
    private clauses: any[]

    constructor(filterClause?: FilterClause) {
        this.clauses = []

        if (filterClause) {
            this.clauses.push(new FilterObj(filterClause))
        }
    }
    public isEmpty(): boolean {
        return this.clauses.length === 0
    }
    public andFilter(filterClause: FilterClause): PrecedenceGroup {
        this.clauses.push(new FilterObj(filterClause, 'and'))
        return this
    }
    public orFilter(filterClause: FilterClause): PrecedenceGroup {
        this.clauses.push(new FilterObj(filterClause, 'or'))
        return this
    }
    public toString(): string {
        let filter: any
        let i: any
        filter = '('
        for (i = 0; i < this.clauses.length; i++) {
            filter += this.clauses[i].toString(i)
        }
        filter += ')'

        return filter
    }
}

import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtHearingModel extends IStandardFieldsModel {
    projectCourtCaseId: 'projectCourtCaseId'
    projectCourtCase: 'courtCase'
    location: 'location'
    summary: 'summary'
    start: 'start'
    end: 'end'
}

const projectCourtHearing: IProjectCourtHearingModel = {
    ...standardFieldsModel,
    projectCourtCaseId: 'projectCourtCaseId',
    projectCourtCase: 'courtCase',
    location: 'location',
    summary: 'summary',
    start: 'start',
    end: 'end'
}

export const projectCourtHearingModel = Object.freeze(projectCourtHearing)

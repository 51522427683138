import * as React from 'react'
import { Layout, Sidebar } from 'react-admin'
import { connect } from 'react-redux'
import AppBar from '../AppBar'
import { createMuiTheme } from '@material-ui/core'

const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00AA3C'
        },
        type: 'dark' // Switching the dark mode on is a single property value change.
    }
})

const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00AA3C'
        },
        secondary: {
            main: '#333333'
        }
    }
})

const CustomSidebar = (props) => <Sidebar size={250} {...props} />

const CustomLayout = (props) => (
    <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />
)

export default connect(
    (state: any) => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme
    }),
    {}
)(CustomLayout)

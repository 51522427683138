import get from 'lodash/get'
import * as React from 'react'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
    chip: {
        fontSize: 10,
        height: 16,
        margin: 2
    }
})

const MiniChipFieldClass = (props) => {
    return (
        <Chip
            className={props.classes.chip}
            label={get(props.record, props.source)}
        />
    )
}

export const MiniChipField = withStyles(styles)(MiniChipFieldClass)

import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS
} from 'react-admin'
import { AuthService } from './authService'

export const AuthProvider = (type, params) => {
    if (type === AUTH_LOGIN) {
        return Promise.resolve()
    }

    if (type === AUTH_LOGOUT) {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
            localStorage.removeItem('role')
            const authService = new AuthService()
            return authService.logout()
        } else {
            return Promise.resolve()
        }
    }

    if (type === AUTH_CHECK) {
        console.log('AUTH_CHECK params', params)
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject()
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role')
        return role ? Promise.resolve(role) : Promise.reject()
    }

    if (type === AUTH_ERROR) {
        const status = params.status
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
            localStorage.removeItem('role')
            return Promise.reject()
        }
        return Promise.resolve()
    }

    return Promise.reject()
}

import * as React from 'react'
import { ReferenceField } from 'react-admin'
import { FullNameField } from './fullNameField'
import { Resources } from '../../api/api'

export default class UserNameField extends React.Component<any, any> {
    public static defaultProps = {
        addLabel: true
    }

    public render() {
        const { record, source, label } = this.props

        return record && record[source] ? (
            <ReferenceField
                source={source}
                reference={Resources.AppUser}
                linkType="show"
                {...this.props}
            >
                <FullNameField source="firstname" label={label} />
            </ReferenceField>
        ) : null
    }
}

import React, { useEffect, useState } from 'react'
import {
    BooleanInput,
    TabbedForm,
    FormTab,
    TextInput,
    LongTextInput,
    required,
    ReferenceInput,
    SelectInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { ToolbarGeneral } from '../../layout/bar'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'
import RichTextInput from 'ra-input-rich-text'

const styles = {
    textEditor: {
        '& .ra-rich-text-input': {
            height: 200,
            marginBottom: 15
        }
    },
    warning: {
        width: '187%',
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

const CategoryInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет категорий&thinsp;&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const CategoryInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Категория"
            source="categoryId"
            reference={Resources.BlogCategory}
        >
            <SelectInput optionText="title" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<CategoryInputLabel style={props.classes} />}
        />
    )
}

const blogFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneCategory, setAtLeastOneCategory] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контент сайта'
        })
        titles.push({
            type: 'link',
            href: '/Blog',
            value: 'Блог'
        })
        titles.push({
            type: 'text',
            value: `Редактирование блога №${props.record.id} - ${props.record.title}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: category } = await oDataProvider(
                action,
                Resources.BlogCategory,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            category.length > 0
                ? setAtLeastOneCategory(true)
                : setAtLeastOneCategory(false)
            fetchEnd()
        })()
    }, [])

    return (
        <TabbedForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <FormTab label="Основная ифнормация">
                <CategoryInput atLeastOne={atLeastOneCategory} {...rest} />

                <TextInput
                    fullWidth={true}
                    label="Заголовок"
                    source="title"
                    validate={required()}
                />
                <RichTextInput
                    className={rest.classes.textEditor}
                    label="Содержание"
                    source="content"
                />

                <TextInput fullWidth={true} label="Автор" source="author" />
                <BooleanInput label="Опубликована" source="isPublished" />
            </FormTab>
            <FormTab label="Содержание (разметка)">
                <LongTextInput
                    label="Содержание"
                    source="content"
                    options={{ inputProps: { rows: 20 } }}
                />
            </FormTab>
            <FormTab label="SEO">
                <TextInput label="Имя страницы" source="slug" />
                <TextInput label="Путь страницы" source="path" />
                <TextInput label="Meta: заголовок окна" source="metaTitle" />
                <TextInput label="Meta: ключевые слова" source="metaKeywords" />
                <LongTextInput
                    label="Meta: описание"
                    source="metaDescription"
                />
            </FormTab>
        </TabbedForm>
    )
}

export const BlogForm = withStyles(styles)(blogFormBase)

import React, { useState, useEffect } from 'react'
import {
    SelectInput,
    ReferenceInput,
    NumberInput,
    SimpleForm,
    TextInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import { ToolbarGeneral } from '../../layout/bar'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'
import Create from '../../layout/create'
import Edit from '../../layout/edit'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warning: {
        width: '160%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

const LookupInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет справочников</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const LookupInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <ReferenceInput
            className={props.classes.inputs}
            label="Справочник"
            reference={Resources.Lookup}
            source="lookupId"
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<LookupInputLabel style={props.classes} />}
        />
    )
}

export const LookupValueFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneLookup, setAtLeastOneLookup] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Настройка процесса'
        })
        titles.push({
            type: 'link',
            href: '/LookupValue',
            value: 'Значения справочников'
        })
        titles.push({
            type: 'text',
            value: `Редактирование значения справочника №${props.record.id} - ${props.record.value}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: lookup } = await oDataProvider(
                action,
                Resources.Lookup,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            lookup.length > 0
                ? setAtLeastOneLookup(true)
                : setAtLeastOneLookup(false)
            fetchEnd()
        })()
    })
    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <LookupInput atLeastOne={atLeastOneLookup} {...rest} />
            <TextInput label="Строковое значение" source="value" />
            <NumberInput label="Целочисленное значение" source="intValue" />
            <NumberInput label="Вещественное значение" source="decimalValue" />
            <NumberInput label="Порядок в списке" source="order" />
            <TextInput label="Системное имя" source="systemName" />
        </SimpleForm>
    )
}
export const LookupValueForm = withStyles(styles as any)(LookupValueFormBase)

const LookupValueCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'link',
        href: '/LookupValue',
        value: 'Значения справочников'
    })
    titles.push({
        type: 'text',
        value: 'Создание значения справочника'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <LookupValueForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const LookupValueCreateView = enchanceCreate(LookupValueCreateViewBase)

const LookupValueEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <LookupValueForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const LookupValueEditView = enchanceEdit(LookupValueEditViewBase)

import * as React from 'react'
import { DashboardMenuItem, MenuItemLink, WithPermissions } from 'react-admin'
import SubMenu from './SubMenu'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import {
    People as PeopleIcon,
    BusinessCenter as ProjectIcon,
    Web as SiteIcon,
    WebAsset as BlogIcon,
    ViewQuilt as CategoryIcon,
    SettingsApplications as SettingsIcon,
    List as ValuesIcon,
    FlipToFront as TemplateIcon,
    ContactPhone as ContactsIcon,
    AttachMoney as MoneyIcon,
    LibraryBooks as InvoiceIcon,
    AssignmentTurnedIn as TaskIcon,
    Mail as MailIcon,
    Event as CalendarIcon,
    Folder as FilesIcon,
    Feedback as RequestIcon,
    Description as DocumentIcon,
    Place as PlaceIcon
} from '@material-ui/icons'
import {
    checkPermissionsAdminOnly,
    checkPermissionsClientOnly,
    checkPermissionsDispatcherAndUp,
    checkPermissionsEmployeeAndUp
} from '../../components/auth/authHelpers'

interface IMenuProps {
    onMenuClick: () => void
    logout: any
}

interface IMenuState {
    menuProjects: boolean
    menuCms: boolean
    menuProcess: boolean
    menuContacts: boolean

    menuCatalog: boolean
    menuSales: boolean
    menuCustomers: boolean
    menuDocs: boolean
}

class MenuComponent extends React.Component<any, IMenuState> {
    constructor(props) {
        super(props)
        this.state = {
            menuProjects: false,
            menuCms: false,
            menuProcess: false,
            menuContacts: false,

            menuCatalog: false,
            menuSales: false,
            menuCustomers: false,
            menuDocs: false
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    render() {
        const { onMenuClick, open } = this.props
        return (
            <div>
                {' '}
                <DashboardMenuItem onClick={onMenuClick} />
                <WithPermissions
                    render={({ permissions }) =>
                        !checkPermissionsEmployeeAndUp(permissions) ? null : (
                            <SubMenu
                                handleToggle={() =>
                                    this.handleToggle('menuProjects')
                                }
                                isOpen={this.state.menuProjects}
                                sidebarIsOpen={open}
                                name={'Работа'}
                                icon={<ProjectIcon />}
                            >
                                <MenuItemLink
                                    to={`/Project`}
                                    primaryText={'Проекты'}
                                    onClick={onMenuClick}
                                    leftIcon={<ProjectIcon />}
                                />

                                <MenuItemLink
                                    to={`/ProjectTask`}
                                    primaryText={'Задачи'}
                                    onClick={onMenuClick}
                                    leftIcon={<TaskIcon />}
                                />

                                <MenuItemLink
                                    to={`/Customer`}
                                    primaryText={'Клиенты'}
                                    onClick={onMenuClick}
                                    leftIcon={<ContactsIcon />}
                                />

                                <MenuItemLink
                                    to={`/Payment`}
                                    primaryText={'Платежи'}
                                    onClick={onMenuClick}
                                    leftIcon={<MoneyIcon />}
                                />

                                <MenuItemLink
                                    to={`/Invoice`}
                                    primaryText={'Счета на оплату'}
                                    onClick={onMenuClick}
                                    leftIcon={<InvoiceIcon />}
                                />
                            </SubMenu>
                        )
                    }
                />
                <WithPermissions
                    render={({ permissions }) =>
                        !checkPermissionsClientOnly(permissions) ? null : (
                            <MenuItemLink
                                to={`/Project`}
                                primaryText={'Мои проекты'}
                                onClick={onMenuClick}
                                leftIcon={<ProjectIcon />}
                            />
                        )
                    }
                />
                <WithPermissions
                    render={({ permissions }) =>
                        !checkPermissionsEmployeeAndUp(permissions) ? null : (
                            <SubMenu
                                handleToggle={() =>
                                    this.handleToggle('menuContacts')
                                }
                                isOpen={this.state.menuContacts}
                                sidebarIsOpen={open}
                                name={'Контакты'}
                                icon={<ContactsIcon />}
                            >
                                <MenuItemLink
                                    to={`/Person`}
                                    primaryText={'Люди'}
                                    onClick={onMenuClick}
                                    leftIcon={<ContactsIcon />}
                                />
                                <MenuItemLink
                                    to={`/Company`}
                                    primaryText={'Компании'}
                                    onClick={onMenuClick}
                                    leftIcon={<ProjectIcon />}
                                />
                                <MenuItemLink
                                    to={`/CompanyEmployee`}
                                    primaryText={'Сотрудники'}
                                    onClick={onMenuClick}
                                    leftIcon={<ContactsIcon />}
                                />
                            </SubMenu>
                        )
                    }
                />
                <WithPermissions
                    render={({ permissions }) =>
                        !checkPermissionsEmployeeAndUp(permissions) ? null : (
                            <MenuItemLink
                                to={`/ProjectCourtCase`}
                                primaryText={
                                    checkPermissionsDispatcherAndUp(permissions)
                                        ? 'Дела'
                                        : 'Мои дела'
                                }
                                onClick={onMenuClick}
                                leftIcon={<ProjectIcon />}
                            />
                        )
                    }
                />
                <WithPermissions
                    render={({ permissions }) => (
                        <MenuItemLink
                            to={`/Request`}
                            primaryText={
                                checkPermissionsDispatcherAndUp(permissions)
                                    ? 'Заявки'
                                    : 'Мои заявки'
                            }
                            onClick={onMenuClick}
                            leftIcon={<RequestIcon />}
                        />
                    )}
                />
                <MenuItemLink
                    to={`/VersionedFileAttachment`}
                    primaryText={'Файлы'}
                    onClick={onMenuClick}
                    leftIcon={<FilesIcon />}
                />
                <MenuItemLink
                    to={`/Calendar`}
                    primaryText={'Календарь'}
                    onClick={onMenuClick}
                    leftIcon={<CalendarIcon />}
                />
                {localStorage.getItem('role') !== 'Консультант' && (
                    <SubMenu
                        handleToggle={() => this.handleToggle('menuDocs')}
                        isOpen={this.state.menuDocs}
                        sidebarIsOpen={open}
                        name={'Документы'}
                        icon={<DocumentIcon />}
                    >
                        <MenuItemLink
                            to="/LetterOfAuthority"
                            primaryText="Доверенности"
                            onClick={onMenuClick}
                            leftIcon={<DocumentIcon />}
                        />
                        {localStorage.getItem('role') !== 'Клиент' && (
                            <MenuItemLink
                                to="LetterOfAuthorityRequesterDictValue"
                                primaryText="Места предъявления"
                                onClick={onMenuClick}
                                leftIcon={<PlaceIcon />}
                            />
                        )}
                        {localStorage.getItem('role') !== 'Клиент' && (
                            <MenuItemLink
                                to="LetterOfAuthorityClassificationDictValue"
                                primaryText="Классификации"
                                onClick={onMenuClick}
                                leftIcon={<ValuesIcon />}
                            />
                        )}
                    </SubMenu>
                )}
                <WithPermissions
                    render={({ permissions }) => (
                        <MenuItemLink
                            to={`/Notification`}
                            primaryText={'Уведомления'}
                            onClick={onMenuClick}
                            leftIcon={<MailIcon />}
                        />
                    )}
                />
                <WithPermissions
                    render={({ permissions }) =>
                        !checkPermissionsAdminOnly(permissions) ? null : (
                            <React.Fragment>
                                <SubMenu
                                    handleToggle={() =>
                                        this.handleToggle('menuCms')
                                    }
                                    isOpen={this.state.menuCms}
                                    sidebarIsOpen={open}
                                    name={'Контент сайта'}
                                    icon={<SiteIcon />}
                                >
                                    <MenuItemLink
                                        to={`/Page`}
                                        primaryText={'Страницы'}
                                        onClick={onMenuClick}
                                        leftIcon={<SiteIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/Blog`}
                                        primaryText={'Блог'}
                                        onClick={onMenuClick}
                                        leftIcon={<BlogIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/BlogCategory`}
                                        primaryText={'Рубрики блога'}
                                        onClick={onMenuClick}
                                        leftIcon={<CategoryIcon />}
                                    />
                                </SubMenu>
                                <SubMenu
                                    handleToggle={() =>
                                        this.handleToggle('menuProcess')
                                    }
                                    isOpen={this.state.menuProcess}
                                    sidebarIsOpen={open}
                                    name={'Настройка процесса'}
                                    icon={<SettingsIcon />}
                                >
                                    <MenuItemLink
                                        to={`/Lookup`}
                                        primaryText={'Справочники'}
                                        onClick={onMenuClick}
                                        leftIcon={<ValuesIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/LookupValue`}
                                        primaryText={'Значения справочников'}
                                        onClick={onMenuClick}
                                        leftIcon={<ValuesIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/ProjectTaskTemplate`}
                                        primaryText={'Шаблоны задач'}
                                        onClick={onMenuClick}
                                        leftIcon={<TemplateIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/PhaseTemplate`}
                                        primaryText={'Шаблоны этапов'}
                                        onClick={onMenuClick}
                                        leftIcon={<TemplateIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/ProcedureTemplate`}
                                        primaryText={'Шаблоны процедур'}
                                        onClick={onMenuClick}
                                        leftIcon={<TemplateIcon />}
                                    />
                                    <MenuItemLink
                                        to={`/ProjectTemplate`}
                                        primaryText={'Шаблоны проектов'}
                                        onClick={onMenuClick}
                                        leftIcon={<TemplateIcon />}
                                    />
                                </SubMenu>
                                <MenuItemLink
                                    to={`/AppUser`}
                                    primaryText={'Пользователи'}
                                    onClick={onMenuClick}
                                    leftIcon={<PeopleIcon />}
                                />
                            </React.Fragment>
                        )
                    }
                />
            </div>
        )
    }

    private handleToggle(menu: string) {
        const state = this.state
        const changeState = { ...state, [menu]: !state[menu] }
        this.setState(changeState)
    }
}

const mapStateToProps = (state) => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale
})

const enhance = compose(withRouter, connect(mapStateToProps, {}))

export default enhance(MenuComponent)

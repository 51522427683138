import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    DateField,
    NullableBooleanInput,
    DateTimeInput,
    AutocompleteInput
} from 'react-admin'
import {
    notificationModel as nm,
    projectModel as pm,
    requestModel as rm,
    projectTaskModel as pt
} from '../../api/models'
import { NotificationTypes } from '../../api/api'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources, ITitle } from '../../api/api'
import { Link } from 'react-router-dom'
import ReplyIcon from '@material-ui/icons/Reply'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const NotificationFilter = (props: any) => {
    const style = { width: 250 }
    return (
        <Filter {...props}>
            <NullableBooleanInput
                label="Прочитанные"
                source={nm.isRead}
                style={style}
                alwaysOn
            />
            {/* <AutocompleteInput label="Тип" source={nm.type} style={style} /> */}
            <DateTimeInput
                label="Время отправки"
                source={`$date(${nm.emailSent})`}
                style={style}
            />
        </Filter>
    )
}

const TitleReferenceNotification = (props) => {
    const { record } = props
    let source = {
        [NotificationTypes.RequestCommentBroadcast]: {
            reference: Resources.Request,
            name: 'requestId',
            linkQuery: 'comments'
        },
        [NotificationTypes.RequestCommentReply]: {
            reference: Resources.Request,
            name: 'requestId',
            linkQuery: 'comments'
        },
        [NotificationTypes.RequestAccept]: {
            reference: Resources.Request,
            name: 'requestId',
            linkQuery: ''
        },
        [NotificationTypes.NewRequest]: {
            reference: Resources.Request,
            name: 'requestId',
            linkQuery: ''
        },
        [NotificationTypes.VerFileCommentReply]: {
            reference: Resources.VersionedFileAttachment,
            name: 'versionedFileId',
            linkQuery: 'comments'
        },
        [NotificationTypes.VerFileCommentBroadcast]: {
            reference: Resources.VersionedFileAttachment,
            name: 'versionedFileId',
            linkQuery: ''
        },
        [NotificationTypes.ProjectCommentBroadcast]: {
            reference: Resources.Project,
            name: 'projectId',
            linkQuery: 'show'
        },
        [NotificationTypes.ProjectCommentReply]: {
            reference: Resources.Project,
            name: 'projectId',
            linkQuery: 'comments'
        },
        [NotificationTypes.ProjectTaskEndOfWork]: {
            reference: Resources.ProjectTask,
            name: 'taskId',
            linkQuery: 'show'
        }
    }[record.type]

    if (!source)
        source = {
            reference: Resources.ProjectTask,
            name: 'taskId',
            linkQuery: 'show'
        }

    const concreteId = record.concreteNotification[source.name]

    console.log(source, concreteId, record)

    return (
        <Link
            to={`/${source.reference}/${concreteId}/${source.linkQuery}`}
            style={{ color: 'rgb(0,170,60)', textDecoration: 'none' }}
            onClick={(e) => e.stopPropagation()}
        >
            <ReplyIcon />
        </Link>
    )
}

const NotificationListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<ITitle> = []
    titles.push({
        type: 'text',
        value: 'Уведомления'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: nm.id, order: 'DESC' }}
            filters={<NotificationFilter />}
            exporter={false}
        >
            <Datagrid rowClick="show" classes={{ rowEven: classes.evenRow }}>
                <DateField label="Отправлено" source={nm.updatedOn} />
                <TextField
                    label="Тема"
                    source={`${nm.concreteNotification}.title`}
                />
                <ReferenceField
                    label="Задача"
                    source={`${nm.concreteNotification}.taskId`}
                    reference={Resources.ProjectTask}
                    allowEmpty
                    linkType="show"
                >
                    <TextField source={pt.title} />
                </ReferenceField>
                <ReferenceField
                    label="Проект"
                    source={`${nm.concreteNotification}.projectId`}
                    reference={Resources.Project}
                    allowEmpty={true}
                    linkType="show"
                >
                    <TextField source={pm.name} />
                </ReferenceField>
                <ReferenceField
                    label="Заявка"
                    source={`${nm.concreteNotification}.requestId`}
                    reference={Resources.Request}
                    allowEmpty={true}
                    linkType="show"
                >
                    <TextField source={rm.subject} />
                </ReferenceField>
                <TitleReferenceNotification {...rest} label="Перейти" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const NotificationList = enchanceList(NotificationListBase)

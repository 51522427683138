const IS_FAVORITE_PROJECT_TASK = 'SET_IS_FAVORITE_PROJECT_TASK'
const initialState = false

export const isFavoriteProjectTaskAction = (isFavoriteProjectTask) => {
    const action = {
        type: IS_FAVORITE_PROJECT_TASK,
        isFavoriteProjectTask
    }
    return action
}

export const isFavoriteProjectTaskReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_FAVORITE_PROJECT_TASK
        ? action.isFavoriteProjectTask
        : priviousState

import React from 'react'
import { DisabledInput } from 'react-admin'

export default (props) => {
    return props.atLeastOne ? (
        props.children
    ) : (
        <DisabledInput label={props.label} className={props.className} />
    )
}

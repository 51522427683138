import * as React from 'react'
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    withStyles,
    Typography
} from '@material-ui/core'
import { Link, LinkProps } from 'react-router-dom'
import { translate } from 'react-admin'
import { compose } from 'recompose'
import { CardIcon } from '../cardIcon'
import { List as WidgetIcon } from '@material-ui/icons'

const style = (theme) => ({
    root: {
        flex: 1
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right'
    },
    avatar: {
        background: theme.palette.background.avatar
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary
    }
})

const LinkToTask = (props) => <Link to={'/ProjectTask/create'} {...props} />

const MyTasksComponent = ({ projects = [], translate, classes }) => {
    return (
        <div className={classes.root}>
            <CardIcon Icon={WidgetIcon} bgColor="#f44336" />
            <Card className={classes.card}>
                <CardHeader title={'Мои задачи'} />
                <List dense={true}>
                    {projects.map((record: any, i) => (
                        <ListItem
                            key={`key-${i}`}
                            button={true}
                            component={({ children, ...props }) => (
                                <Link
                                    to={`/ProjectTask/${record.id}`}
                                    {...(props as LinkProps)}
                                >
                                    {children}
                                </Link>
                            )}
                        >
                            <ListItemText
                                primary={
                                    new Date(
                                        record.startDate
                                    ).toLocaleDateString('ru-RU') +
                                    ' ' +
                                    record.title
                                }
                                secondary={
                                    !!record.assignees &&
                                    record.assignees
                                        .map((a) => a.user.userName)
                                        .join(', ')
                                }
                            />
                            {record.projectId && (
                                <ListItemSecondaryAction>
                                    <Typography className={classes.cost}>
                                        Проект {record.projectId}
                                    </Typography>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
                <CardActions>
                    <Button
                        variant={'contained'}
                        color="primary"
                        component={LinkToTask}
                    >
                        Новая задача
                    </Button>
                </CardActions>
            </Card>
        </div>
    )
}

export const MyTasks = compose(
    withStyles(style as any),
    translate
)(MyTasksComponent)

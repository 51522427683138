import React, { useState, useEffect } from 'react'
import {
    TextField,
    ReferenceField,
    SelectInput,
    ReferenceInput,
    SimpleForm,
    required,
    DateInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { parse } from 'query-string'
import { ToolbarGeneral } from '../../layout'
import { withStyles } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { Resources, RoleTypes } from '../../api/api'
import { getFioFromReferencedPerson, getFio } from '../../utils/dataHelpers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import oDataProvider from '../../api/oDataProvider'
import Edit from '../../layout/edit'
import Create from '../../layout/create'

const styles = {
    inputs: {
        minWidth: 252
    },
    warning: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}

export const AccessTypes = [
    { id: 'Viewer', name: 'Только просмотр' },
    { id: 'Consultant', name: 'Консультант' },
    { id: 'Employee', name: 'Сотрудник' },
    { id: 'Supervisor', name: 'Супервизор' }
]

const validate = (values) => {
    const errors: any = {}
    if (!values.userId) {
        errors.userId = 'Обязан для заполнения'
    }
    return errors
}
const ProjectInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет проектов &thinsp;&thinsp;&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProjectInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference={Resources.Project}
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProjectInputLabel style={props.classes} />}
        />
    )
}

const ProjectParticipantFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneProject, setAtLeastOneProject] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/ProjectParticipant',
            value: 'Участники'
        })
        titles.push({
            type: 'text',
            value: `Редактирование участника №${props.record.id} - ${getFio(
                props.record.user.person
            )}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: project } = await oDataProvider(
                action,
                Resources.Project,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            setAtLeastOneProject(project.length > 0)
            fetchEnd()
        })()
    })
    const userRoles = (x: number) => `userRoles/any(x: x/roleId eq ${x})`
    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    shouldParse
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <SelectAndSearchInput
                label="Пользователь"
                optionRenderer={getFioFromReferencedPerson}
                source="userId"
                reference={Resources.AppUser}
                filter={{
                    customFilter: `${userRoles(3)} or ${userRoles(
                        5
                    )} or ${userRoles(2)}`
                }}
            />
            {!rest.record.projectId && (
                <ProjectInput atLeastOne={atLeastOneProject} {...rest} />
            )}

            {rest.record.projectId && (
                <SelectAndSearchInput
                    label="Проект"
                    source="projectId"
                    reference={Resources.Project}
                    optionText="name"
                    alwaysOn={true}
                    multi={false}
                />
            )}

            <SelectInput
                label="Доступ к проекту"
                source="accessType"
                choices={AccessTypes}
                validate={required()}
            />

            <DateInput
                label="Дата изъятия доступа к проекту"
                source="accessExpirationDate"
            />
        </SimpleForm>
    )
}
export const ProjectParticipantForm = withStyles(styles as any)(
    ProjectParticipantFormBase
)

export const ProjectParticipantCreateViewBase = (props: any) => {
    const { projectId: projectIdString } = parse(props.location.search)
    const projectId = projectIdString
        ? parseInt(projectIdString.toString(), 10)
        : ''
    const redirect = projectId ? `/Project/${projectId}/participants` : false

    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/ProjectParticipant',
        value: 'Участники'
    })
    titles.push({
        type: 'text',
        value: 'Создание участника'
    })
    setTitle(titles)
    return (
        <Create {...rest}>
            <ProjectParticipantForm
                isEditToolbar={false}
                defaultValue={{ projectId: projectId }}
                redirect={redirect}
                {...rest}
            />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const ProjectParticipantCreateView = enchanceCreate(
    ProjectParticipantCreateViewBase
)

export const ProjectParticipantEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    return (
        <Edit {...rest}>
            <ProjectParticipantForm isEditToolbar {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ProjectParticipantEditView = enchanceEdit(
    ProjectParticipantEditViewBase
)

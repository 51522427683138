import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { BusinessCenter as BusinessCenterIcon } from '@material-ui/icons/'
import { withRouter } from 'react-router-dom'

const Icon = (props) => (
    <BusinessCenterIcon fontSize="small" color="secondary" />
)
const Label = (props) => (
    <Typography variant="button" color="secondary">
        Создать проект
    </Typography>
)

const extraButtonBase = (props) => {
    const { history } = props
    const onHandle = async () => history.push('/Project/create')
    return (
        <Button variant="flat" size="small" onClick={onHandle}>
            <Icon /> &nbsp;
            <Label />
        </Button>
    )
}
export default withRouter(extraButtonBase)

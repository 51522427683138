import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    NumberField,
    TextInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const LookupValuesFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="value" />
    </Filter>
)

const LookupValueListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'text',
        value: 'Значения справочников'
    })
    setTitle(titles)

    return (
        <List
            {...props}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<LookupValuesFilter />}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField source="id" />
                <ReferenceField
                    label="Справочник"
                    reference={Resources.Lookup}
                    source="lookupId"
                >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="value" />
                <NumberField source="order" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const LookupValueList = enchanceList(LookupValueListBase)

import { seoEnabledEntity, ISeoEnabledEntity } from '../'

export interface IBlogCategoryModel extends ISeoEnabledEntity {
    parentId: 'parentId'
    description: 'description'
    isPublished: 'isPublished'
    isNews: 'isNews'
    parent: 'parent'
}

const blogCategory: IBlogCategoryModel = {
    ...seoEnabledEntity,
    parentId: 'parentId',
    description: 'description',
    isPublished: 'isPublished',
    isNews: 'isNews',
    parent: 'parent'
}

export const blogCategoryModel = Object.freeze(blogCategory)

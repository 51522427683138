import { standardFieldsModel, IStandardFieldsModel } from '../../'

export interface IProjectCourtInstanceEventModel extends IStandardFieldsModel {
    projectCourtInstanceId: 'projectCourtInstanceId'
    projectCourtInstance: 'courtInstance'
    eventTypeName: 'eventTypeName'
    eventTypeId: 'eventTypeId'
    externalId: 'externalId'
    additionalInfo: 'additionalInfo'
    date: 'date'
    file: 'file'
    publishDate: 'publishDate'
    finishEvent: 'finishEvent'
    eventContentTypeName: 'eventContentTypeName'
    declarer: 'declarer'
    comment: 'comment'
    decisionTypeName: 'decisionTypeName'
    hasSignature: 'hasSignature'
}

const projectCourtInstanceEvent: IProjectCourtInstanceEventModel = {
    ...standardFieldsModel,
    projectCourtInstanceId: 'projectCourtInstanceId',
    projectCourtInstance: 'courtInstance',
    eventTypeName: 'eventTypeName',
    eventTypeId: 'eventTypeId',
    externalId: 'externalId',
    additionalInfo: 'additionalInfo',
    date: 'date',
    file: 'file',
    publishDate: 'publishDate',
    finishEvent: 'finishEvent',
    eventContentTypeName: 'eventContentTypeName',
    declarer: 'declarer',
    comment: 'comment',
    decisionTypeName: 'decisionTypeName',
    hasSignature: 'hasSignature'
}

export const projectCourtInstanceEventModel = Object.freeze(
    projectCourtInstanceEvent
)

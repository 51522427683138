const IS_ENDED_PROJECT_TASK = 'SET_IS_ENDED_PROJECT_TASK'
const initialState = 'ignore'

export const endedStateProjectTaskAction = (isEndedProjectTask) => {
    const action = {
        type: IS_ENDED_PROJECT_TASK,
        isEndedProjectTask
    }
    return action
}

export const endedStateProjectTaskReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_ENDED_PROJECT_TASK
        ? action.isEndedProjectTask
        : priviousState

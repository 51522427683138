const IS_HIDDEN_PROJECT = 'SET_IS_HIDDEN_PROJECT'
const initialState = false

export const isHiddenProjectAction = (isHiddenProject) => {
    const action = {
        type: IS_HIDDEN_PROJECT,
        isHiddenProject
    }
    return action
}

export const isHiddenProjectReducer = (priviousState = initialState, action) =>
    action.type === IS_HIDDEN_PROJECT ? action.isHiddenProject : priviousState

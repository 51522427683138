import React from 'react'
import {
    getDeltaDay,
    getDeltaTime,
    getViewableContent
} from './notificationUtils'
import { MenuItem } from '@material-ui/core'
import { CREATE } from 'react-admin'
import { Link } from 'react-router-dom'
import { Notification } from './notification'
import { Resources, NotificationTypes } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

export const messageContent = (
    message,
    maxContentSymbolsCount = 51,
    maxTitleSymbolsCount = 51
) => {
    let contents: Array<string> = []
    let titles: Array<string> = []
    let linkTo = ''
    const notif = message.concreteNotification

    switch (message.type) {
        case NotificationTypes.TaskAssignee: {
            const title = notif.title
            contents = getViewableContent(
                notif.taskDescription,
                maxContentSymbolsCount
            )
            titles = getViewableContent(
                `${title.substr(0, title.length - 1)}: '${notif.taskTitle}'`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.ProjectTask}/${notif.taskId}/show`
            break
        }
        case NotificationTypes.ProjectCommentBroadcast: {
            titles = getViewableContent(
                `${notif.title} '${notif.projectTitle}' от ${notif.byUserName}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.Project}/${notif.projectId}/show/comments`
            break
        }
        case NotificationTypes.ProjectCommentReply: {
            titles = getViewableContent(
                `На ваш комментарий в проекте '${notif.projectTitle}' ответил ${notif.byUserName}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.Project}/${notif.projectId}/show/comments`
            break
        }
        case NotificationTypes.RequestCommentReply: {
            titles = getViewableContent(
                `В заявке '${notif.requestTitle}' на ваш комментарий ответил ${notif.byUserName}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.Request}/${notif.requestId}/show/comments`
            break
        }
        case NotificationTypes.ProjectTaskEndOfWork: {
            titles = getViewableContent(
                `${notif.title}: '${notif.taskTitle}'`,
                maxTitleSymbolsCount
            )
            contents = getViewableContent(
                `Осталось: ${getDeltaDay(notif.days)}`,
                maxContentSymbolsCount
            )
            linkTo = `/${Resources.ProjectTask}/${notif.taskId}/show`
            break
        }
        case NotificationTypes.ProjectEndOfWork: {
            titles = getViewableContent(
                `${notif.title}: '${notif.projectTitle}'`,
                maxTitleSymbolsCount
            )
            contents = getViewableContent(
                `Осталось: ${getDeltaDay(notif.days)}`,
                maxContentSymbolsCount
            )
            linkTo = `/${Resources.Project}/${notif.projectId}/show`
            break
        }
        case NotificationTypes.VerFileCommentReply: {
            titles = getViewableContent(
                `На ваш комментарий в файле ${notif.versionedFileName} ответил(а) ${notif.byUserName}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.VersionedFileAttachment}/${notif.versionedFileId}/comments`
            break
        }
        case NotificationTypes.VerFileCommentBroadcast: {
            titles = getViewableContent(
                `В файле ${notif.versionedFileName} новый комментарий от ${notif.byUserName}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.VersionedFileAttachment}/${notif.versionedFileId}/comments`
            break
        }
        case NotificationTypes.RequestAccept: {
            titles = getViewableContent(
                `${notif.projectTaskTitle}`,
                maxTitleSymbolsCount
            )
            contents = getViewableContent(
                `${notif.title}`,
                maxContentSymbolsCount
            )
            linkTo = `/${Resources.Request}/${notif.requestId}`
            break
        }
        case NotificationTypes.NewRequest: {
            titles = getViewableContent(
                `${notif.title}: '${notif.requestTitle}'`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.Request}/${notif.requestId}`
            break
        }
        default: {
            titles = getViewableContent(
                `Новое уведомление: ${notif.title}`,
                maxTitleSymbolsCount
            )
            linkTo = `/${Resources.Notification}/${message.id}/show`
        }
    }
    return { linkTo: linkTo, titles: titles, contents: contents }
}

export function NotificationItems({ notificationsData, classes, onClick }) {
    return (
        <>
            {notificationsData.map((value) => {
                const { linkTo, titles, contents } = messageContent(value)
                const coeff = 22 * (contents.length + titles.length)
                const deltaTime = getDeltaTime(value.createdOn)
                const maxHeightDefault = 52
                const readed = () =>
                    oDataProvider(CREATE, Resources.Notification_Read, {
                        data: { key: value.id }
                    })
                return (
                    <Link
                        key={`li-${value.id}`}
                        style={{ textDecoration: 'none' }}
                        to={linkTo}
                        onClick={readed}
                    >
                        <MenuItem
                            style={{ minHeight: maxHeightDefault + coeff }}
                            onClick={onClick}
                        >
                            <Notification
                                value={value}
                                classes={classes}
                                title={titles}
                                contents={contents}
                                deltaTime={deltaTime}
                            />
                        </MenuItem>
                    </Link>
                )
            })}
        </>
    )
}

import React, { useState, useEffect } from 'react'
import {
    Headroom,
    LoadingIndicator,
    toggleSidebar as toggleSidebarAction,
    UserMenu,
    GET_ONE
} from 'react-admin'
import {
    AppBar as MuiAppBar,
    createStyles,
    IconButton,
    MenuItem,
    Toolbar,
    Typography,
    withStyles,
    withWidth
} from '@material-ui/core'
import {
    Menu as MenuIcon,
    Brightness7 as LightThemeIcon,
    Brightness4 as DarkThemeIcon,
    CallMissedOutgoing as GoToMainSiteIcon
} from '@material-ui/icons/'
import { RoleTypeById } from './api/publicOjects'
import { connect } from 'react-redux'
import { LogoutButton } from './components/auth/logoutButton'
import { themeAction } from './reducers'
import { themeContainAction } from './reducers'
import { createMuiTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getFio } from './utils/dataHelpers'
import { NotificationsMenu } from './utils/notifications/notification'
import { Resources } from './api/api'
import { AppUser } from './api/api'
import compose from 'recompose/compose'
import LogoImg from './layout/logoImg'
import oDataProvider from './api/oDataProvider'
import * as classNames from 'classnames'

const styles = (theme) =>
    createStyles({
        titleElemets: {
            display: 'flex'
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em'
        },
        menuButtonIconClosed: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.sharp
            })
        },
        menuButtonIconOpen: {
            transform: 'rotate(180deg)',
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.sharp
            })
        },
        title: {
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 18
        },
        toolbar: {
            paddingRight: 24
        },
        appBarDark: {
            backgroundColor: '#333333'
        },
        appBarLight: {
            backgroundColor: '#BBBBBB'
        },
        notificationsPaper: {
            position: 'absolute',
            right: 85,
            borderBottom: '1px solid #555555',
            borderLeft: '1px solid #555555',
            borderRight: '1px solid #555555',
            backgroundColor: '#333333',
            width: 420
        },
        notificationsHeader: {
            color: 'white',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            borderBottom: '1px solid #555555'
        },
        notificationTitle: {
            color: 'white',
            fontSize: 14
        },
        notificationContent: {
            color: '#C2C2C2',
            width: 400
        },
        notificationSettingIcon: {
            color: 'white',
            position: 'absolute',
            top: '0',
            right: '10px'
        },
        dataContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        notificationSent: {
            color: '#C2C2C2',
            paddingTop: 10
        },
        menuItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        isSentPoint: {
            // left: '0px',
            marginRight: '12px'
        }
    })

const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00AA3C'
        },
        type: 'dark' // Switching the dark mode on is a single property value change.
    }
})

const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00AA3C'
        },
        secondary: {
            main: '#333333'
        }
    },
    typography: {}
})

const CustomUserMenu = ({ ...props }) => (
    <UserMenu {...props}>
        <Link to="/" style={{ outline: 'none', textDecoration: 'none' }}>
            <MenuItem>
                <GoToMainSiteIcon />
                &thinsp;<Typography>На сайт</Typography>
            </MenuItem>
        </Link>
        <Link to="/profile" style={{ outline: 'none', textDecoration: 'none' }}>
            <MenuItem>
                <GoToMainSiteIcon />
                &thinsp;<Typography>Редактировать</Typography>
            </MenuItem>
        </Link>
        <div>
            <LogoutButton />
        </div>
    </UserMenu>
)

const ShowThemeButton = (props) => {
    if (props.isDark) return <DarkThemeIcon />
    else return <LightThemeIcon />
}

const TitleElement = (props) => {
    const { type, value, href } = props
    return type === 'text' ? (
        <div>{value}&nbsp;|&nbsp;</div>
    ) : (
        <div>
            <Link
                to={href}
                style={{ color: '#C8EDFF', textDecoration: 'none' }}
            >
                {value}
            </Link>
            &nbsp;|&nbsp;
        </div>
    )
}

interface ITitleUser {
    role: string
    firstName: string
    lastName: string
    patronymicName: string
}
const AppBar = ({
    children,
    classes,
    className,
    logout,
    open,
    title,
    toggleSidebar,
    userMenu = <CustomUserMenu />,
    width,
    setTheme,
    setContainTheme,
    ...rest
}) => {
    const [isDark, setIsDark] = useState(true)

    const onChageTheme = () => {
        setIsDark(!isDark)
        if (isDark) {
            setTheme('dark')
            setContainTheme(darkTheme)
        } else {
            setTheme('light')
            setContainTheme(lightTheme)
        }
    }

    const [user, setUser] = useState<ITitleUser>({
        role: '',
        firstName: '',
        lastName: '',
        patronymicName: ''
    })

    useEffect(() => {
        const getPersonId = async () => {
            const pId = localStorage.getItem('personId')
            if (pId === undefined) return
            const { data: appUser } = await oDataProvider(
                GET_ONE,
                Resources.AppUser,
                {
                    id: localStorage.getItem('id')
                }
            )
            localStorage.setItem('personId', appUser.person.id)
            const role = RoleTypeById[appUser.userRoles[0].roleId]
            const person = appUser.person
            setUser({ role: role, ...person })
        }
        getPersonId()
    }, [])

    return (
        <Headroom>
            <MuiAppBar
                // className={isDark? classes.appBarLight : classes.appBarDark}
                color="secondary"
                position="static"
                {...rest}
            >
                <Toolbar
                    disableGutters={true}
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        className={classNames(classes.menuButton)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed
                            }}
                        />
                    </IconButton>
                    <LogoImg />
                    <Typography
                        variant="title"
                        color="inherit"
                        className={classes.title}
                        // id="react-admin-title"
                    >
                        <div className={classes.titleElemets}>
                            {title.map((value, index) => (
                                <TitleElement
                                    key={`t-${index}`}
                                    type={value.type}
                                    value={value.value}
                                    href={value.href}
                                />
                            ))}
                        </div>
                    </Typography>
                    {/* <IconButton
            onClick={onChageTheme}
          >
            <ShowThemeButton isDark={isDark} />
          </IconButton> */}

                    <Typography
                        color="inherit"
                        style={{
                            fontSize: 16,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {getFio(user)}: {user.role}
                        {/* {user.person.lastName} {user.person.firstName}{' '}
                        {user.person.patronymicName}: {user.role} */}
                    </Typography>
                    <LoadingIndicator />
                    <NotificationsMenu classes={classes} />
                    {React.cloneElement(userMenu)}
                </Toolbar>
            </MuiAppBar>
        </Headroom>
    )
}

const enhance = compose(
    connect(
        (state: any) => ({
            locale: state.i18n.locale, // force redraw on locale change
            title: state.title
        }),
        {
            toggleSidebar: toggleSidebarAction,
            setTheme: themeAction,
            setContainTheme: themeContainAction
        }
    ),
    withStyles(styles),
    withWidth()
)

export default enhance(AppBar)

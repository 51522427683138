import * as React from 'react'
import {
    EditButton,
    Datagrid,
    Filter,
    List,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})
const TaskTemplateFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Заголовок" source="$startsWith(title)" />
    </Filter>
)

const TaskTemplateListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'text',
        value: 'Шаблоны задач'
    })
    setTitle(titles)

    return (
        <List
            {...props}
            sort={{ field: 'CreatedOn', order: 'DESC' }}
            filters={<TaskTemplateFilter />}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Заголовок" source="title" />
                <TextField label="Описание" source="description" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const TaskTemplateList = enchanceList(TaskTemplateListBase)

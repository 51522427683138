export class GetSettings {
    public key?: string

    public toString(): string {
        if (!this.key) {
            return ''
        }
        return `(${this.key})`
    }

    public reset(): void {
        this.key = undefined
    }

    public isSet(): boolean {
        return !!this.key
    }
}

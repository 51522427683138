import React, { useEffect, useState } from 'react'
import {
    Datagrid,
    BooleanField,
    NumberInput,
    NumberField,
    SelectInput,
    SelectField,
    TabbedForm,
    FormTab,
    TextField,
    TextInput,
    DateField,
    required,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    DeleteButton,
    EditButton,
    BooleanInput,
    DateInput,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd,
    WithPermissions,
    ArrayField,
    FormDataConsumer
} from 'react-admin'
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component'
import {
    LetterTypeField,
    LetterStatusField,
    PersonField
} from '../lettersOfAuthorityList/lettersOfAuthorityList'
import {
    checkPermissionsAdminOnly,
    checkPermissionsEmployeeAndUp
} from '../auth/authHelpers'
import {
    projectModel as pm,
    projectTaskModel as pt,
    phaseModel as ph,
    procedureModel as pc,
    customerModel as cm,
    projectParticipantsModel as pp,
    versionedFileAttachmentModel as vf,
    projectCourtCaseModel as pcc,
    letterOfAuthorityModel as la,
    letterOfAuthorityClassificationDictValueModel as lc,
    letterOfAuthorityRequesterDictValueModel as lr,
    appUserModel as au,
    personModel as pe,
    projectTemplateModel as ptm
} from '../../api/models'
import { withStyles } from '@material-ui/core'
import { ColorInput } from 'react-admin-color-input'
import { CustomerQuickViewPanel } from '../customer/customerQuickViewPanel'
import { AddNewProcedureButton } from '../tasks/addNewProcedureButton'
import { AddNewPhaseButton } from '../tasks/addNewPhaseButton'
import { AddNewTaskButton } from '../tasks/addNewTaskButton'
import { AddNewParticipantButton } from './addNewParticipantButton'
import { AccessTypes } from './projectParticipantForm'
import { UserFullNameField, UserLinkFullNameField } from '../fields'
import { Work as WorkIcon } from '@material-ui/icons'
import { SearchCourtCaseButton } from '../courtCase/searchCourtCaseButton'
import { CourtCaseInfoDetails } from '../courtCase/courtCaseInfoDetails'
import { AddDocumentButton } from '../documents/addDocumentButton'
import { FileLink } from '../fields'
import { AddCourtCaseButton } from '../courtCase/addCourtCaseButton'
import { ToolbarGeneral } from '../../layout/bar'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import { AddNewLetterButton } from '../lettersOfAuthorityList/addNewLetterButton'
import { Resources } from '../../api/api'
import CommentsBlock from '../comments/comments'
import oDataProvider from '../../api/oDataProvider'
import Timeline from '../timeline/timeline'
import RichTextInput from 'ra-input-rich-text'
import Edit from '../../layout/edit'
import Create from '../../layout/create'
import 'react-vertical-timeline-component/style.min.css'
import 'react-vertical-timeline-component/style.min.css'

const validate = () => {
    const errors: any = {}
    return errors
}

export const ProjectStatuses = [
    { id: 'New', name: 'Новый' },
    { id: 'Active', name: 'Активный' },
    { id: 'Closed', name: 'Закрыт' },
    { id: 'Postponed', name: 'Отложен' }
]

const singleRowControlsStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
}

const styles = {
    root: {},
    statusNumberContainer: {
        ...singleRowControlsStyles,
        '& div': {
            minWidth: 250,
            marginRight: 24
        }
    },
    datesContainer: {
        marginTop: 36,
        // ...singleRowControlsStyles,
        '& div': {
            width: 250,
            marginRight: 24
        }
    },
    tabs: {
        '& .form-tab': {
            minWidth: 70
        }
    },
    inputs: {
        minWidth: 252
    },
    textEditor: {
        marginBottom: 48,
        '& .ra-rich-text-input': {
            height: 200
        }
    },
    warning: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}

const TemplateInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет шаблонов&nbsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const TemplateInput = (props) => {
    const { atLeastOneTemplate } = props
    return atLeastOneTemplate ? (
        <SelectAndSearchInput
            label="Использовать шаблон"
            source={pm.projectTemplateId}
            reference={Resources.ProjectTemplate}
            optionText={ptm.templateName}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<TemplateInputLabel style={props.classes} />}
        />
    )
}

const ClientInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет клиентов *</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ClientInput = (props) => {
    const { atLeastOneClient } = props
    return atLeastOneClient ? (
        <div>
            <SelectAndSearchInput
                label="Клиент"
                source={pm.customerId}
                reference={Resources.Customer}
                optionText={cm.displayName}
                validate={required()}
                alwaysOn={true}
                multi={false}
            />
            <CustomerQuickViewPanel id={props.record.customerId} />
        </div>
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ClientInputLabel style={props.classes} />}
        />
    )
}

const projectFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [state, setState] = useState({
        atLeastOneTemplate: false,
        atLeastOneClient: false
    })

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/Project',
            value: 'Проекты'
        })
        titles.push({
            type: 'text',
            value: `Редактирование проекта №${props.record.id} - ${props.record.name}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        const loader = async () => {
            fetchStart()
            const action = GET_LIST
            const { data: template } = await oDataProvider(
                action,
                Resources.ProjectTemplate,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: client } = await oDataProvider(
                action,
                Resources.Customer,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            setState({
                atLeastOneClient: client.length > 0,
                atLeastOneTemplate: template.length > 0
            })
            fetchEnd()
        }
        loader()
    }, [])

    return (
        <TabbedForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    shouldParse
                    disabled={!state.atLeastOneClient}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
            className={rest.classes.tabs}
        >
            <FormTab label="Проект">
                {!rest.isEditToolbar && (
                    <TemplateInput
                        atLeastOneTemplate={state.atLeastOneTemplate}
                        {...rest}
                    />
                )}

                <div className={rest.classes.statusNumberContainer}>
                    <TextInput
                        label="Уникальный номер проекта"
                        source={pm.number}
                        validate={required()}
                    />
                    <SelectInput
                        label="Статус проекта"
                        source={pm.status}
                        choices={ProjectStatuses}
                        validate={required()}
                    />
                </div>
                <ColorInput
                    label="Цвет"
                    source={pm.color}
                    picker="Github"
                    options={{ inputProps: { autocomplete: 'off' } }}
                />
                <TextInput
                    label="Имя проекта"
                    fullWidth={true}
                    source={pm.name}
                    validate={required()}
                />

                <ClientInput
                    atLeastOneClient={state.atLeastOneClient}
                    {...rest}
                />

                <NumberInput label="Бюджет" source={pm.budget} />
                <div className={rest.classes.datesContainer}>
                    <DateInput
                        label="Дата начала"
                        source={pm.startDate}
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                    <DateInput
                        label="Дата завершения"
                        source={pm.endDate}
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                </div>

                {rest.isEditToolbar && rest.record.projectTemplateId && (
                    <ReferenceField
                        label="Создан из шаблона"
                        source={pm.projectTemplateId}
                        reference={Resources.ProcedureTemplate}
                    >
                        <TextField source="templateName" />
                    </ReferenceField>
                )}
                {props.permissions === 'Сотрудник' && (
                    <BooleanInput
                        label="Сделать себя участником"
                        source={pm.assignSelf}
                        disabled={rest.record.id}
                        checked
                    />
                )}
            </FormTab>
            <FormTab label="Описание">
                <RichTextInput
                    label="Краткая заметка"
                    source={pm.note}
                    className={rest.classes.textEditor}
                />
                <RichTextInput
                    label="Описание"
                    source={pm.description}
                    className={rest.classes.textEditor}
                />
            </FormTab>
            {rest.record.id && (
                <FormTab label="Участники" path="participants">
                    <WithPermissions
                        render={({ permissions }) =>
                            checkPermissionsEmployeeAndUp(permissions) ? (
                                <AddNewParticipantButton {...rest} />
                            ) : null
                        }
                    />
                    <ReferenceManyField
                        addLabel={false}
                        reference={Resources.ProjectParticipant}
                        target={pp.projectId}
                        allowEmpty={true}
                    >
                        <Datagrid>
                            <UserLinkFullNameField label="ФИО" />
                            <SelectField
                                label="Доступ"
                                source={pp.accessType}
                                choices={AccessTypes}
                            />
                            <DateField
                                label="Дата изъятия доступа к проекту"
                                source={pp.accessExpirationDate}
                            />
                            <DeleteButton redirect={rest.location.pathname} />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {rest.record.id && rest.record.commentListId && (
                <FormTab label="Комментарии" path="comments">
                    <CommentsBlock commentListId={rest.record.commentListId} />
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab
                    label="Файлы"
                    path="files"
                    filter={{ ownerProjectId: props.record.id }}
                >
                    <AddDocumentButton {...rest} />
                    <ReferenceManyField
                        addLabel={false}
                        target={vf.ownerProjectId}
                        reference={Resources.VersionedFileAttachment}
                        linkType={false}
                    >
                        <Datagrid rowClick="edit">
                            <TextField label="Документ" source={vf.name} />
                            <DateField
                                label="Дата документа"
                                source={vf.atDate}
                            />
                            <DateField
                                label="Дата создания"
                                source={vf.createdOn}
                            />
                            <DateField
                                label="Дата последнего обновления"
                                source={vf.updatedOn}
                            />
                            <FileLink />
                            <ReferenceField
                                label="Автор"
                                source={vf.createdById}
                                reference={Resources.AppUser}
                                allowEmpty={true}
                                linkType={false}
                            >
                                <UserFullNameField />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Суд" path="court">
                    <SearchCourtCaseButton {...rest} />
                    <AddCourtCaseButton {...rest} />
                    <ReferenceManyField
                        addLabel={false}
                        target={pcc.projectId}
                        reference={Resources.ProjectCourtCase}
                    >
                        <Datagrid expand={<CourtCaseInfoDetails />}>
                            <TextField
                                label="Номер дела"
                                source={pcc.caseNumber}
                            />
                            <TextField label="Тип" source={pcc.caseType} />
                            <DateField label="Дата" source={pcc.startDate} />
                            <BooleanField
                                label="Завершено"
                                source={pcc.finished}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Процедуры" path="procedures">
                    <ReferenceManyField
                        addLabel={false}
                        target={pc.projectId}
                        reference={Resources.Procedure}
                    >
                        <Datagrid>
                            <TextField
                                label="Название процедуры"
                                source={pc.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={pc.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={pc.endDate}
                            />
                            <DeleteButton redirect={rest.location.pathname} />
                        </Datagrid>
                    </ReferenceManyField>
                    <WithPermissions
                        render={({ permissions }) =>
                            checkPermissionsAdminOnly(permissions) ? (
                                <AddNewProcedureButton {...rest} />
                            ) : null
                        }
                    />
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Этапы" path="phases">
                    <ReferenceManyField
                        addLabel={false}
                        target={ph.projectId}
                        reference={Resources.Phase}
                    >
                        <Datagrid>
                            <TextField
                                label="Название этапа"
                                source={ph.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={ph.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={ph.endDate}
                            />
                            <DeleteButton redirect={rest.location.pathname} />
                        </Datagrid>
                    </ReferenceManyField>
                    <WithPermissions
                        render={({ permissions }) =>
                            checkPermissionsAdminOnly(permissions) ? (
                                <AddNewPhaseButton {...rest} />
                            ) : null
                        }
                    />
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Задачи" path="tasks">
                    <WithPermissions
                        render={({ permissions }) =>
                            checkPermissionsAdminOnly(permissions) ? (
                                <AddNewTaskButton {...rest} />
                            ) : null
                        }
                    />
                    <ReferenceManyField
                        addLabel={false}
                        target={pt.projectId}
                        reference={Resources.ProjectTask}
                    >
                        <Datagrid>
                            <TextField
                                label="Название задачи"
                                source={pt.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={pt.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={pt.endDate}
                            />
                            <ReferenceField
                                label="Процедура"
                                source={pt.procedureId}
                                reference={Resources.Procedure}
                                allowEmpty={true}
                            >
                                <TextField source={pc.title} />
                            </ReferenceField>
                            <ReferenceField
                                label="Этап"
                                source={pt.phaseId}
                                reference={Resources.Phase}
                                allowEmpty={true}
                            >
                                <TextField source={pt.title} />
                            </ReferenceField>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    return (
                                        <WithPermissions
                                            render={({ permissions }) =>
                                                checkPermissionsAdminOnly(
                                                    permissions
                                                ) ? (
                                                    <>
                                                        <EditButton
                                                            basePath={``}
                                                        />
                                                        <DeleteButton
                                                            redirect={
                                                                props.location
                                                                    .pathname
                                                            }
                                                        />
                                                    </>
                                                ) : null
                                            }
                                        />
                                    )
                                }}
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Доверенности" path="lettersOfAuthority">
                    <WithPermissions
                        render={({ permissions }) =>
                            checkPermissionsAdminOnly(permissions) ? (
                                <AddNewLetterButton {...rest} />
                            ) : null
                        }
                    />
                    <ArrayField source={pm.lettersOfAuthority} label="">
                        <Datagrid
                            rowClick={(data) =>
                                `${Resources.LetterOfAuthority}/${
                                    JSON.parse(data).id
                                }/show`
                            }
                        >
                            <TextField label="Номер" source={la.number} />
                            <ReferenceField
                                label="Проект"
                                source={la.projectId}
                                reference={Resources.Project}
                            >
                                <TextField source={pm.name} />
                            </ReferenceField>
                            <TextField label="Имя" source={la.name} />
                            <DateField
                                label="Дата выдачи"
                                source={la.issuedAt}
                            />
                            <DateField
                                label="Дата истечения"
                                source={la.expireAt}
                            />
                            <LetterStatusField label="Статус" />
                            <LetterTypeField label="Тип" />
                            <ReferenceField
                                label="Классификация"
                                source={la.classificationDictValueId}
                                reference={
                                    Resources.LetterOfAuthorityClassificationDictValue
                                }
                                linkType="show"
                            >
                                <TextField source={lc.value} />
                            </ReferenceField>
                            <ReferenceField
                                label="Место предъявления"
                                source={la.requesterDictValueId}
                                reference={
                                    Resources.LetterOfAuthorityRequesterDictValue
                                }
                                linkType="show"
                            >
                                <TextField source={lr.value} />
                            </ReferenceField>
                            <ReferenceField
                                label="Доверенное лицо"
                                source={la.toPersonId}
                                reference={Resources.Person}
                            >
                                <PersonField />
                            </ReferenceField>
                            <ReferenceField
                                label="Доверитель"
                                source={la.personTrusteeId}
                                reference={Resources.Person}
                            >
                                <PersonField />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            )}
            {rest.record.id && (
                <FormTab label="Таймлайн" path="timeline">
                    <Timeline projectId={rest.record.id} />
                </FormTab>
            )}
        </TabbedForm>
    )
}
export const ProjectFullForm = withStyles(styles as any)(projectFormBase)

const projectClientFormBase = (props: any) => {
    console.log(props)
    return (
        <TabbedForm
            {...props}
            toolbar={
                <ToolbarGeneral isEditToolbar={false} shouldParse {...props} />
            }
            validate={validate}
        >
            <FormTab label="Проект">
                <div className={props.classes.statusNumberContainer}>
                    <TextField
                        label="Уникальный номер проекта"
                        source={pm.number}
                        validate={required()}
                    />
                    <SelectField
                        label="Статус проекта"
                        source={pm.status}
                        choices={ProjectStatuses}
                        validate={required()}
                    />
                </div>
                <TextField
                    label="Имя проекта"
                    source={pm.name}
                    fullWidth={true}
                    validate={required()}
                />

                <ReferenceField
                    label="Клиент"
                    source={pm.customerId}
                    reference={Resources.Customer}
                    validate={required()}
                >
                    <TextField source={cm.displayName} />
                </ReferenceField>
                <CustomerQuickViewPanel id={props.record.customerId} />

                <NumberField label="Бюджет" source={pm.budget} />
                <div className={props.classes.datesContainer}>
                    <DateField
                        label="Дата начала"
                        source={pm.startDate}
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                    <DateField
                        label="Дата завершения"
                        source={pm.endDate}
                        options={{ dateFormat: 'yyyy-MM-dd' }}
                    />
                </div>

                {props.isEditToolbar && props.record.projectTemplateId && (
                    <ReferenceField
                        label="Создан из шаблона"
                        source={pm.projectTemplateId}
                        reference={Resources.ProjectTemplate}
                    >
                        <TextField source="templateName" />
                    </ReferenceField>
                )}
            </FormTab>
            <FormTab label="Описание">
                <RichTextField
                    label="Краткая заметка"
                    source={pm.note}
                    className={props.classes.textEditor}
                />
                <RichTextField
                    label="Описание"
                    source={pm.description}
                    className={props.classes.textEditor}
                />
            </FormTab>
            {(props.record.id && props.permissions === 'Сотрудник') ||
                (props.permissions === 'Диспетчер' && (
                    <FormTab label="Участники" path="participants">
                        <ReferenceManyField
                            addLabel={false}
                            reference={Resources.ProjectParticipant}
                            target={pp.projectId}
                            allowEmpty={true}
                        >
                            <Datagrid>
                                <TextField
                                    label="Фамилия"
                                    source={`${pp.appUser}.${au.person}.${pe.lastName}`}
                                />
                                <TextField
                                    label="Имя"
                                    source={`${pp.appUser}.${au.person}.${pe.firstName}`}
                                />
                                <TextField
                                    label="Отчество"
                                    source={`${pp.appUser}.${au.person}.${pe.patronymicName}`}
                                />
                                <SelectField
                                    label="Доступ"
                                    source={pp.accessType}
                                    choices={AccessTypes}
                                />
                                <DateField
                                    label="Дата изъятия доступа к проекту"
                                    source={pp.accessExpirationDate}
                                />
                                <DeleteButton
                                    redirect={props.location.pathname}
                                />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                ))}
            {props.record.id && props.record.commentListId && (
                <FormTab label="Комментарии" path="comments">
                    <CommentsBlock commentListId={props.record.commentListId} />
                </FormTab>
            )}
            {props.record.id && props.permissions === 'Сотрудник' && (
                <FormTab label="Процедуры" path="procedures">
                    <AddNewProcedureButton {...props} />
                    <ReferenceManyField
                        addLabel={false}
                        target={pc.projectId}
                        reference={Resources.Procedure}
                    >
                        <Datagrid>
                            <TextField
                                label="Название процедуры"
                                source={pc.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={pc.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={pc.endDate}
                            />
                            <DeleteButton redirect={props.location.pathname} />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {props.record.id && props.permissions === 'Сотрудник' && (
                <FormTab label="Этапы" path="phases">
                    <AddNewPhaseButton {...props} />
                    <ReferenceManyField
                        addLabel={false}
                        target={ph.projectId}
                        reference={Resources.Phase}
                    >
                        <Datagrid>
                            <TextField
                                label="Название этапа"
                                source={ph.title}
                            />
                            <DateField
                                label="Дата начала"
                                source={ph.startDate}
                            />
                            <DateField
                                label="Дата завершения"
                                source={ph.endDate}
                            />
                            <DeleteButton redirect={props.location.pathname} />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
            {props.record.id && props.permissions === 'Сотрудник' && (
                <FormTab label="Доверенности" path="lettersOfAuthority">
                    <ArrayField source={pm.lettersOfAuthority} label="">
                        <Datagrid
                            rowClick={(data) =>
                                `${Resources.LetterOfAuthority}/${
                                    JSON.parse(data).id
                                }/show`
                            }
                        >
                            <TextField label="Номер" source={la.number} />
                            <ReferenceField
                                label="Проект"
                                source={la.projectId}
                                reference={Resources.Project}
                            >
                                <TextField source={pm.name} />
                            </ReferenceField>
                            <TextField label="Имя" source={la.name} />
                            <DateField
                                label="Дата выдачи"
                                source={la.issuedAt}
                            />
                            <DateField
                                label="Дата истечения"
                                source={la.expireAt}
                            />
                            <LetterStatusField label="Статус" />
                            <LetterTypeField label="Тип" />
                            <ReferenceField
                                label="Классификация"
                                source={la.classificationDictValueId}
                                reference={
                                    Resources.LetterOfAuthorityClassificationDictValue
                                }
                                linkType="show"
                            >
                                <TextField source={lc.value} />
                            </ReferenceField>
                            <ReferenceField
                                label="Место предъявления"
                                source={la.requesterDictValueId}
                                reference={
                                    Resources.LetterOfAuthorityRequesterDictValue
                                }
                                linkType="show"
                            >
                                <TextField source={lr.value} />
                            </ReferenceField>
                            <ReferenceField
                                label="Доверенное лицо"
                                source={la.toPersonId}
                                reference={Resources.Person}
                            >
                                <PersonField />
                            </ReferenceField>
                            <ReferenceField
                                label="Доверитель"
                                source={la.personTrusteeId}
                                reference={Resources.Person}
                            >
                                <PersonField />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            )}
            {props.record.id && <FormTab label="Файлы" path="files"></FormTab>}
            {props.record.id && (
                <FormTab label="Суд" path="court">
                    <SearchCourtCaseButton {...props} />
                    <AddCourtCaseButton {...props} />
                    <ReferenceManyField
                        addLabel={false}
                        target={pc.projectId}
                        reference={Resources.ProjectCourtCase}
                    >
                        <Datagrid expand={<CourtCaseInfoDetails />}>
                            <TextField
                                label="Номер дела"
                                source={pcc.caseNumber}
                            />
                            <TextField label="Тип" source={pcc.caseType} />
                            <DateField label="Дата" source={pcc.startDate} />
                            <BooleanField
                                label="Завершено"
                                source={pcc.finished}
                            />
                        </Datagrid>
                    </ReferenceManyField>
                    {/* <Typography variant={"body1"}>Нет добавленных дел</Typography> */}
                </FormTab>
            )}
            {props.record.id && (
                <FormTab label="Таймлайн" path="timeline">
                    <div style={{ backgroundColor: '#ddd', width: '100%' }}>
                        <VerticalTimeline>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                date={props.record.createdOn}
                                iconStyle={{
                                    background: 'rgb(33, 150, 243)',
                                    color: '#fff'
                                }}
                                icon={<WorkIcon />}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    Проект создан
                                </h3>
                                <p>{props.record.name}</p>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </div>
                </FormTab>
            )}
        </TabbedForm>
    )
}

export const ProjectClientForm = withStyles(styles as any)(
    projectClientFormBase
)

const ProjectCreateViewBase = (props) => {
    const { setTitle, ...rest } = props
    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/Project',
        value: 'Проекты'
    })
    titles.push({
        type: 'text',
        value: 'Создание проекта'
    })
    setTitle(titles)

    return (
        <Create title="" {...rest}>
            <ProjectFullForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const ProjectCreateView = enchanceCreate(ProjectCreateViewBase)

export const ProjectEditViewBase = ({ permissions, ...props }) => {
    const { setTitle, ...rest } = props

    return (
        <Edit {...rest}>
            {permissions === 'Администратор' ? (
                <ProjectFullForm {...props} isEditToolbar={true} />
            ) : (
                <ProjectClientForm
                    {...props}
                    isEditToolbar={true}
                    permissions={permissions}
                />
            )}
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ProjectEditView = enchanceEdit(ProjectEditViewBase)

import * as React from 'react'
import { Chip, Typography } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, Cancel, Clear } from '@material-ui/icons'
import Select from 'react-select'
import AsyncSelect from 'react-select'
import { AutocompleteArrayInputOption } from './autocompleteArrayInputOption'

const arrowRenderer = (arrowProps) =>
    arrowProps.isOpen ? <ArrowDropUp /> : <ArrowDropDown />
const clearRenderer = () => <Clear />
const filterOptions = (options) => options

const valueComponentRenderer = (valueProps, ...rest) => {
    const { value, children, onRemove } = valueProps
    const onDelete = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onRemove(value)
    }

    if (onRemove) {
        return (
            <Chip
                tabIndex={-1}
                label={children || value}
                deleteIcon={<Cancel onTouchEnd={onDelete} />}
                onDelete={onDelete}
            />
        )
    }
    return <div>{children}</div>
}

export const AutocompleteArrayInputWrapper = (props) => {
    const { classes, creatable, ...other } = props

    return creatable ? (
        <Select.Creatable
            optionComponent={AutocompleteArrayInputOption}
            noResultsText={<Typography>{'Нет подходящих значений'}</Typography>}
            arrowRenderer={arrowRenderer}
            clearRenderer={clearRenderer}
            valueComponent={valueComponentRenderer}
            {...other}
        />
    ) : (
        <AsyncSelect
            onMenuScrollToBottom={() => {
                console.log('Scroll')
            }}
            autoload
            cacheOptions
            noResultsText={<Typography>{'Нет подходящих значений'}</Typography>}
            arrowRenderer={arrowRenderer}
            clearRenderer={clearRenderer}
            valueComponent={valueComponentRenderer}
            {...other}
        />
    )
}

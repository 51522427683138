import * as React from 'react'
import { SimpleForm, TextInput } from 'react-admin'
import { ToolbarGeneral } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import Edit from '../../layout/edit'
import Create from '../../layout/create'

const validate = (values) => {
    const erros: any = {}
    return erros
}

export const LookupForm = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Настройка процесса'
        })
        titles.push({
            type: 'link',
            href: '/Lookup',
            value: 'Справочники'
        })
        titles.push({
            type: 'text',
            value: `Редактирование справочника №${props.record.id} - ${props.record.name}`
        })
        setTitle(titles)
    }

    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <TextInput label="Название справочника" source="name" />
            <TextInput label="Системное имя" source="systemName" />
        </SimpleForm>
    )
}

const LookupCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'link',
        href: '/Lookup',
        value: 'Справочники'
    })
    titles.push({
        type: 'text',
        value: 'Создание справочника'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <LookupForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const LookupCreateView = enchanceCreate(LookupCreateViewBase)

const LookupEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <LookupForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const LookupEditView = enchanceEdit(LookupEditViewBase)

import React, { useState, useEffect } from 'react'
import {
    FileField,
    TextInput,
    FileInput,
    required,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    TabbedForm,
    FormTab,
    ReferenceField,
    TextField,
    BooleanInput,
    SelectInput,
    DateField
} from 'react-admin'
import { FileLink, FilesArrayLink } from '../fields'
import { ToolbarGeneral } from '../../layout/bar'
import { Switch, FormControlLabel } from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import {
    DocumentEditAccess,
    DocumentVisibility
} from '../documents/addDocumentButton'
import { Resources } from '../../api/api'
import Edit from '../../layout/edit'
import Create from '../../layout/create'
import CommentsBlock from '../comments/comments'

const validate = (values) => {
    const errors: any = {}
    if (
        !values.id &&
        (!values.attachments || values.attachments.length === 0)
    ) {
        errors.attachments = 'Необходимо выбрать файл.'
    }
    return errors
}

const PersonalFileForm = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [isShowAllFiles, setIsShowAllFiles] = useState(false)

    const role = localStorage.getItem('role')

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'link',
            href: '/PersonalFile',
            value: 'Мои файлы'
        })
        titles.push({
            type: 'text',
            value: `Карта редактирования файла ${props.record.id} - ${props.record.name}`
        })
        setTitle(titles)
    }

    let isEditToolbaror = JSON.stringify(props.record) === JSON.stringify({})
    if (
        !!props.record &&
        !!props.record.ownerProject &&
        !!props.record.ownerProject.participants
    ) {
        const id = Number(localStorage.getItem('id'))
        isEditToolbaror = false

        for (const participant of props.record.ownerProject.participants) {
            const isID = participant.userId === id
            const isType =
                participant.accessType !== 'Viewer' &&
                (props.record.editAccessFlags === participant.accessType ||
                    participant.accessType === 'Supervisor' ||
                    props.record.editAccessFlags === 'All')

            if (isID && isType) {
                isEditToolbaror = true
            }
        }
    }

    if (role === 'Администратор') {
        isEditToolbaror = true
    } else if (
        role === 'Клиент' &&
        props.location.pathname !== '/VersionedFileAttachment/create' &&
        props.record.ownerProject.customer.personId ===
            Number(localStorage.getItem('personId')) &&
        (props.record.editAccessFlags === 'Client' ||
            props.record.editAccessFlags === 'All')
    ) {
        isEditToolbaror = true
    }

    return (
        <TabbedForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            redirect="list"
            {...rest}
        >
            <FormTab label="Информация о файле">
                {isEditToolbaror && (
                    <TextInput
                        fullWidth
                        label="Название"
                        source="name"
                        validate={required()}
                    />
                )}
                {!isEditToolbaror && (
                    <TextField fullWidth label="Название" source="name" />
                )}

                {isEditToolbaror && (
                    <TextInput
                        fullWidth
                        label="Описание"
                        source="description"
                    />
                )}
                {!isEditToolbaror && (
                    <TextField
                        fullWidth
                        label="Описание"
                        source="description"
                    />
                )}

                {isEditToolbaror && (
                    <DateInput label="Дата документа" source="atDate" />
                )}
                {!isEditToolbaror && (
                    <DateField label="Дата документа" source="atDate" />
                )}

                {!!props.record.ownerProjectId && isEditToolbaror && (
                    <SelectInput
                        label="Видимость"
                        source="visibilityFlags"
                        choices={DocumentVisibility}
                        validate={required()}
                    />
                )}
                {!!props.record.ownerProjectId && isEditToolbaror && (
                    <SelectInput
                        label="Редактирование"
                        source="editAccessFlags"
                        choices={DocumentEditAccess}
                        validate={required()}
                    />
                )}
                {!!props.record.ownerProjectId && (
                    <ReferenceField
                        label="Привязан к проекту"
                        source="ownerProjectId"
                        reference={Resources.Project}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                )}
                {!!props.record.ownerRequestId && (
                    <ReferenceField
                        label="Привязан к заявке"
                        source="ownerRequestId"
                        reference={Resources.Request}
                    >
                        <TextField source="subject" />
                    </ReferenceField>
                )}
                {isEditToolbaror && (
                    <FileInput
                        source="attachments"
                        label={rest.id ? 'Заменить файлом' : 'Файл'}
                        multiple={false}
                        maxSize={100 * 1024 * 1024}
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                )}
                <FileLink />
                {!!props.record.files && props.record.files.length > 1 && (
                    <FormControlLabel
                        style={{ width: 400 }}
                        control={
                            <Switch
                                checked={isShowAllFiles}
                                onChange={() => {
                                    setIsShowAllFiles(!isShowAllFiles)
                                }}
                            />
                        }
                        label="Показать предыдущие файлы"
                    />
                )}
                {isShowAllFiles && <FilesArrayLink />}
            </FormTab>
            {!!props.record.commentListId && (
                <FormTab label="Комментарии" path="comments">
                    <CommentsBlock commentListId={props.record.commentListId} />
                </FormTab>
            )}
            {/* <FormTab label="Таймлайн" path="timeline">
                <Timeline />
            </FormTab> */}
        </TabbedForm>
    )
}

const PersonalFileCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'link',
        href: '/PersonalFile',
        value: 'Файлы'
    })
    titles.push({
        type: 'text',
        value: 'Добавить файл'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <PersonalFileForm isEditToolbar={false} {...rest} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const PersonalFileCreateView = enchanceCreate(PersonalFileCreateViewBase)

const PersonalFileEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <PersonalFileForm isEditToolbar shouldParse {...props} />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const PersonalFileEditView = enchanceEdit(PersonalFileEditViewBase)

import React from 'react'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { LettersOfAuthorityInput } from './lettersOfAuthorityInput'
import { LettersOfAuthorityShow as LettersOfAuthorityShowForm } from './lettersOfAuthorityShow'
import { Create } from '../../layout'
import { Show, Edit } from 'react-admin'
import { ActionGeneral } from '../../layout/bar'

export const LetterOfAuthorityType = [
    { id: 'OneTime', name: 'Разовая' },
    { id: 'General', name: 'Генеральная' }
]

export const LetterOfAuthorityStatus = [
    { id: 'Required', name: 'Требуется доверенность' },
    { id: 'Requested', name: 'Запрошена у клиента' },
    { id: 'HaveScan', name: 'Есть скан' },
    { id: 'HaveOriginal', name: 'Есть оригинал' },
    { id: 'NotValid', name: 'Просрочен/недействителен' }
]

export const choice = [
    { id: 'fromList', name: 'Из списка' },
    { id: 'fromTextInput', name: 'Ручной ввод' }
]

const enchanceTitle = connect(undefined, {
    setTitle: titleAction
})

const LettersOfAuthorityCreateBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'link',
        href: '/LetterOfAuthority',
        value: 'Договоры'
    })
    titles.push({
        type: 'text',
        value: 'Создать договор'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <LettersOfAuthorityInput {...rest} isEditToolbar={false} />
        </Create>
    )
}
export const LettersOfAuthorityCreate = enchanceTitle(
    LettersOfAuthorityCreateBase
)

const LettersOfAuthorityEditBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <LettersOfAuthorityInput {...props} isEditToolbar={true} />
        </Edit>
    )
}
export const LettersOfAuthorityEdit = enchanceTitle(LettersOfAuthorityEditBase)

const LettersOfAuthorityShowBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Show {...rest} actions={<ActionGeneral />}>
            <LettersOfAuthorityShowForm {...props} />
        </Show>
    )
}
export const LettersOfAuthorityShow = enchanceTitle(LettersOfAuthorityShowBase)

import * as React from 'react'
import compose from 'recompose/compose'
import {
    Collapse,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const styles = (theme) =>
    createStyles({
        listItem: {
            paddingLeft: '1rem'
        },
        listItemText: {
            paddingLeft: 2,
            fontSize: '1rem'
        },
        sidebarIsOpen: {
            paddingLeft: 25,
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
        },
        sidebarIsClosed: {
            paddingLeft: 0,
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
        }
    })

// @ts-ignore
const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children
}) => (
    <React.Fragment>
        <ListItem
            dense={true}
            button={true}
            onClick={handleToggle}
            className={classes.listItem}
        >
            <ListItemIcon>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            <ListItemText
                inset={true}
                primary={isOpen ? name : ''}
                secondary={isOpen ? '' : name}
                className={classes.listItemText}
            />
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List
                dense={true}
                // component="div"
                disablePadding
                className={
                    sidebarIsOpen
                        ? classes.sidebarIsOpen
                        : classes.sidebarIsClosed
                }
            >
                {children}
            </List>
            <Divider />
        </Collapse>
    </React.Fragment>
)

const enhance = compose(withStyles(styles))

export default enhance(SubMenu)

import { standardFieldsModel, IStandardFieldsModel } from './'

export interface ITimelineEventModel extends IStandardFieldsModel {
    message: 'message'
    data: 'data'

    requestTimelineEvent: 'requestTimelineEvent'
    projectTimelineEvent: 'projectTimelineEvent'
    versionedFileTimelineEvent: 'versionedFileTimelineEvent'
}

const timelineEvent: ITimelineEventModel = {
    ...standardFieldsModel,
    message: 'message',
    data: 'data',

    requestTimelineEvent: 'requestTimelineEvent',
    projectTimelineEvent: 'projectTimelineEvent',
    versionedFileTimelineEvent: 'versionedFileTimelineEvent'
}

export const timelineEventModel = Object.freeze(timelineEvent)

const THEME = 'SET_THEME'
const initialState = 'light'

export const themeAction = (theme) => {
    const action = {
        type: THEME,
        theme
    }
    return action
}

export const themeReducer = (priviousState = initialState, action) => {
    if (action.type === THEME) return action.theme
    return priviousState
}

import * as React from 'react'
import { Button, Typography } from '@material-ui/core'
import { FilterNone as FilterNoneIcon } from '@material-ui/icons/'

const Icon = (props) => (
    <FilterNoneIcon color={props.isSelected ? 'primary' : undefined} />
)
const Label = (props) => (
    <Typography color={props.isSelected ? 'primary' : undefined}>
        {props.text}
    </Typography>
)

export default (props) => {
    const { text, isSelected, onHandleChange } = props
    return (
        <Button size="small" onClick={onHandleChange}>
            <Icon isSelected={isSelected} /> &nbsp;
            <Label isSelected={isSelected} text={text} />
        </Button>
    )
}

export interface IRequestCommandModel {
    requestId: 'requestId'
    employeeId: 'employeeId'
    comment: 'comment'
    commandType: 'commandType'
}

export const requestCommand: IRequestCommandModel = {
    requestId: 'requestId',
    employeeId: 'employeeId',
    comment: 'comment',
    commandType: 'commandType'
}

export const requestCommandModel = Object.freeze(requestCommand)

import * as React from 'react'
import { AuthService } from './authService'

export class LoginPage extends React.Component<any, any> {
    constructor(props) {
        super(props)
        const authService = new AuthService()
        authService.login()
    }

    render() {
        return null
    }
}

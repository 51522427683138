import * as React from 'react'
import { ROOT_URL } from '../../api/oDataProvider'
import { Typography } from '@material-ui/core'

export const FilesArrayLink = (props) => {
    if (!props.record.files || props.record.files.length < 1) return <div />
    const files: Array<any> = [...props.record.files]
    files.shift()
    return (
        <>
            {files.map((value) => (
                <a
                    download
                    href={`${ROOT_URL}/api/filestorage/${value.internalGuid}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Typography>{value.name}</Typography>
                </a>
            ))}
        </>
    )
}

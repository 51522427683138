import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ILetterOfAuthorityClassificationDictValueModel
    extends IStandardFieldsModel {
    value: 'value'
    lettersOfAuthority: 'lettersOfAuthority'
}

const letterOfAuthorityClassificationDictValue: ILetterOfAuthorityClassificationDictValueModel = {
    ...standardFieldsModel,
    value: 'value',
    lettersOfAuthority: 'lettersOfAuthority'
}

export const letterOfAuthorityClassificationDictValueModel = Object.freeze(
    letterOfAuthorityClassificationDictValue
)

export class FormatSettings {
    public format?: string
    public defaultFormat?: string

    public toString(): string {
        return '$format=' + (this.format || this.defaultFormat)
    }

    public reset(): void {
        this.format = undefined
    }

    public isSet(): boolean {
        return !!this.format || !!this.defaultFormat
    }
}

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface INotificationModel extends IStandardFieldsModel {
    userId: 'userId'
    user: 'user'

    notifyByEmail: 'notifyByEmail'
    notifyBySite: 'notifyBySite'

    concreteNotification: 'concreteNotification'

    emailSent: 'emailSent'
    emailSendAttempts: 'emailSendAttempts'
    isRead: 'isRead'

    type: 'type'
}

const notification: INotificationModel = {
    ...standardFieldsModel,
    userId: 'userId',
    user: 'user',

    notifyByEmail: 'notifyByEmail',
    notifyBySite: 'notifyBySite',

    concreteNotification: 'concreteNotification',

    emailSent: 'emailSent',
    emailSendAttempts: 'emailSendAttempts',
    isRead: 'isRead',

    type: 'type'
}

export const notificationModel = Object.freeze(notification)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICommentModel extends IStandardFieldsModel {
    parentCommentId: 'parentCommentId'
    parentComment: 'parentComment'

    commentListId: 'commentListId'
    commentList: 'commentList'

    text: 'text'
    creationTimeStamp: 'creationTimeStamp'
    childrenComments: 'childrenComments'
}

const comment: ICommentModel = {
    ...standardFieldsModel,
    parentCommentId: 'parentCommentId',
    parentComment: 'parentComment',

    commentListId: 'commentListId',
    commentList: 'commentList',

    text: 'text',
    creationTimeStamp: 'creationTimeStamp',
    childrenComments: 'childrenComments'
}

export const commentModel = Object.freeze(comment)

const IS_HIDDEN_PROJECT_TASK = 'SET_IS_HIDDEN_PROJECT_TASK'
const initialState = false

export const isHiddenProjectTaskAction = (isHiddenProjectTask) => {
    const action = {
        type: IS_HIDDEN_PROJECT_TASK,
        isHiddenProjectTask
    }
    return action
}

export const isHiddenProjectTaskReducer = (
    priviousState = initialState,
    action
) =>
    action.type === IS_HIDDEN_PROJECT_TASK
        ? action.isHiddenProjectTask
        : priviousState

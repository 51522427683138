import React from 'react'
import {
    TextField,
    SimpleShowLayout,
    DateField,
    ReferenceField
} from 'react-admin'
import {
    LetterOfAuthorityType,
    LetterOfAuthorityStatus
} from './lettersOfAuthorityForm'
import { Typography } from '@material-ui/core'
import { PersonField } from './lettersOfAuthorityList'
import { connect } from 'react-redux'
import { isEditedLettersOfAuthorityAction } from '../../reducers'
import { checkPermissionsDispatcherAndUp } from '../auth/authHelpers'
import { Resources } from '../../api/api'

const LetterStatusField = (props) => {
    const status = LetterOfAuthorityStatus.find(
        (status) => status.id === props.record.status
    )
    return status === undefined ? (
        <Typography>Без типа</Typography>
    ) : (
        <div style={{ marginTop: '16px', marginBottom: '8px' }}>
            <Typography style={{ color: 'rgb(117,117,117)' }} variant="caption">
                Тип
            </Typography>
            <Typography style={{ padding: '8px 0 4px' }}>
                {status.name}
            </Typography>
        </div>
    )
}

const LetterTypeField = (props) => {
    const type = LetterOfAuthorityType.find(
        (status) => status.id === props.record.type
    )
    return type === undefined ? (
        <Typography>Без типа</Typography>
    ) : (
        <div style={{ marginTop: '16px', marginBottom: '8px' }}>
            <Typography style={{ color: 'rgb(117,117,117)' }} variant="caption">
                Статус
            </Typography>
            <Typography style={{ padding: '8px 0 4px' }}>
                {type.name}
            </Typography>
        </div>
    )
}

const LettersOfAuthorityShowBase = (props) => {
    const { setIsEdited, setTitle, ...rest } = props
    const id = Number(localStorage.getItem('id'))
    const role = localStorage.getItem('role')

    if (
        id === props.record.createdById ||
        checkPermissionsDispatcherAndUp(role)
    )
        setIsEdited(true)

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'link',
        href: '/LetterOfAuthority',
        value: 'Договоры'
    })
    titles.push({
        type: 'text',
        value: `Карта договора номер ${props.record.number} - ${props.record.name}`
    })
    setTitle(titles)

    return (
        <SimpleShowLayout actions={null} toolbar={null} {...rest}>
            <TextField label="Номер" source="number" />
            <ReferenceField
                label="Проект"
                source="projectId"
                reference={Resources.Project}
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Имя" source="name" />
            <DateField label="Дата выдачи" source="issuedAt" />
            <DateField label="Дата истечения" source="expireAt" />
            <LetterStatusField />
            <LetterTypeField />
            <ReferenceField
                label="Классификация"
                source="classificationDictValueId"
                reference={Resources.LetterOfAuthorityClassificationDictValue}
                linkType="show"
            >
                <TextField source="value" />
            </ReferenceField>
            <ReferenceField
                label="Место предъявления"
                source="requesterDictValueId"
                reference={Resources.LetterOfAuthorityRequesterDictValue}
                linkType="show"
            >
                <TextField source="value" />
            </ReferenceField>
            <ReferenceField
                label="Доверитель"
                source="personTrusteeId"
                reference={Resources.Person}
            >
                <PersonField />
            </ReferenceField>
            <ReferenceField
                label="Доверенное лицо"
                source="toPersonId"
                reference={Resources.Person}
            >
                <PersonField />
            </ReferenceField>
        </SimpleShowLayout>
    )
}
export const LettersOfAuthorityShow = connect(undefined, {
    setIsEdited: isEditedLettersOfAuthorityAction
})(LettersOfAuthorityShowBase)

import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICustomerModel extends IStandardFieldsModel {
    displayName: 'displayName'
    personId: 'personId'
    person: 'person'
    companyId: 'companyId'
    company: 'company'

    projects: 'projects'
    requests: 'requests'
}

const customer: ICustomerModel = {
    ...standardFieldsModel,
    displayName: 'displayName',
    personId: 'personId',
    person: 'person',
    companyId: 'companyId',
    company: 'company',
    projects: 'projects',
    requests: 'requests'
}

export const customerModel = Object.freeze(customer)

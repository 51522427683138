import { createStyles, withStyles } from '@material-ui/core'
import * as React from 'react'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { FlipToFront as TemplateIcon } from '@material-ui/icons'

const addNewTaskTemplateStyles = (theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing.unit * 2
        }
    })

const AddNewTaskTemplateComponent = ({ record, classes }) => (
    <Button
        className={classes.button}
        component={Link}
        variant="raised"
        to={`/ProjectTaskTemplate/create?projectTemplateId=${record.id}`}
        label="Добавить шаблон задачи"
        title="Добавить шаблон задачи"
    >
        <TemplateIcon />
    </Button>
)
export const AddNewTaskTemplateButton = withStyles(addNewTaskTemplateStyles)(
    AddNewTaskTemplateComponent
)

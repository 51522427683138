import React from 'react'
import { TextInput, required, SimpleForm } from 'react-admin'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'
import { Create, Edit } from '../../../layout'
import { DefaultFormActions, ToolbarGeneral } from '../../../layout/bar'

const validate = (values) => {
    const errors: any = {}
    return errors
}

const ClassificationDictValueFormBase = (props: any) => {
    const { isEditToolbar, setTitle, classes, ...rest } = props

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Документы'
        })
        titles.push({
            type: 'link',
            href: '/LetterOfAuthorityClassificationDictValue',
            value: 'Классификация доверенностей'
        })
        titles.push({
            type: 'text',
            value: `Редактирование классификации №${props.record.id} - ${props.record.value}`
        })
        setTitle(titles)
    }

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    shouldParse
                    isEditToolbar={isEditToolbar}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <TextInput
                label="Классификация"
                source="value"
                validate={required()}
            />
        </SimpleForm>
    )
}

const ClassificationDictValueCreateBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'link',
        href: '/LetterOfAuthorityClassificationDictValue',
        value: 'Классификация доверенностей'
    })
    titles.push({
        type: 'text',
        value: 'Создание классификации'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <ClassificationDictValueFormBase {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const ClassificationDictValueCreate = enchanceCreate(
    ClassificationDictValueCreateBase
)

const ClassificationDictValueEditBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest} actions={<DefaultFormActions />}>
            <ClassificationDictValueFormBase {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const ClassificationDictValueEdit = enchanceEdit(
    ClassificationDictValueEditBase
)

import React from 'react'
import { CardActions } from 'react-admin'
import { EditButton, HistoryBackButton } from '../components/fields'
import {
    ToolbarLetterOfAuthorityCreate,
    ToolbarLetterOfAuthorityEdit,
    ToolbarAppUserCreate,
    ToolbarAppUserEdit,
    ToolbarRequestEdit,
    ToolbarProjectTask,
    ToolbarContactCreate,
    ToolbarContactEdit,
    ToolbarDefaultCreate,
    ToolbarCompany,
    ToolbarDefautlEdit,
    ActionDefaultShow,
    ActionLettersOfAuthority
} from './bars'
import { Resources } from '../api/api'
import {
    NavigateToDeclineRequestButton,
    NavigateToAssignRequestButton
} from '../components/requests/requestList'
import ToolbarProject from './bars/toolbarProject'

export const DefaultFormActions = (props) => (
    <CardActions {...props}>
        <HistoryBackButton variant="text" size="small" />
    </CardActions>
)

export const RequestEditActions = (props) => {
    const { isEdit, onHandleEditChange, data, canBeEdited, ...rest } = props
    return (
        <CardActions {...rest}>
            <NavigateToAssignRequestButton
                record={data}
                id={rest.id}
                basePath={rest.basePath}
            />
            <NavigateToDeclineRequestButton
                record={data}
                id={rest.id}
                basePath={rest.basePath}
            />
            {/* <ExtraInfoButton path={`/Request/${props.id}/comments`}  /> */}
            {!!canBeEdited && (
                <EditButton
                    isEdit={isEdit}
                    redirect="list"
                    onHandleEditChange={onHandleEditChange}
                />
            )}
            <HistoryBackButton variant="text" />
        </CardActions>
    )
}

const ToolbarEdit = (props) => {
    const { resource } = props

    if (resource === Resources.Person) return <ToolbarContactEdit {...props} />

    if (resource === Resources.LetterOfAuthority)
        return <ToolbarLetterOfAuthorityEdit {...props} />

    if (resource === Resources.ProjectTask)
        return <ToolbarProjectTask {...props} />

    if (resource === Resources.AppUser) return <ToolbarAppUserEdit {...props} />

    if (resource === Resources.Request) return <ToolbarRequestEdit {...props} />

    if (resource === Resources.Project) return <ToolbarProject {...props} />

    return <ToolbarDefautlEdit {...props} />
}

const ToolbarCreate = (props) => {
    const { resource } = props

    if (resource === Resources.AppUser)
        return <ToolbarAppUserCreate {...props} />

    if (resource === Resources.Person)
        return <ToolbarContactCreate {...props} />

    if (resource === Resources.LetterOfAuthority)
        return <ToolbarLetterOfAuthorityCreate {...props} />

    if (resource === Resources.Company) return <ToolbarCompany {...props} />

    if (resource === Resources.ProjectTask)
        return <ToolbarProjectTask {...props} />

    return <ToolbarDefaultCreate {...props} />
}

export const ToolbarGeneral = (props) => {
    const { isEditToolbar } = props
    if (isEditToolbar === true) return <ToolbarEdit {...props} />
    if (isEditToolbar === false) return <ToolbarCreate {...props} />
    return null
}

export const ActionGeneral = (props) => {
    const { resource } = props

    if (resource === Resources.Project) return <ActionDefaultShow {...props} />

    if (resource === Resources.LetterOfAuthority)
        return <ActionLettersOfAuthority {...props} />

    if (resource === Resources.Request) return <RequestEditActions {...props} />

    if (resource === Resources.ProjectTask) {
        return <ToolbarProjectTask {...props} />
    }

    return <DefaultFormActions {...props} />
}

import React, { useState, useEffect } from 'react'
import {
    WithPermissions,
    LongTextInput,
    SelectInput,
    TextInput,
    required,
    TabbedForm,
    FormTab,
    ReferenceManyField,
    TextField,
    Datagrid,
    DeleteButton,
    FormDataConsumer,
    GET_LIST
} from 'react-admin'
import { AddEmployeeButton } from './addEmployeeButton'
import { ToolbarGeneral } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Resources } from '../../api/api'
import Create from '../../layout/create'
import Edit from '../../layout/edit'
import oDataProvider from '../../api/oDataProvider'

const validate = (values) => {
    const errors: any = {}
    if (
        values.companyType == 'Company' &&
        (!values.inn || !/[0-9]+/.test(values.inn) || values.inn.length != 10)
    ) {
        errors.inn =
            'Неверный ИНН. Должен состоять из 10 цифр (для организаций).'
    }
    if (
        values.companyType == 'IndividualEntrepreneur' &&
        (!values.inn || !/[0-9]+/.test(values.inn) || values.inn.length !== 12)
    ) {
        errors.inn = 'Неверный ИНН. Должен состоять из 12 цифр (для ИП).'
    }
    if (
        values.companyType == 'Company' &&
        !errors.inn &&
        values.inn.length === 10 &&
        !values.kpp
    ) {
        errors.kpp = 'КПП обязателен для организаций.'
    } else if (
        values.kpp !== undefined &&
        !errors.inn &&
        (values.inn.length === 10 || values.inn.length === 12) &&
        (!values.kpp || !/[0-9]+/.test(values.kpp) || values.kpp.length !== 9)
    ) {
        errors.kpp = 'Неверный КПП (9 цифр)'
    }
    return errors
}

export const OrgTypes = [
    { id: 'Company', name: 'Компания' },
    { id: 'IndividualEntrepreneur', name: 'ИП' }
]

const setNavbarTitle = (props) => {
    const { setTitle, isEditToolbar } = props
    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контакты'
        })
        titles.push({
            type: 'link',
            href: '/Company',
            value: 'Компании'
        })
        titles.push({
            type: 'text',
            value: props.record.shortName
        })
        titles.push({
            type: 'text',
            value: `№ ${props.record.id}`
        })
        setTitle(titles)
    }
}

export const CompanyForm = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [showCheckBox, setShowCheckBox] = useState(true)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Контакты'
        })
        titles.push({
            type: 'link',
            href: '/Company',
            value: 'Компании'
        })
        titles.push({
            type: 'text',
            value: `Редактирование компании №${props.record.id} - ${props.record.shortName}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        const loader = async () => {
            const { data: customer } = await oDataProvider(
                GET_LIST,
                Resources.Customer,
                {
                    filter: { companyId: props.record.id }
                }
            )

            setShowCheckBox(customer.length === 0)
        }
        if (isEditToolbar) loader()
    }, [])

    return (
        <TabbedForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    showCheckBox={showCheckBox}
                    shouldParse
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <FormTab label="Основная информация">
                <SelectInput
                    label="Тип организации"
                    source="companyType"
                    choices={OrgTypes}
                    validate={required()}
                />
                <TextInput
                    fullWidth={true}
                    label="Короткое название организации (для списков)"
                    source="shortName"
                    validate={required()}
                />
                <TextInput
                    fullWidth={true}
                    label="Полное название организации (для документов)"
                    source="fullName"
                    validate={required()}
                />
                <TextInput label={'ИНН'} source={'inn'} validate={required()} />
                <FormDataConsumer>
                    {({ formData }) => (
                        <TextInput
                            style={{ width: 255 }}
                            label={'КПП'}
                            source={'kpp'}
                            validate={
                                formData.companyType === 'Company'
                                    ? required()
                                    : undefined
                            }
                        />
                    )}
                </FormDataConsumer>
                <LongTextInput
                    label="Заметки"
                    source="comments"
                    options={{ inputProps: { rows: 5 } }}
                />
            </FormTab>

            {rest.id && (
                <FormTab label="Сотрудники организации" path="employees">
                    <AddEmployeeButton {...rest} />
                    <ReferenceManyField
                        addLabel={false}
                        target="companyId"
                        reference={Resources.CompanyEmployee}
                    >
                        <Datagrid>
                            <TextField
                                label="Фамилия"
                                source="person.lastName"
                            />
                            <TextField label="Имя" source="person.firstName" />
                            <TextField
                                label="Отчество"
                                source="person.patronymicName"
                            />
                            <TextField label="Должность" source="jobPosition" />
                            <TextField label="Email" source="person.email" />
                            <TextField label="Телефон" source="person.phone" />
                            <DeleteButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            )}
        </TabbedForm>
    )
}

const CompanyCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Контакты'
    })
    titles.push({
        type: 'link',
        href: '/Company',
        value: 'Компании'
    })
    titles.push({
        type: 'text',
        value: 'Добавление компании'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <CompanyForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const CompanyCreateView = enchanceCreate(CompanyCreateViewBase)

const CompanyEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <CompanyForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const CompanyEditView = enchanceEdit(CompanyEditViewBase)

import { useEffect } from 'react'

export const getDeltaDay = (deltaDays) => {
    if (deltaDays !== 0) {
        let daysName = 'дней'
        if (deltaDays === 1 || (deltaDays > 20 && deltaDays % 10 === 1))
            daysName = 'день'
        else if (deltaDays % 10 > 1 && deltaDays % 10 < 5) daysName = 'дня'
        return `${deltaDays} ${daysName}`
    }
    return `${deltaDays} дней`
}

export const getDeltaTime = (time: string): string => {
    const currentDate = new Date()
    const sentDate = new Date(time)

    const timeDiff = currentDate.getTime() - sentDate.getTime()

    const deltaYears = currentDate.getFullYear() - sentDate.getFullYear()
    const deltaMonths = currentDate.getMonth() - sentDate.getMonth()
    const deltaDays = Math.round(timeDiff / (1000 * 3600 * 24))
    const deltaHours = Math.round(timeDiff / (1000 * 3600))
    const deltaMinutes = Math.round(timeDiff / (1000 * 60))
    const deltaSeconds = Math.round(timeDiff / 1000)

    if (deltaYears !== 0 && deltaDays > 364) {
        let yearsName = 'лет'
        if (deltaYears === 1 || (deltaYears > 20 && deltaYears % 10 === 1))
            yearsName = 'год'
        else if (deltaYears % 10 > 1 && deltaYears % 10 < 5) yearsName = 'года'
        return `${deltaYears} ${yearsName} назад`
    }
    if (deltaMonths !== 0 && deltaDays > 27) {
        let monthsName = 'месяцев'
        if (deltaMonths === 1) monthsName = 'месяц'
        else if (deltaMonths > 1 && deltaMonths < 5) monthsName = 'месяца'
        return `${deltaMonths} ${monthsName} назад`
    }
    if (deltaDays !== 0 && deltaHours > 23) {
        let daysName = 'дней'
        if (deltaDays === 1 || (deltaDays > 20 && deltaDays % 10 === 1))
            daysName = 'день'
        else if (deltaDays % 10 > 1 && deltaDays % 10 < 5) daysName = 'дня'
        return `${deltaDays} ${daysName} назад`
    }
    if (deltaHours !== 0 && deltaMinutes > 59) {
        let hoursName = 'часов'
        if (deltaHours === 1 || (deltaHours > 20 && deltaHours % 10 === 1))
            hoursName = 'час'
        else if (deltaHours % 10 > 1 && deltaHours % 10 < 5) hoursName = 'часа'

        return `${deltaHours} ${hoursName} назад`
    }
    if (deltaMinutes !== 0 && deltaSeconds > 59) {
        let minutesName = 'минут'
        if (
            deltaMinutes === 1 ||
            (deltaMinutes > 20 && deltaMinutes % 10 === 1)
        )
            minutesName = 'минута'
        else if (deltaMinutes % 10 > 1 && deltaMinutes % 10 < 5)
            minutesName = 'минуты'

        return `${deltaMinutes} ${minutesName} назад`
    }
    let secondsName = 'секунд'
    if (deltaSeconds === 1 || (deltaSeconds > 20 && deltaSeconds % 10 === 1))
        secondsName = 'секунда'
    else if (deltaSeconds % 10 > 1 && deltaSeconds % 10 < 5)
        secondsName = 'секунды'
    return `${deltaSeconds} ${secondsName} назад`
}

export const getViewableContent = (
    content: string,
    div: number
): Array<string> => {
    if (content.length <= div) {
        const ret: Array<string> = []
        ret.push(content)
        return ret
    }

    const rows: Array<string> = []
    rows.push('')

    const words = content.split(' ')
    for (let j = 0, i = 0; j < words.length; ++j) {
        if (words[j].length + rows[i].length <= div - 1) {
            rows[i] += words[j] + ' '
        } else {
            ++i
            --j
            rows.push('')
        }
    }
    return rows
}

export const useOutsideSetter = (ref, set, val) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                set(val)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

import * as React from 'react'
import { Button } from '@material-ui/core'
import { KeyboardArrowLeft as LeftArrowIcon } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

class HistoryBackButtonCmp extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    render() {
        const { variant, label } = this.props
        return (
            <Button
                size="small"
                variant={variant || 'contained'}
                onClick={this.handleClick}
            >
                <LeftArrowIcon />
                {label || 'Назад'}
            </Button>
        )
    }

    private handleClick() {
        this.props.history.goBack()
    }
}

export const HistoryBackButton = withRouter(HistoryBackButtonCmp)

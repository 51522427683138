import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IPaymentModel extends IStandardFieldsModel {
    amount: 'amount'
    tax: 'tax'
    comment: 'comment'

    invoiceId: 'invoiceId'
    invoice: 'invoice'
    projectId: 'projectId'
    project: 'project'
}

const payment: IPaymentModel = {
    ...standardFieldsModel,
    amount: 'amount',
    tax: 'tax',
    comment: 'comment',

    invoiceId: 'invoiceId',
    invoice: 'invoice',
    projectId: 'projectId',
    project: 'project'
}

export const paymentModel = Object.freeze(payment)

import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput
} from 'react-admin'
import { FullNameField } from '../fields/fullNameField'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'
import { Link } from 'react-router-dom'
import { Resources } from '../../api/api'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const CustomersFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Наименование" source="$contains(displayName)" />
    </Filter>
)

const CompanyLink = (props) => {
    if (props.record.companyId === null || props.record.companyId === undefined)
        return <span></span>
    return (
        <Link
            to={`/Company/${props.record.company.id}`}
            style={{ color: 'rgb(0,170,60)', textDecoration: 'none' }}
            onClick={(e) => e.stopPropagation()}
        >
            {props.record.company.shortName}
        </Link>
    )
}

export const CustomerListBase = (props: any) => {
    const classes = useStyles()

    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'text',
        value: 'Клиенты'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<CustomersFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField label="Наименование" source="displayName" />
                <ReferenceField
                    source="personId"
                    label="Физ. лицо"
                    reference={Resources.Person}
                    allowEmpty={true}
                >
                    <FullNameField />
                </ReferenceField>
                <CompanyLink label="Компания" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const CustomerList = enchanceList(CustomerListBase)

import { seoEnabledEntity, ISeoEnabledEntity } from '../'

export interface IPageModel extends ISeoEnabledEntity {
    content: 'content'

    parentId: 'parentId'
    parent: 'parent'

    order: 'order'
    isPublished: 'isPublished'
    isInMenu: 'isInMenu'
}

const page: IPageModel = {
    ...seoEnabledEntity,
    content: 'content',

    parentId: 'parentId',
    parent: 'parent',

    order: 'order',
    isPublished: 'isPublished',
    isInMenu: 'isInMenu'
}

export const pageModel = Object.freeze(page)

import * as React from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import { connect } from 'react-redux'
import {
    reset as resetAction,
    change as changeAction,
    submit as submitAction,
    isSubmitting as isSubmittingStatus
} from 'redux-form'
import {
    Button,
    CREATE,
    crudGetMatching as crudGetMatchingAction,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction,
    refreshView as refreshViewAction,
    showNotification as showNotificationAction,
    SaveButton,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required
} from 'react-admin'
import { Resources } from '../../api/api'
import { PersonEmployeeInput } from '../../utils/employee/PersonEmployeeInput'
import oDataProvider from '../../api/oDataProvider'

const formName = 'add-employee-form'

const validate = (values) => {
    const errors: any = {}
    return errors
}

class AddEmployeeButtonComponent extends React.Component<any, any> {
    constructor(props) {
        super(props)

        this.state = {
            error: false,
            showDialog: false
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    public render() {
        const { showDialog } = this.state
        const { color, isSubmitting, label, variant, record, rest } = this.props

        return (
            <>
                <Button
                    style={{ marginTop: 20 }}
                    color={color || 'secondary'}
                    variant={variant || 'contained'}
                    label={label || 'Добавить сотрудника'}
                    onClick={this.handleClick}
                />
                <Dialog
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleClose}
                >
                    <DialogTitle>Сотрудник</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            record={{}}
                            form={formName}
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            validate={validate}
                        >
                            <PersonEmployeeInput
                                fullWidth
                                label="Существующий контакт"
                                {...rest}
                            />
                            <TextInput
                                fullWidth={true}
                                label="Должность"
                                source="jobPosition"
                                validate={required()}
                                {...rest}
                            />

                            <FormDataConsumer fullWidth={true}>
                                {({ formData, ...rest }) =>
                                    !formData.personId && (
                                        <>
                                            <TextInput
                                                fullWidth={true}
                                                label="Фамилия"
                                                source="lastName"
                                                validate={required()}
                                                {...rest}
                                            />
                                            <TextInput
                                                fullWidth={true}
                                                label="Имя"
                                                source="firstName"
                                                validate={required()}
                                                {...rest}
                                            />
                                            <TextInput
                                                fullWidth={true}
                                                label="Отчетство"
                                                source="patronymicName"
                                                {...rest}
                                            />
                                            <TextInput
                                                fullWidth={true}
                                                label="Эл. почта"
                                                source="email"
                                                {...rest}
                                            />
                                            <TextInput
                                                fullWidth={true}
                                                label="Телефон"
                                                source="phone"
                                                {...rest}
                                            />
                                        </>
                                    )
                                }
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleClose}
                        />
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    private handleClick() {
        this.setState({ showDialog: true })
    }

    private handleClose() {
        const { reset } = this.props
        reset(formName)
        this.setState({ showDialog: false })
    }

    private handleSaveClick() {
        const { submit } = this.props
        submit(formName)
    }

    private async handleSubmit(values) {
        const {
            change,
            crudGetMatching,
            fetchStart,
            fetchEnd,
            refreshView,
            reset,
            showNotification,
            record
        } = this.props

        fetchStart()
        const action = CREATE

        await oDataProvider(action, Resources.CompanyEmployee, {
            id: values.id,
            data: {
                ...values,
                companyId: record.id
            }
        })
            .then(({ data }) => {
                this.setState({ showDialog: false })
                reset(formName)
                refreshView()
            })
            .catch((error) => {
                showNotification(error.message, 'error')
            })

        fetchEnd()
    }
}

const mapStateToProps = (state) => ({
    isSubmitting: isSubmittingStatus(formName)(state)
})

export const AddEmployeeButton = connect(mapStateToProps, {
    change: changeAction,
    crudGetMatching: crudGetMatchingAction,
    fetchEnd: fetchEndAction,
    fetchStart: fetchStartAction,
    refreshView: refreshViewAction,
    reset: resetAction,
    showNotification: showNotificationAction,
    submit: submitAction
})(AddEmployeeButtonComponent)

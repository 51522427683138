import * as React from 'react'
import { UserManager } from 'oidc-client'
import { Redirect } from 'react-router-dom'
import { AuthConfiguration } from './authConfiguration'

export class Callback extends React.Component<any, any> {
    state = { authorized: false, error: undefined }
    userManager = new UserManager(AuthConfiguration)

    constructor(props) {
        super(props)

        console.log('-===CALLBACK===-')
        this.userManager.signinRedirectCallback().then(
            (user) => {
                if (user && user.access_token) {
                    localStorage.setItem('token', user.access_token)
                    localStorage.setItem('role', user.profile.role)
                    this.setState({ authorized: true, error: false })
                }
                this.setState({ authorized: false, error: true })
            },
            (error) => {
                console.error(error)
                this.setState({ authorized: false, error })
            }
        )
    }

    componentDidMount() {}

    render() {
        if (this.state.authorized) {
            return <Redirect to="/" />
        }

        // todo: show spinner
        return null
    }
}

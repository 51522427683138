import * as React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers/titleReducer'

const useStyles = makeStyles({
    evenRow: {
        backgroundColor: 'rgb(247, 250, 255)'
    }
})

const LookupsFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="name" />
    </Filter>
)

const LookupListBase = (props: any) => {
    const classes = useStyles()
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Настройка процесса'
    })
    titles.push({
        type: 'text',
        value: 'Справочники'
    })
    setTitle(titles)

    return (
        <List
            {...rest}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<LookupsFilter />}
        >
            <Datagrid rowClick="edit" classes={{ rowEven: classes.evenRow }}>
                <TextField source="id" />
                <TextField label="Название" source="name" />
                <TextField label="Системное имя" source="systemName" />
            </Datagrid>
        </List>
    )
}
const enchanceList = connect(undefined, {
    setTitle: titleAction
})
export const LookupList = enchanceList(LookupListBase)

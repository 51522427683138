import React, { useState, useEffect, useRef } from 'react'
import {
    Typography,
    FormControl,
    TextField,
    InputAdornment,
    IconButton,
    Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
    CREATE,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction,
    refreshView as refreshViewAction,
    showNotification as showNotificationAction
} from 'react-admin'
import {
    reset as resetAction,
    change as changeAction,
    submit as submitAction,
    isSubmitting as isSubmittingStatus
} from 'redux-form'
import {
    Send as SendIcon,
    Reply as ReplyIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons/'
import { Resources } from '../../api/api'
import { connect } from 'react-redux'
import oDataProvider from '../../api/oDataProvider'
import commentsData from './commentsData'

const formName = 'create-comment-form'

const useStyles = makeStyles({
    root: {
        paddingTop: '1em',
        width: '50%'
    },
    commentBox: {
        paddingTop: '1em',
        display: 'flex'
    },
    fullName: {
        fontWeight: 700
    },
    date: {
        color: 'rgb(92, 92, 92)'
    },
    repliedComments: {
        paddingLeft: '0.8em'
    },
    replyInput: {
        paddingLeft: '0.77em',
        paddingRight: 0,
        paddingTop: '0.8em',
        width: '98.6%'
    },
    buttons: {
        paddingTop: '3px'
    },
    button: {
        // paddingLeft: "1em"
        border: 'none',
        backgroundColor: 'white',
        color: '#A7A7A7',
        paddingLeft: 0,
        cursor: 'pointer',
        '&:hover': {
            color: '#868686'
        },
        '&:active, &:focus': {
            color: '#505050',
            outline: 'none'
        },
        '&::-moz-focus-inner': {
            border: 0
        }
    }
})

const CommentFieldBase = (props) => {
    {
        /* Поле ввода коммента */
    }
    const { openedShow, classes, parentId, defaultValue } = props

    if (!openedShow) return null

    const [comment, setComment] = useState(defaultValue)
    const [disabled, setDisabled] = useState(true)

    const onHandleChange = (event) => {
        const value = event.target.value
        setComment(value)
        if (value.length === 0) setDisabled(true)
        else setDisabled(false)
    }

    const onHandleSubmit = async () => {
        const {
            fetchStart,
            fetchEnd,
            refreshView,
            reset,
            showNotification,
            commentListId,
            record
        } = props

        const action = CREATE
        console.log('----CREATE COMMENT props', props)

        fetchStart()
        await oDataProvider(action, Resources.Comment, {
            data: {
                commentListId: commentListId || record.commentListId,
                text: comment,
                parentCommentId: parentId === null ? null : parentId
            }
        })
            .then(() => {
                reset(formName)
                refreshView()
            })
            .catch((error) => {
                showNotification(error.message, 'error')
            })
        fetchEnd()
    }
    return (
        <FormControl variant="outlined" className={classes} fullWidth>
            {' '}
            {/* Текстовое поле для коммента */}
            <TextField
                value={comment}
                defaultValue={defaultValue}
                onChange={onHandleChange}
                label="Комментарий"
                multiline
                variant="outlined"
                id="outlined-adornment-comment"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={onHandleSubmit}
                                disabled={disabled}
                                color="primary"
                                aria-label="comment field"
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </FormControl>
    )
}

const mapStateToProps = (state) => ({
    isSubmitting: isSubmittingStatus(formName)(state)
})
const CommentField = connect(mapStateToProps, {
    change: changeAction,
    fetchEnd: fetchEndAction,
    fetchStart: fetchStartAction,
    refreshView: refreshViewAction,
    reset: resetAction,
    showNotification: showNotificationAction,
    submit: submitAction
})(CommentFieldBase)

const AnsverButton = ({
    classes,
    setIsOpenedCommentInput,
    isOpenedCommentInput,
    setIsOpenedRepliedComments,
    isOpenedRepliedComments
}) => (
    <button
        className={classes.button}
        color="gray"
        onClick={(e) => {
            e.preventDefault()
            setIsOpenedCommentInput(!isOpenedCommentInput)
            setIsOpenedRepliedComments(
                isOpenedCommentInput ? isOpenedRepliedComments : true
            )
        }}
    >
        <Typography className={classes.button}>
            {isOpenedCommentInput ? 'Убрать ввод' : 'Ответить'}&nbsp;
        </Typography>
    </button>
)

const OpenReplyButton = ({
    classes,
    setIsOpenedRepliedComments,
    isOpenedRepliedComments,
    setIsOpenedCommentInput
}) => (
    <button
        className={classes.button}
        color="gray"
        onClick={(e) => {
            e.preventDefault()
            setIsOpenedRepliedComments(!isOpenedRepliedComments)
            setIsOpenedCommentInput(false)
        }}
    >
        <Typography className={classes.button}>
            {isOpenedRepliedComments ? 'Скрыть' : 'Ответы'}
        </Typography>
    </button>
)

const Comment = ({ comment, classes, isReplied, parentId, commentListId }) => {
    const [isOpenedComments, setIsOpenedComments] = useState(true)
    const [isOpenedCommentInput, setIsOpenedCommentInput] = useState(false)
    return (
        <div className={isReplied ? classes.repliedComments : undefined}>
            <div className={classes.commentBox}>
                <Typography variant="subtitle2" className={classes.fullName}>
                    {comment.fullName} &nbsp;
                </Typography>
                <Typography variant="subtitle2" className={classes.date}>
                    {comment.date}
                </Typography>
            </div>
            <Typography variant="subtitle2">{comment.comment}</Typography>
            {!isReplied && (
                <AnsverButton
                    classes={classes}
                    setIsOpenedCommentInput={setIsOpenedCommentInput}
                    isOpenedCommentInput={isOpenedCommentInput}
                    setIsOpenedRepliedComments={setIsOpenedComments}
                    isOpenedRepliedComments={isOpenedComments}
                />
            )}
            {!isReplied &&
                !!comment.childrenComments &&
                comment.childrenComments.length > 0 && (
                    <OpenReplyButton
                        classes={classes}
                        setIsOpenedCommentInput={setIsOpenedCommentInput}
                        setIsOpenedRepliedComments={setIsOpenedComments}
                        isOpenedRepliedComments={isOpenedComments}
                    />
                )}
            {isOpenedComments &&
                !!comment.childrenComments &&
                comment.childrenComments.map((value) => (
                    <Comment
                        comment={value}
                        classes={classes}
                        isReplied={true}
                        parentId={parentId}
                        commentListId={commentListId}
                    />
                ))}
            {isOpenedCommentInput && (
                <CommentField
                    parentId={parentId}
                    openedShow={isOpenedCommentInput}
                    classes={classes.replyInput}
                    defaultValue={`${comment.fullName.trim()}, `}
                    commentListId={commentListId}
                />
            )}
        </div>
    )
}

const LoadMore = ({ updateIterator, classes }) => {
    return (
        <button
            className={classes.button}
            style={{ paddingTop: '16px' }}
            onClick={(e) => {
                e.preventDefault()
                updateIterator()
            }}
        >
            <Typography
                className={classes.button}
                style={{ color: 'black', fontWeight: 'bold' }}
            >
                Загрузить еще комментарии
            </Typography>
        </button>
    )
}

export default (props) => {
    {
        /* Модуль с комментариями */
    }
    const { commentListId } = props
    const classes = useStyles('')

    const [commentsPage, setCommentsPage] = useState(1)
    const updateIterator = () => setCommentsPage(commentsPage + 1)

    const [isAnyMoreComments, setIsAnyMoreComments] = useState(false)

    const [comments, setComments]: any = useState([])

    useEffect(() => {
        const loader = async (page) => {
            const pageSize = 10
            const temp: any = []
            await commentsData(commentListId, temp, page)
            if (temp.length < pageSize) setIsAnyMoreComments(false)
            setComments(comments.concat(temp))
        }
        loader(commentsPage)
    }, [commentsPage])
    console.warn(comments)
    return (
        <div className={classes.root}>
            <CommentField
                parentId={null}
                openedShow={true}
                classes={null}
                {...props}
            />
            {comments.map((comment) => (
                <Comment
                    key={`comment-${comment.id}`}
                    commentListId={commentListId}
                    comment={comment}
                    classes={classes}
                    isReplied={false}
                    parentId={comment.id}
                />
            ))}
            {isAnyMoreComments && (
                <LoadMore updateIterator={updateIterator} classes={classes} />
            )}
        </div>
    )
}

import * as React from 'react'
import { MenuItem } from '@material-ui/core'

export class AutocompleteArrayInputOption extends React.Component<any, any> {
    public handleClick = (event) => {
        this.props.onSelect(this.props.option, event)
    }

    public render() {
        const { children, isFocused, isSelected, onFocus } = this.props

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{ fontWeight: isSelected ? 500 : 400 }}
            >
                {children}
            </MenuItem>
        )
    }
}

import { FilterClause } from '../FilterClause'
import { FilterObj } from '../FilterObj'
import { PrecedenceGroup } from '../PrecedenceGroup'

export class FilterSettings {
    public Filters: any[]
    public DefaultFilters: any[]
    public CapturedFilter: any[]

    constructor() {
        this.Filters = []
        this.DefaultFilters = []
        this.CapturedFilter = []
    }

    public toString() {
        let allFilters: any[]
        let i: any
        let filter: any

        allFilters = []
        filter = '$filter='

        if (this.DefaultFilters.length > 0) {
            for (i = 0; i < this.DefaultFilters.length; i++) {
                allFilters.push(this.DefaultFilters[i])
            }
        }

        for (i = 0; i < this.Filters.length; i++) {
            allFilters.push(this.Filters[i])
        }

        for (i = 0; i < allFilters.length; i++) {
            filter += allFilters[i].toString(i)
        }

        return filter
    }

    public reset() {
        this.Filters = []
        if (this.CapturedFilter.length > 0) {
            for (const filter of this.CapturedFilter) {
                this.Filters.push(filter)
            }
        }
    }

    public fullReset(): void {
        this.Filters = []
        this.CapturedFilter = []
    }

    public loadFromJson(filterSettings: any): void {
        let i: any
        let filter: any

        for (i = 0; i < filterSettings.Filters.length; i++) {
            filter = filterSettings.Filters[i]
            const fO: FilterObj = new FilterObj(
                this.loadFilterObj(filter.filterObj),
                filter.logicalOperator
            )
            this.Filters.push(fO)
        }

        for (i = 0; i < filterSettings.DefaultFilters.length; i++) {
            filter = filterSettings.DefaultFilters[i]
            this.DefaultFilters.push(
                new FilterObj(
                    this.loadFilterObj(filter.filterObj),
                    filter.logicalOperator
                )
            )
        }
    }

    public isSet(): boolean {
        return this.Filters.length > 0 || this.DefaultFilters.length > 0
    }

    private loadPrecedenceGroup(precedenceGroup: any): any {
        let j: any
        let group: any
        let currentClause: any

        group = new PrecedenceGroup()

        for (j = 0; j < precedenceGroup.clauses.length; j++) {
            currentClause = precedenceGroup.clauses[j]
            group.clauses.push(
                new FilterObj(
                    this.loadFilterObj(currentClause.filterObj),
                    currentClause.logicalOperator
                )
            )
        }

        return group
    }

    private loadFilterObj(currentFilter: any): any {
        if (currentFilter.clauses !== undefined) {
            return this.loadPrecedenceGroup(currentFilter)
        }

        let key: any
        const newFilterClause = new FilterClause('')

        for (key in currentFilter) {
            if (currentFilter.hasOwnProperty(key)) {
                newFilterClause[key] = currentFilter[key]
            }
        }

        return newFilterClause
    }
}

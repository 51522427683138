import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface IRequestTimelineEventModel extends IStandardFieldsModel {
    requestId: 'requestId'
    request: 'request'

    timelineEventId: 'timelineEventId'
    timelineEvent: 'timelineEvent'
}

const requestTimelineEvent: IRequestTimelineEventModel = {
    ...standardFieldsModel,
    requestId: 'requestId',
    request: 'request',

    timelineEventId: 'timelineEventId',
    timelineEvent: 'timelineEvent'
}

export const requestTimelineEventModel = Object.freeze(requestTimelineEvent)

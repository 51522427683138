import { standardFieldsModel, IStandardFieldsModel } from '../'

export interface ICompanyModel extends IStandardFieldsModel {
    companyType: 'companyType'
    shortName: 'shortName'
    fullName: 'fullName'
    inn: 'inn'
    kpp: 'kpp'
    comments: 'comments'
    employees: 'employees'
    contactInformation: 'contactInformation'
}

const company: ICompanyModel = {
    ...standardFieldsModel,
    companyType: 'companyType',
    shortName: 'shortName',
    fullName: 'fullName',
    inn: 'inn',
    kpp: 'kpp',
    comments: 'comments',
    employees: 'employees',
    contactInformation: 'contactInformation'
}

export const companyModel = Object.freeze(company)

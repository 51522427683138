import React, { useEffect, useState } from 'react'
import {
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    required,
    FormDataConsumer,
    DisabledInput,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { Resources } from '../../api/api'
import { ToolbarGeneral } from '../../layout'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons/'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { SelectAndSearchInput } from '../fields/selectAndSearchInput'
import Edit from '../../layout/edit'
import Create from '../../layout/create'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    dates: {
        marginTop: 36,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& div': {
            width: 250,
            marginRight: 24
        }
    },
    warning: {
        width: '187%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const validate = (values) => {
    const erros: any = {}
    return erros
}

export const InvoiceStatuses = [
    { id: 'Issued', name: 'Сформирован' },
    { id: 'Sent', name: 'Отправлен' },
    { id: 'WaitingForPayment', name: 'Ожидает оплаты' },
    { id: 'Paid', name: 'Оплачен' },
    { id: 'Cancelled', name: 'Отменен' }
]

const CustomerInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет клиентов *&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const CustomerInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Клиент"
            source="customerId"
            reference="Customer"
            optionText="displayName"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<CustomerInputLabel style={props.classes} />}
        />
    )
}

const ProjectInputLabel = (props) => {
    return (
        <div className={props.style.warning}>
            <div>Нет проектов *&thinsp;</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}
const ProjectInput = (props) => {
    const { atLeastOne } = props
    return atLeastOne ? (
        <SelectAndSearchInput
            label="Проект"
            source="projectId"
            reference="Project"
            optionText="name"
            alwaysOn={true}
            multi={false}
        />
    ) : (
        <DisabledInput
            className={props.classes.inputs}
            label={<ProjectInputLabel style={props.classes} />}
        />
    )
}

export const InvoiceFormBase = (props: any) => {
    const { isEditToolbar, setTitle, ...rest } = props
    const [atLeastOneCustomer, setAtLeastOneCustomer] = useState(false)
    const [atLeastOneProject, setAtLeastOneProject] = useState(false)

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/Invoice',
            value: 'Счета на оплату'
        })
        titles.push({
            type: 'text',
            value: `Редактирование счета на оплату №${props.record.id} - ${props.record.title}`
        })
        setTitle(titles)
    }

    useEffect(() => {
        ;(async () => {
            fetchStart()
            const action = GET_LIST
            const { data: customer } = await oDataProvider(
                action,
                Resources.Customer,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            const { data: project } = await oDataProvider(
                action,
                Resources.Project,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            customer.length > 0
                ? setAtLeastOneCustomer(true)
                : setAtLeastOneCustomer(false)
            project.length > 0
                ? setAtLeastOneProject(true)
                : setAtLeastOneProject(false)
            fetchEnd()
        })()
    }, [])

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    disabled={!atLeastOneCustomer || !atLeastOneProject}
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <TextInput
                label="Название счета"
                source="title"
                validate={required()}
            />
            <ProjectInput atLeastOne={atLeastOneProject} {...rest} />
            <CustomerInput atLeastOne={atLeastOneCustomer} {...rest} />
            <NumberInput
                label="Сумма счета"
                source="requestedAmount"
                validate={required()}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={InvoiceStatuses}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.status === 3 && (
                        <NumberInput
                            label="Уплаченная сумма"
                            source="paidAmount"
                            {...rest}
                            validate={required()}
                        />
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    )
}
export const InvoiceForm = withStyles(styles as any)(InvoiceFormBase)

export const InvoiceCreateViewBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Работа'
    })
    titles.push({
        type: 'link',
        href: '/Invoice',
        value: 'Счета на оплату'
    })
    titles.push({
        type: 'text',
        value: 'Создание счета на оплату'
    })
    setTitle(titles)

    return (
        <Create title="" {...rest}>
            <InvoiceForm {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const InvoiceCreateView = enchanceCreate(InvoiceCreateViewBase)

const InvoiceEditViewBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest}>
            <InvoiceForm {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const InvoiceEditView = enchanceEdit(InvoiceEditViewBase)

import * as React from 'react'
import {
    SimpleForm,
    TextInput,
    TextField,
    LongTextInput,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    DateInput
} from 'react-admin'
import { Resources } from '../../api/api'
import { parse } from 'query-string'
// import {DateInput} from "../ra-date-inputs/dateInputs";
import Edit from '../../layout/edit'
import Create from '../../layout/create'

const validate = (values) => {
    const erros: any = {}
    return erros
}

const PhaseForm = (props: any) => (
    <SimpleForm validate={validate} {...props}>
        <TextInput source={'title'} label={'Название'} />
        <LongTextInput
            source={'description'}
            label={'Описание'}
            options={{ inputProps: { rows: 10 } }}
        />

        <DateInput
            label="Дата начала"
            source="startDate"
            options={{ dateFormat: 'yyyy-MM-dd' }}
        />
        <DateInput
            label="Дата завершения"
            source="endDate"
            options={{ dateFormat: 'yyyy-MM-dd' }}
        />

        {!props.record.projectId && (
            <ReferenceInput
                label="Проект"
                source="projectId"
                reference={Resources.Project}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        )}

        {props.record.projectId && (
            <ReferenceField
                label="Проект"
                source="projectId"
                reference={Resources.Project}
            >
                <TextField source="name" />
            </ReferenceField>
        )}

        <ReferenceInput
            label="Процедура"
            source="procedureId"
            reference={Resources.Procedure}
        >
            <SelectInput optionText="title" />
        </ReferenceInput>

        {props.record.phaseTemplateId && (
            <ReferenceField
                label="Создан из шаблона"
                source="phaseTemplateId"
                reference={Resources.PhaseTemplate}
            >
                <TextField source="title" />
            </ReferenceField>
        )}
    </SimpleForm>
)

export const PhaseCreateView = (props: any) => {
    const { projectId: projectIdString } = parse(props.location.search)
    const projectId = projectIdString
        ? parseInt(projectIdString.toString(), 10)
        : ''
    const redirect = projectId ? `/Project/${projectId}/phases` : false

    return (
        <Create title="Создание этапа" {...props}>
            <PhaseForm
                {...props}
                defaultValue={{ projectId: projectId }}
                redirect={redirect}
            />
        </Create>
    )
}

export const PhaseEditView = (props: any) => (
    <Edit title="Редактирование этапа" {...props}>
        <PhaseForm {...props} />
    </Edit>
)

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AuthService } from './components/auth/authService'

const authService = new AuthService()

authService.getUser().then((user) => {
    if (user) {
        ReactDOM.render(<App />, document.getElementById('root') as HTMLElement)
    } else {
        authService.login()
    }
})

serviceWorker.unregister()

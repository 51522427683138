import * as React from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import { connect } from 'react-redux'
import {
    reset as resetAction,
    change as changeAction,
    submit as submitAction,
    isSubmitting as isSubmittingStatus
} from 'redux-form'
import {
    Button,
    FileInput,
    FileField,
    CREATE,
    crudGetMatching as crudGetMatchingAction,
    fetchEnd as fetchEndAction,
    fetchStart as fetchStartAction,
    refreshView as refreshViewAction,
    showNotification as showNotificationAction,
    SaveButton,
    SimpleForm,
    SelectInput,
    TextInput,
    required,
    DateInput
} from 'react-admin'
import { Resources } from '../../api/api'
import oDataProvider from '../../api/oDataProvider'

const formName = 'add-document-form'

export const DocumentVisibility = [
    { id: 'Owner', name: 'Только автору (мне)' },
    { id: 'Employee', name: 'Сотрудниками' },
    { id: 'Consultant', name: 'Консультантам' },
    { id: 'Client', name: 'Клиенту' },
    { id: 'All', name: 'Всем' }
]

export const DocumentEditAccess = [
    { id: 'Owner', name: 'Только автору (мне)' },
    { id: 'Employee', name: 'Сотрудниками' },
    { id: 'Consultant', name: 'Консультантам' },
    { id: 'Client', name: 'Клиенту' },
    { id: 'All', name: 'Всем' }
]

const validate = (values) => {
    const errors: any = {}
    return errors
}

class AddDocumentButtonComponent extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            showDialog: false
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSaveClick = this.handleSaveClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    public render() {
        const { showDialog } = this.state
        const { color, isSubmitting, label, variant, rest } = this.props
        return (
            <React.Fragment>
                <Button
                    style={{ marginTop: 20 }}
                    color={color || 'secondary'}
                    variant={variant || 'contained'}
                    label={label || 'Добавить документ'}
                    onClick={this.handleClick}
                />
                <Dialog
                    fullWidth={true}
                    open={showDialog}
                    onClose={this.handleClose}
                >
                    <DialogTitle>Документ</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            record={{}}
                            form={formName}
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            validate={validate}
                        >
                            <TextInput
                                fullWidth
                                label="Название документа"
                                source="name"
                            />
                            <TextInput
                                fullWidth
                                label="Описание"
                                source="description"
                            />
                            <DateInput label="Дата документа" source="atDate" />
                            <SelectInput
                                label="Видимость"
                                source="visibilityFlags"
                                choices={DocumentVisibility}
                                validate={required()}
                            />
                            <SelectInput
                                label="Редактирование"
                                source="editAccessFlags"
                                choices={DocumentEditAccess}
                                validate={required()}
                            />
                            <FileInput
                                source="attachments"
                                label="Файл"
                                multiple={false}
                                maxSize={100 * 1024 * 1024}
                                validate={required()}
                            >
                                <FileField source="src" title="title" />
                            </FileInput>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                            variant="text"
                        />
                        <Button
                            label="ra.action.cancel"
                            variant="text"
                            onClick={this.handleClose}
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    private handleClick() {
        this.setState({ showDialog: true })
    }

    private handleClose() {
        const { reset } = this.props
        reset(formName)
        this.setState({ showDialog: false })
    }

    private handleSaveClick() {
        const { submit } = this.props
        submit(formName)
    }

    private async handleSubmit(values) {
        const {
            fetchStart,
            fetchEnd,
            refreshView,
            reset,
            showNotification,
            record,
            bindTo
        } = this.props

        fetchStart()
        const action = CREATE

        const data: any = {}
        switch (bindTo) {
            case 'request':
                data.ownerRequestId = record.id
                break
            case 'project':
                data.ownerProjectId = record.id
                break
            default:
                data.ownerProjectId = record.id
        }
        await oDataProvider(action, Resources.VersionedFileAttachment, {
            data: { ...values, ...data }
        })
            .then(({ data }) => {
                this.setState({ showDialog: false })
                reset(formName)
                refreshView()
            })
            .catch((error) => {
                showNotification(error.message, 'error')
            })

        fetchEnd()
    }
}

const mapStateToProps = (state) => ({
    isSubmitting: isSubmittingStatus(formName)(state)
})

export const AddDocumentButton = connect(mapStateToProps, {
    change: changeAction,
    crudGetMatching: crudGetMatchingAction,
    fetchEnd: fetchEndAction,
    fetchStart: fetchStartAction,
    refreshView: refreshViewAction,
    reset: resetAction,
    showNotification: showNotificationAction,
    submit: submitAction
})(AddDocumentButtonComponent)

import React, { useState, useEffect } from 'react'
import {
    SimpleForm,
    SelectInput,
    TextInput,
    required,
    DateTimeInput,
    FormDataConsumer,
    SimpleFormIterator,
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin'
import {
    projectTaskModel as pt,
    procedureModel as pm,
    phaseModel as ph,
    companyEmployeeModel as ce,
    taskAssigneeModel as ta,
    personModel as pe,
    appUserModel as au
} from '../../api/models'
import { SelectAndSearchInput } from '../fields'
import { ToolbarGeneral, ActionGeneral } from '../../layout/bar'
import { connect } from 'react-redux'
import { titleAction } from '../../reducers'
import { makeStyles } from '@material-ui/styles'
import { Resources } from '../../api/api'
import { AutoComplete } from 'material-ui'
import { getFips } from 'crypto'

const useStyles = makeStyles({
    createBlock: {
        display: 'flex',
        '& > *': {
            width: 255,
            paddingRight: 10
        }
    }
})

const validate = (values) => {
    const errors: any = {}
    return errors
}

const getFio = (props) => {
    if (!props.person) return 'Господин неизвестный'
    const { firstName, lastName, patronymicName } = props.person
    const fio = [lastName || '', firstName || '', patronymicName || '']
        .filter((e) => !!e)
        .join(' ')
    return fio
}

const choices = [
    { id: 'High', name: 'Высокий' },
    { id: 'Middle', name: 'Средний' },
    { id: 'Low', name: 'Низкий' }
]

const TaskInputBase = (props) => {
    const { isEditToolbar, setTitle, ...rest } = props

    const classes = useStyles(``)
    const role = localStorage.getItem('role')

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Работа'
        })
        titles.push({
            type: 'link',
            href: '/ProjectTask',
            value: 'Задачи'
        })
        titles.push({
            type: 'text',
            value: `Редактирование задачи №${props.record.id} - ${props.record.title}`
        })
        setTitle(titles)
    }
    console.clear()
    console.log(`${ce.person}.${pe.user}.${au.id}`)
    return (
        <SimpleForm
            toolbar={<ToolbarGeneral isEditToolbar={isEditToolbar} {...rest} />}
            validate={validate}
            {...rest}
        >
            <TextInput
                label="Название"
                source={pt.title}
                validate={required()}
            />
            <TextInput
                label="Описание"
                source={pt.description}
                fullWidth
                rows={10}
            />
            <DateTimeInput label="Время начала" source={pt.startDate} />
            <DateTimeInput label="Время завершения" source={pt.endDate} />
            <SelectInput
                label="Приоритетность задачи"
                source={pt.priorityLevel}
                choices={choices}
                validate={required()}
            />
            {role === 'Сотрудник' && (
                <BooleanInput
                    label="Назначить себя исполнителем"
                    source={pt.taskAssignees}
                />
            )}
            <ArrayInput
                label="Исполнители"
                source={pt.taskAssignees}
                validate={required()}
            >
                <SimpleFormIterator validate={required()}>
                    <ReferenceInput
                        validate={required()}
                        label="Исполнитель"
                        source={ta.userId}
                        reference={Resources.CompanyEmployee}
                        // filter={{
                        //     customFilter: `${ce.person}/${pe.user} ne null`
                        // }}
                    >
                        <AutocompleteInput
                            optionText={getFio}
                            optionValue={`${ce.person}.${pe.user}.${au.id}`}
                        />
                    </ReferenceInput>
                    <TextInput label="Комментарий" source="comment" />
                </SimpleFormIterator>
            </ArrayInput>

            <SelectAndSearchInput
                label="Проект"
                source={pt.projectId}
                reference={Resources.Project}
            />
            <FormDataConsumer>
                {({ formData }) =>
                    !!formData &&
                    !!formData.projectId && (
                        <div style={{ display: 'block' }}>
                            <CheckboxGroupInput
                                choices={[
                                    { id: '1', name: 'Создать новую процедуру' }
                                ]}
                                source="createNewProcedure"
                                label=""
                            />
                            <FormDataConsumer>
                                {({ formData }) =>
                                    !!formData &&
                                    !!formData.createNewProcedure &&
                                    !!formData.createNewProcedure.length && (
                                        <div className={classes.createBlock}>
                                            <TextInput
                                                label="Название процедуры"
                                                source={`${pt.procedure},${pm.title}`}
                                            />
                                            <TextInput
                                                label="Описание процедуры"
                                                source={`${pt.procedure},${pm.description}`}
                                            />
                                            <DateTimeInput
                                                label="Дата начала"
                                                source={`${pt.procedure},${pm.startDate}`}
                                            />
                                            <DateTimeInput
                                                label="Дата конца"
                                                source={`${pt.procedure},${pm.endDate}`}
                                            />
                                        </div>
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData }) =>
                                    !!formData &&
                                    (!formData.createNewProcedure ||
                                        (!!formData.createNewProcedure &&
                                            !formData.createNewProcedure
                                                .length)) && (
                                        <SelectAndSearchInput
                                            label="Процедура"
                                            source={pt.procedureId}
                                            reference={Resources.Procedure}
                                            optionText="title"
                                        />
                                    )
                                }
                            </FormDataConsumer>

                            <CheckboxGroupInput
                                choices={[
                                    { id: '2', name: 'Создать новый этап' }
                                ]}
                                source="createNewPhase"
                                label=""
                            />
                            <FormDataConsumer>
                                {({ formData }) =>
                                    !!formData &&
                                    !!formData.createNewPhase &&
                                    !!formData.createNewPhase.length && (
                                        <div className={classes.createBlock}>
                                            <TextInput
                                                label="Название этапа"
                                                source={`${pt.phase},${ph.title}`}
                                            />
                                            <TextInput
                                                label="Описание этапа"
                                                source={`${pt.phase},${ph.description}`}
                                            />
                                            <DateTimeInput
                                                label="Дата начала"
                                                source={`${pt.phase},${ph.startDate}`}
                                            />
                                            <DateTimeInput
                                                label="Дата конца"
                                                source={`${pt.phase},${ph.endDate}`}
                                            />
                                        </div>
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData }) =>
                                    !!formData &&
                                    (!formData.createNewPhase ||
                                        (!!formData.createNewPhase &&
                                            !formData.createNewPhase
                                                .length)) && (
                                        <SelectAndSearchInput
                                            label="Этап"
                                            source={pt.phaseId}
                                            reference={Resources.Phase}
                                            optionText={ph.title}
                                        />
                                    )
                                }
                            </FormDataConsumer>
                        </div>
                    )
                }
            </FormDataConsumer>

            <BooleanInput label="Задача закрыта" source="isCompleted" />
        </SimpleForm>
    )
}

const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const TaskInput = enchanceEdit(TaskInputBase)

import React from 'react'
import { TextInput, required, SimpleForm } from 'react-admin'
import { connect } from 'react-redux'
import { titleAction } from '../../../reducers/titleReducer'
import { Create, Edit } from '../../../layout'
import { DefaultFormActions, ToolbarGeneral } from '../../../layout/bar'

const validate = (values) => {
    const errors: any = {}
    return errors
}

const RequesterDictValueFormBase = (props: any) => {
    const { isEditToolbar, setTitle, classes, ...rest } = props

    if (isEditToolbar) {
        const titles: Array<any> = []
        titles.push({
            type: 'text',
            value: 'Документы'
        })
        titles.push({
            type: 'link',
            href: '/LetterOfAuthorityClassificationDictValue',
            value: 'Места предъявлений'
        })
        titles.push({
            type: 'text',
            value: `Редактирование места представления №${props.record.id} - ${props.record.value}`
        })
        setTitle(titles)
    }

    return (
        <SimpleForm
            toolbar={
                <ToolbarGeneral
                    isEditToolbar={isEditToolbar}
                    shouldParse
                    {...rest}
                />
            }
            validate={validate}
            {...rest}
        >
            <TextInput
                label="Место предъявления"
                source="value"
                validate={required()}
            />
        </SimpleForm>
    )
}

const RequesterDictValueCreateBase = (props: any) => {
    const { setTitle, ...rest } = props

    const titles: Array<any> = []
    titles.push({
        type: 'text',
        value: 'Документы'
    })
    titles.push({
        type: 'link',
        href: '/LetterOfAuthorityClassificationDictValue',
        value: 'Места предъявлений'
    })
    titles.push({
        type: 'text',
        value: 'Создание места представления'
    })
    setTitle(titles)

    return (
        <Create {...rest}>
            <RequesterDictValueFormBase {...rest} isEditToolbar={false} />
        </Create>
    )
}
const enchanceCreate = connect(undefined, {
    setTitle: titleAction
})
export const RequesterDictValueCreate = enchanceCreate(
    RequesterDictValueCreateBase
)

const RequesterDictValueEditBase = (props: any) => {
    const { setTitle, ...rest } = props
    return (
        <Edit {...rest} actions={<DefaultFormActions />}>
            <RequesterDictValueFormBase {...props} isEditToolbar />
        </Edit>
    )
}
const enchanceEdit = connect(undefined, {
    setTitle: titleAction
})
export const RequesterDictValueEdit = enchanceEdit(RequesterDictValueEditBase)

import React, { useEffect, useState } from 'react'
import { getFio } from '../dataHelpers'
import {
    ReferenceInput,
    DisabledInput,
    SelectInput,
    required,
    GET_LIST,
    fetchStart,
    fetchEnd
} from 'react-admin'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import { Resources } from '../../api/api'
import { withStyles } from '@material-ui/styles'
import oDataProvider from '../../api/oDataProvider'

const styles = {
    warningContact: {
        width: '180%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputs: {
        minWidth: 252
    }
}

const PersonInputLabel = (props) => {
    return (
        <div className={props.style.warningContact}>
            <div>Нет контактов *</div>
            <div>
                <ArrowDropDownIcon />
            </div>
        </div>
    )
}

const PersonProvider = (props) => {
    const {
        choices,
        employee,
        candidatePerson,
        source,
        label,
        fullWidth,
        className
    } = props
    const result: Array<object> = []
    let flag = false

    choices.forEach((person) => {
        employee.some((emp) => {
            if (person.id === emp.personId) {
                flag = true
            }
        })
        if (!flag) result.push(person)
        flag = false
    })

    if (candidatePerson) result.push(candidatePerson)

    return (
        <SelectInput
            validate={props.required ? required() : false}
            source={source}
            label={label}
            fullWidth={fullWidth}
            className={className}
            optionText={getFio}
            choices={result}
        />
    )
}

const PersonEmployeeInputBase = (props) => {
    const [companyEmployee, setCompanyEmployee] = useState([])
    const [atLeastOnePerson, setAtLeastOnePerson] = useState(false)
    const candidatePerson =
        props.id && props.record ? props.record.person : null

    useEffect(() => {
        fetchStart()
        const action = GET_LIST
        ;(async () => {
            const { data: employee } = await oDataProvider(
                action,
                Resources.CompanyEmployee,
                {}
            )
            const { data: person } = await oDataProvider(
                action,
                Resources.Person,
                {
                    pagination: { page: 1, perPage: 1 }
                }
            )
            setCompanyEmployee(employee)
            person.length > 0
                ? setAtLeastOnePerson(true)
                : setAtLeastOnePerson(false)
        })()
        fetchEnd()
    }, [])

    return atLeastOnePerson ? (
        <ReferenceInput
            className={props.classes.inputs}
            label={props.label || 'Контакт'}
            source="personId"
            reference={Resources.Person}
            validate={props.required ? required() : false}
        >
            <PersonProvider
                candidatePerson={candidatePerson}
                employee={companyEmployee}
                required={props.required}
                id={props.id}
                fullWidth={props.fullWidth}
            />
        </ReferenceInput>
    ) : (
        <DisabledInput
            validate={required()}
            className={props.classes.inputs}
            label={<PersonInputLabel style={props.classes} />}
        />
    )
}

export const PersonEmployeeInput = withStyles(styles as any)(
    PersonEmployeeInputBase
)

import React, { useEffect, useState } from 'react'
import oDataProvider from '../../api/oDataProvider'
import { Resources } from '../../api/api'
import { RoleTypeById } from '../../api/publicOjects'
export const ROOT_URL: string = (window as any)['apiRootUrl'] || ''
import { GET_ONE } from 'react-admin'
import {
    Edit,
    SimpleForm,
    PasswordInput,
    FormDataConsumer,
    TextInput
} from 'react-admin'
import { Input, Button } from '@material-ui/core'
import { appUserModel as au, personModel as pm } from '../../api/models'

interface ITitleUser {
    role: string
    email: string
    firstName: string
    lastName: string
    patronymicName: string
    userId: number
}

interface IAppUser {
    oldPassword: string
    repeatPassword: string
    newPassword: string
}

const UserProfileEdit = () => {
    const [user, setUser] = useState<ITitleUser>({
        role: '',
        firstName: '',
        email: '',
        lastName: '',
        patronymicName: '',
        userId: 0
    })
    const pId = localStorage.getItem('personId')
    const [edit, setEdit] = useState<IAppUser>({
        oldPassword: '',
        repeatPassword: '',
        newPassword: ''
    })
    const [userID, setUserID] = useState<number>()
    useEffect(() => {
        const getPersonId = async () => {
            const pId = localStorage.getItem('personId')
            if (pId === undefined) return
            const { data: appUser } = await oDataProvider(
                GET_ONE,
                Resources.AppUser,
                {
                    id: localStorage.getItem('id')
                }
            )
            localStorage.setItem('personId', appUser.person.id)
            const role = RoleTypeById[appUser.userRoles[0].roleId]
            const person = appUser.person
            setUser({ role: role, ...person })
        }
        getPersonId()
    }, [])
    const editPass = () => {
        fetch(`${ROOT_URL}/api/users/change-password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: user.userId,
                newPassword: edit.newPassword,
                oldPassword: edit.oldPassword,
                repeatPassword: edit.repeatPassword
            })
        })
            .then((response) => response.json())
            .then((commits) => alert(commits[0].author.login))
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Input
                style={{ marginBottom: '10px' }}
                placeholder={'email'}
                value={user.email}
                disabled={true}
            />
            <Input
                style={{ marginBottom: '10px' }}
                placeholder={'Старый пароль'}
                value={edit.oldPassword}
                onChange={(e) =>
                    setEdit({ ...edit, oldPassword: e.target.value })
                }
            />
            <Input
                style={{ marginBottom: '10px' }}
                placeholder={'Пароль'}
                value={edit.newPassword}
                onChange={(e) =>
                    setEdit({ ...edit, newPassword: e.target.value })
                }
            />
            <Input
                style={{ marginBottom: '10px' }}
                placeholder={'Повтор пароля'}
                value={edit.repeatPassword}
                onChange={(e) =>
                    setEdit({ ...edit, repeatPassword: e.target.value })
                }
            />
            <Button variant="contained" onClick={editPass}>
                Изменить
            </Button>
        </div>
    )
}

export default UserProfileEdit
